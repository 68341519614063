import { Icon, List } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { INotifItem } from '../../../../../../store/alerts/types';
import { generateNotifications } from '../../../../../../utils/notification-utils';
import { IBudget, IClient, INotification } from '../../../../../../utils/types';
import { NotificationItem } from '../NotificationItem';

// tslint:disable-next-line: no-empty-interface
interface IShowNotificationsBoxProps {
  clients: IClient[];
  budgets: IBudget[];
  notifications: INotifItem[];
  length: number;
}

export const ShowNotificationsBox: React.FC<IShowNotificationsBoxProps> = ({ budgets, clients, notifications, length }) => {
  const [notifList, setNotifList] = useState<INotification[]>([]);

  useEffect(() => {
    setNotifList(generateNotifications(notifications, clients, budgets));
  },        [notifications, clients, budgets]);

  return (
    <div className="notifications-result-box">
      <div className="notifications-result-head">
        {notifList.length ? `Vous avez ${notifList.length} Nouvelles Notifications !` : 'vous n\'avez aucune notification'}
      </div>
      <div className="notifications-result-body">
        {notifList.length ? (
          <List dataSource={notifList} renderItem={item => <NotificationItem notif={item} />} />
        ) : (
          <Icon className="no-notifs" type="dropbox" />
        )}
      </div>
      <div className="notifications-result-foot">
        <Link to={'/notifications'}>
          >> Afficher toutes les Notifications
        </Link>
      </div>
    </div>
  );
};
