import { message } from 'antd';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as requests from '../../api';
import { parseAxiosError } from '../../utils/axios-utils';

import { addLogFileError, addLogFileSuccess, createNewFileError, createNewFileSuccess, dowloadFileError, dowloadFileSuccess, downloadFilesError, downloadFilesSuccess, fileDispatchTeamError, fileDispatchTeamSuccess, fileUpdatePriorityError, fileUpdatePrioritySuccess, getAllFiles, getAllFilesError, getAllFilesSuccess, getFilePreviewError, getFilePreviewSuccess, getFileRetouchLogsError, getFileRetouchLogsSuccess, getFilesToBeCheckedError, getFilesToBeCheckedSuccess, getFilesToBeSentError, getFilesToBeSentSuccess, getSingleFileError, getSingleFileSuccess, requestVersionPresignedURLError, requestVersionPresignedURLSuccess, updateFileStatusError, updateFileStatusSuccess, updateListFilesError, updateListFilesSuccess, uploadFileRequestUrlError, uploadFileRequestUrlSuccess } from './actions';
import { ADD_LOG_FILE, DOWNLOAD_FILE, DOWNLOAD_FILES, FILE_ADD_NEW, FILE_DISPATCH_TEAM, FILE_UPDATE_PRIORITY, FILE_UPDATE_STATUS, GET_BUDGET_FILES, GET_FILES_TO_BE_CHECKED, GET_FILES_TO_BE_SENT, GET_FILE_PREVIEW, GET_FILE_RETOUCH_LOGS, GET_SINGLE_FILE, IAddLogFileAction, IDownloadFilesAction, IFileAddNewAction, IFileDIspatchTeamAction, IFileGetPreviewAction, IFileUpdateStatus, IFileUpdateStatusAction, IGetBudgetFilesAction, IGetFileRetouchLogsAction, IGetFilesToBeCheckedAction, IGetFilesToBeSentAction, IGetSingleFile, IRequestVersionPresignedURLAction, IUpdateListFilesAction, IUploadRequestUrlAction, REQUEST_VERSION_PRESIGNED_URL, UPDATE_LIST_FILES, UPLOAD_FILE_REQUEST_URL } from './types';

/**
 * Request pre-signed URL
 */
function* requestUrl({payload}: IUploadRequestUrlAction) {
  try {
    const result = yield requests.requestPresignedURL(payload, yield select(state => state.auth.token));
    yield put(uploadFileRequestUrlSuccess(result.data));
  } catch (error) {
    yield put(uploadFileRequestUrlError(error));
  }
}

export function* watchRequestUrl() {
  yield takeLatest(UPLOAD_FILE_REQUEST_URL, requestUrl);
}

/**
 * Add new file
 */
function* createNewFile({ payload: fileInfos }: IFileAddNewAction) {
  try {
    yield requests.addFile(fileInfos, yield select(state => state.auth.token));
    message.success(`Le fichier ${fileInfos.name} a été ajouté avec succès`);
    yield put(createNewFileSuccess());
  } catch (error) {
    yield put(createNewFileError(error));
  }
}

export function* watchCreateNewFile() {
  yield takeLatest(FILE_ADD_NEW, createNewFile);
}

/**
 * Add modified file log
 */
function* addLogFile({ payload: logFile }: IAddLogFileAction) {
  try {
    yield requests.addLogFile(logFile, yield select(state => state.auth.token));
    message.success(`Le fichier ${logFile.name} a été ajouté avec succès`);
    yield put(addLogFileSuccess());
  } catch (error) {
    yield put(addLogFileError(error));
  }
}

export function* watchAddLogFile() {
  yield takeLatest(ADD_LOG_FILE, addLogFile);
}

/**
 * Get All files
 */
function* getAllBudgetFiles({payload: id }: IGetBudgetFilesAction) {
  try {
    const result = yield requests.getProjectFiles(id, yield select(state => state.auth.token));
    yield put(getAllFilesSuccess(result.data));
  } catch (error) {
    yield put(getAllFilesError(error));
  }
}
export function* watchGetAllFiles() {
  yield takeLatest(GET_BUDGET_FILES, getAllBudgetFiles);
}

/**
 * Get single file
 */
function* getSingleFile({ payload: fileID }: IGetSingleFile) {
  try {
    const result = yield requests.getFile(fileID, yield select(state => state.auth.token));
    yield put(getSingleFileSuccess(result.data));
  } catch (error) {
    yield put(getSingleFileError(error));
  }
}

export function* watchGetSingleFile() {
  yield takeLatest(GET_SINGLE_FILE, getSingleFile);
}

/**
 * Download files
 */
function* downloadFiles({ payload: folder }: IDownloadFilesAction) {
  try {
    const result = yield requests.downloadFiles(folder, yield select(state => state.auth.token));
    yield put(downloadFilesSuccess(result.data));
  } catch (error) {
      yield put(downloadFilesError(error));
      if (error.response.data.status) message.warning(error.response.data.message);
  }
}

export function* watchDownloadFiles() {
  yield takeLatest(DOWNLOAD_FILES, downloadFiles);
}

/**
 * Update File Status
 */
function* updateFileStatus({ payload: file }: IFileUpdateStatus) {
  try {
    const result = yield requests.changeFileStatus(file, yield select(state => state.auth.token));
    message.success(result.data.message);
    yield put(updateFileStatusSuccess());
    yield put(getAllFiles(file.relativeBudget));
  } catch (error) {
    yield put(updateFileStatusError(error));
  }
}

export function* watchUpdateFileStatus() {
  yield takeLatest(FILE_UPDATE_STATUS, updateFileStatus);
}

/**
 * Update list of files Status
 */
function* updateListFiles({ payload: file }: IUpdateListFilesAction) {
  try {
    yield requests.updateFiles(file, yield select(state => state.auth.token));
    yield put(updateListFilesSuccess());
    yield put(getAllFiles(file.relativeBudget));
  } catch (errors) {
    errors.general.forEach((err: string) => {
      message.warning(err);
    });
    yield put(updateListFilesError(errors));
  }
}

export function* watchUpdateListFiles() {
  yield takeLatest(UPDATE_LIST_FILES, updateListFiles);
}

/**
 * Update file (Dispatch file team)
 */
function* fileDispatchTeam({ payload: team }: IFileDIspatchTeamAction) {
  try {
    yield requests.dispatchTeam(team, yield select(state => state.auth.token));
    if (team.budgetId) yield put(getAllFiles(team.budgetId));
    yield put(fileDispatchTeamSuccess());
  } catch (error) {
    yield put(fileDispatchTeamError(error));
  }
}

export function* watchFileDispatchTeam() {
  yield takeLatest(FILE_DISPATCH_TEAM, fileDispatchTeam);
}

/**
 * Update file priority
 */
function* fileUpdatePriority({ payload: file }: IFileUpdateStatusAction) {
  try {
    yield requests.updateFilePrio(file, yield select(state => state.auth.token));
    yield put(fileUpdatePrioritySuccess());
    message.destroy();
    message.success(`La priorité du fichier ${file.filename} a été changée avec succès`);
    if (file.projectId) yield put(getAllFiles(file.projectId));
  } catch (error) {
    yield put(fileUpdatePriorityError(parseAxiosError(error)));
  }
}

export function* watchFileUpdatePriority() {
  yield takeLatest(FILE_UPDATE_PRIORITY, fileUpdatePriority);
}

/**
 * Get File Preview
 */
function* getFilePreview({ payload: filePack }: IFileGetPreviewAction) {
  try {
    const response = yield requests.getThumbnail(filePack, yield select(state => state.auth.token));
    yield put(getFilePreviewSuccess(response.data));
  } catch (error) {
    yield put(getFilePreviewError(parseAxiosError(error)));
  }
}

export function* watchGetFilePreview() {
  yield takeLatest(GET_FILE_PREVIEW, getFilePreview);
}

/**
 * Get file retouched versions
 */
function* getFileLogs({ payload: term }: IGetFileRetouchLogsAction) {
  try {
    const res = yield requests.getFileRetouchLog(term, yield select(state => state.auth.token));
    yield put(getFileRetouchLogsSuccess(res.data));
  } catch (error) {
    yield put(getFileRetouchLogsError(error));
  }
}

export function* watchGetFileLogs() {
  yield takeLatest(GET_FILE_RETOUCH_LOGS, getFileLogs);
}

/**
 * Get brut file
 */
function* getBrutFile({ payload: term }: IGetFileRetouchLogsAction) {
  try {
    const res = yield requests.getBrutFileURL(term, yield select(state => state.auth.token));
    yield put(dowloadFileSuccess(res.data.url));
  } catch (error) {
    yield put(dowloadFileError(error));
  }
}

export function* watchGetBrutFile() {
  yield takeLatest(DOWNLOAD_FILE, getBrutFile);
}

/**
 * Request presigned URL for versionning
 */
function* getVersionPresignedUrl({ payload: terms }: IRequestVersionPresignedURLAction) {
  try {
    const res = yield requests.requestPresignedURL(terms, yield select(state => state.auth.token));
    yield put(requestVersionPresignedURLSuccess(res.data.url));
  } catch (error) {
    yield put(requestVersionPresignedURLError(error));
  }
}

export function* watchGetVersionPresignedUrl() {
  yield takeLatest(REQUEST_VERSION_PRESIGNED_URL, getVersionPresignedUrl);
}
/**
 * Load FIles to check
 */
function* getFilesToCheck({ payload: page }: IGetFilesToBeCheckedAction) {
  try {
    const res = yield requests.getFilesToCheck(page, yield select(state => state.auth.token));
    yield put(getFilesToBeCheckedSuccess({
      files: res.data.projects,
      total: res.data.versionsTotales.number,
    }));
  } catch (error) {
    yield put(getFilesToBeCheckedError(error));
  }
}

export function* watchGetFilesToCheck() {
  yield takeLatest(GET_FILES_TO_BE_CHECKED, getFilesToCheck);
}

/**
 * Load FIles to send
 */
function* getFilesToSend({ payload: page }: IGetFilesToBeSentAction) {
  try {
    const res = yield requests.getFilesToSend(page, yield select(state => state.auth.token));
    yield put(getFilesToBeSentSuccess({
      files: res.data.projects,
      total: res.data.versionsTotales.number,
    }));
  } catch (error) {
    yield put(getFilesToBeSentError(error));
  }
}

export function* watchGetFilesToSend() {
  yield takeLatest(GET_FILES_TO_BE_SENT, getFilesToSend);
}
