import { Select } from 'antd';
import React, { Component } from 'react';

import { IUser } from '../../store/users/types';
import { CustomAvatar } from '../CustomAvatar';

const { Option } = Select;

interface ITeamSelectProps {
  users: IUser[];
  placeholder: string | React.ReactNode;
  mode?: 'default' | 'multiple' | 'tags';
  size?: 'default' | 'large' | 'small';
  allowClear: boolean;
}

export default class TeamSelect extends Component<ITeamSelectProps, {}> {
  state = {};

  render() {
    const { users, ...rest } = this.props;
    return (
      <Select { ...rest }>
        {
          users.map((user: IUser) => {
            return (
              <Option key={ user.id } value={ `/users/${ user.id }` }>
                <div>
                  <CustomAvatar shape="square" type="user" src={user.avatar?.path} />
                  {` ${user.firstName} ${user.lastName}`}
                </div>
              </Option>
            );
          })
        }
      </Select>
    );
  }
}
