import { ActionCreator } from 'redux';

import { IErrors, IUserPermissions } from '../../utils/types';

import {
  CONNECTED_USER_GET_PERMISSIONS,
  CONNECTED_USER_GET_PERMISSIONS_ERROR,
  CONNECTED_USER_GET_PERMISSIONS_SUCCESS,
  GET_CONNECTED_USER_DATA,
  GET_CONNECTED_USER_DATA_ERROR,
  GET_CONNECTED_USER_DATA_SUCCESS,
  IConnectedUserData,
  IConnectedUserGetPermissionsAction,
  IConnectedUserGetPermissionsErrorAction,
  IConnectedUserGetPermissionsSuccessAction,
  IGetConnectedUserDataAction,
  IGetConnectedUserDataErrorAction,
  IGetConnectedUserDataSuccessAction,
} from './types';

export const getConnectedUserData: ActionCreator<IGetConnectedUserDataAction> = () => ({
  type: GET_CONNECTED_USER_DATA,
});

export const getConnectedUserDataSuccess: ActionCreator<IGetConnectedUserDataSuccessAction> = (response: IConnectedUserData) => ({
  type: GET_CONNECTED_USER_DATA_SUCCESS,
  payload: response,
});

export const getConnectedUserDataError: ActionCreator<IGetConnectedUserDataErrorAction> = (error: IErrors) => ({
  type: GET_CONNECTED_USER_DATA_ERROR,
  payload: error,
});

/**
 * Get connected user permissions
 */
export const getConnectedUserPermissions = (): IConnectedUserGetPermissionsAction => ({
  type: CONNECTED_USER_GET_PERMISSIONS,
});

export const getConnectedUserPermissionsSuccess = (permissions: IUserPermissions): IConnectedUserGetPermissionsSuccessAction => ({
  type: CONNECTED_USER_GET_PERMISSIONS_SUCCESS,
  payload: permissions,
});

export const getConnectedUserPermissionsError = (errors: IErrors): IConnectedUserGetPermissionsErrorAction => ({
  type: CONNECTED_USER_GET_PERMISSIONS_ERROR,
  payload: errors,
});
