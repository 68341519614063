import { Action } from 'redux';

import { IErrors } from '../../utils/types';

export interface IDirectories {
  directories: string[];
  isLoading: boolean;
  errors: IErrors[] | null;
}

export const DIRECTORY_GET_ALL = 'DIRECTORY_GET_ALL';
export type DIRECTORY_GET_ALL  = typeof DIRECTORY_GET_ALL;

export const DIRECTORY_GET_ALL_SUCCESS = 'DIRECTORY_GET_ALL_SUCCESS';
export type DIRECTORY_GET_ALL_SUCCESS  = typeof DIRECTORY_GET_ALL_SUCCESS;

export const DIRECTORY_GET_ALL_ERROR = 'DIRECTORY_GET_ALL_ERROR';
export type DIRECTORY_GET_ALL_ERROR  = typeof DIRECTORY_GET_ALL_ERROR;

export interface IGetAllDirectoriesAction extends Action<DIRECTORY_GET_ALL> {}

export interface IGetAllDirectoriesSuccessAction extends Action<DIRECTORY_GET_ALL_SUCCESS> {
  payload: string[];
}

export interface IGetAllDirectoriesErrorAction extends Action<DIRECTORY_GET_ALL_ERROR> {
  payload: IErrors[];
}

export type directoriesActionTypes =
    IGetAllDirectoriesAction
  | IGetAllDirectoriesSuccessAction
  | IGetAllDirectoriesErrorAction;
