import { Alert, Button, Col, Form, Icon, Row, Select, Upload } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { RcCustomRequestOptions, UploadChangeParam } from 'antd/lib/upload/interface';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';

import { CustomAvatar } from '../../../../../components/CustomAvatar';
import { FormElementPlaceholder } from '../../../../../components/FormElementPlaceholder';
import { IFileInfos, IPreSigned } from '../../../../../store/file/types';
import { IBudget, IClient, IErrors } from '../../../../../utils/types';

const { Option } = Select;
const { Dragger } = Upload;

interface IUploadFileProps extends FormComponentProps {
  preSigned: IPreSigned | null;
  clients: IClient[];
  budgets: IBudget[];
  uploadErrors: IErrors | null;
  isClientLoading: boolean;
  isBudgetLoading: boolean;
  onRequestPreSignedUrl: (folder: string, filename: string) => void;
  onClientChange: (client: string) => void;
  onFileUploaded: (budget: IFileInfos) => void;
}

const UploadFileInner: React.FC<IUploadFileProps> = ({
  onRequestPreSignedUrl,
  preSigned,
  clients,
  budgets,
  uploadErrors,
  onClientChange,
  onFileUploaded,
  form,
  isClientLoading,
  isBudgetLoading,
}) => {
  const { resetFields, getFieldDecorator } = form;

  const [targetBudget, setTargetBudget] = useState<string | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [uploadedThumbnails, setUploadedThumbnails] = useState<any[]>([]);
  const [params, setParams] = useState<RcCustomRequestOptions | null>(null);
  const [thumbParams, setThumbParams] = useState<RcCustomRequestOptions | null>(null);
  const [uploaded, setUploaded] = useState(0);
  const [preSignedURLs, setPreSignedURLs] = useState<IPreSigned | null>(null);
  const [canDrop, setCanDrop] = useState(0);
  const [errors, setErrors] = useState<IErrors | null>(null);

  const handleBudgetChange = (budget: string) => {
    setTargetBudget(budget);
    setCanDrop(canDrop + 1);
  };

  const handleClientChange = (clientId: string) => {
    onClientChange(clientId);
    resetFields(['budget']);
    setCanDrop(canDrop + 1);
  };

  useEffect(() => {
    setErrors(uploadErrors);
  },        [uploadErrors]);

  useEffect(() => {
    setPreSignedURLs(null);
  },        [uploaded]);

  useEffect(() => {
    setPreSignedURLs(preSigned);
  },        [preSigned]);

  const getBudgetFolder = (budget: string) => budgets.find(el => el.id === budget)?.directory;

  const handlePreSignedURL = (parameters: RcCustomRequestOptions) => {
    let projectFolder;
    if (targetBudget) projectFolder = getBudgetFolder(targetBudget);
    if (projectFolder) onRequestPreSignedUrl(`${projectFolder}/bruts`, parameters.file.name);
  };

  const fileCustomRequest = (parameters: RcCustomRequestOptions, urls: IPreSigned) => {
    Axios.put(urls.url, parameters.file, {
      onUploadProgress: progressEvent => {
        parameters.onProgress(
          {
            percent: Math.round((progressEvent.loaded * 100) / progressEvent.total),
          },
          parameters.file
        );
      },
    })
    .then(() => {
      setUploaded(uploaded + 1);
      setParams(null);
      parameters.onSuccess({}, parameters.file);
      onFileUploaded({
        status: 'À faire',
        project: `/projects/${targetBudget}`,
        name: urls.file,
      });
      setUploadedFiles([]);
    })
    .catch(err => {
      parameters.onError(err);
    });
  };

  const thumbnailCustomRequest = (parameters: RcCustomRequestOptions, urls: IPreSigned) => {
    Axios.put(urls.thumbnailUrl, parameters.file, {
      onUploadProgress: progressEvent => {
        parameters.onProgress(
          {
            percent: Math.round((progressEvent.loaded * 100) / progressEvent.total),
          },
          parameters.file
        );
      },
    })
    .then(() => {
      setUploaded(uploaded + 1);
      setThumbParams(null);
      parameters.onSuccess({}, parameters.file);
      resetFields();
      setUploadedThumbnails([]);
    })
    .catch(err => {
      parameters.onError(err);
    });
  };

  const UploadPsdFile = (parameters: RcCustomRequestOptions) => {
    setParams(parameters);
    handlePreSignedURL(parameters);
  };

  const UploadThumbnail = (parameters: RcCustomRequestOptions) => {
    setThumbParams(parameters);
  };

  const handleUploadedFilesChange = (info: UploadChangeParam) => setUploadedFiles(info.fileList);

  const handleUploadedThumbnailsChnage = (info: UploadChangeParam) => setUploadedThumbnails(info.fileList);

  const handleUploadFiles = () => {
    if (params && thumbParams && preSignedURLs) {
      fileCustomRequest(params, preSignedURLs);
      thumbnailCustomRequest(thumbParams, preSignedURLs);
    }
  };

  const handleRemoveFile = () => {
    setErrors(null);
  };

  return (
    <Form className="content has-card-view">
      <h2 className="file-manager-title">
        {' '}
        <Icon type="cloud-upload" />
        Télécharger un Fichier brut
      </h2>

      <Row gutter={16}>
        <Col span={12} xl={12} md={24}>
          <Form.Item>
            {getFieldDecorator('client', {
              rules: [
                {
                  required: true,
                  message: 'Veuillez sélectionner un client !',
                },
              ],
              validateTrigger: ['onBlur', 'onChange'],
            })(
              <Select
                onChange={handleClientChange}
                placeholder={ <FormElementPlaceholder text="-- Sélectionner un client --" /> }
                size="large"
                loading={isClientLoading}>
                {clients.length &&
                  clients.map(clientItem => {
                    return (
                      <Option key={clientItem.id} value={clientItem.id}>
                        <div>
                          <CustomAvatar shape="square" type="client" src={clientItem.logo} />
                          {` ${clientItem.companyName}`}
                        </div>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={12} xl={12} md={24}>
          <Form.Item>
            {getFieldDecorator('budget', {
              rules: [
                {
                  required: true,
                  message: 'Veuillez sélectionner un projet !',
                },
              ],
              validateTrigger: ['onBlur', 'onChange'],
            })(
              <Select
                onChange={handleBudgetChange}
                placeholder={ <FormElementPlaceholder text="-- Sélectionner un budget --" /> }
                size="large"
                loading={isBudgetLoading}>
                {budgets.length &&
                  budgets.map(budget => {
                    return (
                      <Option key={budget.id} value={budget.id}>
                        <div>
                          <Icon type="folder-open" />
                          {` ${budget.name}`}
                        </div>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ 16 }>
        <Col span={ 12 } xl={ 12 } md={ 24 }>
          <Row>
            <Col span={24}>
              <Form.Item>
                {getFieldDecorator('file', {
                  valuePropName: 'file',
                })(
                  <Dragger
                    customRequest={UploadPsdFile}
                    multiple={false}
                    fileList={uploadedFiles}
                    onChange={ handleUploadedFilesChange }
                    disabled={ canDrop < 2 }
                    onRemove={ handleRemoveFile }
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="file" />
                    </p>
                    <p className="ant-upload-text">Cliquer ou glisser un fichier brut.</p>
                    <p className="ant-upload-hint">supporte le téléchargement unique.</p>
                  </Dragger>
                )}
              </Form.Item>
            </Col>
            {
              !!errors && (
                <Col span={24}>
                  {
                    errors.general.map(error => (
                      <Form.Item key={ error }>
                        <Alert message={ error } type="error" showIcon closable />
                      </Form.Item>
                    ))
                  }
                </Col>
              )
            }
          </Row>
        </Col>

        <Col span={ 12 } xl={ 12 } md={ 24 }>
          <Row>
            <Col span={24}>
              <Form.Item>
                {getFieldDecorator('thumbnail', {
                  valuePropName: 'thumbnail',
                })(
                  <Dragger
                    customRequest={UploadThumbnail}
                    accept="image/*"
                    multiple={false}
                    fileList={ uploadedThumbnails }
                    onChange={ handleUploadedThumbnailsChnage }
                    disabled={ !!uploadErrors || !params }
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="file-image" />
                    </p>
                    <p className="ant-upload-text">Cliquer ou glisser une image d'aperçu.</p>
                    <p className="ant-upload-hint">supporte le téléchargement unique.</p>
                  </Dragger>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col>
          <Button
            className="btn-success"
            shape="round"
            icon="upload"
            onClick={ handleUploadFiles }
            disabled={ (!params && !thumbParams) || !!uploadErrors }
          >
            Télécharger les fichiers
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const UploadFile = Form.create<IUploadFileProps>()(UploadFileInner);
