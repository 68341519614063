import { applyMiddleware, combineReducers, createStore, Middleware } from 'redux';
// tslint:disable-next-line: no-implicit-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { alertReducer } from './alerts/reducers';
import { authReducer } from './auth/reducers';
import { watchLogin, watchLogout, watchRequestResetPassword, watchResetPassword } from './auth/sagas';
import { manageToken } from './auth/subscriptions';
import { ClientsReducer } from './clients/reducers';
import { watchAddClient, watchDisableClient, watchGetFilesystemClients, watchGetListClients, watchSingleClient, watchUpdateClient } from './clients/sagas';
import { connectedUserDataReducer } from './connectedUser/reducer';
import { watchGetConnectedUserData, watchGetPermissions } from './connectedUser/saga';
import { DirectoryReducer } from './directory/reducers';
import { watchGetDirectories } from './directory/sagas';
import { uploadFileReducer } from './file/reducers';
import { watchAddLogFile, watchCreateNewFile, watchDownloadFiles, watchFileDispatchTeam, watchFileUpdatePriority, watchGetAllFiles, watchGetBrutFile, watchGetFileLogs, watchGetFilePreview, watchGetFilesToCheck, watchGetFilesToSend, watchGetSingleFile, watchGetVersionPresignedUrl, watchRequestUrl, watchUpdateFileStatus, watchUpdateListFiles } from './file/sagas';
import { budgetReducer } from './project/reducers';
import { watchAddBrief, watchAddBudget, watchGetAllBudgets, watchGetBriefs, watchGetBudgetsByClient, watchGetCheckpoints, watchGetFileBriefs, watchGetInProgressBudgets, watchGetProjectNumberPerStatus, watchGetSingleBudget, watchGetUserBudgets, watchUpdateBudgetList, watchUpdateProject } from './project/sagas';
import { settingsReducer } from './settings/reducers';
import { watchTriggerSynchronization } from './settings/sagas';
import { usersListReducer } from './users/reducer';
import { watchCreateUser, watchDisableUser, watchGetDispatchTeam, watchGetRoles, watchGetUser, watchGetUsers, watchUpdateUser } from './users/saga';

// Create a root reducer with combined reducers
const rootReducer = combineReducers({
  alerts: alertReducer,
  auth: authReducer,
  clients: ClientsReducer,
  connectedUser: connectedUserDataReducer,
  directories: DirectoryReducer,
  file: uploadFileReducer,
  listUsers: usersListReducer,
  project: budgetReducer,
  settings: settingsReducer,
});

// Define GlobalState Type
export type GlobalState = ReturnType<typeof rootReducer>;

function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchLogout),
    fork(watchRequestResetPassword),
    fork(watchResetPassword),
    fork(watchGetRoles),
    fork(watchCreateUser),
    fork(watchGetConnectedUserData),
    fork(watchGetUsers),
    fork(watchUpdateUser),
    fork(watchAddClient),
    fork(watchGetListClients),
    fork(watchGetFilesystemClients),
    fork(watchSingleClient),
    fork(watchUpdateClient),
    fork(watchGetDirectories),
    fork(watchAddBrief),
    fork(watchGetBriefs),
    fork(watchAddBudget),
    fork(watchGetSingleBudget),
    fork(watchGetAllBudgets),
    fork(watchDisableClient),
    fork(watchRequestUrl),
    fork(watchGetBudgetsByClient),
    fork(watchCreateNewFile),
    fork(watchGetAllFiles),
    fork(watchGetCheckpoints),
    fork(watchDownloadFiles),
    fork(watchGetSingleFile),
    fork(watchUpdateFileStatus),
    fork(watchUpdateBudgetList),
    fork(watchUpdateListFiles),
    fork(watchFileDispatchTeam),
    fork(watchGetPermissions),
    fork(watchDisableUser),
    fork(watchFileUpdatePriority),
    fork(watchGetFilePreview),
    fork(watchGetInProgressBudgets),
    fork(watchGetFileBriefs),
    fork(watchGetFileLogs),
    fork(watchGetBrutFile),
    fork(watchGetVersionPresignedUrl),
    fork(watchGetUser),
    fork(watchGetUserBudgets),
    fork(watchGetProjectNumberPerStatus),
    fork(watchTriggerSynchronization),
    fork(watchUpdateProject),
    fork(watchAddLogFile),
    fork(watchGetFilesToCheck),
    fork(watchGetFilesToSend),
    fork(watchGetDispatchTeam),
  ]);
}

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware: Middleware[] = [sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middleware);
  const composeEnhancers = composeWithDevTools({});

  const store = createStore(rootReducer, composeEnhancers(middlewareEnhancer));
  sagaMiddleware.run(rootSaga);

  // Subscriptions
  store.subscribe(manageToken(store));
  return store;
};

export default configureStore;
