import { Avatar, Col, Icon, List, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { loadMoreItems, renderPaginationItems } from '../../../../../../utils/table-pagination-item-render';
import { IBudget, IBudgetFiles, IClient, IFileAlert, IFilesAlertPayload } from '../../../../../../utils/types';

import './files-notif-modal.less';

interface IFilesProgressNotifModalProps {
  dataSource: IBudgetFiles[];
  defaultData: IFilesAlertPayload | null;
  budgets: IBudget[];
  clients: IClient[];
  isVisible: boolean;
  status: 'checked' | 'transferred' | null;
  onAlertModalCloseClick: () => void;
  onLoadMoreItemsChange: (page: number, status: string) => void;
}

export const FilesProgressNotifModal: React.FC<IFilesProgressNotifModalProps> = ({
  dataSource,
  defaultData,
  budgets,
  isVisible,
  status,
  onAlertModalCloseClick,
  onLoadMoreItemsChange,
}) => {
  const [listFiles, setListFiles] = useState<IFileAlert[]>([]);

  useEffect(() => {
    if (defaultData) setListFiles(defaultData.files);
  },        [defaultData]);

  useEffect(() => {
    makeAlertsFiles(dataSource);
  },        [dataSource]);

  const findProject = (projectId: string, listBudgets: IBudget[]) => listBudgets.find(({ id }) => id === projectId.replace('/projects/', ''));

  const makeAlertsFiles = (source: IBudgetFiles[]) => {
    source.forEach(file => {
      const project = findProject(file.project, budgets);
      if (project)
        setListFiles([
          {
            client: project.directory.split('/')[0],
            project: project.directory.split('/')[1],
            version: project.files.length,
          },
          ...listFiles,
        ]);
    });
  };

  const handleCloseClick = () => {
    onAlertModalCloseClick();
  };

  const isChecked = status === 'checked';

  return (
    <Modal
      className="notif-files-status-modal has-card-view"
      visible={isVisible}
      onCancel={handleCloseClick}
      closable={false}
      title={(
        <Row gutter={8}>
          <Col span={12}>
            <Avatar className={`status ${status}`} size={50} shape="square" icon={isChecked ? 'file-done' : 'file-protect'} />
          </Col>
          <Col span={12}>
            <Row type="flex" justify="end">
              <Col span={24}>
                <span className="category">Fichiers à {isChecked ? 'Chécker' : 'Envoyer'}</span>
              </Col>
              <Col span={24}>
                <span className="files-versions">{defaultData?.total || 0}v</span>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      footer={null}>
      <List
        dataSource={listFiles}
        pagination={{
          pageSize: 8,
          size: 'small',
          hideOnSinglePage: true,
          itemRender: (page, type, original) => renderPaginationItems(page, type, original),
          onChange: (page, size) => {
            if (size && defaultData && status) {
              const pageNumber = loadMoreItems(size, page, defaultData.files.length);
              if (pageNumber) onLoadMoreItemsChange(pageNumber, status);
            }
          },
        }}
        renderItem={item => {
          return (
            <List.Item>
              <List.Item.Meta
                description={(
                  <Row type="flex" align="middle" gutter={8}>
                    <Col md={4}>
                      <Icon type="clock-circle" />
                    </Col>
                    <Col xl={17}>
                      <Row>
                        <Col>
                          <span className="item-client">{item.client}</span>
                        </Col>
                        <Col>
                          <span className="item-budget">{item.project}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={3}>
                      <Row type="flex" justify="center">
                        <Col>
                          <span className="item-version">{item.version} v</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              />
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};
