import { List } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { IConnectedUserData } from '../../store/connectedUser/types';
import { CustomAvatar } from '../CustomAvatar';

import './connected-user.less';

const { Item } = List;

interface ISiderProfileDetailsProps {
  userData: IConnectedUserData;
  onResetCurrentUser?: () => void;
}

export const ConnectedUserSider: React.FC<ISiderProfileDetailsProps> = ({ userData }) => {

  return (
    <div className="connected-user-sider">
      <h4 className="orange-title">Mon profil</h4>
      <List>
        <Link to={`/team/${userData.id}`}>
          <Item>
            <Item.Meta
              avatar={<CustomAvatar src={ userData.avatar?.path } type="user" />}
              title={`${ userData.firstName} ${userData.lastName }`}
            />
          </Item>
        </Link>
      </List>
    </div>
  );
};
