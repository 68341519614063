import { Button, Checkbox, Col, Icon, Menu, Radio, Row } from 'antd';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import './custom-tabs-selector.less';

interface ICustomTabsSelectorProps {
  placeholder: string;
  onTabsValueChange: (values: string[]) => void;
  onProgressValueChange: (ref: string) => void;
}

export const CustomTabsSelector: React.FC<ICustomTabsSelectorProps> = ({ placeholder, onTabsValueChange, onProgressValueChange }) => {

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [tabs, setTabs] = useState<string[]>([]);
  const [progression, setProgression] = useState('');

  const node = useRef() as MutableRefObject<HTMLInputElement>;

  const handleClickOutside = (e: any) => {
    if (!node.current.contains(e.target)) setMenuOpen(false);
  };

  useEffect(() => {
    if (isMenuOpen) document.addEventListener('mousedown', handleClickOutside);
    else document.removeEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  },        [isMenuOpen]);

  const tabsToShow = [
    {
      text: 'Statut',
      ref: 'status',
    },
    {
      text: 'A faire',
      ref: 'todo',
    },
    {
      text: 'A envoyer',
      ref: 'to-be-sent',
    },
    {
      text: 'Retour Client',
      ref: 'client-return',
    },
    {
      text: 'Retour Interne',
      ref: 'internal-return',
    },
    {
      text: 'Type retouche',
      ref: 'type',
    },
    {
      text: 'Date Livraison',
      ref: 'delivery',
    },
  ];

  const progress = [
    {
      text: '%',
      ref: 'percent',
    },
    {
      text: 'Fichiers envoyés',
      ref: 'transferred',
    },
  ];

  useEffect(() => {
    const defaultVisibleTabs =  localStorage.getItem('lunatik-visible-tabs');
    if (defaultVisibleTabs) setTabs(JSON.parse(defaultVisibleTabs));
  },        [setTabs]);

  useEffect(() => {
    const defaultVisibleProgress =  localStorage.getItem('lunatik-visible-progression');
    if (defaultVisibleProgress) setProgression(defaultVisibleProgress);
  },        [setProgression]);

  useEffect(() => {
    onTabsValueChange(tabs);
  },        [tabs, onTabsValueChange]);

  const handleButtonClick = () => setMenuOpen(!isMenuOpen);

  const handleCheckboxClick = (ref: string) => {
    if (tabs.includes(ref)) {
      setTabs(tabs.filter(tab => tab !== ref));
      return null;
    }
    setTabs([...tabs, ref]);
  };

  const handleRadioClick = (ref: string) => {
    setProgression(ref);
    onProgressValueChange(ref);
  };

  const renderTabs = () => {
    return tabsToShow.map(tab => {
      return (
        <Menu.Item key={tab.ref} className={tabs.includes(tab.ref) ? 'ant-menu-item-selected' : ''}>
          <Checkbox checked={ tabs.includes(tab.ref) } onClick={() => handleCheckboxClick(tab.ref)} className="custom-selector-checkbox">
            {tab.text}
          </Checkbox>
        </Menu.Item>
      );
    });
  };

  const renderProgress = () => {
    return progress.map(el => {
      return (
        <Menu.Item key={el.ref} className={el.ref === progression ? 'ant-menu-item-selected' : ''}>
          <Radio checked={ progression === el.ref } onClick={ () => handleRadioClick(el.ref) } className="custom-selector-checkbox">
            {el.text}
          </Radio>
        </Menu.Item>
      );
    });
  };

  const menu = (
    <Menu className="custom-selector-droppable">
      {renderTabs()}
      <div className="indicator">Progression</div>
      {renderProgress()}
    </Menu>
  );

  return (
    <div ref={ node } id="custom-selector" className="custom-selector">
      <Button onClick={handleButtonClick}>
        <Row type="flex">
          <Col span={12}>
            <span>{placeholder}</span>
          </Col>
          <Col span={12}>
            <Row type="flex" justify="end">
              <Col>
                <Icon type="down" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Button>
      {isMenuOpen && menu}
    </div>
  );
};
