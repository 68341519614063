import { IClient, IDisableTerm, IErrors, IFileSystemClient } from '../../utils/types';

import { CLIENTS_ADD_CLIENT, CLIENTS_ADD_CLIENT_ERROR, CLIENTS_ADD_CLIENT_SUCCESS, CLIENTS_DISABLE_CLIENT, CLIENTS_DISABLE_CLIENT_ERROR, CLIENTS_DISABLE_CLIENT_SUCCESS, CLIENTS_GET_FILTERED_LIST_CLIENTS_SUCCESS, CLIENTS_GET_LIST_CLIENTS, CLIENTS_GET_LIST_CLIENTS_ERROR, CLIENTS_GET_LIST_CLIENTS_SUCCESS, CLIENTS_GET_SINGLE_CLIENT, CLIENTS_GET_SINGLE_CLIENT_ERROR, CLIENTS_GET_SINGLE_CLIENT_SUCCESS, CLIENTS_UPDATE_CLIENT, CLIENTS_UPDATE_CLIENT_ERROR, CLIENTS_UPDATE_CLIENT_SUCCESS, GET_FILESYSTEM_CLIENTS, GET_FILESYSTEM_CLIENTS_ERROR, GET_FILESYSTEM_CLIENTS_SUCCESS, IClientsActionType, IClientsAddClientErrorAction, IClientsAddClientSuccessAction, IClientsDisableClientAction, IClientsDisableClientErrorAction, IClientsDisableClientSuccessAction, IClientsGetFilteredListSuccessAction, IClientsGetListAction, IClientsGetListErrorAction, IClientsGetListSuccessAction, IClientsGetSingleClientAction, IClientsGetSingleClientErrorAction, IClientsGetSingleClientSuccessAction, IClientsUpdateClientAction, IClientsUpdateClientErrorAction, IClientsUpdateClientSuccessAction, IGetFilesystemClientsAction, IGetFilesystemClientsErrorAction, IGetFilesystemClientsSuccessAction, IGetListClients } from './types';

// Add client actions
export const addClient = (client: IClient): IClientsActionType => ({
  type: CLIENTS_ADD_CLIENT,
  payload: client,
});

export const addClientSuccess = (): IClientsAddClientSuccessAction => ({
  type: CLIENTS_ADD_CLIENT_SUCCESS,
});

export const addClientError = (errors: IErrors): IClientsAddClientErrorAction => ({
  type: CLIENTS_ADD_CLIENT_ERROR,
  payload: errors,
});

// Get list clients actions
export const getListClients = (terms: IGetListClients): IClientsGetListAction => ({
  type: CLIENTS_GET_LIST_CLIENTS,
  payload: terms,
});

export const getListClientsSuccess = (clients: IClient[]): IClientsGetListSuccessAction => ({
  type: CLIENTS_GET_LIST_CLIENTS_SUCCESS,
  payload: clients,
});

export const getListClientsAndReplaceSuccess = (clients: IClient[]): IClientsGetFilteredListSuccessAction => ({
  type: CLIENTS_GET_FILTERED_LIST_CLIENTS_SUCCESS,
  payload: clients,
});

export const getListClientsError = (errors: IErrors): IClientsGetListErrorAction => ({
  type: CLIENTS_GET_LIST_CLIENTS_ERROR,
  payload: errors,
});

/**
 * Get file system clients list
 */
export const getFilesystemClients = (): IGetFilesystemClientsAction => ({
  type: GET_FILESYSTEM_CLIENTS,
});

export const getFilesystemClientsSuccess = (clients: IFileSystemClient): IGetFilesystemClientsSuccessAction => ({
  type: GET_FILESYSTEM_CLIENTS_SUCCESS,
  payload: clients,
});

export const getFilesystemClientsError = (errors: IErrors): IGetFilesystemClientsErrorAction => ({
  type: GET_FILESYSTEM_CLIENTS_ERROR,
  payload: errors,
});

// Get single client actions
export const getSingleClient = (clientID: string): IClientsGetSingleClientAction => ({
  type: CLIENTS_GET_SINGLE_CLIENT,
  payload: clientID,
});

export const getSingleClientSuccess = (client: IClient): IClientsGetSingleClientSuccessAction => ({
  type: CLIENTS_GET_SINGLE_CLIENT_SUCCESS,
  payload: client,
});

export const getSingleClientError = (errors: IErrors): IClientsGetSingleClientErrorAction => ({
  type: CLIENTS_GET_SINGLE_CLIENT_ERROR,
  payload: errors,
});

// Update client actions
export const updateClient = (client: IClient): IClientsUpdateClientAction => ({
  type: CLIENTS_UPDATE_CLIENT,
  payload: client,
});

export const updateClientSuccess = (): IClientsUpdateClientSuccessAction => ({
  type: CLIENTS_UPDATE_CLIENT_SUCCESS,
});

export const updateClientError = (errors: IErrors): IClientsUpdateClientErrorAction => ({
  type: CLIENTS_UPDATE_CLIENT_ERROR,
  payload: errors,
});

export const disableClient = (term: IDisableTerm): IClientsDisableClientAction => ({
  type: CLIENTS_DISABLE_CLIENT,
  payload: term,
});
export const disableClientSuccess = (): IClientsDisableClientSuccessAction => ({
  type: CLIENTS_DISABLE_CLIENT_SUCCESS,
});
export const disableClientError = (errors: IErrors): IClientsDisableClientErrorAction => ({
  type: CLIENTS_DISABLE_CLIENT_ERROR,
  payload: errors,
});
