import React from 'react';

export const Rainbow = () => {
  return (
    <div className="rainbow">
      <span className="red" />
      <span className="purple" />
      <span className="sky-blue" />
      <span className="green" />
      <span className="yellow" />
    </div>
  );
};
