import { Button, Checkbox, Icon, Table, Tooltip } from 'antd';
// tslint:disable-next-line: no-implicit-dependencies
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { BriefFormModal } from '../../../../../components/BriefFormModal';
import { GridSelector } from '../../../../../components/GridSelector';
import { PermissionsHandler } from '../../../../../components/permissions-handlers/PermissionsHandler';
import { TableStatusCounterFooter } from '../../../../../components/TableStatusCounterFooter';
import { IConnectedUserData } from '../../../../../store/connectedUser/types';
import { IFileStatus } from '../../../../../store/file/types';
import { IBriefs } from '../../../../../store/project/types';
import { IUser } from '../../../../../store/users/types';
import { mapUserById } from '../../../../../utils/filterUsersById';
import { IBrief, IBriefToSave, IBrutFile, IBudget, IBudgetFiles, IClient, IDownloadTerms, IFileChangeStatus, IFilePreviewUrls, IFileTeamToSave, IFileVersions, ILogFile, IPermissionsHandler, IPlainBrief, IUpdateFilePriority } from '../../../../../utils/types';
import { FileDetailsModal } from '../FileDetailsModal';
import { FilePrioUpdater } from '../FilePrioUpdater';
import { FilesFilters } from '../FilesFilters';
import { FileStatusList } from '../FileStatusList';
import { CustomAvatar } from '../../../../../components/CustomAvatar';

const { Column } = Table;

interface IListFilesProps {
  budget: IBudget;
  budgets: IBudget[];
  client: IClient | null;
  isLoadingFiles: boolean;
  briefsPack: IBriefs;
  fileBriefs: IPlainBrief[];
  listFiles: IBudgetFiles[];
  fileVersions: IFileVersions;
  brutFile: IBrutFile;
  fileBriefLoader: boolean;
  filePreview: IFilePreviewUrls;
  users: IUser[] | undefined;
  connectedUser?: IConnectedUserData;
  singleFile: IBudgetFiles | null;
  permissions: IPermissionsHandler | null;
  isFileBriefsLoading: boolean;
  onFileStatusChange: (file: IFileStatus) => void;
  onFileToUpdateChange: (file: IFileChangeStatus) => void;
  onEditFileStatusChange: (status: string, file: string) => void;
  onSelectedItemsChange: (team: IFileTeamToSave) => void;
  onFilePrioChange: (newVal: IUpdateFilePriority) => void;
  onSelectFileToOpen: (file: IBudgetFiles) => void;
  onSelectFileToLoadBriefs: (fileId: string) => void;
  onSaveFileBriefText: (text: string, file: string) => void;
  onSubmitBrief: (brief: IBriefToSave) => void;
  onResetFileBriefs: () => void;
  onDownloadFile: (terms: IDownloadTerms) => void;
  onRequestPresignedURL: (filename: string, dir: string) => void;
  onAddLogFile: (logFile: ILogFile) => void;
}

export const ListFiles: React.FC<IListFilesProps> = ({
  budget,
  budgets,
  client,
  fileVersions,
  permissions,
  briefsPack,
  listFiles,
  fileBriefs: fileBriefList,
  fileBriefLoader,
  brutFile,
  filePreview,
  isFileBriefsLoading,
  users,
  connectedUser,
  isLoadingFiles,
  onFileStatusChange,
  onFileToUpdateChange,
  onEditFileStatusChange,
  onSelectedItemsChange,
  onFilePrioChange,
  onSelectFileToOpen,
  onSelectFileToLoadBriefs,
  onSaveFileBriefText,
  onSubmitBrief,
  onResetFileBriefs,
  onDownloadFile,
  onRequestPresignedURL,
  onAddLogFile,
}) => {
  // States
  const [files, setFiles] = useState<IBudgetFiles[]>([]);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [selectdFile, setSelectdFile] = useState<IBudgetFiles | null>(null);
  const [tabsToShow, setTabsToShow] = useState<string[]>([
    'Chécké par',
    'Team',
    'Timing RT',
    'Début RT',
    'Fin RT',
    'Prio',
    'Timing Final',
  ]);
  const [paginationNbr, setPaginationNbr] = useState(10);
  const [imagesToFilter, setImagesToFilter] = useState<string[]>([]);
  const [isActionEnabled, setIsActionEnabled] = useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [itemsToCheck, setItemsToCheck] = useState('');
  const [editableRow, setEditableRow] = useState<'no' | 'yes' | 'onHold'>('no');
  const [fileId, setFileId] = useState('');
  const [listAddedRetouchers, setListAddedRetouchers] = useState<string[]>([]);
  const [budgetRetouchers, setBudgetRetouchers] = useState<IUser[]>([]);
  const [fileBriefs, setFileBriefs] = useState<IBrief[]>([]);
  const [fileToUpdate, setFileToUpdate] = useState<IBudgetFiles | null>(null);

  // List State
  const [isModalOpen, setModalOpen] = useState(false);
  const [targetFile, setTargetFile] = useState<IBudgetFiles | null>(null);

  const getBriefsNames = (listFileBriefs: IPlainBrief[]) => {
    return listFileBriefs.map(brief => brief.name);
  };

  const filterFileBriefs = (budgetBriefs: IBrief[], listFileBriefs: IPlainBrief[]) => {
    const names = getBriefsNames(listFileBriefs);
    return budgetBriefs.filter(el => names.includes(el.filename));
  };

  const handleSaveModal = (briefText?: string, file?: string) => {
    setSelectdFile(null);
    if (briefText && file) onSaveFileBriefText(briefText, file);
    else setIsVisibleModal(false);
  };

  useEffect(() => {
    setCheckedItems([]);
  },        [listFiles]);

  useEffect(() => {
    setFileBriefs(filterFileBriefs(briefsPack.briefList, fileBriefList));
  },        [fileBriefList, briefsPack.briefList]);

  useEffect(() => {
    if (fileToUpdate) {
      setTargetFile(fileToUpdate);
      setModalOpen(true);
    }
  },        [fileToUpdate, fileBriefs]);

  useEffect(() => {
    const storedItems = localStorage.getItem('lunatik-files-visible-tabs');
    if (!!storedItems) setTabsToShow(JSON.parse(storedItems));
  },        []);

  useEffect(() => {
    if (selectdFile) {
      setIsVisibleModal(true);
      onDownloadFile({
        originalFileId: selectdFile.name,
        folder: budget.directory,
      });
    }
  },        [selectdFile]);

  useEffect(() => {
    const filteredFiles = filterImagesToShowByStatus(listFiles, imagesToFilter);
    setFiles(filteredFiles);
    if (!!selectdFile) setSelectdFile(listFiles.find(el => el.id === selectdFile.id) || null);
  },        [imagesToFilter, listFiles]);

  useEffect(() => {
    if (budget && users) {
      const projectRetouchers: IUser[] = users.filter(user => {
        return budget.retoucher.includes(`/users/${ user.id }`);
      });
      setBudgetRetouchers(projectRetouchers);
    }
  },        [budget, users]);

  // Callbacks
  const getFileStatus = (file: IFileStatus) => onFileStatusChange(file);
  const getTabsToShow = (tabs: string[]) => setTabsToShow(tabs);
  const imagesNbrToShow = (nbr: number) => setPaginationNbr(nbr);
  const imagesToShow = (listStatus: string[]) => setImagesToFilter(listStatus);
  const getToggleEnabledActions = (isEnabled: boolean) => setIsActionEnabled(isEnabled);
  const getStatusToUpdate = (status: string) =>
    onFileToUpdateChange({
      listIds: checkedItems,
      status,
      relativeBudget: '',
    });

  // Methods
  const filterImagesToShowByStatus = (images: IBudgetFiles[], filters: string[]) => {
    return images.filter(image => {
      if (!filters.length) return images;
      return filters.includes(image.status) && image;
    });
  };

  const handleEachItemCheckbox = (id: string) => setCheckedItems([...checkedItems, id]);

  const getItemsToChecked = (toCheck: string) => {
    if (listFiles.length) {
      if (toCheck === 'all') {
        const listOfIds: string[] = listFiles.map(({ id }: IBudgetFiles) => id);
        setCheckedItems(listOfIds);
        setCheckAll(true);
      } else if (toCheck === 'none') {
        setCheckAll(false);
        setCheckedItems([]);
      } else {
        const listOfIds: any[] = listFiles.map(({ status, id }: IBudgetFiles) => {
          return toCheck === status && id;
        });
        if (listOfIds) setCheckedItems(listOfIds);
        setItemsToCheck(toCheck);
      }
    }
  };

  const toggleSetEditableRow = (id: string) => {
    if (editableRow === 'yes') {
      if (fileId) onSelectedItemsChange({
          fileId,
          retoucher: listAddedRetouchers,
        });
      setEditableRow('no');
      setFileId('');
      return;
    }
    setEditableRow('yes');
    setFileId(id);
  };

  const getStatusValue = (status: string, file: string) => onEditFileStatusChange(status, file);

  const getSelectedItemsList = (items: string[]) => {
    setListAddedRetouchers(items);
  };

  const handleFileDispatch = (fileID: string, type: string) => {
    let team: IFileTeamToSave;
    if (type === 'check')
      team = {
        fileId: fileID,
        checker: connectedUser?.id,
        action: 'Check',
      };
    else team = {
        fileId: fileID,
        currentRetoucher: connectedUser?.id,
        action: 'Retouch',
      };
    onSelectedItemsChange(team);
  };

  const handleOpenBriefModalClick = (file: IBudgetFiles) => {
    setFileToUpdate(file);
    onSelectFileToLoadBriefs(file.id);
  };

  const getMenuItemClickEvent = (prioValue: IUpdateFilePriority) => onFilePrioChange(prioValue);

  // Handle File brief
  useEffect(() => {
    setModalOpen(false);
  },        []);

  // Brief Form
  const handleOpenFileModal = (file: IBudgetFiles) => {
    onSelectFileToOpen(file);
    setSelectdFile(file);
    onSelectFileToLoadBriefs(file.name);
  };

  const handleCloseModal = () => {
    setIsVisibleModal(false);
    setSelectdFile(null);
    setFileToUpdate(null);
    setModalOpen(false);
    onResetFileBriefs();
    setTargetFile(null);
  };

  const getBrief = (brief: IBriefToSave) => {
    onSubmitBrief(brief);
    setTargetFile(null);
  };

  /**
   * Download / upload
   */
  const handlePresignedURL = (filename: string) => onRequestPresignedURL(filename, `${ budget.directory }/retouchers`);

  const handleUploadLogFile = (logFile: ILogFile) => onAddLogFile(logFile);

  return (
    <>
      <FilesFilters
        permissions={permissions}
        tabsToShow={ tabsToShow }
        listFiles={ listFiles }
        onTabsToShowChange={getTabsToShow}
        onImagesNbrToShowChange={imagesNbrToShow}
        onImagesToShowChange={imagesToShow}
        onItemToCheckChange={getItemsToChecked}
        onToggleEnableActions={getToggleEnabledActions}
        onStatusToUpdateChange={getStatusToUpdate}
      />

      <Table<IBudgetFiles>
        loading={isLoadingFiles}
        pagination={{ pageSize: paginationNbr, hideOnSinglePage: true }}
        dataSource={files || listFiles}
        footer={() => <TableStatusCounterFooter totalIndicator files={files} category="files" />}
        rowKey={record => record.id}
        scroll={{ x: 2000 }}
      >
        <Column
          title=""
          key="action_"
          width={50}
          fixed="left"
          render={record => (
            <Checkbox
              disabled={!isActionEnabled}
              checked={checkedItems?.includes(record.id) || checkAll || record.status === itemsToCheck}
              onChange={() => handleEachItemCheckbox(record.id)}
            />
          )}
        />

        {tabsToShow?.includes('Prio') && (
          <Column
            title="Prio"
            key="priority"
            width={90}
            fixed="left"
            sorter={(a: IBudgetFiles, b: IBudgetFiles) => (a.filePriority === b.filePriority ? 0 : a.filePriority ? -1 : 1)}
            render={record => (
              <div>
                <FilePrioUpdater
                  prioValue={ record.filePriority }
                  isDroppable={ editableRow === 'yes' && record.id === fileId }
                  onMenuItemClick={ getMenuItemClickEvent }
                  file={ record }
                >
                  <Icon type="clock-circle" className={`priority ${ record.filePriority ? 'is-prio' : '' }`} />
                </FilePrioUpdater>
              </div>
            )}
          />
        )}

        <Column
          sorter={(a: IBudgetFiles, b: IBudgetFiles) => a.name.localeCompare(b.name)}
          title="Image(s)"
          key="filename"
          width={250}
          fixed="left"
          ellipsis
          render={(record: IBudgetFiles) => (
            <Link onClick={() => handleOpenFileModal(record)} to="#">
              {record.fileName}
            </Link>
          )}
        />

        <Column
          title="Briefs"
          key="briefs"
          render={record => (
            <div>
              {
                editableRow === 'yes' && fileId === record.id
                ?
                <Button icon="file-add" style={{ border: 0 }} onClick={ () => handleOpenBriefModalClick(record) } />
                :
                <Icon type="file" />
              }
            </div>
          )}
        />

        {tabsToShow?.includes('Début RT') && (
          <Column
            sorter={(a: IBudgetFiles, b: IBudgetFiles) => moment(a.startDate).diff(moment(b.startDate))}
            title="Début RT"
            render={record => (
              <span>{record.startDate && `${moment(record.startDate).format('DD-MM-YYYY')} à ${moment(record.startDate).format('hh:mm')}`}</span>
            )}
            key="startRt"
          />
        )}

        {tabsToShow?.includes('Fin RT') && (
          <Column
            sorter={(a: IBudgetFiles, b: IBudgetFiles) => moment(a.finishDate).diff(moment(b.finishDate))}
            title="Fin RT"
            render={record => (
              <span>{record.finishDate && `${moment(record.finishDate).format('DD-MM-YYYY')} à ${moment(record.finishDate).format('hh:mm')}`}</span>
            )}
            key="finRt"
          />
        )}

        {tabsToShow?.includes('Timing RT') && (
          <Column
            sorter={(a: IBudgetFiles, b: IBudgetFiles) => a.retoucherTotalTimes.localeCompare(b.retoucherTotalTimes)}
            title="Timing RT"
            render={record => <span>{record.retoucherTotalTimes}</span>}
            key="timingRt"
          />
        )}

        {tabsToShow?.includes('Team') && (
          <Column
            sorter
            title="Team"
            key="team"
            render={record => {
              return (
                <GridSelector
                  data={record.retoucher || []}
                  projectTeam={budget.retoucher}
                  users={users || []}
                  maxVisibleItems={2}
                  editable={(editableRow === 'yes' && fileId === record.id)}
                  onListItemsChange={getSelectedItemsList}
                />
                );
              }
            }
          />
        )}

        {tabsToShow?.includes('Chécké par') && (
          <Column
            sorter
            title="Chécké par"
            key="checker"
            render={(record: IBudgetFiles) => {
              const fileChecker = users && mapUserById(users, record.checker);
              if (fileChecker?.length && !!record.checkerTimes.length)
                return (
                  <Tooltip title={`${fileChecker[0].firstName} ${fileChecker[0].lastName}`}>
                    <CustomAvatar shape="square" type="user" src={fileChecker[0].avatar?.path} />
                  </Tooltip>
                );
            }}
          />
        )}

        {tabsToShow?.includes('Timing Final') && (
          <Column
            sorter={(a: IBudgetFiles, b: IBudgetFiles) => a.totalTimes.localeCompare(b.totalTimes)}
            title="Timing Final"
            render={record => <span>{record.totalTimes}</span>}
            key="timingFinal"
          />
        )}

        <Column
          sorter={(a: IBudgetFiles, b: IBudgetFiles) => a.status.localeCompare(b.status)}
          title="Statut"
          key="status"
          width={140}
          render={record => (
            <>
              <FileStatusList
                isDroppable={editableRow === 'yes' && record.id === fileId}
                status={record.status}
                targetFile={ record.id }
                onStatusChange={getStatusValue}
              />
            </>
          )}
        />

        <Column
          title="Actions"
          key="action"
          fixed="right"
          width={90}
          render={record => (
            <PermissionsHandler resourceName="File" blocPermission="CAN_PUT" userPermissions={permissions}>
              <div style={{
                textAlign: 'center',
                opacity: (editableRow === 'yes' && record.id === fileId)
                ?
                1
                :
                0.4,
                }}>
                <Button icon="edit" onClick={() => toggleSetEditableRow(record.id)} />
              </div>
            </PermissionsHandler>
          )}
        />
      </Table>

      {/* Modal */}
      <FileDetailsModal
        singleFile={selectdFile}
        isVisible={isVisibleModal}
        isLoadingBriefs={ fileBriefLoader }
        briefList={ fileBriefs }
        filePreview={ filePreview }
        briefsPack={ briefsPack }
        users={users}
        brutFile={ brutFile }
        fileVersions={ fileVersions }
        connectedUser={ connectedUser }
        onModalClose={handleCloseModal}
        onModalSave={handleSaveModal}
        onStatusButtonsClick={getFileStatus}
        onFileDispatched={handleFileDispatch}
        onRequestPreSignedUrl={ handlePresignedURL }
        onUploadLogFile={ handleUploadLogFile }
      />
      {/* Modal */}
      {/* Add Biref Modal */}
      {
        !!connectedUser && (
          <BriefFormModal
            isVisible={ isModalOpen }
            isLoading={ isFileBriefsLoading }
            briefs={ fileBriefs }
            briefsPack={ briefsPack }
            targetFile={ targetFile }
            projects={ budgets }
            tergetBudget={ budget }
            connectedUser={ connectedUser }
            client={ client }
            onGetBriefClick={ getBrief }
            onModalCloseClick={ handleCloseModal }
          />
        )
      }
      {/* Add Biref Modal */}
    </>
  );
};
