import { Button, Col, DatePicker, Form, Icon, Input, Layout, Row, Select, Tooltip, Upload } from 'antd';
import { FormComponentProps } from 'antd/es/form';
// tslint:disable-next-line: no-implicit-dependencies
import moment from 'moment';
import generatePassword from 'password-generator';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { FormElementPlaceholder } from '../../../../../components/FormElementPlaceholder';
import { GlobalState } from '../../../../../store';
import { IConnectedUserData } from '../../../../../store/connectedUser/types';
import listCountries from '../../../../../utils/countries/index.json';
import { mergeErrors } from '../../../../../utils/form-errors-utils';
import { IErrors, IRole, IUserData } from '../../../../../utils/types';

const { Option } = Select;
const { Header } = Layout;

interface IProfileFormProps extends FormComponentProps {
  onSubmitProfileClick: (newProfile: IUserData) => void;
  roleList: IRole[];
  connectedUser: IConnectedUserData;
  errors: IErrors | null;
  isLoading: boolean;
  isCreated: boolean;
}

interface ICountry {
  name: string;
  code: string;
}

const ProfileFormValidation: React.FC<IProfileFormProps> = ({
  roleList,
  errors,
  form,
  isLoading,
  isCreated,
  connectedUser,
  onSubmitProfileClick,
}) => {

  const token = useSelector((state: GlobalState) => state.auth.token);

  const { getFieldDecorator, resetFields, getFieldsValue, setFields } = form;
  const [generatedPassword, setGeneratedPassword] = useState('');
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [country, setCountry] = useState<ICountry | null>(null);

  // tslint:disable-next-line: no-any
  const loadData: any = () => JSON.parse(JSON.stringify(listCountries));

  const countriesList = loadData();
  const [fieldsValues, setFieldsValues] = useState();

  const avatarProps = {
    name: 'file',
    action: `${process.env.REACT_APP_SERVER_URL}/avatars`,
    headers: { authorization: `Bearer ${token}` },
  };

  // tslint:disable-next-line: no-any
  const func = (e: any) => {
    return e && e.fileList.slice(-1);
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const newProfile: IUserData = {
          ...values,
          birthday: values.birthday && (values.birthday as moment.Moment).format('YYYY-MM-DD'),
          avatar: values.avatar && values.avatar[0].response['@id'],
        };
        onSubmitProfileClick(newProfile);
      }
    });
    setFieldsValues(getFieldsValue());
  };

  const generateNewPassword = () => {
    resetFields(['password']);
    setGeneratedPassword(generatePassword(8));
  };

  const disableFutureDays = (current: moment.Moment | null) => {
    if (!current) return false;
    return current > moment().endOf('day');
  };

  useEffect(() => {
    setCountries(countriesList.list);
  },        []);

  useEffect(() => {
    if (isCreated) {
      setGeneratedPassword('');
      resetFields();
    }
    // tslint:disable-next-line: align
  }, [isCreated]);

  useEffect(
    () => {
      const fields = mergeErrors(fieldsValues, errors);
      if (fields) setFields(fields);
    // tslint:disable-next-line: align
    }, [errors, fieldsValues]
  );

  const handleSearch = (term: string) => {
    const filterRes = countriesList.list.filter((el: ICountry) => el.name.toLocaleLowerCase().includes(term)) || [];
    setCountries(filterRes);
  };

  return (
    <>
      <Header>
        <Row>
          <Col span={12}>
            <span className="header-title">Ajouter un nouveau profil</span>
          </Col>
          <Col span={12}>
            <span className="shortcut-btn">
              <Link to="/team">
                <Icon type="appstore" />
              </Link>
            </span>
          </Col>
        </Row>
      </Header>

      <Form onSubmit={handleFormSubmit} className="inner-padding">
        <Row>
          <Col>
            <Form.Item>
              Champs obligatoires <span className="required-field">*</span>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <span className="orange-title form-text-indicator">Informations personnelles</span>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('firstName', {
                    rules: [{ required: true, message: 'Veuillez entrer le prénom !' }],
                    validateTrigger: 'onBlur',
                  })(<Input type="text" placeholder="Prénom" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={12} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                    {getFieldDecorator('lastName', {
                    rules: [{ required: true, message: 'Veuillez entrer le nom !' }],
                    validateTrigger: 'onBlur',
                  })(<Input type="text" placeholder="Nom de Famille" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={8} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder empty />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('gender', {
                    rules: [{ required: true, message: 'Veuillez entrer le genre !' }],
                    validateTrigger: 'onBlur',
                  })(
                  <Select placeholder={ <FormElementPlaceholder text="-- Selectionner Genre --" /> }>
                    <Option value="Female">Femme</Option>
                    <Option value="Male">Homme</Option>
                  </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={8} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder empty />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('birthday')(
                    <DatePicker
                      placeholder="--Date d'anniversaire--"
                      style={{ width: '100%' }}
                      disabledDate={disableFutureDays}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={8} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder empty />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('birthdayAlert', {
                    validateTrigger: 'onBlur',
                  })(
                    <Select placeholder="-- Alerte Anniversaire --">
                      <Option value="1">Oui</Option>
                      <Option value="0">Non</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item >
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true, message: 'Veuillez entrer un email !' },
                      { type: 'email', message: 'l\'email n\'est pas valide !' },
                    ],
                    validateTrigger: 'onBlur',
                  })(<Input addonBefore={<Icon type="mail" />} type="email" placeholder="Adresse Email" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={12} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Veuillez entrer un mot de passe !' }],
                  initialValue: generatedPassword,
                  })(
                    <Input.Password
                      placeholder="Mot de passe"
                      addonAfter={(
                        <Tooltip title="Générer un mot de passe">
                          <Icon type="sync" onClick={generateNewPassword} />
                        </Tooltip>
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder empty />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('avatar', {
                    validateTrigger: 'onChange',
                    valuePropName: 'fileList',
                    getValueFromEvent: func,
                  })(
                    <Upload accept=".jpeg,.jpg,.png" {...avatarProps} style={{ width: '100%' }}>
                      <Input
                        addonBefore={<Icon type="camera" />}
                        type="button"
                        value="Télécharger une photo"
                        addonAfter={<Icon type="search" />}
                      />
                    </Upload>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={12} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('phone', {
                    rules: [{ required: true, message: 'Veuillez entrer un numéro de téléphone !' }],
                    validateTrigger: 'onBlur',
                  })(<Input addonBefore={<Icon type="phone" />} type="phone" placeholder="Numéro de Téléphone" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={8} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder empty />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('phoneExtra', {
                    validateTrigger: 'onBlur',
                  })(<Input addonBefore={<Icon type="phone" />} type="phone" placeholder="Numéro à appeler en cas d'urgence !" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={16} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('address', {
                    rules: [{ required: true, message: 'Veuillez entrer une adresse !' }],
                    validateTrigger: 'onBlur',
                  })(<Input type="text" placeholder="Adresse" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={8} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('zipCode', {
                    rules: [{ required: true, message: 'Veuillez entrer un code postal !' }],
                    validateTrigger: 'onBlur',
                  })(<Input type="text" placeholder="Code Postal" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={8} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('city', {
                    rules: [{ required: true, message: 'Veuillez entrer la ville !' }],
                    validateTrigger: 'onBlur',
                  })(<Input type="text" placeholder="Ville" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={8} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder empty />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('country', {
                    rules: [{ required: true, message: 'Veuillez entrer une pays !' }],
                    validateTrigger: 'onBlur',
                    initialValue: country || '-- Pays --',
                  })(
                    <Select
                      showSearch
                      placeholder="-- Pays --"
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      onSearch={handleSearch}
                      notFoundContent={null}
                    >
                      {countries.map((countryItem: ICountry) => (
                        <Option key={countryItem.code} value={countryItem.name}>
                          {countryItem.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <span className="orange-title form-text-indicator">Status & Niveau d'accès</span>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator('role', {
                validateTrigger: 'onBlur',
              })(
                <Select placeholder="-- Selectionner un niveau d'accès --">
                  {roleList.map(role => {
                    return (
                      <Option key={role.id} value={`/roles/${role.id}`}>
                        { role.title }
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Button loading={isLoading && true} htmlType="submit" className="btn-success">
          Créer Profil
        </Button>
      </Form >
    </>
  );
};

export const ProfileForm = Form.create<IProfileFormProps>()(ProfileFormValidation);
