import { Button, Col, Form, Icon, Input, Layout, Row, Select, Upload } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { FormElementPlaceholder } from '../../../../../components/FormElementPlaceholder';
import { GlobalState } from '../../../../../store';
import { filenameValidator } from '../../../../../utils/client-utils';
import { mergeErrors } from '../../../../../utils/form-errors-utils';
import { IClient, IErrors } from '../../../../../utils/types';

const { Option } = Select;
const { Header } = Layout;
const { TextArea } = Input;

interface IAddClientFormProps extends FormComponentProps {
  isCreated: boolean;
  errors: IErrors | null;
  onSubmitNewClient: (client: IClient) => void;
}

const AddClientFormInner: React.FC<IAddClientFormProps> = ({
  form,
  isCreated,
  errors,
  onSubmitNewClient,
}) => {

  const { getFieldDecorator, resetFields, getFieldsValue, setFields } = form;

  const token = useSelector((state: GlobalState) => state.auth.token);

  const [fieldsValues, setFieldsValues] = useState();

  const avatarProps = {
    name: 'file',
    action: `${process.env.REACT_APP_SERVER_URL}/avatars`,
    headers: { authorization: `Bearer ${token}` },
  };

  // tslint:disable-next-line: no-any
  const getAvatar = (e: any) => {
    return e?.fileList.slice(-1);
  };

  const submitNewClient = (e: React.FormEvent) => {
    e.preventDefault();
    form.validateFields((err, values) => {

      if (!err) onSubmitNewClient({
        ...values,
        logo: values.logo && values.logo[0].response['@id'],
      });
    });
    setFieldsValues(getFieldsValue());
  };

  useEffect(() => {
      if (isCreated) {
        resetFields();
      }
    },      [isCreated, resetFields]
  );

  useEffect(() => {
      const fields = mergeErrors(fieldsValues, errors);
      if (fields) setFields(fields);
    },      [errors, fieldsValues, setFields]
  );

  return (
    <>
      <Header>
        <Row>
          <Col span={12}>
            <span className="header-title">Ajouter un nouveau client</span>
          </Col>
          <Col span={12}>
            <span className="shortcut-btn">
              <Link to="/clients">
                <Icon type="appstore" />
              </Link>
            </span>
          </Col>
        </Row>
      </Header>
      <Form className="inner-padding" onSubmit={submitNewClient}>

        <Row>
          <Col>
            Champs obligatoires <span className="required-field">*</span>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder empty />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('type', {
                    rules: [{ required: true, message: 'Veuillez sélectionner un type !' }],
                  })(
                    <Select placeholder={ <FormElementPlaceholder text="-- Selectionner un type --" /> }>
                      <Option value="Client">Client</Option>
                      <Option value="Prospect">Prospect</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={12} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder empty />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('companyName', {
                    rules: [
                      { required: true, message: 'Veuillez entrer le nom de la société !' },
                      { validator: filenameValidator },
                    ],
                    validateTrigger: 'onBlur',
                  })(
                    <Input type="text" placeholder="Nom du client" />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={8} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('contactFirstName', {
                    rules: [{ required: true, message: 'Veuillez entrer le prénom !' }],
                    validateTrigger: 'onBlur',
                  })(<Input type="text" placeholder="Prénom du contact" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={8} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('contactLastName', {
                    rules: [{ required: true, message: 'Veuillez entrer le nom !' }],
                    validateTrigger: 'onBlur',
                  })(<Input type="text" placeholder="Nom du contact" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={8} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('phone', {
                    rules: [{ required: true, message: 'Veuillez entrer un numéro de téléphone !' }],
                    validateTrigger: 'onBlur',
                  })(<Input addonBefore={<Icon type="phone" />} type="phone" placeholder="Numéro de Téléphone" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true, message: 'Veuillez entrer un email !' },
                      { type: 'email', message: 'l\'email n\'est pas valide !' },
                    ],
                    validateTrigger: 'onBlur',
                  })(<Input addonBefore={<Icon type="mail" />} type="email" placeholder="Adresse Email" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={12} md={24}>
          <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('address', {
                    rules: [{ required: true, message: 'Veuillez entrer l\'adresse du client !' }],
                    validateTrigger: 'onBlur',
                  })(<Input type="text" placeholder="Adresse" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={8} md={24}>
            <Form.Item>
              {getFieldDecorator('gender')(
                <Select placeholder="-- Selectionner Genre --">
                  <Option value="Female">Femme</Option>
                  <Option value="Male">Homme</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xl={8} md={24}>
            <Form.Item>
              {getFieldDecorator('logo', {
                validateTrigger: 'onChange',
                valuePropName: 'fileList',
                getValueFromEvent: getAvatar,
              })(
                <Upload
                  {...avatarProps}
                  style={{ width: '100%' }}
                  accept=".jpeg,.png">
                  <Input
                    addonBefore={<Icon type="camera" />}
                    className="file"
                    type="button"
                    value="Logo de la société"
                    addonAfter={<Icon type="search" />}
                  />
                </Upload>
              )}
            </Form.Item>
          </Col>
          <Col xl={8} md={24}>
            <Form.Item>
              {getFieldDecorator('comment')(
                <TextArea
                  placeholder="Commentaire..."
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button type="primary" htmlType="submit" className="btn-success">
             + Ajouter Client
            </Button>
          </Col>
        </Row>

      </Form>
    </>
  );
};

export const AddClientForm = Form.create<IAddClientFormProps>()(AddClientFormInner);
