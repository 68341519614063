import { Col, Divider, Dropdown, Icon, Layout, Menu, Row, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CustomAvatar } from '../../../../components/CustomAvatar';
import { Logo } from '../../../../components/Logo';
import { ILayout, IRoute, routes } from '../../../../routes';
import { IConnectedUserData } from '../../../../store/connectedUser/types';
import { siderPermissionHandler } from '../../../../utils/permission-handlers';
import { resizeEventListener } from '../../../../utils/resizeEventListener';
import { IUserPermissions } from '../../../../utils/types';
import { siderWidth } from '../../../../utils/vars';

import './sider.less';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface IAppSiderProps {
  trigger?: null;
  connectedUser?: IConnectedUserData;
  isConnectedUserLoading: boolean;
  permissions: IUserPermissions | null;
  selectedKey: string[];
  onLogoutClick: () => void;
  onBriefModalTriggered: () => void;
}

const generateSubMenus = (subRoutes: IRoute[], permissions: IUserPermissions | null, handleBriefForm: (path?: string) => void) => {
  if (!subRoutes) return [];
  return subRoutes.map(r => {
    if (!r.hasSiderLink) return null;
    if (!r.routes) {
      if (!r.path) return null;
      // const isPermitted = siderPermissionHandler(permissions, r.resources);
      return (
        <Menu.Item key={r.path}>
          <Link onClick={() => handleBriefForm(r.path)} to={r.path}>
            {!!r.icon && <Icon type={r.icon} />}
            <span>{r.title}</span>
          </Link>
        </Menu.Item>
      );
    }
    const title = (
      <span>
        <Icon type={r.icon} />
        <span>{r.title}</span>
      </span>
    );
    return (
      <SubMenu key={r.name} title={title}>
        {generateSubMenus(r.routes, permissions, handleBriefForm)}
      </SubMenu>
    );
  });
};

const generateMenu = (layouts: ILayout[], permissions: IUserPermissions | null, handleBriefForm: (path?: string) => void) => {
  return layouts.map(layout => generateSubMenus(layout.routes, permissions, handleBriefForm));
};

export const AppSider: React.FC<IAppSiderProps> = ({
  trigger,
  isConnectedUserLoading,
  connectedUser,
  permissions,
  selectedKey,
  onLogoutClick,
  onBriefModalTriggered, ...rest }) => {

  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    resizeEventListener(setWindowWidth);
  },        []);

  const handleSubmenuClick = (keys: string[]) => {
    if (!keys.length) setOpenKeys([]);
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (latestOpenKey) setOpenKeys([latestOpenKey]);
  };

  const handleBriefForm = (path?: string) => {
    if (path === '#add-brief') onBriefModalTriggered();
  };

  const handleLogoutClick = () => onLogoutClick();

  const profileMenu = (
    <Menu>
      <Menu.Item key="0">
        <Link to={`/team/${connectedUser?.id}`}>
          <Row gutter={[8, 16]}>
            <Col span={4}>
              <Icon type="user" />
            </Col>
            <Col span={20}>Mon Profil</Col>
          </Row>
        </Link>
      </Menu.Item>

      <Menu.Item key="1">
        <Link to="#">
          <Row gutter={[8, 16]}>
            <Col span={4}>
              <Icon type="mail" />
            </Col>
            <Col span={20}>Messages</Col>
          </Row>
        </Link>
      </Menu.Item>

      <Menu.Item key="3">
        <Link to="#">
          <Row gutter={[8, 16]}>
            <Col span={4}>
              <Icon type="setting" />
            </Col>
            <Col span={20}>Paramètres</Col>
          </Row>
        </Link>
      </Menu.Item>

      <Menu.Item key="4">
        <Link to="#" onClick={handleLogoutClick}>
          <Row gutter={[8, 16]}>
            <Col span={4}>
              <Icon type="poweroff" />
            </Col>
            <Col span={20}>Logout</Col>
          </Row>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Spin spinning={ !permissions }>
      <Sider width={siderWidth} breakpoint="xl" trigger={trigger} {...rest}>
        <Tooltip placement="bottom" title="Lunatik Production">
          <Link to="/">
            <Logo width="60%" />
          </Link>
        </Tooltip>

        <div className="sider-user-coords-container">
          <Divider />
          <Dropdown overlay={profileMenu} trigger={isConnectedUserLoading ? ['contextMenu'] : ['click']}>
            <Row className="sider-user-coords" gutter={8} type="flex">
              <Col span={7}>
                <CustomAvatar size={40} shape="square" type="user" src={connectedUser?.avatar?.path} />
              </Col>
              {
                windowWidth > 1199 && (
                  <Col span={17}>
                    <span className="welcome-text">Bienvenue,</span>
                    <strong>
                      {isConnectedUserLoading ? 'Loading ...' : `${connectedUser?.firstName} ${connectedUser?.lastName}`.substr(0, 13) + '...'}
                      <Icon className="icon-size-10" type="caret-down" />
                    </strong>
                  </Col>
                )
              }
            </Row>
          </Dropdown>
          <Divider />
        </div>

        <div className="navigation-wrapper">
          <div className="sider-text-indicators">Prod.</div>

          <Menu mode="inline"
            openKeys={openKeys}
            onOpenChange={handleSubmenuClick}
            defaultSelectedKeys={['']}
            selectedKeys={selectedKey}
          >
            {generateMenu(routes, permissions, handleBriefForm)}

            <Menu.Item>
              <Icon type="calendar" />
              <span>Agenda</span>
            </Menu.Item>

            <Menu.Item>
              <Icon type="calendar" />
              <span>Demandes Congés</span>
            </Menu.Item>
            <div className="sider-text-indicators">App</div>
            <Menu.Item>
              <Icon type="message" />
              <span>Messenger</span>
            </Menu.Item>
            <div className="sider-text-indicators">Utilities</div>
            <Menu.Item>
              <Icon type="bar-chart" />
              <span>Statistiques</span>
            </Menu.Item>
            <Menu.Item>
              <Icon type="setting" />
              <span>Parametres</span>
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
    </Spin>
  );
};
