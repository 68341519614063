import { Alert, Button, Card, Checkbox, Form, Icon, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import React, { FormEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Logo } from '../../../../../components/Logo';
import { ICredentials } from '../../../../../store/auth/types';
import { IErrors } from '../../../../../utils/types';

interface IUserFormProps extends FormComponentProps {
  onLoginButtonClick: (credentials: ICredentials) => void;
  loginErrors: IErrors | null;
  isLoading?: boolean;
}

const LoginFormInner: React.FC<IUserFormProps> = props => {

  const { form, isLoading, loginErrors } = props;
  const { getFieldDecorator } = form;
  const [errors, setErrors] = useState<IErrors | null>(null);

  const handleFormSubmitEvent = (e: FormEvent) => {
    if (errors) setErrors(null);
    e.preventDefault();
    props.form.validateFields((err, { email, password }) => {
      if (!err) {
        const credentials = { email, password };
        props.onLoginButtonClick(credentials);
      }
    });
  };

  const loginGeneralErrors = errors && errors.general;

  useEffect(() => {
    setErrors(loginErrors);
  },        [loginErrors]);

  return (
    <div className="auth flex-container">
      <div>
        <Logo
          width={120}
          wrapperWidth={320}
          bottomSpacing={20}
        />
        <Card>
          <Form onSubmit={handleFormSubmitEvent}>

            <h2 className="auth-title">
              <FormattedMessage
                id="auth.login.headerText"
              />
            </h2>

            {
              loginGeneralErrors
              &&
              loginGeneralErrors.length
              &&
              loginGeneralErrors.map((m, i) => <Alert message={m} key={`${i}-${m}`} type="error" closable showIcon />)
            }

            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  { type: 'email', message: <FormattedMessage id="auth.login.emailValidity" /> },
                  { required: true, message: <FormattedMessage id="auth.login.emptyEmail" /> },
                ],
                validateTrigger: 'onBlur',
              })(<Input type="text" placeholder="Email" />)}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: <FormattedMessage id="auth.login.emptyPassword" /> },
                  { min: 6, message: <FormattedMessage id="auth.login.passwordLength" /> },
                ],
                validateTrigger: 'onBlur',
              })(<Input type="password" placeholder="Mot de passe" />)}
            </Form.Item>

            <Checkbox className="margin-bottom">
              <FormattedMessage id="auth.login.rememberMe" />
            </Checkbox>

            <Button
              className="auth-btn margin-bottom"
              disabled={isLoading && true}
              loading={isLoading && true}
              type="primary"
              shape="round"
              block
              htmlType="submit"
            >
              <FormattedMessage id="auth.login.loginBtn" />
            </Button>
            <Link to="/forgot-password">
              <Button className="auth-footer-btn" type="link" shape="round" block>
                <Icon className="lock-icon" type="lock" />
                {' '}
                <FormattedMessage id="auth.login.forgotPassword" /> ?
              </Button>
            </Link>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export const LoginForm = Form.create<IUserFormProps>()(LoginFormInner);
