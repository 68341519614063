import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { EnabledPeopleToggler } from '../../../components/EnabledPeopleToggler';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionsHandler } from '../../../components/permissions-handlers/PermissionsHandler';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { disableClient, getListClients, getSingleClient } from '../../../store/clients/actions';
import { IDisableTerm, IPageProps, IPermissionsHandler } from '../../../utils/types';

import { ListClients } from './components/ListClients';

// tslint:disable-next-line: no-empty-interface
interface IClientsPageProps extends IPageProps {}

const Clients: React.FC<IClientsPageProps & ConnectedProps<typeof connector>> = ({
  route,
  listClients,
  isLoading,
  getListClients: getListClientsAction,
  disableClient: disableClientAction,
}) => {
  const permissions: IPermissionsHandler | null = useContext(PermissionContext);
  const [activeState, setActiveState] = useState<boolean[]>([true]);

  useEffect(() => {
    getListClientsAction({
      status: null,
    });
  },        [getListClientsAction]);

  // Callbacks
  const getTogglerValue = (value: boolean[]) => {
    setActiveState(value);
    getListClientsAction({
      status: value,
    });
  };

  const getDisabledClient = (term: IDisableTerm) => {
    disableClientAction(term);
  };

  const loadMoreItems = (page: number) => {
    getListClientsAction({
      status: null,
      page,
    });
  };

  return (
    <>
      <CustomBreadcrumb route={route} />
      <div className="list-clients">
        <PageTitle title={route.title} />
        <PermissionsHandler userPermissions={permissions} blocPermission="CAN_VIEW" resourceName="Client" redirectOption>
          <EnabledPeopleToggler onActionChange={getTogglerValue} />

          <ListClients
            currentState={activeState}
            listClients={listClients}
            isLoading={isLoading}
            onDisableClient={getDisabledClient}
            permissions={permissions}
            onLoadMoreItemsChange={loadMoreItems}
          />
        </PermissionsHandler>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  getListClients,
  getSingleClient,
  disableClient,
};

const mapStateToPeops = (state: GlobalState) => ({
  listClients: state.clients.listClients,
  isLoading: state.clients.isLoading,
  client: state.clients.singleClient,
});

const connector = connect(mapStateToPeops, mapDispatchToProps);

export const ClientsPage = connector(Clients);
