import { Breadcrumb, Button, Col, Form, Icon, Input, Modal, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { FormEvent, useEffect, useState } from 'react';

import { IConnectedUserData } from '../../store/connectedUser/types';
import { IBriefs } from '../../store/project/types';
import { IBrief, IBriefToSave, IBudget, IBudgetFiles, IClient } from '../../utils/types';
import { Briefs } from '../Briefs';
import { FormElementPlaceholder } from '../FormElementPlaceholder';

import './brief-form-modal.less';

const { TextArea } = Input;
const { Option } = Select;

interface IBriefFormModalProps extends FormComponentProps {
  targetFile?: IBudgetFiles | null;
  projects: IBudget[];
  tergetBudget?: IBudget;
  briefsPack: IBriefs;
  isVisible: boolean;
  isLoading: boolean;
  briefs?: IBrief[];
  client?: IClient | null;
  isCreated?: boolean;
  connectedUser: IConnectedUserData;
  onGetBriefClick: (brief: IBriefToSave) => void;
  onModalCloseClick: () => void;
  onSelectBudget?: (id: string) => void;
}

const BriefFormModalInner: React.FC<IBriefFormModalProps> = ({
  isVisible,
  isLoading,
  briefsPack,
  tergetBudget,
  projects,
  targetFile,
  briefs,
  client,
  form,
  connectedUser,
  onGetBriefClick,
  onModalCloseClick,
  onSelectBudget,
}) => {

  const { getFieldDecorator, resetFields } = form;

  // States
  const [selectedProject, setSelectedProject] = useState('');

  // Methods
  const filterTargetBudget = (id: string, budgets: IBudget[]) => {
    return budgets.find(b => b.id === id.replace('/projects/', '')) || null;
  };

  // Effects
  useEffect(() => {
    if (briefsPack?.isCreated) onModalCloseClick();
    resetFields();
  },        [briefsPack.isCreated, resetFields]);

  // Callbacks
  const handleSubmitNewBrief = (e: FormEvent) => {
    e.preventDefault();
    form.validateFields((err: any, values: any) => {
      if (!err && (targetFile || selectedProject)) {
        // project: targetFile ? targetFile.project : `/projects/${ selectedProject }`
        if (targetFile) onGetBriefClick({
          additionalInfo: values.additionalInfo,
          file: `/files/${ targetFile.id }`,
        });
        else onGetBriefClick({
          additionalInfo: values.additionalInfo,
          project: `/projects/${ selectedProject }`,
        });
      }
    });
  };

  const cancelModal = () => {
    onModalCloseClick();
  };

  const getProjectId = (value: string) => {
    if (onSelectBudget) {
      const target = filterTargetBudget(value, projects);
      if (target) onSelectBudget(target.directory);
      setSelectedProject(value);
    }
  };

  return (
    <Modal
      className="brief-form-modal"
      title={(
        <Row gutter={ 16 }>
          <Col span={ 12 }>
            <span className="title">Ajouter un Brief Express</span>
          </Col>
          <Col span={ 12 }>
            <span className="modal-connected-user">
              Ajouter par { connectedUser.firstName } { connectedUser.lastName }
              <Icon type="clock-circle" />
            </span>
          </Col>
        </Row>
      )}
      visible={ isVisible }
      closable={ false }
      width={ 500 }
      footer={[
        (
        <Button key="back" type="danger" onClick={ cancelModal }>
          Annuler
        </Button>
        ),
        (
        <Button loading={ isLoading && true } key="submit" className="btn-success" onClick={ handleSubmitNewBrief }>
          + Ajouter un Brief
        </Button>
        ),
      ]}
    >
      <Form onSubmit={ handleSubmitNewBrief }>

        <Row>
          <Col>
            <Form.Item>
              Champs obligatoires <span className="required-field">*</span>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={ 16 }>
          <Col>
            {
              targetFile
              ?
              (
                <>
                  <Breadcrumb separator={ <Icon type="right" /> }>
                    <Breadcrumb.Item>{ client?.companyName }</Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {`${ tergetBudget?.name } - ${ tergetBudget?.files.length }`}
                      <span className="versions">v</span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <span className="file-name">{ targetFile.name }</span>
                </>
              )
              :
              (
                <Form.Item>
                  {getFieldDecorator('budget', {
                    rules: [{
                      required: true,
                      message: 'Veuillez selectionner un budget !',
                    }],
                    validateTrigger: 'onBlur',
                  })(
                    <Select onChange={ getProjectId } placeholder={ <FormElementPlaceholder text="-- Budget --" /> }>
                      {
                        projects.map((project) => {
                          return (
                            <Option key={ project.id } value={ project.id }>
                              <div>
                                <Icon type="folder" />
                                { ` ${ project.name }` }
                              </div>
                            </Option>
                          );
                        })
                      }
                    </Select>
                  )}
                </Form.Item>
              )
            }
          </Col>
        </Row>

        <Briefs
          briefs={ briefs || [] }
          isLoadingBriefs={ briefsPack.isLoading || isLoading }
        />

        <Row gutter={ 16 }>
          <Col xl={ 24 } md={ 24 }>
            <Row>
              <Col>
                <FormElementPlaceholder />
              </Col>
              <Col>
                <Form.Item>
                  {getFieldDecorator('additionalInfo', {
                    rules: [{
                      required: true,
                      message: 'Veuillez entrer vos informations !',
                    }],
                    validateTrigger: 'onBlur',
                  })(
                    <TextArea
                      placeholder="Informations complémentaires..."
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

      </Form>
    </Modal>
  );
};

export const BriefFormModal = Form.create<IBriefFormModalProps>()(BriefFormModalInner);
