import { Button, Form, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import React, { FormEvent, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { IResetPasswordData } from '../../../../../store/auth/types';

interface IUserFormProps extends FormComponentProps {
  onResetPasswordClick: (resetPasswordData: IResetPasswordData) => void;
  isLoading?: boolean;
  isReset: boolean;
}

const ResetPasswordFormInner: React.FC<IUserFormProps & RouteComponentProps> = props => {
  const { onResetPasswordClick, isLoading, isReset } = props;
  const { getFieldDecorator, getFieldValue, validateFields, resetFields } = props.form;

  // tslint:disable-next-line: no-any
  const validatePassword = (_rule: any, value: string, callback: (msg?: string) => void) =>
    value && value !== getFieldValue('password') ? callback('Mots de passe n\'est pas identique!') : callback();

  const handleFormSubmitEvent = (e: FormEvent) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const resetPasswordData = {
          password: values.password,
          confirmationToken: values.validationCode,
        };
        onResetPasswordClick(resetPasswordData);
      }
    });
  };

  useEffect(() => {
    if (isReset) resetFields();
  });

  return (
    <Form onSubmit={ handleFormSubmitEvent }>
      <Form.Item style={{ textAlign: 'left' }}>
        {getFieldDecorator('validationCode', {
          rules: [{ required: true, message: 'Veuillez entrer votre code de confirmation!' }],
        })(<Input type="text" placeholder="Entrer le code" />)}
      </Form.Item>

      <Form.Item style={{ textAlign: 'left' }}>
        {getFieldDecorator('password', {
          rules: [
            { required: true, message: 'Veuillez entrer votre nouveau mot de passe!' },
            { min: 6, message: '6 caractères minimum !' },
          ],
          validateTrigger: 'onBlur',
        })(<Input type="password" placeholder="Entrer une nouveau mot de passe" />)}
      </Form.Item>

      <Form.Item style={{ textAlign: 'left' }}>
        {getFieldDecorator('cpassword', {
          rules: [
            { required: true, message: 'Veuillez confirmer votre mot de passe !' },
            { validator: validatePassword },
          ],
          validateTrigger: 'onBlur',
        })(<Input type="password" placeholder="Confirmer votre mot de passe" />)}
      </Form.Item>

      <Button className="margin-bottom" loading={ isLoading && true } type="primary" shape="round" block htmlType="submit">
        Réinitialiser
      </Button>
    </Form>
  );
};

export const ResetPasswordForm = Form.create<IUserFormProps>()(ResetPasswordFormInner);
