import { Icon, Spin } from 'antd';
import React from 'react';

import './page-loader.less';

interface IPageLoaderProps {}

export const PageLoader: React.FC<IPageLoaderProps> = () => {
  return (
    <div className="page-loader-wrapper">
      <div className="logo-wrapper">
        <img src="/images/logo-150.png" alt="Lunatik Production | Logo" />
      </div>
      <Spin size="large" indicator={ <Icon type="loading" spin /> } />
      <div>Chargement...</div>
    </div>
  );
};
