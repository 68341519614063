import { Breadcrumb, Icon } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { IRoute } from '../../routes';

interface ICustomBreadcrumbProps {
  route: IRoute;
}

interface IBreadcrumbDictionary {
  fr: string;
  en: string;
}

export const CustomBreadcrumb: React.FC<ICustomBreadcrumbProps> = ({
  route,
}) => {

  const breadcrumbDictionary: IBreadcrumbDictionary[] = [
    {
      fr: 'Nouveau budget',
      en: 'new-budget',
    },
    {
      fr: 'Budgets en cours',
      en: 'in-progress',
    },
    {
      fr: 'Budgets à venir',
      en: 'to-come-up',
    },
    {
      fr: 'Budgets cloturés',
      en: 'closed',
    },
    {
      fr: 'Budgets archivés',
      en: 'archived',
    },
    {
      fr: 'Télécharger fichier',
      en: 'upload',
    },
    {
      fr: 'Équipe',
      en: 'team',
    },
    {
      fr: 'Dispatch équipe',
      en: 'dispatch',
    },
    {
      fr: 'Nouveau profil',
      en: 'create-profile',
    },
    {
      fr: 'Modifier profil',
      en: 'update-profile',
    },
    {
      fr: 'Nouveau client',
      en: 'add',
    },
    {
      fr: 'Modifier client',
      en: 'update',
    },
  ];

  const breadcrumbTranslater = (fragment: string, dictionary: IBreadcrumbDictionary[]) => {
    const item = dictionary.find(term => term.en === fragment);
    if (item) return item.fr;
    return fragment;
  };

  const getIDMatcher = (fragment: string, originalRoute: IRoute) => {
    if (fragment === ':id') return originalRoute.title;
    return fragment;
  };

  // const idCleaner = (arr: string[]) => {
  //   if (arr[0] !== 'budgets' && arr[arr.length - 1] === ':id') {
  //     arr.pop();
  //     return arr;
  //   }
  //   return arr;
  // };

  const generateBreads = (r: IRoute) => {
    if (!r.path) return null;
    const pathArray = r.path.split('/').filter(frag => frag);
    // pathArray = idCleaner(pathArray);
    if (pathArray.length === 1 && (pathArray[0] === 'notification' || pathArray[0] === 'unauthorized')) return null;
    if (pathArray.length === 2) pathArray.shift();
    if (pathArray.length === 3) pathArray.splice(0, 2);
    return pathArray.map((frag, i, arr) => {
      const url = `/${ arr.slice(0, i + 1).join('/') }`;
      const last = i === arr.length - 1;
      return last ? (
        <Breadcrumb.Item key={frag}>
          <span className="last">
            { breadcrumbTranslater(getIDMatcher(frag, r), breadcrumbDictionary) }
          </span>
        </Breadcrumb.Item>
      ) : (
        <Breadcrumb.Item key={frag}>
          <Link to={url}>
            <span>
              { breadcrumbTranslater(getIDMatcher(frag, r), breadcrumbDictionary) }
            </span>
          </Link>
        </Breadcrumb.Item>
      );
    });
  };
  return (
    <Breadcrumb separator={ <Icon type="right" /> }>
      <Breadcrumb.Item key="main-bc">
        <span style={{
          textTransform: 'capitalize',
        }}>
          { route.name }
        </span>
      </Breadcrumb.Item>
      {
        generateBreads(route)
      }
    </Breadcrumb>
  );
};
