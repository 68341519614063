import { EventSourcePolyfill } from 'event-source-polyfill';
import {
  alertGetToBeSentFiles,
  alertGetBudgetNotifications,
  alertGetToCheckFiles } from './actions';
  import { notification } from 'antd';

const formatObject = (obj) => {
  delete obj['@context'];
  delete obj.mercureIri;
  delete obj['@type'];
  delete obj['@id'];
  return obj;
}

const fireNotification = (notif) => {
  const audio = new Audio('/sounds/ring.ogg');
  audio.play();
  notification.open({
    message: notif.title,
    description: notif.description,
    placement: notif.placement,
  });
};

const triggerEventListenner = (eventSource, store, action) => {

  eventSource.addEventListener(action, (response) => {
    const entityAction = response.type;
    const incomingData = JSON.parse(response.data);
    const data = incomingData[0];
    
    let entity = data['@type'].split('/');
    entity = entity[entity.length - 1].toLowerCase();

    if (entityAction === 'Create') {
      if (entity === 'user') {
        fireNotification({
          title: 'Nouvelle Utilisateur',
          description: `Bienvenue parmi nous ${ data.firstName } ${ data.lastName }`,
          placement: 'bottomLeft',
        });
        store.dispatch(alertGetBudgetNotifications({
          type: 'new-user',
          data: formatObject(data),
        }));
        return;
      }
      else if (entity === 'project') {
        if (data.projectPriority) {
          fireNotification({
            title: 'Nouveau budget prioritaire',
            description: `Un nouveau projet ${ data.name } prioritaire a été ajouté.`,
            placement: 'bottomLeft',
          });
          store.dispatch(alertGetBudgetNotifications({
            type: 'budget-prio',
            data: formatObject(data),
          }));
          return;
        }
      }
    }
    else if (entityAction === 'Update') {
      let updates = incomingData[1];
      if (updates.status) {
        const isStatusChange = updates.status;
        /********** Handling Status updates *************/
        if (entity === 'project') {
          if (isStatusChange) {
            store.dispatch(alertGetBudgetNotifications({
              type: 'budget-status',
              data: formatObject({...data, changedAt: new Date()}),
            }));
            fireNotification({
              title: 'Mises à jour du projet',
              description: `Le projet ${ data.name } est passé à ${ data.status }.`,
              placement: 'bottomLeft',
            });
            return;
          }
        }
        else if (entity === 'file') {
          const fileStatus = isStatusChange[1];
          if (fileStatus === 'À checker') {
            store.dispatch(alertGetToCheckFiles(formatObject(data)));
            fireNotification({
              title: 'Un nouveau fichier à checker',
              description: `Le fichier ${ data.name } est passé à ${ data.status }`,
              placement: 'bottomLeft',
            });
            return;
          }
          else if (fileStatus === 'Transféré') {
            store.dispatch(alertGetToBeSentFiles(formatObject(data)));
            fireNotification({
              title: 'Un nouveau fichier à Transféré',
              description: `Le fichier ${ data.name } est passé à ${ data.status }`,
              placement: 'bottomLeft',
            });
            return;
          }
        }
        /********** Handling Status updates *************/
      }
    }
  });
};

/****************** Create | Update ******************/
/**
 * 
 * @param {'users' | 'projects' | 'files'} resource
 */
export const alertHandler = (store, resource) => {
  const mercureToken = localStorage.getItem('mercureToken');
  const url = new URL(`${ process.env.REACT_APP_MERCURE_HUB }/.well-known/mercure`);

  url.searchParams.append('topic', `${ process.env.REACT_APP_SERVER_URL }/${ resource }/{id}`);

  const eventSource = new EventSourcePolyfill(url, {
    headers: {
      Authorization: `Bearer ${ mercureToken }`,
    }
  });

  triggerEventListenner(eventSource, store, 'Create');
  triggerEventListenner(eventSource, store, 'Update');

};
