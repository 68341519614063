import { IClient, IFileSystemClient } from '../types';

export interface IClientToSave {
  id?: string;
  type: string;
  companyName: string;
  contactFirstName: string;
  contactLastName: string;
  gender: string;
  address: string;
  phone: string;
  email: string;
  comment: string;
  logo: {
    path: string;
  };
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
}

// tslint:disable-next-line: no-any
export const parseClientLogo = (data: any) => {
  if (Array.isArray(data)) {
    // tslint:disable-next-line: no-any
    const newData: any = [];
    // tslint:disable-next-line: no-any
    data.map((obj: any) => {
      obj.logo = obj.logo?.path;
      newData.push(obj);
    });
    return newData;
  }
  data.logo = data.logo?.path;
  return data;
};

export const reformatFilesystemClients = (clients: IFileSystemClient) => {
  let listClients: string[] = [];
  for (const key in clients) if (key) listClients = [...listClients, key];
  return listClients;
};

export const extractFileSystemProjectsByClient = (fsClients: IFileSystemClient, client: string, dbClients: IClient[]) => {
  const resClient = findClient(client, dbClients)?.companyName.toUpperCase();
  if (!resClient) return null;
  return fsClients[resClient].map(el => `${ resClient }/${ el }`);
};

export const findClient = (clientId: string, clients: IClient[]) => clients.find(({ id }) => id === clientId.replace('/clients/', ''));

/**
 * Validate a directory / file name
 */
export const filenameValidator = (rule: any, value: any) => {
  const invalidCharsASCIIList = [33, 34, 36, 37, 38, 47, 59, 60, 61, 62, 63, 64, 92];
  const valueToArray: string[] = Array.from(value);
  const hasInvalidChars = valueToArray.filter(str => invalidCharsASCIIList.includes(str.charCodeAt(0)));
  const reduceInvalidChars: string[] = [];
  hasInvalidChars.forEach(char => {
    if (!reduceInvalidChars.includes(char)) reduceInvalidChars.push(char);
  });
  if (hasInvalidChars.length) {
    const isSingleChar = reduceInvalidChars.length < 2;
    rule.message = `${reduceInvalidChars.join(' ')} n${isSingleChar ? '\'est' : 'e sont'} pas ${isSingleChar ? 'un' : 'des'} caractère${isSingleChar ? '' : 's'} valide${isSingleChar ? '' : 's'}.`;
    return false;
  }
  return true;
};
