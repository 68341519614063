import { Avatar, Card, Col, List, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { generateNotifications } from '../../../../utils/notification-utils';
import { renderPaginationItems } from '../../../../utils/table-pagination-item-render';
import { IBudget, IClient, INotification } from '../../../../utils/types';

import './list-notifs.less';

interface IListNotificationsProps {
  clients: IClient[];
  budgets: IBudget[];
  notifications: any[];
}

export const ListNotifications: React.FC<IListNotificationsProps> = ({ clients, budgets, notifications }) => {

  const [notifList, setNotifList] = useState<INotification[]>([]);

  useEffect(() => {
    if (notifications.length) setNotifList(generateNotifications(notifications, clients, budgets));
  },        [notifications, clients, budgets]);

  return (
    <List
      className="notifications-list has-cards"
      dataSource={notifList}
      pagination={{
        pageSize: 30,
        hideOnSinglePage: true,
        size: 'small',
        itemRender: (page, type, original) => renderPaginationItems(page, type, original),
      }}
      footer={<div>{`${ notifList.length } Notification${ notifList.length > 1 ? 's' : '' }`}</div>}
      renderItem={(item: INotification) => (
        <Card className={`notif ${ item.class }`}>
          <Row type="flex" align="middle" gutter={8}>
            <Col xl={2} md={3}>
              <Avatar size={40} shape="square" icon={ item.icon } />
            </Col>

            <Col xl={20} md={11}>
              <Row>
                <Col>
                  <span className="category">{ item.category }</span>
                </Col>
                <Col>
                  <span className="notif-content">{ item.content }</span>
                </Col>
              </Row>
            </Col>

            <Col xl={2} md={10}>
              <Row>
                <Col>
                  <span className="item-date">{ item.date }</span>
                </Col>
                <Col>
                  <span className="item-date">{ item.time }</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      )}
    />
  );
};
