import { Button, Checkbox, Col, Divider, Icon, Progress, Row, Table, Tag, Tooltip } from 'antd';
// tslint:disable-next-line: no-implicit-dependencies
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { BudgetDetailsModal } from '../../../../../components/BudgetDetailsModal';
import { TableStatusCounterFooter } from '../../../../../components/TableStatusCounterFooter';
import { IUser } from '../../../../../store/users/types';
import { mapUserById } from '../../../../../utils/filterUsersById';
import { permissionArrayFilter } from '../../../../../utils/permission-handlers';
import { getAlternativeStatus } from '../../../../../utils/status-transformer';
import { loadMoreItems } from '../../../../../utils/table-pagination-item-render';
import { IBrief, IBriefToSave, IBudget, IBudgetChangeStatus, IBudgetDetailsModal, IClient, IPermissionsHandler, IProjectStatus } from '../../../../../utils/types';
import { BudgetFilter } from '../BudgetFilter';
import { BudgetTeam } from '../BudgetTeam';

import './list-budgets.less';
interface IListBudgetsProps {
  listBudgets: IBudget[];
  byStatus: boolean;
  projectsCounter: IProjectStatus[];
  clients: IClient[];
  isLoading: boolean;
  listUsers: IUser[];
  permissions: IPermissionsHandler | null;
  connectedUserId?: string;
  listBriefs: IBrief[];
  isLoadingBriefs: boolean;
  onUpdateApplied: (budget: IBudgetChangeStatus) => void;
  onNewBriefModalTrigger: () => void;
  onBudgetDetailsClick: (budget: IBudget) => void;
  onNewBriefSubmit: (newBrief: IBriefToSave) => void;
  onLoadMoreItemsChange: (page: number) => void;
  onArchiveProject: (project: IBudgetChangeStatus) => void;
}

export const ListBudgets: React.FC<IListBudgetsProps> = ({
  byStatus,
  listBudgets,
  projectsCounter,
  isLoading,
  listUsers,
  clients,
  permissions,
  listBriefs,
  isLoadingBriefs,
  connectedUserId,
  onUpdateApplied,
  onNewBriefModalTrigger,
  onBudgetDetailsClick,
  onNewBriefSubmit,
  onLoadMoreItemsChange,
  onArchiveProject,
}) => {
  const { Column } = Table;

  // State list
  const [budgets, setBudgets] = useState<IBudget[] | undefined>();
  const [filterTabsStatus, setFilterTabsStatus] = useState<string[]>([]);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [itemsToChek, setItemsToChek] = useState('');
  const [isActionEnabled, setIsActionEnabled] = useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [progress, setProgress] = useState('');

  const [isModalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState<IBudgetDetailsModal | null>(null);
  // State list

  useEffect(() => {
    setCheckedItems([]);
  },        [listBudgets]);

  useEffect(() => {
    if (listBudgets && connectedUserId && permissions)
    setBudgets(permissionArrayFilter(listBudgets, permissions, 'Project', 'CAN_VIEW', connectedUserId));
  },        [listBudgets, connectedUserId, permissions]);

  // Separate client / status filters
  const separateFilterTerms = (terms: string[], filterBy: string | null, exclude?: string) => {
    if (!terms.length) return [];
    if (filterBy)
      return terms.filter(term => term.includes(filterBy)) || [];
    else if (exclude) return terms.filter(term => !term.includes(exclude)) || [];
    return [];
  };

  // Filter budgets to show
  const filterBudgetsToShow = (filterBy: string[], budgetList: IBudget[]) => {
    if (!filterBy.length) return budgetList;
    const statusTerm = separateFilterTerms(filterBy, null, '/clients/');
    const clientTerm = separateFilterTerms(filterBy, '/clients/');
    return budgetList.filter(budget => {
      if (statusTerm.length && clientTerm.length) return filterBy.includes(budget.status) && filterBy.includes(budget.client);
      return filterBy.includes(budget.status) || filterBy.includes(budget.client);
    });
  };

  // Getters
  const getFilterTabsStatus = (status: string[]) => {
    setFilterTabsStatus(status);
  };

  const getFilterByFields = (fields: string[]) => {
    setBudgets(filterBudgetsToShow(fields, listBudgets));
  };

  const getBudgetsPerPage = (perPage: number) => setItemPerPage(perPage);

  const getItemsToCheck = (toCheck: string) => {
    if (toCheck === 'all') {
      const listOfIds: string[] = listBudgets.map(({ id }) => id);
      setCheckedItems(listOfIds);
    } else if (toCheck === 'none') {
      setCheckedItems([]);
    } else {
      const listOfIds: any[] = listBudgets?.map(({ status, id }) => {
        return toCheck === status && id;
      });
      if (listOfIds) setCheckedItems(listOfIds);
      setItemsToChek(toCheck);
    }
  };

  const toggleEnableActions = (isEnable: boolean) => setIsActionEnabled(isEnable);

  const handleEachItemCheckbox = (id: string) => {
    if (checkedItems.includes(id)) {
      const index = checkedItems.indexOf(id);
      if (index > -1) checkedItems.splice(index, 1);
    } else setCheckedItems([...checkedItems, id]);
  };

  const getBudgetToUpdate = (action: string) =>
    onUpdateApplied({
      listIds: checkedItems,
      status: action,
    });

  useEffect(() => {
    const defaultVisibleTabs =  localStorage.getItem('lunatik-visible-tabs');
    if (defaultVisibleTabs) setFilterTabsStatus(JSON.parse(defaultVisibleTabs));
  },        [setFilterTabsStatus]);

  useEffect(() => {
    const defaultVisibleProgress =  localStorage.getItem('lunatik-visible-progression');
    if (defaultVisibleProgress) setProgress(defaultVisibleProgress);
  },        [setProgress]);

  const getProgressionValue = (ref: string) => setProgress(ref);

  const handleBriefModalTrigger = () => onNewBriefModalTrigger();

  // Return Client name
  const getClientName = (clientId: string) => {
    return (clientId && clients?.find(({ id }) => id === clientId.replace('/clients/', ''))) || null;
  };

  const handleBudgetDetailsModal = (budget: IBudget) => {
    const companyName = getClientName(budget.client)?.companyName;
    if (budget) setModalData({
      budget,
      client: companyName || '',
      relativeFiles: [],
    });
    onBudgetDetailsClick(budget);
    setModalVisible(true);
  };

  const handleModalCloseClick = () => setModalVisible(false);

  const getNewBrief = (brief: IBriefToSave) => onNewBriefSubmit(brief);

  const handleArchiveProject = (project: IBudgetChangeStatus) => onArchiveProject(project);

  return (
    <>
      <BudgetFilter
        byStatus={ byStatus }
        defaultTabs={ filterTabsStatus }
        permissions={permissions}
        budgets={listBudgets}
        clients={clients}
        onFilterTabsStatusChange={getFilterTabsStatus}
        onFilterFieldsChange={getFilterByFields}
        onBudgetPerPageChange={getBudgetsPerPage}
        onItemToCheckChange={getItemsToCheck}
        onToggleEnableActions={toggleEnableActions}
        onActionToApplyChange={getBudgetToUpdate}
        onProgressionChange={ getProgressionValue }
        onTriggerBriefModal={ handleBriefModalTrigger }
      />

      <Table<IBudget>
        pagination={{
          pageSize: itemPerPage,
          hideOnSinglePage: true,
          onChange: (page, size) => {
            if (size) {
              const pageNumber = loadMoreItems(size, page, listBudgets.length);
              if (pageNumber) onLoadMoreItemsChange(pageNumber);
            }
          },
        }}
        dataSource={budgets}
        locale={{
          emptyText: 'Aucun projet auquel vous êtes attribués.',
        }}
        rowKey={(record, i) => `key-${i}-${record.name}`}
        loading={isLoading}
        footer={() => <TableStatusCounterFooter totalIndicator formattedStatusList={ projectsCounter } category="budgets" />}
        scroll={{ x: 2700 }}>

        <Column
          key="action"
          fixed="left"
          width={ 70 }
          render={record => (
            <Checkbox
              disabled={!isActionEnabled}
              checked={checkedItems?.includes(record.id) || record.status === itemsToChek}
              onChange={() => handleEachItemCheckbox(record.id)}
            />
          )}
        />

        <Column
          title="Prio"
          key="projectPriority"
          width={ 90 }
          fixed="left"
          sorter={(a: IBudget, b: IBudget) => (a.projectPriority === b.projectPriority ? 0 : a.projectPriority ? -1 : 1)}
          render={record => (
            <Icon type="clock-circle" className={`priority ${ record.projectPriority ? 'is-prio' : '' }`} />
          )}
        />

        <Column
          key="budget"
          fixed="left"
          title="Budget"
          width={ 290 }
          ellipsis
          sorter={(a: IBudget, b: IBudget) => a.name.localeCompare(b.name)}
          render={record => (
            <Row>
              <Col className="list-budget-client">
                {getClientName(record.client)?.companyName}
              </Col>
              <Col>
                <Link className="list-budget-name" to={`/budgets/${record.id}`}>{record.name}</Link>
              </Col>
            </Row>
          )}
        />

        <Column
          key="recieved"
          title="Reçus"
          sorter={(a: IBudget, b: IBudget) => a.totalFiles - b.totalFiles}
          render={record => <span>{record.totalFiles}</span>}
        />

        {filterTabsStatus.includes('to-be-sent') && (
          <Column
            key="to-be-sent"
            title="A envoyer"
            sorter={(a: IBudget, b: IBudget) => a.totalFileToTransfer - b.totalFileToTransfer}
            render={record => <span>{record.totalFileToTransfer}</span>}
          />
        )}

        {filterTabsStatus.includes('todo') && (
          <Column
            key="to-do"
            title="A faire"
            sorter={(a: IBudget, b: IBudget) => a.totalFileToDo - b.totalFileToDo}
            render={record => <span>{record.totalFileToDo}</span>}
          />
        )}

        {filterTabsStatus.includes('internal-return') && (
          <Column
            key="r.interne"
            title="R.Interne"
            sorter={(a: IBudget, b: IBudget) => a.totalFileToRevise - b.totalFileToRevise}
            render={record => <span>{record.totalFileToRevise}</span>}
          />
        )}

        {filterTabsStatus.includes('client-return') && (
          <Column
            key="r.client"
            title="R. Client"
            sorter={ (a: IBudget, b: IBudget) => a.totalFileToRevise - b.totalFileToRevise }
            render={record => <span>-</span>}
          />
        )}

        {filterTabsStatus.includes('type') && (
          <Column
            key="type"
            title="Type"
            sorter={(a: IBudget, b: IBudget) => a.retouchType.localeCompare(b.retouchType)}
            render={record => (
              <span>
                <Icon type="instagram" />
              </span>
            )}
          />
        )}

        <Column
          title="Réception"
          key="startDate"
          sorter={(a: IBudget, b: IBudget) => moment(a.startDate).diff(moment(b.startDate))}
          render={record => <span>{moment(record.startDate).format('DD-MM-YYYY')}</span>}
        />
        <Column
          key="deadline"
          title="Dead Line"
          sorter={(a: IBudget, b: IBudget) => moment(a.deadline).diff(moment(b.deadline))}
          render={record => <span>{moment(record.deadline).format('DD-MM-YYYY')}</span>}
        />

        {filterTabsStatus.includes('delivery') && (
          <Column
            key="delivery"
            title="Livraison"
            sorter={(a: IBudget, b: IBudget) => moment(a.dateTransfer).diff(moment(b.dateTransfer))}
            render={record => <span>{!!record.dateTransfer && moment(record.dateTransfer).format('DD-MM-YYYY')}</span>}
          />
        )}

        <Column
          key="timing"
          title="Timing"
          sorter={(a: IBudget, b: IBudget) => a.totalProjectTime.localeCompare(b.totalProjectTime)}
          render={record => <span>{record.totalProjectTime}</span>}
        />

        <Column
          key="team"
          title="Team"
          width={190}
          ellipsis
          render={record => {
            const prodManagers = listUsers && mapUserById(listUsers, record.productionManager);
            const projManager = listUsers && mapUserById(listUsers, record.projectManager);
            const retoucher = listUsers && mapUserById(listUsers, record.retoucher);
            return (
              <BudgetTeam
                team={{
                  retoucher,
                  prodManagers,
                  projManager,
                }}
              />
            );
          }}
        />

        <Column
          key="progress"
          title="Progression"
          sorter={(a: IBudget, b: IBudget) => a.percentageCheckedFile - b.percentageCheckedFile}
          render={record => {
            if (progress === 'percent') return (
              <Progress percent={record.percentageCheckedFile} size="small" status="active" strokeWidth={3} />
            );
            if (progress === 'transferred') return (
              <span><b>{ record.totalFileTransferred }</b> fichies envoyés</span>
            );
            return <Progress percent={record.percentageCheckedFile} size="small" status="active" strokeWidth={3} />;
          }}
        />

        {filterTabsStatus.includes('status') && (
          <Column
            title="Statut"
            key="status"
            sorter={(a: IBudget, b: IBudget) => a.status.localeCompare(b.status)}
            render={record => (
              <Tag className={ getAlternativeStatus(record.status)?.en.toLowerCase().replace(' ', '-')} key={record.status}>
                {record.status}
              </Tag>
            )}
          />
        )}

        <Column
          fixed="right"
          title="Actions"
          key="actions"
          render={record => (
            <span>
              <Tooltip placement="top" title="Infos">
                <Button onClick={ () => handleBudgetDetailsModal(record) } icon="info" />
              </Tooltip>

              <Divider type="vertical" />

              <Tooltip placement="top" title="Archivé">
                <Button
                  onClick={ () => onUpdateApplied({
                    listIds: [record.id],
                    status: 'Archivé',
                    }) }
                  icon="switcher"
                />
              </Tooltip>

              <Divider type="vertical" />

              <Tooltip placement="left" title="Modifier">
                <Link to={`/budgets/update/${record.id}`}>
                  <Button icon="edit" />
                </Link>
              </Tooltip>

            </span>
          )}
        />
      </Table>
      <BudgetDetailsModal
        isVisible={isModalVisible}
        data={ modalData }
        users={ listUsers }
        briefs={listBriefs || []}
        isLoadingBriefs={isLoadingBriefs}
        onModalClose={ handleModalCloseClick }
        onCreateNewBrief={ getNewBrief }
        onArchiveProject={ handleArchiveProject }
      />
    </>
  );
};