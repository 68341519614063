import React, { useEffect, useState } from 'react';

import { IBudget, IBudgetFiles, IProjectStatus } from '../../utils/types';

interface ITableFooterProps {
  projects?: IBudget[];
  files?: IBudgetFiles[];
  location?: string;
  formattedStatusList?: IProjectStatus[];
  category: 'budgets' | 'files';
  totalIndicator: boolean;
}

export const TableStatusCounterFooter: React.FC<ITableFooterProps> = ({
  projects,
  files,
  formattedStatusList,
  location,
}) => {
  const [groupStatus, setGroupStatus] = useState<IProjectStatus[]>([]);
  const [total, setTotal] = useState(0);

  // Check if a given status exists in the list
  const isStatusExist = (status: string, list: IProjectStatus[]) => {
    if (!list.length) return null;
    return list.find(e => e.status === status);
  };

  // Determine the number of files for each status
  const getFilesStatusList = (fileList: IBudgetFiles[]) => {
    let listStatus: IProjectStatus[] = [];
    fileList.forEach(file => {
      const isExist = isStatusExist(file.status, listStatus);
      if (!!isExist)
        for (const i in listStatus) {
          if (listStatus[i].status === file.status) {
            listStatus[i].projectNumber += 1;
            break;
          }
        }
      else
        listStatus = [
          ...listStatus,
          {
            status: file.status,
            projectNumber: 1,
          },
        ];
    });
    return listStatus;
  };

  // Determine the number of budgets for each status
  const getBudgetsStatusList = (projectList: IBudget[], countLocation?: string) => {
    let listStatus: IProjectStatus[] = [];
    projectList.forEach(budget => {
      const status = budget.status === 'À faire' && countLocation === 'dashboard' ? 'Non entamé' : budget.status;
      const isExist = isStatusExist(status, listStatus);
      if (!!isExist)
        for (const i in listStatus) {
          if (listStatus[i].status === status) {
            listStatus[i].projectNumber += 1;
            break;
          }
        }
      else
        listStatus = [
          ...listStatus,
          {
            status,
            projectNumber: 1,
          },
        ];
    });
    return listStatus;
  };

  useEffect(() => {
    if (projects) setGroupStatus(getBudgetsStatusList(projects, location));
  },        [projects]);

  useEffect(() => {
    if (files) setGroupStatus(getFilesStatusList(files));
  },        [files]);

  useEffect(() => {
    if (formattedStatusList) setGroupStatus(formattedStatusList);
  },        [formattedStatusList]);

  const renderLastItem = (group: IProjectStatus, isLastItem: boolean) => {
    if (group.projectNumber !== 0) return <span key={group.status}>{`${group.status} ${group.projectNumber}${isLastItem ? '' : ','} `}</span>;
  };

  const countTotal = (group: IProjectStatus[]) => {
    let sum = 0;
    groupStatus.forEach(({ projectNumber }) => {
      sum += projectNumber;
    });
    return sum;
  };

  useEffect(() => {
    setTotal(countTotal(groupStatus));
  },        [groupStatus]);

  return (
    <span>
      { !!groupStatus.length && <span>Total {total}: </span> }
      {groupStatus.map((group, i, groups) => {
        if (groups.length === i + 1) return renderLastItem(group, true);
        return renderLastItem(group, false);
      })}
    </span>
  );
};
