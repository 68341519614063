import { Button } from 'antd';
import React from 'react';

export const renderPaginationItems = (
  page: number,
  type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
  original: React.ReactElement) => {
    if (type === 'prev') return <Button style={{ marginRight: 5 }} size="small" icon="caret-up" />;
    if (type === 'next') return <Button size="small" icon="caret-down" />;
    return original;
};

export const loadMoreItems = (pageSize: number, pageNumber: number, totalItems: number) => {
  if (pageSize * pageNumber === totalItems) {
    return totalItems / (pageSize * pageNumber) + 1;
  }
  return null;
};
