import { Button, Card, Col, Icon, Modal, Row, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';

import { ICheckpoints } from '../../../../store/project/types';

import './checkpoint.less';

interface ICheckpointModalProps {
  isModalVisible: boolean;
  checkpoints: ICheckpoints | null;
  onModalClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const CheckpointModal: React.FC<ICheckpointModalProps> = ({
  isModalVisible,
  onModalClose,
  checkpoints,
}) => {

  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onModalClose(e);
  };

  const modalTitle = (
    <Row>
      <Col span={ 12 }>
        <div className="orange-title">Check Point</div>
        <span className="checkpoint-modal-date">
        <Icon type="clock-circle" /> Edité le { moment(checkpoints?.date).format('DD/MM/YY') } à { moment(checkpoints?.date).format('hh:mm').replace(':', 'h') }
        </span>
      </Col>
      <Col span={ 6 } offset={ 6 }>
        <Row gutter={ 8 }>
          <Col span={ 6 }>
            <Tooltip title="Informations">
              <Button icon="info" />
            </Tooltip>
          </Col>
          <Col span={ 6 }>
            <Tooltip title="Partager">
              <Button icon="share-alt" />
            </Tooltip>
          </Col>
          <Col span={ 6 }>
            <Tooltip title="Télécharger">
              <a href={ `${ process.env.REACT_APP_SERVER_URL }${ checkpoints?.downloadLink }` } download>
                <Button icon="download" />
              </a>
            </Tooltip>
          </Col>
          <Col span={ 6 }>
            <Tooltip title="Imprimer">
              <Button icon="printer" />
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const checkpointContent = (
    <div style={{
      maxHeight: '90vh',
      overflowY: 'scroll',
    }}>
      <Card style={{ backgroundColor: '#f2f2f2' }}>
        <div style={{ marginBottom: 30 }}>
          <h2 style={{ marginBottom: 0 }}><strong>Check Point</strong></h2>
          <Icon type="clock-circle" style={{ fontSize: '10px' }} />
          <span
            style={{ fontSize: '10px', marginLeft: '5px' }}
          >
            Edité le { moment(checkpoints?.date).format('DD/MM/YY') } à { moment(checkpoints?.date).format('hh:mm').replace(':', 'h') }
          </span>
        </div>

        {
          checkpoints?.projects?.map(project => {
            return (
              <div className="checkpoint-item" key={ project.id } style={{ marginBottom: 20 }}>
                <h3 className="checkpoint-project-name">{ project.name }</h3>
                <Card
                  title={(
                    <Row gutter={ 8 }>
                      <Col span={ 1 }>
                        <Icon type="clock-circle" />
                      </Col>
                      <Col span={ 17 }>
                        <strong style={{ fontSize: 14 }}>{ project.name } - { project.totalFiles }v</strong>
                        <div className="checkpoint-team">
                          {
                            project.teams.productionManager
                            &&
                            <span>{ `${ project.teams.productionManager }, ` }</span>
                          }

                          {
                            project.teams.projectManager
                            &&
                            <span>{ `${ project.teams.projectManager }, ` }</span>
                          }

                          {
                            !!project?.teams.retouchers.length && project.teams.retouchers.map((retoucher, i) => {
                              if (project.teams.retouchers.length - 1 === i) return <span key={ retoucher }>{ retoucher }</span>;
                              return <span key={ retoucher }>{ retoucher }, </span>;
                            })
                          }
                        </div>
                      </Col>
                      <Col span={ 6 }>
                        <div style={{ textAlign: 'right', color: '#DE3544', fontSize: 10 }}>
                          <div>{`pour le (${ moment(project.deadline).format('DD/MM/YY') })`}</div>
                          <div>{`à ${ moment(project.deadline).format('hh:mm').replace(':', 'h') }`}</div>
                        </div>
                      </Col>
                    </Row>
                  )}
                >
                  <ul style={{ listStyle: 'none' }}>
                    <li key="i-project-100">{ project.totalFileTransfered }v Transféré</li>
                    <li key="i-project-101">{ project.totalFileToTransfer }v à envoyer</li>
                    <li key="i-project-102">{ project.totalFileToCheck }v à checker</li>
                    {
                      project.totalFileInProgress.length !== 0
                      &&
                      project?.totalFileInProgress.map(userInProgress => {
                        return <li key={ userInProgress }>1v en cours par { userInProgress }</li>;
                      })
                    }
                    <li key="i-project-103">{ project.totalFileToRevise }v à reviser</li>
                    <li key="i-project-104">{ project.totalFileToDo }v à faire</li>
                  </ul>
                </Card>
              </div>
            );
          })
        }

      </Card>
    </div>
  );

  return (
    <Modal
      visible={ isModalVisible }
      width={ 700 }
      title={ modalTitle }
      onCancel={ handleClose }
      closable={ false }
      centered
      className="checkpoint-modal"
      bodyStyle={{
        maxHeight: '100vh',
      }}
      footer={[
        (
        <Button type="danger" key="back" onClick={ handleClose }>
          Fermer
        </Button>
        ),
      ]}
    >
      { checkpointContent }
    </Modal>
  );
};
