import { Button, Col, Divider, Form, Icon, Input, Modal, Progress, Row, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { IUser } from '../../store/users/types';
import { statusToClass } from '../../utils/status-transformer';
import { IBrief, IBriefToSave, IBudgetChangeStatus, IBudgetDetailsModal } from '../../utils/types';
import { Briefs } from '../Briefs';
import { CustomAvatar } from '../CustomAvatar';

import './budget-details-modal.less';

const { TextArea } = Input;

interface IBudgetDetailsModalProps {
  isVisible: boolean;
  data: IBudgetDetailsModal | null;
  users: IUser[];
  briefs: IBrief[];
  isLoadingBriefs: boolean;
  onModalClose: () => void;
  onCreateNewBrief: (newBrief: IBriefToSave) => void;
  onArchiveProject: (project: IBudgetChangeStatus) => void;
}

export const BudgetDetailsModal: React.FC<IBudgetDetailsModalProps> = ({
  isVisible,
  data,
  users,
  briefs,
  isLoadingBriefs,
  onModalClose,
  onCreateNewBrief,
  onArchiveProject,
}) => {

  const [infos, setInfos] = useState('');
  const [canArchive, setCanArchive] = useState(false);
  const [isArchived, setArchived] = useState(false);

  useEffect(() => {
    if (data) {
      setCanArchive(
        data.budget.status !== 'Transféré' && data.budget.status !== 'Confirmé'
      );
      setArchived(data.budget.status === 'Archivé');
    }
  },        [data]);

  const getBudgetAutor = (authorId: string | undefined) => {
    if (authorId) return users.filter(({ id }) => id === authorId.replace('/users/', ''))[0];
    return null;
  };

  const author = getBudgetAutor(data?.budget.createdBy);

  const filterUsersById = (usersList: IUser[], team: string[] = []) => {
    return usersList.filter(({id}) => team.includes(id));
  };

  const RenderAvatar = (usersList: IUser[], team: string[]) => {
    const groupUsers = filterUsersById(usersList, team);
    return groupUsers.map(el => {
      return (
        <Tooltip key={el.id} title={`${ el.firstName } ${ el.lastName }`}>
          <CustomAvatar src={ el.avatar?.path } type="user" shape="square" />
        </Tooltip>
      );
    });
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setInfos(e.target.value);

  const hadleNewBriefSubmit = () => {
    if (data)
      onCreateNewBrief({
        additionalInfo: infos,
        project: data.budget.id,
      });
  };

  const handleArichiveProject = () => {
    if (data) onArchiveProject({
      listIds: [data.budget.id],
      status: 'Archivé',
    });
    onModalClose();
  };

  return (
    <Modal
      className="budget-details"
      visible={isVisible}
      width={600}
      title={(
        <>
          <Row gutter={8}>
            <Col span={ 12 }>
              <h3 className="client">{data?.client}</h3>
            </Col>
            <Col span={ 12 }>
              <Row type="flex" justify="end">
                <Col>
                  <span className="author">
                    Ajouter par <b>{`${author?.firstName} ${author?.lastName}`}</b>
                    <Icon type="clock-circle" />
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="budget-name">
                {data?.budget.name} - {data?.budget.files.length}v
              </h3>
            </Col>
          </Row>
        </>
      )}
      onCancel={onModalClose}
      closable={false}
      style={{ top: 20 }}
      footer={(
        <Row type="flex" justify="start">
          <Col span={12}>
            <div className="modal-buttons">
              <Button
                onClick={ handleArichiveProject }
                disabled={canArchive}
                type="ghost"
                className={!isArchived && !canArchive ? 'btn-success' : ''}
                icon="switcher"
              >
                Archiv{ isArchived ? 'é' : 'er' }
              </Button>
            </div>
          </Col>
        </Row>
      )}>
      <Row gutter={8}>
        <Col span={8}>
          Statut:{' '}
          {
            data &&
            (
            <Tag className={statusToClass(data.budget.status)}>
              {data.budget.status}
            </Tag>
            )
          }
        </Col>
        <Col span={8}>
          Type de Retouche: <Icon type="desktop" />
        </Col>
        <Col span={8}>
          <span className="prio">
            <Icon className={`priority ${ data?.budget.projectPriority ? 'is-prio' : '' }`} type="clock-circle" /> Budget Prioritaire
          </span>
        </Col>
      </Row>

      <Divider />

      <Row gutter={8}>
        <Col span={8}>
          <Row gutter={8}>
            <Col span={4}>
              <Icon type="clock-circle" />
            </Col>
            <Col span={20}>
              Date de début:
              <br />
              {moment(data?.budget.startDate).format('DD/MM/YY')} à {moment(data?.budget.startDate).format('HH:mm')}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={8}>
            <Col span={4}>
              <Icon type="clock-circle" />
            </Col>
            <Col span={20}>
              Dead line:
              <br />
              {moment(data?.budget.deadline).format('DD/MM/YY')} à {moment(data?.budget.deadline).format('HH:mm')}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <span>
            <Row gutter={8}>
              <Col span={4}>
                <Icon type="clock-circle" />
              </Col>
              <Col span={20}>
                Transféré le:
                <br />
                {
                  data?.budget.dateTransfer ?
                  `${moment(data?.budget.dateTransfer).format('DD/MM/YY')} à ${moment(data?.budget.dateTransfer).format('HH:mm')}`
                  : '--'
                }
              </Col>
            </Row>
          </span>
        </Col>
      </Row>

      <Divider />

      <Row className="budget-team" gutter={[16, 16]}>
        <Col span={ 24 }>
          <Row gutter={8}>
            <Col span={6}>
              <span>
                <Icon type="team" />
                Retouché par:
              </span>
            </Col>
            <Col span={18}>{!!data && RenderAvatar(users, data.budget.retouchersFiles)}</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={8}>
            <Col span={6}>
              <span>
                <Icon type="team" />
                Chécké par:
              </span>
            </Col>
            <Col span={18}>{!!data && RenderAvatar(users, data?.budget.checkerFilesProject)}</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={8}>
            <Col span={6}>
              <span>
                <Icon type="team" />
                Transféré par:
              </span>
            </Col>
            <Col span={18}>{!!data && RenderAvatar(users, data?.budget.transferredFilesProject)}</Col>
          </Row>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span={24}>
          <span>Progression:</span>
          <br />
          <Progress
            strokeWidth={3}
            percent={data?.budget.percentageCheckedFile}
            successPercent={ data?.budget.percentageCheckedFile }
          />
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span={24}>
        <Icon type="clock-circle" /> <span>Timing Moyen / image: { data?.budget.timeAverage }</span>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span={24}>
          <Briefs briefs={briefs} isLoadingBriefs={isLoadingBriefs} />
          <Form>
            <TextArea
              placeholder="Informations complémentaires..."
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={handleTextareaChange}
              onPressEnter={hadleNewBriefSubmit}
            />
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
