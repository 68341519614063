import { ActionCreator } from 'redux';

import { IDisableTerm, IDispatchTeamData, IErrors, IRole, IUpdateUser, IUserData } from '../../utils/types';

import {
  CREATE_NEW_USER,
  CREATE_NEW_USER_ERROR,
  CREATE_NEW_USER_SUCCESS,
  GET_DISPATCH_TEAM,
  GET_DISPATCH_TEAM_ERROR,
  GET_DISPATCH_TEAM_SUCCESS,
  GET_ROLES,
  GET_ROLES_ERROR,
  GET_ROLES_SUCCESS,
  GET_USER,
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  ICreateNewUser,
  ICreateNewUserError,
  ICreateNewUserSuccess,
  IGetDispatchTeamAction,
  IGetDispatchTeamErrorAction,
  IGetDispatchTeamSuccessAction,
  IGetListRoles,
  IGetRolesError,
  IGetRolesSuccess,
  IGetUserAction,
  IGetUserErrorAction,
  IGetUsersAction,
  IGetUsersErrorAction,
  IGetUsersSuccessAction,
  IGetUserSuccessAction,
  IUpdateUserAction,
  IUpdateUserErrorAction,
  IUpdateUserSuccessAction,
  IUser,
  IUserDisableEnableAction,
  IUserDisableEnableErrorAction,
  IUserDisableEnableSuccessAction,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  USER_DISABLE_ENABLE,
  USER_DISABLE_ENABLE_ERRROR,
  USER_DISABLE_ENABLE_SUCCESS,
} from './types';

/**
 * CREATE USER
 */
export const createNewUser = (profileData: IUserData): ICreateNewUser => ({
  type: CREATE_NEW_USER,
  payload: profileData,
});

export const createNewUserSuccess = (): ICreateNewUserSuccess => ({
  type: CREATE_NEW_USER_SUCCESS,
});

export const createNewUserError = (response: IErrors): ICreateNewUserError => ({
  type: CREATE_NEW_USER_ERROR,
  payload: response,
});

/**
 * UPDATE USER
 */
export const updateUser: ActionCreator<IUpdateUserAction> = (requestData: IUpdateUser): IUpdateUserAction => ({
  type: UPDATE_USER,
  payload: requestData,
});

export const updateUserSuccess: ActionCreator<IUpdateUserSuccessAction> = () => ({
  type: UPDATE_USER_SUCCESS,
});

export const updateUserError: ActionCreator<IUpdateUserErrorAction> = (response: IErrors) => ({
  type: UPDATE_USER_ERROR,
  payload: response,
});

/**
 * GET USERS
 */
export const getUsers: ActionCreator<IGetUsersAction> = (togglerValue: boolean[] | null) => ({
  type: GET_USERS,
  payload: togglerValue,
});

export const getUsersSuccess: ActionCreator<IGetUsersSuccessAction> = (usres: IUser[]) => ({
  type: GET_USERS_SUCCESS,
  payload: usres,
});

export const getUsersError: ActionCreator<IGetUsersErrorAction> = (error: IErrors) => ({
  type: GET_USERS_ERROR,
  payload: error,
});

/**
 * GET USER
 */
export const getUser = (id: string): IGetUserAction => ({
  type: GET_USER,
  payload: id,
});

export const getUserSuccess = (user: IUser): IGetUserSuccessAction => ({
  type: GET_USER_SUCCESS,
  payload: user,
});

export const getUserError = (error: IErrors): IGetUserErrorAction => ({
  type: GET_USER_ERROR,
  payload: error,
});

/**
 * Enable Disable User
 */
export const disableUser = (term: IDisableTerm): IUserDisableEnableAction => ({
  type: USER_DISABLE_ENABLE,
  payload: term,
});

export const disableUserSuccess = (): IUserDisableEnableSuccessAction => ({
  type: USER_DISABLE_ENABLE_SUCCESS,
});

export const disableUserError = (errors: IErrors): IUserDisableEnableErrorAction => ({
  type: USER_DISABLE_ENABLE_ERRROR,
  payload: errors,
});

/**
 * Roles
 */
export const getListRoles: ActionCreator<IGetListRoles> = () => ({
  type: GET_ROLES,
});

export const getListRoleSuccess: ActionCreator<IGetRolesSuccess> = (listRoles: IRole[]) => ({
  type: GET_ROLES_SUCCESS,
  payload: listRoles,
});

export const getListRoleError: ActionCreator<IGetRolesError> = (response: IErrors) => ({
  type: GET_ROLES_ERROR,
  payload: response,
});

/**
 * Get Users ==> Dispatch team
 */
export const getTispatchTeam = (page: number): IGetDispatchTeamAction => ({
  type: GET_DISPATCH_TEAM,
  payload: page,
});

export const getTispatchTeamSuccess = (team: IDispatchTeamData): IGetDispatchTeamSuccessAction => ({
  type: GET_DISPATCH_TEAM_SUCCESS,
  payload: team,
});

export const getTispatchTeamError = (errors: IErrors): IGetDispatchTeamErrorAction => ({
  type: GET_DISPATCH_TEAM_ERROR,
  payload: errors,
});
