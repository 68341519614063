export const formattedMessages = {
  en: {
    /* Auth global messages */
    'auth.login.emailValidity': 'Your email is invalid !',
    'auth.login.emptyEmail': 'Please enter your email !',
    'auth.login.emptyPassword': 'Please enter your password !',
    'auth.login.passwordLength': '6 characters minimum !',

    /* Login */
    'auth.login.headerText': 'Sign in to my account',

    'auth.login.passwordPlaceholder': 'Password',
    'auth.login.rememberMe': 'Remember me',
    'auth.login.loginBtn': 'login',
    'auth.login.forgotPassword': 'Forgot password',

    /* Forgot password */
    'auth.forgotpw.headerText': 'Forgot your password',
    'auth.forgotpw.sendBtn': 'Send',
    'auth.backToLoginLink': 'Login',
  },
  fr: {
    /* Auth global messages */
    'auth.login.emailValidity': 'Votre email n\'est pas valide !',
    'auth.login.emptyEmail': 'Veuillez entrer votre email !',
    'auth.login.emptyPassword': 'Veuillez entrer votre mot de passe !',
    'auth.login.passwordLength': '6 caractères minimum !',

    /* Login */
    'auth.login.headerText': 'Me connecter à mon compte',
    'auth.login.passwordPlaceholder': 'Mot de passe',
    'auth.login.rememberMe': 'Se souvenir de moi',
    'auth.login.loginBtn': 'connexion',
    'auth.login.forgotPassword': 'Mot de passe oublié',

    /* Forgot password */
    'auth.forgotpw.headerText': 'Mot de passe oublié',
    'auth.forgotpw.sendBtn': 'Envoyer',
    'auth.backToLoginLink': 'Connexion',
  },
};
