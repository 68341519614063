import { Action } from 'redux';

import { IErrors, IUserPermissions, IUserRole } from '../../utils/types';

export interface IConnectedUserData {
  id: string;
  firstName: string;
  lastName: string;
  gender: string;
  birthday: string;
  email: string;
  avatar: {
    path: string;
  };
  phone: string;
  phoneExtra: string;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  role: IUserRole;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
}

export interface IConnectedUser {
  userData?: IConnectedUserData;
  permissions: IUserPermissions | null;
  error: IErrors | null;
  loading: boolean;
}

export const GET_CONNECTED_USER_DATA = 'GET_CONNECTED_USER_DATA';
export type GET_CONNECTED_USER_DATA  = typeof GET_CONNECTED_USER_DATA;

export const GET_CONNECTED_USER_DATA_SUCCESS = 'GET_CONNECTED_USER_DATA_SUCCESS';
export type GET_CONNECTED_USER_DATA_SUCCESS  = typeof GET_CONNECTED_USER_DATA_SUCCESS;

export const GET_CONNECTED_USER_DATA_ERROR = 'GET_CONNECTED_USER_DATA_ERROR';
export type GET_CONNECTED_USER_DATA_ERROR  = typeof GET_CONNECTED_USER_DATA_ERROR;

export interface IGetConnectedUserDataAction extends Action<GET_CONNECTED_USER_DATA> {}

export interface IGetConnectedUserDataSuccessAction extends Action<GET_CONNECTED_USER_DATA_SUCCESS> {
  payload: IConnectedUserData;
}

export interface IGetConnectedUserDataErrorAction extends Action<GET_CONNECTED_USER_DATA_ERROR> {
  payload: IErrors;
}

export const CONNECTED_USER_GET_PERMISSIONS = 'CONNECTED_USER_GET_PERMISSIONS';
export type CONNECTED_USER_GET_PERMISSIONS  = typeof CONNECTED_USER_GET_PERMISSIONS;

export const CONNECTED_USER_GET_PERMISSIONS_SUCCESS = 'CONNECTED_USER_GET_PERMISSIONS_SUCCESS';
export type CONNECTED_USER_GET_PERMISSIONS_SUCCESS  = typeof CONNECTED_USER_GET_PERMISSIONS_SUCCESS;

export const CONNECTED_USER_GET_PERMISSIONS_ERROR = 'CONNECTED_USER_GET_PERMISSIONS_ERROR';
export type CONNECTED_USER_GET_PERMISSIONS_ERROR  = typeof CONNECTED_USER_GET_PERMISSIONS_ERROR;

export interface IConnectedUserGetPermissionsAction extends Action<CONNECTED_USER_GET_PERMISSIONS> {}

export interface IConnectedUserGetPermissionsSuccessAction extends Action<CONNECTED_USER_GET_PERMISSIONS_SUCCESS> {
  payload: IUserPermissions;
}

export interface IConnectedUserGetPermissionsErrorAction extends Action<CONNECTED_USER_GET_PERMISSIONS_ERROR> {
  payload: IErrors;
}

export type connectedUserActionType =
  IGetConnectedUserDataAction
  | IGetConnectedUserDataSuccessAction
  | IGetConnectedUserDataErrorAction
  | IConnectedUserGetPermissionsAction
  | IConnectedUserGetPermissionsSuccessAction
  | IConnectedUserGetPermissionsErrorAction;
