import React from 'react';

interface Props {
  width?: string | number;
  wrapperWidth?: string | number;
  bottomSpacing?: number;
  opacity?: string;
}

export const Logo: React.FC<Props> = (props) => {
  const { width, wrapperWidth, bottomSpacing, opacity } = props;
  return (
    <div
      className="img-block"
      style={{ width: (typeof wrapperWidth === 'number') ? `${ wrapperWidth }px` : wrapperWidth}}
    >
      <img
        src={`/images/logo.png`}
        alt="Lunatik Production - Logo"
        style={{
          width: (typeof width === 'number') ? `${ width }px` : width,
          marginBottom: bottomSpacing,
          opacity: opacity ? opacity : 1,
        }}
      />
    </div>
  );
};
