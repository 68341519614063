import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { PageLoader } from '../../../components/PageLoader';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { getListClients } from '../../../store/clients/actions';
import { getDirectories } from '../../../store/directory/actions';
import { createNewFile, uploadFileRequestUrl } from '../../../store/file/actions';
import { IFileInfos } from '../../../store/file/types';
import { getBudgetsByClient } from '../../../store/project/actions';
import { permissionArrayFilter } from '../../../utils/permission-handlers';
import * as roles from '../../../utils/roles-utils';
import { IBudget, IPermissionsHandler, IPageProps } from '../../../utils/types';

import { UploadFile } from './components/UploadFile';
import './transfer-file.less';

const TransferFileInner: React.FC<{} & RouteComponentProps & ConnectedProps<typeof connector> & IPageProps> = ({
  route,
  directories,
  preSigned,
  uploadErrors,
  clients,
  budgets,
  history,
  isClientLoading,
  isBudgetLoading,
  connectedUser,
  getDirectories: getDirectoriesAction,
  uploadFileRequestUrl: uploadFileRequestUrlAction,
  getListClients: getListClientsAction,
  getBudgetsByClient: getBudgetsByClientAction,
  createNewFile: createNewFileAction,
}) => {
  const permissions: IPermissionsHandler | null = useContext(PermissionContext);

  const [userBudgets, setUserBudgets] = useState<IBudget[]>([]);

  useEffect(() => {
    if (connectedUser && connectedUser.role.slug === roles.ROLE_RETOUCHER) history.push('/unauthorized');
  },        []);

  useEffect(() => {
    getDirectoriesAction();
  },        [getDirectoriesAction]);

  useEffect(() => {
    getListClientsAction({
      status: null,
    });
  },        [getListClientsAction]);

  useEffect(() => {
    if (connectedUser && permissions) {
      const filteredBudgets = permissionArrayFilter(budgets, permissions, 'Project', 'CAN_VIEW', connectedUser.id);
      if (filteredBudgets) setUserBudgets(filteredBudgets);
    }
  },        [budgets, permissions, connectedUser]);

  const getSelectedUploadFolder = (folder: string, filename: string) => {
    uploadFileRequestUrlAction({
      folder,
      filename,
    });
  };

  const getSelectedClient = (client: string) => getBudgetsByClientAction(client);

  const getFileInfos = (fileInfos: IFileInfos) => createNewFileAction(fileInfos);

  if (!permissions?.listPermissions && !permissions?.userRole && !clients && !budgets) return <PageLoader />;

  return (
    <>
      <CustomBreadcrumb route={route} />
      <Row className="file-manager" gutter={16}>
        <PageTitle title={route.title} />
        <Col span={24}>
          <UploadFile
            onRequestPreSignedUrl={getSelectedUploadFolder}
            onClientChange={getSelectedClient}
            onFileUploaded={getFileInfos}
            preSigned={preSigned}
            uploadErrors={uploadErrors}
            clients={clients}
            budgets={userBudgets}
            isClientLoading={isClientLoading}
            isBudgetLoading={isBudgetLoading}
          />
        </Col>
      </Row>
    </>
  );
};

const mapDispatchToProps = {
  getDirectories,
  uploadFileRequestUrl,
  getListClients,
  getBudgetsByClient,
  createNewFile,
};

const mapStateToProps = (state: GlobalState) => ({
  directories: state.directories.directories,
  preSigned: state.file.upload.preSigned,
  uploadErrors: state.file.upload.errors,
  clients: state.clients.listClients,
  budgets: state.project.listProjects,
  isClientLoading: state.clients.isLoading,
  isBudgetLoading: state.project.isLoading,
  filesDownload: state.file.filesToDwonload,
  connectedUser: state.connectedUser.userData,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const TransferFile = connector(TransferFileInner);
