import { IBudgetFiles, IDownloadTerms, IErrors, IFileChangeStatus, IFilePreviewUrls, IFilesAlertPayload, IFilesToDownload, IFileTeamToSave, ILogFile, IUpdateFilePriority } from '../../utils/types';

import {
  ADD_LOG_FILE,
  ADD_LOG_FILE_ERROR,
  ADD_LOG_FILE_SUCCESS,
  DOWNLOAD_FILE,
  DOWNLOAD_FILES,
  DOWNLOAD_FILES_ERROR,
  DOWNLOAD_FILES_SUCCESS,
  DOWNLOAD_FILE_ERROR,
  DOWNLOAD_FILE_SUCCESS,
  FILE_ADD_NEW,
  FILE_ADD_NEW_ERROR,
  FILE_ADD_NEW_SUCCESS,
  FILE_DISPATCH_TEAM,
  FILE_DISPATCH_TEAM_ERROR,
  FILE_DISPATCH_TEAM_SUCCESS,
  FILE_UPDATE_PRIORITY,
  FILE_UPDATE_PRIORITY_ERROR,
  FILE_UPDATE_PRIORITY_SUCCESS,
  FILE_UPDATE_STATUS,
  FILE_UPDATE_STATUS_ERROR,
  FILE_UPDATE_STATUS_SUCCESS,
  GET_BUDGET_FILES,
  GET_BUDGET_FILES_ERROR,
  GET_BUDGET_FILES_SUCCESS,
  GET_FILES_TO_BE_CHECKED,
  GET_FILES_TO_BE_CHECKED_ERROR,
  GET_FILES_TO_BE_CHECKED_SUCCESS,
  GET_FILES_TO_BE_SENT,
  GET_FILES_TO_BE_SENT_ERROR,
  GET_FILES_TO_BE_SENT_SUCCESS,
  GET_FILE_PREVIEW,
  GET_FILE_PREVIEW_ERROR,
  GET_FILE_PREVIEW_SUCCESS,
  GET_FILE_RETOUCH_LOGS,
  GET_FILE_RETOUCH_LOGS_ERROR,
  GET_FILE_RETOUCH_LOGS_SUCCESS,
  GET_SINGLE_FILE,
  GET_SINGLE_FILE_ERROR,
  GET_SINGLE_FILE_SUCCESS,
  IAddLogFileAction,
  IAddLogFileErrorAction,
  IAddLogFileSuccessAction,
  IDownloadFileAction,
  IDownloadFileErrorAction,
  IDownloadFilesAction,
  IDownloadFilesErrorAction,
  IDownloadFilesSuccessAction,
  IDownloadFileSuccessAction,
  IFileAddNewAction,
  IFileAddNewErrorAction,
  IFileAddNewSuccessAction,
  IFileDIspatchTeamAction,
  IFileDIspatchTeamErrorAction,
  IFileDIspatchTeamSuccessAction,
  IFileGetPreviewAction,
  IFileGetPreviewErrorAction,
  IFileGetPreviewSuccessAction,
  IFileInfos,
  IFilePreview,
  IFilesDownload,
  IFileStatus,
  IFileUpdateStatus,
  IFileUpdateStatusAction,
  IFileUpdateStatusError,
  IFileUpdateStatusErrorAction,
  IFileUpdateStatusSuccess,
  IFileUpdateStatusSuccessAction,
  IGetBudgetFilesAction,
  IGetBudgetFilesErrorAction,
  IGetBudgetFilesSuccessAction,
  IGetFileRetouchLogsAction,
  IGetFileRetouchLogsErrorAction,
  IGetFileRetouchLogsSuccessAction,
  IGetFilesToBeCheckedAction,
  IGetFilesToBeCheckedErrorAction,
  IGetFilesToBeCheckedSuccessAction,
  IGetFilesToBeSentAction,
  IGetFilesToBeSentErrorAction,
  IGetFilesToBeSentSuccessAction,
  IGetSingleFile,
  IGetSingleFileError,
  IGetSingleFileSuccess,
  IPreSigned,
  IRequestPreSignedURL,
  IRequestVersionPresignedURLAction,
  IRequestVersionPresignedURLErrorAction,
  IRequestVersionPresignedURLSuccessAction,
  IUpdateListFilesAction,
  IUpdateListFilesErrorAction,
  IUpdateListFilesSuccessAction,
  IUploadRequestUrlAction,
  IUploadRequestUrlErrorAction,
  IUploadRequestUrlSuccessAction,
  REQUEST_VERSION_PRESIGNED_URL,
  REQUEST_VERSION_PRESIGNED_URL_ERROR,
  REQUEST_VERSION_PRESIGNED_URL_SUCCESS,
  UPDATE_LIST_FILES,
  UPDATE_LIST_FILES_ERROR,
  UPDATE_LIST_FILES_SUCCESS,
  UPLOAD_FILE_REQUEST_URL,
  UPLOAD_FILE_REQUEST_URL_ERROR,
  UPLOAD_FILE_REQUEST_URL_SUCCESS,
} from './types';

/**
 * Request pre-signed URL
 */
export const uploadFileRequestUrl = (term: IRequestPreSignedURL): IUploadRequestUrlAction => ({
  type: UPLOAD_FILE_REQUEST_URL,
  payload: term,
});

export const uploadFileRequestUrlSuccess = (preSigned: IPreSigned): IUploadRequestUrlSuccessAction => ({
  type: UPLOAD_FILE_REQUEST_URL_SUCCESS,
  payload: preSigned,
});

export const uploadFileRequestUrlError = (errors: IErrors): IUploadRequestUrlErrorAction => ({
  type: UPLOAD_FILE_REQUEST_URL_ERROR,
  payload: errors,
});

/**
 * Add new file
 */
export const createNewFile = (fileInfos: IFileInfos): IFileAddNewAction => ({
  type: FILE_ADD_NEW,
  payload: fileInfos,
});

export const createNewFileSuccess = (): IFileAddNewSuccessAction => ({
  type: FILE_ADD_NEW_SUCCESS,
});

export const createNewFileError = (errors: IErrors): IFileAddNewErrorAction => ({
  type: FILE_ADD_NEW_ERROR,
  payload: errors,
});

/**
 * Get All Files
 */
export const getAllFiles = (id: string): IGetBudgetFilesAction => ({
  type: GET_BUDGET_FILES,
  payload: id,
});

export const getAllFilesSuccess = (files: IBudgetFiles[]): IGetBudgetFilesSuccessAction => ({
  type: GET_BUDGET_FILES_SUCCESS,
  payload: files,
});

export const getAllFilesError = (errors: IErrors): IGetBudgetFilesErrorAction => ({
  type: GET_BUDGET_FILES_ERROR,
  payload: errors,
});

/**
 * Download files
 */
export const downloadFiles = (folder: string): IDownloadFilesAction => ({
  type: DOWNLOAD_FILES,
  payload: folder,
});

export const downloadFilesSuccess = (files: IFilesDownload[]): IDownloadFilesSuccessAction => ({
  type: DOWNLOAD_FILES_SUCCESS,
  payload: files,
});

export const downloadFilesError = (errors: IErrors): IDownloadFilesErrorAction => ({
  type: DOWNLOAD_FILES_ERROR,
  payload: errors,
});

/**
 * Get Single File
 */
export const getSingleFile = (fileID: string): IGetSingleFile => ({
  type: GET_SINGLE_FILE,
  payload: fileID,
});

export const getSingleFileSuccess = (SingleFile: IBudgetFiles): IGetSingleFileSuccess => ({
  type: GET_SINGLE_FILE_SUCCESS,
  payload: SingleFile,
});

export const getSingleFileError = (errors: IErrors): IGetSingleFileError => ({
  type: GET_SINGLE_FILE_ERROR,
  payload: errors,
});

/**
 * Update File Status
 */
export const updateFileStatus = (singleFile: IFileStatus): IFileUpdateStatus => ({
  type: FILE_UPDATE_STATUS,
  payload: singleFile,
});

export const updateFileStatusSuccess = (): IFileUpdateStatusSuccess => ({
  type: FILE_UPDATE_STATUS_SUCCESS,
});

export const updateFileStatusError = (errors: IErrors): IFileUpdateStatusError => ({
  type: FILE_UPDATE_STATUS_ERROR,
  payload: errors,
});

/**
 * Update list of files' Status
 */
export const updateListFiles = (file: IFileChangeStatus): IUpdateListFilesAction => ({
  type: UPDATE_LIST_FILES,
  payload: file,
});

export const updateListFilesSuccess = (): IUpdateListFilesSuccessAction => ({
  type: UPDATE_LIST_FILES_SUCCESS,
});

export const updateListFilesError = (errors: IErrors): IUpdateListFilesErrorAction => ({
  type: UPDATE_LIST_FILES_ERROR,
  payload: errors,
});

/**
 * Update file (Dispatch file team)
 */
export const fileDispatchTeam = (team: IFileTeamToSave): IFileDIspatchTeamAction => ({
  type: FILE_DISPATCH_TEAM,
  payload: team,
});

export const fileDispatchTeamSuccess = (): IFileDIspatchTeamSuccessAction => ({
  type: FILE_DISPATCH_TEAM_SUCCESS,
});

export const fileDispatchTeamError = (errors: IErrors): IFileDIspatchTeamErrorAction => ({
  type: FILE_DISPATCH_TEAM_ERROR,
  payload: errors,
});

/**
 * Update file priority
 */
export const fileUpdatePriority = (newPrio: IUpdateFilePriority): IFileUpdateStatusAction => ({
  type: FILE_UPDATE_PRIORITY,
  payload: newPrio,
});

export const fileUpdatePrioritySuccess = (): IFileUpdateStatusSuccessAction => ({
  type: FILE_UPDATE_PRIORITY_SUCCESS,
});

export const fileUpdatePriorityError = (errors: IErrors): IFileUpdateStatusErrorAction => ({
  type: FILE_UPDATE_PRIORITY_ERROR,
  payload: errors,
});

/**
 * Get File Preview
 */
export const getFilePreview = (filePack: IFilePreview): IFileGetPreviewAction => ({
  type: GET_FILE_PREVIEW,
  payload: filePack,
});

export const getFilePreviewSuccess = (preview: IFilePreviewUrls): IFileGetPreviewSuccessAction => ({
  type: GET_FILE_PREVIEW_SUCCESS,
  payload: preview,
});

export const getFilePreviewError = (error: IErrors): IFileGetPreviewErrorAction => ({
  type: GET_FILE_PREVIEW_ERROR,
  payload: error,
});

/**
 * Get file logs
 */
export const getFileRetouchLogs = (term: IDownloadTerms): IGetFileRetouchLogsAction => ({
  type: GET_FILE_RETOUCH_LOGS,
  payload: term,
});

export const getFileRetouchLogsSuccess = (logs: IFilesToDownload[]): IGetFileRetouchLogsSuccessAction => ({
  type: GET_FILE_RETOUCH_LOGS_SUCCESS,
  payload: logs,
});

export const getFileRetouchLogsError = (error: IErrors): IGetFileRetouchLogsErrorAction => ({
  type: GET_FILE_RETOUCH_LOGS_ERROR,
  payload: error,
});

/**
 * Download brut file
 */
export const dowloadFile = (terms: IDownloadTerms): IDownloadFileAction => ({
  type: DOWNLOAD_FILE,
  payload: terms,
});

export const dowloadFileSuccess = (file: string): IDownloadFileSuccessAction => ({
  type: DOWNLOAD_FILE_SUCCESS,
  payload: file,
});

export const dowloadFileError = (errors: IErrors): IDownloadFileErrorAction => ({
  type: DOWNLOAD_FILE_ERROR,
  payload: errors,
});

/**
 * Request presigned URL for versionning
 */
export const requestVersionPresignedURL = (terms: IRequestPreSignedURL): IRequestVersionPresignedURLAction => ({
  type: REQUEST_VERSION_PRESIGNED_URL,
  payload: terms,
});

export const requestVersionPresignedURLSuccess = (presignedURL: string): IRequestVersionPresignedURLSuccessAction => ({
  type: REQUEST_VERSION_PRESIGNED_URL_SUCCESS,
  payload: presignedURL,
});

export const requestVersionPresignedURLError = (error: IErrors): IRequestVersionPresignedURLErrorAction => ({
  type: REQUEST_VERSION_PRESIGNED_URL_ERROR,
  payload: error,
});

/**
 * Add modified file log
 */
export const addLogFile = (file: ILogFile): IAddLogFileAction => ({
  type: ADD_LOG_FILE,
  payload: file,
});

export const addLogFileSuccess = (): IAddLogFileSuccessAction => ({
  type: ADD_LOG_FILE_SUCCESS,
});

export const addLogFileError = (errors: IErrors): IAddLogFileErrorAction => ({
  type: ADD_LOG_FILE_ERROR,
  payload: errors,
});

/**
 * Load FIles to check
 */
export const getFilesToBeChecked = (page: number): IGetFilesToBeCheckedAction => ({
  type: GET_FILES_TO_BE_CHECKED,
  payload: page,
});

export const getFilesToBeCheckedSuccess = (files: IFilesAlertPayload): IGetFilesToBeCheckedSuccessAction => ({
  type: GET_FILES_TO_BE_CHECKED_SUCCESS,
  payload: files,
});

export const getFilesToBeCheckedError = (errors: IErrors): IGetFilesToBeCheckedErrorAction => ({
  type: GET_FILES_TO_BE_CHECKED_ERROR,
  payload: errors,
});

/**
 * Load FIles to send
 */
export const getFilesToBeSent = (page: number): IGetFilesToBeSentAction => ({
  type: GET_FILES_TO_BE_SENT,
  payload: page,
});

export const getFilesToBeSentSuccess = (files: IFilesAlertPayload): IGetFilesToBeSentSuccessAction => ({
  type: GET_FILES_TO_BE_SENT_SUCCESS,
  payload: files,
});

export const getFilesToBeSentError = (errors: IErrors): IGetFilesToBeSentErrorAction => ({
  type: GET_FILES_TO_BE_SENT_ERROR,
  payload: errors,
});
