export const siderWidth = 220;

export const tablePagination = {
  pageSize: 10,
  totalRecievedItems: 30,
};

export const status = {
  inProgress: 'encours',
  toComeUp: 'àvenir',
  closed: 'cloturés',
  archived: 'archivés',
};

export const defaultAvatar = `/images/logo-light-192.png`;

export const clientDefaultAvatar = `/images/company.png`;

export const lastVisitedPath = 'lunatik-last-visited-pathname';
