import { Reducer } from 'redux';

import {
  connectedUserActionType,
  CONNECTED_USER_GET_PERMISSIONS,
  CONNECTED_USER_GET_PERMISSIONS_ERROR,
  CONNECTED_USER_GET_PERMISSIONS_SUCCESS,
  GET_CONNECTED_USER_DATA,
  GET_CONNECTED_USER_DATA_ERROR,
  GET_CONNECTED_USER_DATA_SUCCESS,
  IConnectedUser,
} from './types';

export const initialState: IConnectedUser = {
  permissions: null,
  error: null,
  loading: false,
};

export const connectedUserDataReducer: Reducer<typeof initialState, connectedUserActionType> = (state = initialState, action) => {
  switch (action.type) {

    case GET_CONNECTED_USER_DATA:
      return { ...state, loading: true };

    case GET_CONNECTED_USER_DATA_SUCCESS:
      return { ...state, loading: false, userData: action.payload };

    case GET_CONNECTED_USER_DATA_ERROR:
      return { ...state, loading: false };

    case CONNECTED_USER_GET_PERMISSIONS:
      return { ...state, loading: true };

    case CONNECTED_USER_GET_PERMISSIONS_SUCCESS:
      return { ...state, loading: false, permissions: action.payload };

    case CONNECTED_USER_GET_PERMISSIONS_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
};
