import { Button, Card } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Logo } from '../../../components/Logo';
import { PageTitle } from '../../../components/PageTitle';
import { GlobalState } from '../../../store';
import { requestResetPassword, resetErrors } from '../../../store/auth/actions';
import { IPageProps } from '../../../utils/types';

import { RequestResetPassword } from './components/RequestResetPassword';

// tslint:disable-next-line: no-empty-interface
interface IForgotPasswordProps extends IPageProps {}

export const ForgotPasswordConnect: React.FC<IForgotPasswordProps & RouteComponentProps & ConnectedProps<typeof connector>> = ({
  route,
  isLoading,
  hasRequestedNewPassword,
  history,
  errors,
  requestResetPassword: requestResetPasswordAction,
  resetErrors: resetErrorsAction,
}) => {

  // Callback for sending email
  const handleSendEmail = (email: string) => {
    requestResetPasswordAction(email);
  };

  useEffect(() => {
    if (hasRequestedNewPassword) history.push('/reset-password');
  },        [hasRequestedNewPassword, history]);

  useEffect(() => {
    return () => {
      resetErrorsAction();
    };
  },        [resetErrorsAction]);

  return (
    <div className="flex-container">
      <PageTitle title={ route.title } />
      <div className="anonym-form">
      <Logo
        width={ 120 }
        bottomSpacing={ 20 }
      />

        <Card style={{ width: '320px', textAlign: 'center' }}>
          <div className="dark-box-look">
            <h2 className="form-title">
              <FormattedMessage id="auth.forgotpw.headerText" />
            </h2>
            <RequestResetPassword isLoading={ isLoading } errors={errors} onSendEmailClick={handleSendEmail} />
            <Link to="/login">
              <Button icon="lock" type="link" shape="round" block>
                {' '}
                <FormattedMessage id="auth.backToLoginLink" />
              </Button>
            </Link>
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  requestResetPassword,
  resetErrors,
};

const mapStateToProps = (state: GlobalState) => ({
  isLoading: state.auth.isLoading,
  hasRequestedNewPassword: state.auth.hasRequestedNewPassword,
  errors: state.auth.requestResetPassword,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ForgotPassword = connector(ForgotPasswordConnect);
