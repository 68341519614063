import { Avatar, Card, Col, Row } from 'antd';
import React from 'react';

import './file-status.less';

interface IFileStatusCardProps {
  children: string;
  vNumber: number;
  icon: string;
  status: 'checked' | 'transferred';
  onStatusCardClick: (status: 'checked' | 'transferred') => void;
}

export const FileStatusCard: React.FC<IFileStatusCardProps> = ({ children, vNumber, icon, status, onStatusCardClick }) => {

  const handleFireCallback = () => onStatusCardClick(status);

  return (
    <Col xl={ 12 } md={ 12 }>
      <Card className="files-status" onClick={ handleFireCallback }>
        <Row>
          <Col span={ 10 }>
            <Avatar className={`status ${status}`} size={ 50 } shape="square" icon={ icon } />
          </Col>
          <Col span={ 14 }>
            <div className="text-col">
              <span className="text-indicator">{children}</span>
              <h1 className="versions">
                { vNumber }v
              </h1>
            </div>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
