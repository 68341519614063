import { Reducer } from 'redux';

import { ADD_PROJECT_BRIEF, ADD_PROJECT_BRIEF_ERROR, ADD_PROJECT_BRIEF_SUCCESS, budgetActionTypes, BUDGET_ADD, BUDGET_ADD_ERROR, BUDGET_ADD_SUCCESS, BUDGET_GET_ALL, BUDGET_GET_ALL_ERROR, BUDGET_GET_ALL_SUCCESS, BUDGET_GET_BY_CLIENT, BUDGET_GET_BY_CLIENT_ERROR, BUDGET_GET_BY_CLIENT_SUCCESS, BUDGET_GET_ONE, BUDGET_GET_ONE_ERROR, BUDGET_GET_ONE_SUCCESS, GET_CHECKPOINTS, GET_CHECKPOINTS_ERROR, GET_CHECKPOINTS_SUCCESS, GET_FILE_BRIEFS, GET_FILE_BRIEFS_ERROR, GET_FILE_BRIEFS_SUCCESS, GET_IN_PROGRESS_BUDGETS, GET_IN_PROGRESS_BUDGETS_ERROR, GET_IN_PROGRESS_BUDGETS_SUCCESS, GET_PROJECTS_NUMBER_PER_STATUS, GET_PROJECTS_NUMBER_PER_STATUS_ERROR, GET_PROJECTS_NUMBER_PER_STATUS_SUCCESS, GET_PROJECT_BRIEFS, GET_PROJECT_BRIEFS_ERROR, GET_PROJECT_BRIEFS_SUCCESS, GET_USER_BUDGETS, GET_USER_BUDGETS_ERROR, GET_USER_BUDGETS_SUCCESS, IBudgets, RESET_BRIEF_CREATED_PROPERTY, RESET_CHECKPOINT, UPDATE_LIST_BUDGETS, UPDATE_LIST_BUDGETS_ERROR, UPDATE_LIST_BUDGETS_SUCCESS, UPDATE_PROJECT, UPDATE_PROJECT_ERROR, UPDATE_PROJECT_SUCCESS } from './types';

const initialState: IBudgets = {
  listProjects: [],
  singleProject: null,
  isLoading: false,
  isCreated: false,
  isUpdated: false,
  projectsNumberPerStatus: [],
  checkpoints: null,
  isCheckpointLoading: false,
  briefs: {
    briefList: [],
    isCreated: false,
    isLoading: false,
    errors: null,
  },
  errors: null,
  budgetInProgress: [],
  relativeFileBriefs: {
    plainBriefs: [],
    isLoading: false,
    errors: null,
  },
};

export const budgetReducer: Reducer<typeof initialState, budgetActionTypes> = (state = initialState, action) => {
  switch (action.type) {

    case BUDGET_ADD:
      return { ...state, isLoading: true, isCreated: false, errors: null };

    case BUDGET_ADD_SUCCESS:
      return { ...state, isLoading: false, isCreated: true };

    case BUDGET_ADD_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case BUDGET_GET_ALL:
      return { ...state, isLoading: true, errors: null };

    case BUDGET_GET_ALL_SUCCESS:
      if (action.payload.page === 1)
        return { ...state, isLoading: false, listProjects: action.payload.budgets };
      return { ...state, isLoading: false, listProjects: [...state.listProjects, ...action.payload.budgets] };

    case BUDGET_GET_ALL_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case BUDGET_GET_ONE:
      return { ...state, isLoading: true, errors: null };

    case BUDGET_GET_ONE_SUCCESS:
      return { ...state, isLoading: false, singleProject: action.payload };

    case BUDGET_GET_ONE_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case BUDGET_GET_BY_CLIENT:
      return { ...state, isLoading: true, errors: null };

    case BUDGET_GET_BY_CLIENT_SUCCESS:
      return { ...state, isLoading: false, listProjects: action.payload };

    case BUDGET_GET_BY_CLIENT_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case GET_USER_BUDGETS:
      return { ...state, isLoading: true, errors: null };

    case GET_USER_BUDGETS_SUCCESS:
      if (action.payload.page === 1)
        return { ...state, isLoading: false, listProjects: action.payload.budgets };
      return { ...state, isLoading: false, listProjects: [...state.listProjects, ...action.payload.budgets] };

    case GET_USER_BUDGETS_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case UPDATE_LIST_BUDGETS:
      return { ...state, isLoading: true, errors: null };

    case UPDATE_LIST_BUDGETS_SUCCESS:
      return { ...state, isLoading: false };

    case UPDATE_LIST_BUDGETS_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case GET_CHECKPOINTS:
      return { ...state, isCheckpointLoading: true, errors: null };

    case GET_CHECKPOINTS_SUCCESS:
      return { ...state, isCheckpointLoading: false, checkpoints: action.payload };

    case GET_CHECKPOINTS_ERROR:
      return { ...state, isCheckpointLoading: false, errors: action.payload };

    case RESET_CHECKPOINT:
      return { ...state, checkpoints: null };

    case ADD_PROJECT_BRIEF:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isLoading: true,
          isCreated: false,
        },
      };

    case ADD_PROJECT_BRIEF_SUCCESS:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isLoading: false,
          isCreated: true,
        },
      };

    case ADD_PROJECT_BRIEF_ERROR:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isLoading: false,
          errors: action.payload,
        },
      };

    case GET_PROJECT_BRIEFS:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isLoading: true,
        },
      };

    case GET_PROJECT_BRIEFS_SUCCESS:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isLoading: false,
          briefList: action.payload,
        },
      };

    case GET_PROJECT_BRIEFS_ERROR:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isLoading: false,
          errors: action.payload,
        },
      };

    case RESET_BRIEF_CREATED_PROPERTY:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          isCreated: false,
        },
      };

    case GET_IN_PROGRESS_BUDGETS:
      return { ...state, isLoading: true };

    case GET_IN_PROGRESS_BUDGETS_SUCCESS:
      return { ...state, isLoading: false, budgetInProgress: action.payload };

    case GET_IN_PROGRESS_BUDGETS_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case GET_FILE_BRIEFS:
      return {
        ...state,
        relativeFileBriefs: {
          ...state.relativeFileBriefs,
          isLoading: true,
        },
      };

    case GET_FILE_BRIEFS_SUCCESS:
      if (!action.payload.length) return {
          ...state,
          briefs: {
            ...state.briefs,
            briefList: [],
          },
          relativeFileBriefs: {
            ...state.relativeFileBriefs,
            isLoading: false,
            plainBriefs: action.payload,
          },
        };
      else return {
        ...state,
        relativeFileBriefs: {
          ...state.relativeFileBriefs,
          isLoading: false,
          plainBriefs: action.payload,
        },
      };

      case GET_FILE_BRIEFS_ERROR:
        return {
          ...state,
          relativeFileBriefs: {
            ...state.relativeFileBriefs,
            isLoading: false,
            errors: action.payload,
          },
        };

      case GET_PROJECTS_NUMBER_PER_STATUS:
        return { ...state, isLoading: true, errors: null };

      case GET_PROJECTS_NUMBER_PER_STATUS_SUCCESS:
        return { ...state, isLoading: false, projectsNumberPerStatus: action.payload };

      case GET_PROJECTS_NUMBER_PER_STATUS_ERROR:
        return { ...state, isLoading: false, errors: action.payload };

      case UPDATE_PROJECT:
        return { ...state, isLoading: true, errors: null };

      case UPDATE_PROJECT_SUCCESS:
        return { ...state, isLoading: false, isUpdated: true };

      case UPDATE_PROJECT_ERROR:
        return { ...state, isLoading: false, errors: action.payload };

    default:
      return state;
  }
};
