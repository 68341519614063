import { Layout, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { EnabledPeopleToggler } from '../../../components/EnabledPeopleToggler';
import { PageLoader } from '../../../components/PageLoader';
import { PageTitle } from '../../../components/PageTitle';
import { SiderProfileDetails } from '../../../components/SiderProfileDetails';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { disableUser, getUsers } from '../../../store/users/actions';
import { IDisableTerm, IPageProps, IPermissionsHandler } from '../../../utils/types';
import { siderWidth } from '../../../utils/vars';

import { ListUsers } from './components/ListUsers';
import './manage-profile.less';

const { Content, Sider } = Layout;

// tslint:disable-next-line: no-empty-interface
interface IManageProfilesProps extends IPageProps {}

const ManageProfilesInner: React.FC<IManageProfilesProps & ConnectedProps<typeof connector>> = ({
  route,
  users,
  isLoadingUsers,
  isDisableComplete,
  connectedUser,
  getUsers: getUsersAction,
  disableUser: disableUserAction,
}) => {
  const permissions: IPermissionsHandler | null = useContext(PermissionContext);
  const [activeStatus, setActiveStatus] = useState<boolean[]>([true]);

  const getDisableUser = (term: IDisableTerm) => {
    disableUserAction(term);
  };

  useEffect(() => {
    getUsersAction();
    // tslint:disable-next-line: align
  }, [getUsersAction, isDisableComplete]);

  const getTogglerValues = (togglerValue: boolean[]) => {
    setActiveStatus(togglerValue);
    getUsersAction(togglerValue);
  };

  if (!permissions?.listPermissions && !permissions?.userRole && !users) return <PageLoader />;

  return (
    <>
      <CustomBreadcrumb route={route} />
      <Layout className="page-layout">
        <PageTitle title={route.title} />
        <Layout>
          <Content className="inner-layout-content">
            {/* Begins */}

            <Row className="team-list" gutter={[30, 30]}>
              <EnabledPeopleToggler onActionChange={getTogglerValues} />
              <ListUsers
                currentStatus={activeStatus}
                users={users}
                onDisableUser={getDisableUser}
                permissions={permissions}
                connectedUserId={connectedUser?.id}
              />
            </Row>

            {/* Ends */}
          </Content>

          <Sider width={siderWidth} className="profile-sider-data manage-profiles">
            {!!connectedUser && <SiderProfileDetails userData={connectedUser} />}
          </Sider>
        </Layout>
      </Layout>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  users: state.listUsers.users,
  token: state.auth.token,
  isLoadingUsers: state.listUsers.loading,
  connectedUser: state.connectedUser.userData,
  isDisableComplete: state.listUsers.isEnablingComplete,
});

const mapDispatchToProps = {
  getUsers,
  disableUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ManageProfiles = connector(ManageProfilesInner);
