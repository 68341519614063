import { Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ConnectedUserSider } from '../../../components/ConnectedUserSider';
import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { PageTitle } from '../../../components/PageTitle';
import { SiderClientsList } from '../../../components/SiderClientsList';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { disableClient, getListClients } from '../../../store/clients/actions';
import { IClient, IPageProps, IPermissionsHandler } from '../../../utils/types';
import { siderWidth } from '../../../utils/vars';

import { BlockDetailClient } from './components/BlockDetailClient';

const { Sider, Content } = Layout;

// tslint:disable-next-line: no-empty-interface
interface IDetailClientProps extends IPageProps {}
const DetailClientInner: React.FC<IDetailClientProps & ConnectedProps<typeof connector> & RouteComponentProps<{ id: string }>> = ({
  route,
  match,
  listClient,
  history,
  getListClients: getListClientsAction,
  disableClient: disableClientAction,
  connectedUserData,
}) => {
  const permissions: IPermissionsHandler | null = useContext(PermissionContext);

  const [client, setClient] = useState<IClient | null>(null);

  useEffect(() => {
    getListClientsAction({
      status: [true, false],
    });
    // tslint:disable-next-line: align
  }, [getListClientsAction]);

  useEffect(() => {
    const selectedClient = listClient.filter(user => user.id === match.params.id)[0];
    selectedClient ? setClient(selectedClient) : history.replace('/clients');
  },        [listClient, history, match.params.id]);

  const getDesactivateConfirmation = () => {
    if (client)
      disableClientAction({
        id: match.params.id,
        nextStatus: !client.enabled,
        name: client.companyName,
      });
  };

  return (
    <>
      <CustomBreadcrumb route={route} />
      <Layout className="page-layout has-card-view">
        {client && <PageTitle title={`Client ${client.companyName}`} />}
        <Sider width={siderWidth}>
          {!!connectedUserData && <ConnectedUserSider userData={connectedUserData} />}
          <SiderClientsList listPeople={listClient} selectedUser={match.params.id} />
        </Sider>

        <Layout>
          <Content>
            {!!connectedUserData && (
              <BlockDetailClient
                client={client}
                connectedUser={connectedUserData}
                permissions={permissions}
                onDesactivateConfirmed={getDesactivateConfirmation}
              />
            )}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

const mapDispatchToProps = {
  getListClients,
  disableClient,
};

const mapStateToProps = (state: GlobalState) => ({
  listClient: state.clients.listClients,
  connectedUserData: state.connectedUser.userData,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const DetailClient = connector(DetailClientInner);
