import { Col, Icon, Row, Tooltip } from 'antd';
import React from 'react';

import { IBrief } from '../../utils/types';

import './briefs.less';

interface IBriefsProps {
  briefs: IBrief[];
  isLoadingBriefs: boolean;
}

export const Briefs: React.FC<IBriefsProps> = ({ briefs, isLoadingBriefs }) => {
  return (
    <div className="briefs-list-wrapper">
      <Row gutter={ 16 }>
        <Col span={ 12 }>
          <span className="modal-initial-brief">
            <Icon type="edit" /> Brief initial
          </span>
        </Col>
        <Col span={ 12 }>
          <span className="modal-folder-access">
            <Icon type="search" /> Accéder au dossier
          </span>
        </Col>
      </Row>
      <div
        className="brief-list clearfix">
        {isLoadingBriefs && (
          <span>
            <Icon type="loading" /> Loading...
          </span>
        )}
        {!briefs?.length && !isLoadingBriefs ? (
          <div className="empty-brief-list">
            <Icon type="folder-open" /> Dossier vide !
          </div>
        ) : (
          !isLoadingBriefs &&
          briefs?.map((brief, i) => {
            return (
              <span className="brief-item" key={`file-${i}`}>
                <Tooltip title={brief.filename}>
                  <a href={brief.url} download>
                    <img
                      src={`/images/brief-files/${brief.ext}.png`}
                      alt={ brief.filename }
                    />
                  </a>
                </Tooltip>
              </span>
            );
          })
        )}
      </div>
    </div>
  );
};
