import { Button, Card } from 'antd';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import { Logo } from '../../../components/Logo';
import { PageTitle } from '../../../components/PageTitle';
import { GlobalState } from '../../../store';
import { resetPassword, resetRequestedNewPassword } from '../../../store/auth/actions';
import { IResetPasswordData } from '../../../store/auth/types';
import { IPageProps } from '../../../utils/types';

import { ResetPasswordForm } from './components/ResetPasswordForm';

// tslint:disable-next-line: no-empty-interface
interface IForgotPasswordProps extends IPageProps {}

export const ResetPasswordInner: React.FC<IForgotPasswordProps & ConnectedProps<typeof connector>> = ({
  route,
  isLoading,
  isReset,
  resetPassword: resetPasswordAction,
  resetRequestedNewPassword: resetRequestedNewPasswordAction,
}) => {
  // Callback gets the new password and its confirm one
  const handleResetPassword = (resetPasswordData: IResetPasswordData) => {
    resetPasswordAction(resetPasswordData);
  };

  useEffect(() => {
    resetRequestedNewPasswordAction();
  },        [resetRequestedNewPasswordAction]);

  return (
    <div className="flex-container">
      <PageTitle title={ route.title } />
      <div className="anonym-form">
        <Logo width={120} bottomSpacing={20} />

        <Card style={{ width: '320px', textAlign: 'center' }}>
          <div className="dark-box-look">
            <h2 className="form-title">Réinitialiser votre mot de passe</h2>
            <ResetPasswordForm isLoading={isLoading} onResetPasswordClick={handleResetPassword} isReset={isReset} />
            <Link to="/login">
              <Button icon="lock" type="link" shape="round" block>
                Connexion
              </Button>
            </Link>
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  resetPassword,
  resetRequestedNewPassword,
};

const mapStateToProps = (state: GlobalState) => ({
  isLoading: state.auth.isLoading,
  isReset: state.auth.isReset,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ResetPassword = connector(ResetPasswordInner);
