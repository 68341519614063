import { Divider, Icon, Popconfirm, Table, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { CustomAvatar } from '../../../../../components/CustomAvatar';
import { PermissionsHandler } from '../../../../../components/permissions-handlers/PermissionsHandler';
import { loadMoreItems } from '../../../../../utils/table-pagination-item-render';
import { IClient, IDisableTerm, IPermissionsHandler } from '../../../../../utils/types';
import { tablePagination } from '../../../../../utils/vars';

import './../../clients.less';

// tslint:disable-next-line: no-empty-interface
interface IListClientsProps {
  currentState: boolean[];
  listClients: IClient[];
  isLoading: boolean;
  permissions: IPermissionsHandler | null;
  onDisableClient: (term: IDisableTerm) => void;
  onLoadMoreItemsChange: (page: number) => void;
}

export const ListClients: React.FC<IListClientsProps> = ({
  currentState,
  listClients,
  isLoading,
  onDisableClient,
  permissions,
  onLoadMoreItemsChange,
}) => {

  const { Column } = Table;

  const filterClients = (clients: IClient[]) => {
    return clients.filter(client => currentState.includes(client.enabled));
  };

  return (
    <>
      <Table<IClient>
        dataSource={filterClients(listClients)}
        className="has-card-view"
        rowKey={record => record.companyName}
        loading={isLoading}
        pagination={{
        defaultPageSize: tablePagination.pageSize,
        hideOnSinglePage: true,
        onChange: (page, size) => {
          if (size) {
            const pageNumber = loadMoreItems(size, page, listClients.length);
            if (pageNumber) onLoadMoreItemsChange(pageNumber);
          }
        },
      }}>
        <Column
          title="Logo"
          key="logo"
          width={64}
          render={(record: IClient) => {
            return (
              <span>
                <Tooltip placement="top" title={`${record.companyName}`}>
                  <CustomAvatar shape="square" type="client" src={record.logo} />
                </Tooltip>
              </span>
            );
          }}
        />
        <Column title="Client" dataIndex="companyName" key="companyName" />
        <Column title="Type" dataIndex="type" key="type" />
        <Column
          title="Nom du contact"
          key="contactLastName"
          render={(record: IClient) => (
          <span>{ record.contactFirstName } { record.contactLastName }</span>
          )}
        />
        <Column title="Email" dataIndex="email" key="email" ellipsis />
        <Column title="Téléphone" dataIndex="phone" key="phone" />
        <Column
          title="Actions"
          key="action"
          width={110}
          render={record => (
            <span className="client-actions">
              <PermissionsHandler blocPermission="CAN_VIEW" resourceName="Client" userPermissions={permissions}>
                <>
                  <Tooltip placement="top" title={`Détails `}>
                    <Link to={`/clients/${record.id}`}>
                      <Icon className="client-actions-view" type="eye" />
                    </Link>
                  </Tooltip>
                  <Divider type="vertical" />
                </>
              </PermissionsHandler>

              <PermissionsHandler blocPermission="CAN_PUT" resourceName="Client" userPermissions={permissions}>
                {
                  record.enabled && (
                    <>
                      <Tooltip placement="top" title={`Modifier`}>
                        <Link to={`/clients/update/${record.id}`}>
                          <Icon className="client-actions-update" type="edit" />
                        </Link>
                      </Tooltip>
                      <Divider type="vertical" />
                    </>
                  )
                }
              </PermissionsHandler>

              <PermissionsHandler blocPermission="CAN_DESACTIVATE" resourceName="Client" userPermissions={permissions}>
                <Tooltip placement="topRight" title={record.enabled ? `Désactiver ` : `Activer `}>
                  <Popconfirm
                    placement="topRight"
                    title={`Voulez vous vraiment ${record.enabled ? `Désactiver ` : `Activer `} ${record.companyName} ?`}
                    onConfirm={() => onDisableClient({
                      id: record.id,
                      nextStatus: !record.enabled,
                      name: record.companyName,
                    })}
                    okText="Oui"
                    cancelText="Non">
                    <Icon className={`client-actions-enabler ${ record.enabled ? 'enabled' : 'disabled' }`} type={ record.enabled ? 'stop' : 'check-circle' } />
                  </Popconfirm>
                </Tooltip>
              </PermissionsHandler>
            </span>
          )}
        />
      </Table>
    </>
  );
};
