import { Layout } from 'antd';
import React from 'react';

const { Content } = Layout;

// tslint:disable-next-line: no-empty-interface
interface IAppContentProps {}

const AppContent: React.FC<IAppContentProps> = ({ children }) => {
  return (
    <Content className="outer-main-content">
      {children}
    </Content>
  );
};

export default AppContent;
