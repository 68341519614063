import { Button, Card, Col, Pagination, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CustomAvatar } from '../../../../../components/CustomAvatar';
import { PermissionsHandler } from '../../../../../components/permissions-handlers/PermissionsHandler';
import { IUser } from '../../../../../store/users/types';
import * as roles from '../../../../../utils/roles-utils';
import { IDisableTerm, IPermissionsHandler } from '../../../../../utils/types';
import { AddUserCard } from '../AddUserCard';

import { renderPaginationItems } from './../../../../../utils/table-pagination-item-render';

const { Meta } = Card;

interface Props {
  currentStatus: boolean[];
  users: IUser[];
  permissions: IPermissionsHandler | null;
  connectedUserId?: string;
  onDisableUser: (term: IDisableTerm) => void;
}

export const ListUsers: React.FC<Props> = ({ currentStatus, users, permissions, connectedUserId, onDisableUser }) => {

  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [paginatedUsers, setPaginatedUsers] = useState<IUser[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);

  const initialPageSize = 11;

  const handlePageChange = (page: number, pageSize: number | undefined) => {
    if (pageSize) setPaginatedUsers(customPaginator(users, page, pageSize));
  };

  const customPaginator = (listUsers: IUser[], page: number, perPage: number) => {
    return listUsers.slice((page - 1) * perPage, page * perPage);
  };

  useEffect(() => {
    setFilteredUsers(filterUsers(users));
  },        [users]);

  useEffect(() => {
    setTotalUsers(filteredUsers.length);
  },        [filteredUsers]);

  useEffect(() => {
    if (totalUsers) setPaginatedUsers(filteredUsers.slice(0, initialPageSize));
  },        [filteredUsers, totalUsers]);

  const filterUsers = (usersList: IUser[]) => usersList.filter(user => currentStatus.includes(user.enabled));

  return (
    <>
      <PermissionsHandler blocPermission="CAN_CREATE" resourceName="User" userPermissions={permissions}>
        <AddUserCard />
      </PermissionsHandler>
      {!!connectedUserId && !!paginatedUsers.length && paginatedUsers.map(user => (
        <Col key={user?.id} className="gutter-row" span={6} xl={6} md={12} sm={24}>
          <div className="gutter-box" style={{ height: '100%' }}>
            <Card
              hoverable
              bordered
              className="team-card"
              actions={[
                (
                  <Tooltip title="Chat">
                    <Button key="chat" icon="wechat" />
                  </Tooltip>
                ),
                (
                <PermissionsHandler
                  blocPermission="CAN_PUT"
                  resourceName="User"
                  userPermissions={permissions}
                  owner={ user.id }
                  ownershipTarget={ connectedUserId }
                >
                  <Tooltip title="Modifier">
                    <Link to={ user?.enabled ? `/update-profile/${user.id}` : '#'}>
                      <Button disabled={ !user?.enabled } key="edit" icon="edit" />
                    </Link>
                  </Tooltip>
                </PermissionsHandler>
                ),
                (
                  user?.role.slug !== roles.ROLE_SUPER_ADMIN
                  &&
                  user?.role.slug !== roles.ROLE_ADMIN
                  &&
                  (
                    <PermissionsHandler
                      blocPermission="CAN_DESACTIVATE"
                      resourceName="User"
                      userPermissions={permissions}
                    >
                      <Tooltip title={ user.enabled ? 'Désactiver' : 'Activer' }>
                        <Button
                          key="desactivate"
                          icon={ user.enabled ? 'stop' : 'check' }
                          className={`profile-btn ${ user.enabled ? 'disable-btn' : 'enable-btn' }`}
                          onClick={() => onDisableUser({
                            id: user.id,
                            nextStatus: !user.enabled,
                            name: `${user.firstName} ${user.lastName}`,
                          }) }
                        />
                      </Tooltip>
                    </PermissionsHandler>
                  )
                ),
              ]}>
              <Link style={{ display: 'block' }} to={`/team/${user.id}`}>
                <CustomAvatar
                  styleClassName="manage-profiles-avatar"
                  size={100}
                  src={user.avatar?.path}
                  type="user"
                />
                <Meta
                  style={{ paddingTop: '-20px' }}
                  title={(
                    <strong>
                      {user.firstName} {user.lastName}
                    </strong>
                  )}
                  description={user.role ? user.role.title : '--'}
                />
              </Link>
            </Card>
          </div>
        </Col>
      ))}
      {
        !!totalUsers && (
          <Col span={24}>
            <Row type="flex" justify="end">
              <div className="pagination-only small-non-pages-paginator user-management-pagination">
                <Pagination
                  total={totalUsers}
                  defaultPageSize={initialPageSize}
                  itemRender={(current, type, originalElement) => renderPaginationItems(current, type, originalElement)}
                  onChange={ handlePageChange }
                  hideOnSinglePage
                />
                  <span className="users-counter">Total Utilisateurs { totalUsers }</span>
              </div>
            </Row>
          </Col>
        )
      }
    </>
  );
};
