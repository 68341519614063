import { Reducer } from 'redux';

import {
  CLIENTS_ADD_CLIENT,
  CLIENTS_ADD_CLIENT_ERROR,
  CLIENTS_ADD_CLIENT_SUCCESS,
  CLIENTS_DISABLE_CLIENT,
  CLIENTS_DISABLE_CLIENT_ERROR,
  CLIENTS_DISABLE_CLIENT_SUCCESS,
  CLIENTS_GET_FILTERED_LIST_CLIENTS_SUCCESS,
  CLIENTS_GET_LIST_CLIENTS,
  CLIENTS_GET_LIST_CLIENTS_ERROR,
  CLIENTS_GET_LIST_CLIENTS_SUCCESS,
  CLIENTS_GET_SINGLE_CLIENT,
  CLIENTS_GET_SINGLE_CLIENT_ERROR,
  CLIENTS_GET_SINGLE_CLIENT_SUCCESS,
  CLIENTS_UPDATE_CLIENT,
  CLIENTS_UPDATE_CLIENT_ERROR,
  CLIENTS_UPDATE_CLIENT_SUCCESS,
  GET_FILESYSTEM_CLIENTS,
  GET_FILESYSTEM_CLIENTS_ERROR,
  GET_FILESYSTEM_CLIENTS_SUCCESS,
  IClients,
  IClientsActionType,
} from './types';

const initialState: IClients = {
  listClients: [],
  singleClient: null,
  filesystemClients: null,
  isLoading: false,
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  errors: null,
};

export const ClientsReducer: Reducer<typeof initialState, IClientsActionType> = (state = initialState, action) => {
  switch (action.type) {
    case CLIENTS_ADD_CLIENT:
      return { ...state, isLoading: true, isCreated: false };

    case CLIENTS_ADD_CLIENT_SUCCESS:
      return { ...state, isLoading: false, isCreated: true, errors: null };

    case CLIENTS_ADD_CLIENT_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case CLIENTS_GET_LIST_CLIENTS:
      return { ...state, isLoading: true };

    case CLIENTS_GET_LIST_CLIENTS_SUCCESS:
      return { ...state, isLoading: false, listClients: [...state.listClients, ...action.payload] };

    case CLIENTS_GET_FILTERED_LIST_CLIENTS_SUCCESS:
      return { ...state, isLoading: false, listClients: action.payload };

    case CLIENTS_GET_LIST_CLIENTS_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case GET_FILESYSTEM_CLIENTS:
      return { ...state, isLoading: true };

    case GET_FILESYSTEM_CLIENTS_SUCCESS:
      return { ...state, isLoading: false, filesystemClients: action.payload };

    case GET_FILESYSTEM_CLIENTS_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case CLIENTS_GET_SINGLE_CLIENT:
      return { ...state, isLoading: true };

    case CLIENTS_GET_SINGLE_CLIENT_SUCCESS:
      return { ...state, isLoading: false, singleClient: action.payload };

    case CLIENTS_GET_SINGLE_CLIENT_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case CLIENTS_UPDATE_CLIENT:
      return { ...state, isLoading: true, isUpdated: false };

    case CLIENTS_UPDATE_CLIENT_SUCCESS:
      return { ...state, isLoading: false, isUpdated: true, errors: null };

    case CLIENTS_UPDATE_CLIENT_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case CLIENTS_DISABLE_CLIENT:
      return { ...state, isLoading: true };

    case CLIENTS_DISABLE_CLIENT_SUCCESS:
      return { ...state, isLoading: false, errors: null };

    case CLIENTS_DISABLE_CLIENT_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    default:
      return state;
  }
};
