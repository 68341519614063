import { Button, Icon, Popover, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';

import { IUser } from '../../store/users/types';
import { ROLE_RETOUCHER } from '../../utils/roles-utils';
import { CustomAvatar } from '../CustomAvatar';

import './grid-selector.less';

interface IGridSelectorProps {
  data: string[];
  projectTeam: string[];
  users: IUser[];
  maxVisibleItems?: number;
  editable: boolean;
  onListItemsChange: (listItems: string[]) => void;
}

export const GridSelector: React.FC<IGridSelectorProps> = ({ data, projectTeam, users, maxVisibleItems, editable, onListItemsChange }) => {
  // States
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [restItems, setRestItems] = useState<IUser[]>([]);
  const [team, setTeam] = useState<IUser[]>([]);

  // ************ Methods ************ //
  // create list of selected items
  const setItemAsSelected = (item: string) => {
    if (!selectedItems.includes(item)) setSelectedItems([...selectedItems, item]);
    const rest = restItems?.filter(el => el.id !== item);
    setRestItems(rest);
  };

  /**
   * Get actual Team
   */
  const getActualTeam = (allUsers: IUser[], preSelected: string[]) => {
    return allUsers.filter(({id}) => preSelected.includes(id));
  };

  const excludePreSeleetedUsers = (allUsers: IUser[], preSelected: string[]) => {
    return allUsers.filter(({id, role}) => !preSelected.includes(id) && role.slug === ROLE_RETOUCHER);
  };

  const removeUsersPrefix = (ids: string[]) => ids.map(id => id.replace('/users/', ''));

  // Remove item from generated items list & set it back to popover content
  const removeItem = (item: string) => {
    setSelectedItems(selectedItems.filter(e => e !== item));
    const resetRest = users?.filter(elem => elem.id === item);
    if (restItems && resetRest?.length) setRestItems([...restItems, resetRest[0]]);
  };

  useEffect(() => {
    setTeam(getActualTeam(users, removeUsersPrefix(projectTeam)));
  },        [users, data, projectTeam]);

  useEffect(() => {
    setRestItems(excludePreSeleetedUsers(team, removeUsersPrefix(data)));
  },        [team, data]);

  useEffect(() => {
    if (editable) onListItemsChange(selectedItems);
  },        [selectedItems, editable, onListItemsChange]);

  useEffect(() => {
    setSelectedItems(removeUsersPrefix(data));
  },        [data, users]);

  // Generate items as visible items
  const generateSelectedItems = (dataSource: IUser[], selectedItemsList: string[]) => {
    return dataSource.map(element => {
      if (selectedItemsList.includes(element.id)) return (
          <span key={element.id} className="avatar-wapper">
            {editable && <Icon className="remove-btn" type="close-circle" onClick={() => removeItem(element.id)} />}
            <Tooltip title={`${element.firstName} ${element.lastName}`}>
              <CustomAvatar shape="square" src={element.avatar?.path} size={32} />
            </Tooltip>
          </span>
        );
      return null;
    });
  };

  // Popover generated content
  const popoverContentGrid = (
    <div className="users-grid-selector">
      {restItems?.length
        ? restItems.map(item => {
          return (
            <Tooltip key={item.id} title={`${item.firstName} ${item.lastName}`}>
              <span className="avatar-wapper" onClick={() => setItemAsSelected(item.id)}>
                <CustomAvatar shape="square" type="user" src={item.avatar?.path} size={32} />
              </span>
            </Tooltip>
          );
        })
        : []}
    </div>
  );

  return (
    <div className="users-grid-selector">
      {users && selectedItems && generateSelectedItems(users, selectedItems)}
      {editable && (
        <Popover placement="bottomLeft" content={
          users.length
          ?
          popoverContentGrid
          :
          (
            <div className="no-data-available">
              <Icon type="frown" />
              <p>Aucun retoucheur n'est affecté à ce projet !</p>
            </div>
          )
          } trigger="click">
          <Button icon="plus" className="add-btn" />
        </Popover>
      )}
    </div>
  );
};
