import moment from 'moment';

export const dateFormatter = (date: Date) => {
  const day   = moment(date).format('DD');
  const month = moment(date).format('MM');
  const year  = moment(date).format('YYYY');

  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  const compDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
  const diff = today.getTime() - compDate.getTime();
  if (compDate.getTime() === today.getTime()) return 'Aujourd\'hui';
  else if (diff <= (24 * 60 * 60 * 1000)) return 'Hier';
  else return moment(date).format('DD/MM/YYYY');
};
