import { Layout } from 'antd';
import React from 'react';

import './anonymousLayout.less';
import { Rainbow } from './Rainbow';

// tslint:disable-next-line: no-empty-interface
interface IAnonymousLayoutProps {}

export const AnonymousLayout: React.FC<IAnonymousLayoutProps> = props => {
  const { children } = props;

  return (
    <Layout className="anonym-layout">
      <Rainbow />
      {children}
    </Layout>
  );
};
