import { Action } from 'redux';

import { IErrors } from '../../utils/types';

export interface IAuth {
  token: string | null;
  mercureToken: string | null;
  isLoggedIn: boolean;
  isLoading: boolean;
  loginErrors: IErrors | null;
  requestResetPassword: IErrors | null;
  resetPasswordErrors: IErrors | null;
  hasRequestedNewPassword: boolean;
  isReset: boolean;
}

export interface ITokens {
  token: string;
  mercureToken: string;
}

export interface ICredentials {
  email: string;
  password: string;
}

// Reset password incoming data { code / new password }
export interface IResetPasswordData {
  confirmationToken: string;
  password: string;
}

/**
 * Auth Login Types
 */
export const AUTH_LOGIN = 'AUTH_LOGIN';
export type AUTH_LOGIN = typeof AUTH_LOGIN;

export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export type AUTH_LOGIN_SUCCESS = typeof AUTH_LOGIN_SUCCESS;

export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export type AUTH_LOGIN_ERROR = typeof AUTH_LOGIN_ERROR;

/**
 * Auth set is loggedin types
 */
export const AUTH_SET_LOGGED_IN = 'AUTH_SET_LOGGED_IN';
export type AUTH_SET_LOGGED_IN = typeof AUTH_SET_LOGGED_IN;

/**
 * Auth Logout types
 */
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export type AUTH_LOGOUT = typeof AUTH_LOGOUT;

export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export type AUTH_LOGOUT_SUCCESS = typeof AUTH_LOGOUT_SUCCESS;

/**
 * Auth Request reset password types
 */
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export type REQUEST_RESET_PASSWORD  = typeof REQUEST_RESET_PASSWORD;

export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';
export type REQUEST_RESET_PASSWORD_SUCCESS  = typeof REQUEST_RESET_PASSWORD_SUCCESS;

export const REQUEST_RESET_PASSWORD_ERROR = 'REQUEST_RESET_PASSWORD_ERROR';
export type REQUEST_RESET_PASSWORD_ERROR  = typeof REQUEST_RESET_PASSWORD_ERROR;

export const RESET_ERRORS = 'RESET_ERRORS';
export type RESET_ERRORS  = typeof RESET_ERRORS;

/**
 * Auth Reset password types
 */
export const RESET_PASSWORD = 'RESET_PASSWORD';
export type RESET_PASSWORD  = typeof RESET_PASSWORD;

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export type RESET_PASSWORD_SUCCESS  = typeof RESET_PASSWORD_SUCCESS;

export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export type RESET_PASSWORD_ERROR  = typeof RESET_PASSWORD_ERROR;

export const RESET_REQUESTED_NEW_PASSWORD = 'RESET_REQUESTED_NEW_PASSWORD';
export type RESET_REQUESTED_NEW_PASSWORD  = typeof RESET_REQUESTED_NEW_PASSWORD;

/**
 * Auth Login Action Creators
 */
export interface IAuthLoginAction extends Action<AUTH_LOGIN> {
  payload: ICredentials;
}

export interface IAuthLoginSuccessAction extends Action<AUTH_LOGIN_SUCCESS> {
  payload: ITokens;
}

export interface IAuthLoginErrorAction extends Action<AUTH_LOGIN_ERROR> {
  payload: IErrors;
}

/**
 * Auth set is loggedIn action creators
 */
export interface IAuthSetLoggedInAction extends Action<AUTH_SET_LOGGED_IN> {
  payload: boolean;
}

/**
 * Auth logout action creators
 */
export interface IAuthLogoutAction extends Action<AUTH_LOGOUT> {}

export interface IAuthLogoutSuccessAction extends Action<AUTH_LOGOUT_SUCCESS> {}

/**
 * Auth Request reset password action creators
 */
export interface IRequestResetPasswordAction extends Action<REQUEST_RESET_PASSWORD> {
  payload: string;
}

export interface IRequestResetPasswordSuccessAction extends Action<REQUEST_RESET_PASSWORD_SUCCESS> {}

export interface IRequestResetPasswordErrorAction extends Action<REQUEST_RESET_PASSWORD_ERROR> {
  payload: IErrors;
}

export interface IResetErrorsAction extends Action<RESET_ERRORS> {}

/**
 * Auth Reset password action creators
 */
export interface IResetPasswordAction extends Action<RESET_PASSWORD> {
  payload: IResetPasswordData;
}

export interface IResetPasswordSuccessAction extends Action<RESET_PASSWORD_SUCCESS> {}

export interface IResetPasswordErrorAction extends Action<RESET_PASSWORD_ERROR> {
  payload: IErrors;
}

export interface IResetRequestedNewPasswordAction extends Action<RESET_REQUESTED_NEW_PASSWORD> {}

export type AuthActionType =
    IAuthLoginAction
  | IAuthLoginSuccessAction
  | IAuthLoginErrorAction
  | IAuthSetLoggedInAction
  | IAuthLogoutAction
  | IResetErrorsAction
  | IAuthLogoutSuccessAction
  | IRequestResetPasswordAction
  | IRequestResetPasswordSuccessAction
  | IRequestResetPasswordErrorAction
  | IResetPasswordAction
  | IResetPasswordSuccessAction
  | IResetPasswordErrorAction
  | IResetRequestedNewPasswordAction;
