import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { PageLoader } from '../../../components/PageLoader';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { getListClients } from '../../../store/clients/actions';
import { createProjectBrief, getAllBudgets, getAllProjectBriefs, getInProgressBudgets, getProjectNumberPerStatus, getUserBudgets, updateListBudgets } from '../../../store/project/actions';
import { toggleOpenModal } from '../../../store/settings/actions';
import { getUsers } from '../../../store/users/actions';
import { IBriefToSave, IBudget, IBudgetChangeStatus, IPageProps, IPermissionsHandler } from '../../../utils/types';

import { ListBudgets } from './Components/ListBudgets';
// tslint:disable-next-line: no-empty-interface

interface IBudgetsPageProps extends IPageProps {}

const BudgetsInner: React.FC<IBudgetsPageProps & RouteComponentProps & ConnectedProps<typeof connector>> = ({
  route,
  listBudgets,
  isLoading,
  users,
  projectsCounter,
  clients,
  connectedUser,
  listBriefs,
  isLoadingBriefs,
  history,
  getUsers: getUsersAction,
  updateListBudgets: updateListBudgetsAction,
  getListClients: getListClientsAction,
  toggleOpenModal: toggleOpenModalAction,
  getAllProjectBriefsFromFolder: getAllProjectBriefsFromFolderAction,
  createProjectBrief: createProjectBriefAction,
  getAllBudgets: getAllBudgetsAction,
  getUserBudgets: getUserBudgetsAction,
  getProjectNumberPerStatus: getProjectNumberPerStatusAction,
}) => {

  const permissions: IPermissionsHandler | null = useContext(PermissionContext);
  const [status, setStatus] = useState('');

  useEffect(() => {
    getProjectNumberPerStatusAction([]);
  },        [getProjectNumberPerStatusAction]);

  useEffect(() => {
    const fragments = history.location.pathname.split('/');
    const statusParam = fragments[fragments.length - 1];
    if (fragments.length > 2) setStatus(statusParam);
    if (connectedUser) switch (statusParam) {

      case 'in-progress':
        getAllBudgetsAction({
          page: 1,
          status: ['En cours'],
        });
        break;

      case 'to-come-up':
        getAllBudgetsAction({
          page: 1,
          status: ['À venir'],
        });
        break;

      case 'archived':
        getAllBudgetsAction({
          page: 1,
          status: ['Archivé'],
        });
        break;

      case 'closed':
        getAllBudgetsAction({
          page: 1,
          status: ['Confirmé'],
        });
        break;

      default:
        setStatus('');
        getAllBudgetsAction({
          page: 1,
        });
    }
  },        [getAllBudgetsAction, history, connectedUser, getUserBudgetsAction]);

  useEffect(() => {
    getListClientsAction({
      status: [true, false],
    });
  },        [getListClientsAction]);

  useEffect(() => {
    getUsersAction();
  },        [getUsersAction]);

  const getBudgetToUpdate = (budget: IBudgetChangeStatus) => budget.listIds.length && updateListBudgetsAction(budget);

  const handleOpenBriefModal = () => toggleOpenModalAction(true);

  const getTargetBudget = (budget: IBudget) => {
    getAllProjectBriefsFromFolderAction(budget.directory);
  };

  const handleNewBriefSubmit = (newBrief: IBriefToSave) => createProjectBriefAction(newBrief);

  const handleLoadMoreItems = (page: number) => {
    getAllBudgetsAction({
      page,
      status: status ? [status] : undefined,
    });
  };

  const handleArchiveProject = (project: IBudgetChangeStatus) => updateListBudgetsAction(project);

  if (!permissions?.listPermissions
    && !permissions?.userRole
    && !clients
    && !listBudgets
    ) return <PageLoader />;

  return (
    <>
      <CustomBreadcrumb route={ route } />
      <div className="list-budgets">
        <PageTitle title={ status ? `Budgets ${route.title}` : route.title } />
        <ListBudgets
          byStatus={!!status}
          permissions={permissions}
          listBudgets={listBudgets}
          projectsCounter={ projectsCounter }
          clients={clients}
          listUsers={users}
          isLoading={isLoading}
          listBriefs={ listBriefs }
          isLoadingBriefs={ isLoadingBriefs }
          onUpdateApplied={getBudgetToUpdate}
          connectedUserId={connectedUser?.id}
          onNewBriefModalTrigger={handleOpenBriefModal}
          onBudgetDetailsClick={getTargetBudget}
          onNewBriefSubmit={ handleNewBriefSubmit }
          onLoadMoreItemsChange={ handleLoadMoreItems }
          onArchiveProject={ handleArchiveProject }
        />
      </div>
    </>
  );
};
const mapDispachToProps = {
  getUsers,
  updateListBudgets,
  getListClients,
  toggleOpenModal,
  getAllProjectBriefsFromFolder: getAllProjectBriefs,
  createProjectBrief,
  getAllBudgets,
  getInProgressBudgets,
  getUserBudgets,
  getProjectNumberPerStatus,
};

const mapStateToProps = (state: GlobalState) => ({
  listBudgets: state.project.listProjects,
  projectsCounter: state.project.projectsNumberPerStatus,
  isLoading: state.project.isLoading,
  users: state.listUsers.users,
  clients: state.clients.listClients,
  connectedUser: state.connectedUser.userData,
  listBriefs: state.project.briefs.briefList,
  isLoadingBriefs: state.project.briefs.isLoading,
});

const connector = connect(mapStateToProps, mapDispachToProps);

export const Budgets = connector(BudgetsInner);