import { Badge, Button, Col, Input, Layout, Row, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { IAlert } from '../../../../store/alerts/types';
import * as roles from '../../../../utils/roles-utils';
import { IBudget, IClient } from '../../../../utils/types';

import { ShowNotificationsBox } from './components/ShowNotificationsBox';
import './header.less';

const { Header } = Layout;
const { Search } = Input;

interface IAppHeaderProps {
  connectedUserRole?: string;
  clients: IClient[];
  budgets: IBudget[];
  alerts: IAlert;
  onLogoutBtnClick: () => void;
  onAlertResetClick: (category: 'checked' | 'transferred') => void;
  onNotifResetClick: () => void;
  onSyncButtonClick: () => void;
}

export const AppHeader: React.FC<IAppHeaderProps> = ({
  connectedUserRole,
  budgets,
  clients,
  alerts,
  onLogoutBtnClick,
  onAlertResetClick,
  onNotifResetClick,
  onSyncButtonClick,
}) => {
  const handleLogout = () => {
    onLogoutBtnClick();
  };

  const handleAlertReset = (category: 'checked' | 'transferred') => onAlertResetClick(category);
  const handleNotifReset = () => onNotifResetClick();

  const handleSyncClick = () => onSyncButtonClick();

  return (
    <Header>
      <Row className="header-outer-row" type="flex" justify="start">
        <Col span={9}>
          <Search placeholder="Recherche..." />
        </Col>

        <Col span={15} className="icons-section">
          <Row type="flex" justify="end" gutter={16}>
            {
              connectedUserRole !== roles.ROLE_RETOUCHER && (
                <Col>
                  <span className="header-icon-wrapper">
                    <Tooltip placement="bottom" title="Synchronisation">
                      <Button onClick={handleSyncClick} icon="sync" />
                    </Tooltip>
                  </span>
                </Col>
              )
            }

            <Col>
              <span className="header-icon-wrapper">
                <Tooltip placement="bottom" title="Notifications">
                  <Tooltip
                    overlayClassName="notifications-droppable"
                    placement="bottom"
                    trigger="click"
                    title={(
                      <ShowNotificationsBox
                        clients={clients}
                        budgets={budgets}
                        notifications={alerts.notifications.items}
                        length={alerts.notifications.newItems}
                      />
                    )}>
                    <Badge count={alerts.notifications.newItems}>
                      <Button onClick={() => handleNotifReset()} icon="bell" />
                    </Badge>
                  </Tooltip>
                </Tooltip>
              </span>
            </Col>

            <Col>
              <Link to="#">
                <span className="header-icon-wrapper">
                  <Tooltip placement="bottom" title="Fichiers checkés">
                    <Badge count={alerts.filesToCheck.newItems}>
                      <Button onClick={() => handleAlertReset('checked')} icon="file-done" />
                    </Badge>
                  </Tooltip>
                </span>
              </Link>
            </Col>

            <Col>
              <Link to="#">
                <span className="header-icon-wrapper">
                  <Tooltip placement="bottom" title="Fichiers envoyés">
                    <Badge count={alerts.filesToSend.newItems}>
                      <Button onClick={() => handleAlertReset('transferred')} icon="cloud-upload" />
                    </Badge>
                  </Tooltip>
                </span>
              </Link>
            </Col>

            <Col>
              <span className="header-icon-wrapper">
                <Tooltip placement="bottomRight" title="Déconnexion">
                  <Button icon="poweroff" onClick={handleLogout} />
                </Tooltip>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  );
};
