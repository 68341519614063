import { Action } from 'redux';

import { IBrief, IBrutFile, IBudgetFiles, IDownloadTerms, IErrors, IFileChangeStatus, IFilePreviewUrls, IFilesAlert, IFilesAlertPayload, IFilesToDownload, IFileTeamToSave, IFileVersions, ILogFile, IUpdateFilePriority } from '../../utils/types';

export interface IFileInfos {
  status: string;
  project: string;
  name: string;
}

export interface IPreSigned {
  url: string;
  file: string;
  thumbnailUrl: string;
  thumbnailFile: string;
}

export interface IRequestPreSignedURL {
  folder: string;
  filename: string;
}

export interface IFilePreview {
  folder: string;
  file: string;
}

export interface IFile {
  upload: {
    preSigned: IPreSigned | null;
    errors: IErrors | null;
  };
  listFiles: IBudgetFiles[] | undefined;
  filesCollection: IBudgetFiles[];
  filesToDwonload: IFilesDownload[] | null;
  isLoading: boolean;
  errors: IErrors | null;
  SingleFile: IBudgetFiles | null;
  filePreview: IFilePreviewUrls;
  fileLogs: IFileVersions;
  brutFile: IBrutFile;
  filesToCheck: IFilesAlert;
  filesToSend: IFilesAlert;
}

// tslint:disable-next-line: no-empty-interface
export interface IFilesDownload extends IBrief {}

export interface IFileStatus {
  status: string;
  id: string;
  relativeBudget: string;
  action?: 'Check' | 'Retouch';
}
/**
 * Request pre-signed URL
 */
export const UPLOAD_FILE_REQUEST_URL = 'UPLOAD_FILE_REQUEST_URL';
export type UPLOAD_FILE_REQUEST_URL = typeof UPLOAD_FILE_REQUEST_URL;

export const UPLOAD_FILE_REQUEST_URL_SUCCESS = 'UPLOAD_FILE_REQUEST_URL_SUCCESS';
export type UPLOAD_FILE_REQUEST_URL_SUCCESS = typeof UPLOAD_FILE_REQUEST_URL_SUCCESS;

export const UPLOAD_FILE_REQUEST_URL_ERROR = 'UPLOAD_FILE_REQUEST_URL_ERROR';
export type UPLOAD_FILE_REQUEST_URL_ERROR = typeof UPLOAD_FILE_REQUEST_URL_ERROR;

export interface IUploadRequestUrlAction extends Action<UPLOAD_FILE_REQUEST_URL> {
  payload: IRequestPreSignedURL;
}

export interface IUploadRequestUrlSuccessAction extends Action<UPLOAD_FILE_REQUEST_URL_SUCCESS> {
  payload: IPreSigned;
}

export interface IUploadRequestUrlErrorAction extends Action<UPLOAD_FILE_REQUEST_URL_ERROR> {
  payload: IErrors;
}

/**
 * Get File List
 */
export const GET_BUDGET_FILES = 'GET_BUDGET_FILES';
export type GET_BUDGET_FILES = typeof GET_BUDGET_FILES;

export const GET_BUDGET_FILES_SUCCESS = 'GET_BUDGET_FILES_SUCCESS';
export type GET_BUDGET_FILES_SUCCESS = typeof GET_BUDGET_FILES_SUCCESS;

export const GET_BUDGET_FILES_ERROR = 'GET_BUDGET_FILES_ERROR';
export type GET_BUDGET_FILES_ERROR = typeof GET_BUDGET_FILES_ERROR;

export interface IGetBudgetFilesAction extends Action<GET_BUDGET_FILES> {
  payload: string;
}

export interface IGetBudgetFilesSuccessAction extends Action<GET_BUDGET_FILES_SUCCESS> {
  payload: IBudgetFiles[];
}
export interface IGetBudgetFilesErrorAction extends Action<GET_BUDGET_FILES_ERROR> {
  payload: IErrors;
}

/**
 * Get Single File
 */
export const GET_SINGLE_FILE = 'GET_SINGLE_FILE';
export type GET_SINGLE_FILE = typeof GET_SINGLE_FILE;

export const GET_SINGLE_FILE_SUCCESS = 'GET_SINGLE_FILE_SUCCESS';
export type GET_SINGLE_FILE_SUCCESS = typeof GET_SINGLE_FILE_SUCCESS;

export const GET_SINGLE_FILE_ERROR = 'GET_SINGLE_FILE_ERROR ';
export type GET_SINGLE_FILE_ERROR = typeof GET_SINGLE_FILE_ERROR;

export interface IGetSingleFile extends Action<GET_SINGLE_FILE> {
  payload: string;
}

export interface IGetSingleFileSuccess extends Action<GET_SINGLE_FILE_SUCCESS> {
  payload: IBudgetFiles;
}

export interface IGetSingleFileError extends Action<GET_SINGLE_FILE_ERROR> {
  payload: IErrors;
}

/**
 * Add new file
 */
export const FILE_ADD_NEW = 'FILE_ADD_NEW';
export type FILE_ADD_NEW = typeof FILE_ADD_NEW;

export const FILE_ADD_NEW_SUCCESS = 'FILE_ADD_NEW_SUCCESS';
export type FILE_ADD_NEW_SUCCESS = typeof FILE_ADD_NEW_SUCCESS;

export const FILE_ADD_NEW_ERROR = 'FILE_ADD_NEW_ERROR';
export type FILE_ADD_NEW_ERROR = typeof FILE_ADD_NEW_ERROR;

export interface IFileAddNewAction extends Action<FILE_ADD_NEW> {
  payload: IFileInfos;
}

export interface IFileAddNewSuccessAction extends Action<FILE_ADD_NEW_SUCCESS> {}

export interface IFileAddNewErrorAction extends Action<FILE_ADD_NEW_ERROR> {
  payload: IErrors;
}

/**
 * Add modified file log
 */
export const ADD_LOG_FILE = 'ADD_LOG_FILE';
export type ADD_LOG_FILE  = typeof ADD_LOG_FILE;

export const ADD_LOG_FILE_SUCCESS = 'ADD_LOG_FILE_SUCCESS';
export type ADD_LOG_FILE_SUCCESS  = typeof ADD_LOG_FILE_SUCCESS;

export const ADD_LOG_FILE_ERROR = 'ADD_LOG_FILE_ERROR';
export type ADD_LOG_FILE_ERROR  = typeof ADD_LOG_FILE_ERROR;

export interface IAddLogFileAction extends Action<ADD_LOG_FILE> {
  payload: ILogFile;
}

export interface IAddLogFileSuccessAction extends Action<ADD_LOG_FILE_SUCCESS> {}

export interface IAddLogFileErrorAction extends Action<ADD_LOG_FILE_ERROR> {
  payload: IErrors;
}

/**
 * Download files
 */
export const DOWNLOAD_FILES = 'DOWNLOAD_FILES';
export type DOWNLOAD_FILES = typeof DOWNLOAD_FILES;

export const DOWNLOAD_FILES_SUCCESS = 'DOWNLOAD_FILES_SUCCESS';
export type DOWNLOAD_FILES_SUCCESS = typeof DOWNLOAD_FILES_SUCCESS;

export const DOWNLOAD_FILES_ERROR = 'DOWNLOAD_FILES_ERROR';
export type DOWNLOAD_FILES_ERROR = typeof DOWNLOAD_FILES_ERROR;

export interface IDownloadFilesAction extends Action<DOWNLOAD_FILES> {
  payload: string;
}

export interface IDownloadFilesSuccessAction extends Action<DOWNLOAD_FILES_SUCCESS> {
  payload: IFilesDownload[];
}

export interface IDownloadFilesErrorAction extends Action<DOWNLOAD_FILES_ERROR> {
  payload: IErrors;
}

/**
 * Update File status
 */
export const FILE_UPDATE_STATUS = 'FILE_UPDATE_STATUS';
export type FILE_UPDATE_STATUS = typeof FILE_UPDATE_STATUS;

export const FILE_UPDATE_STATUS_SUCCESS = 'FILE_UPDATE_STATUS_SUCCESS';
export type FILE_UPDATE_STATUS_SUCCESS = typeof FILE_UPDATE_STATUS_SUCCESS;

export const FILE_UPDATE_STATUS_ERROR = 'FILE_UPDATE_STATUS_ERROR';
export type FILE_UPDATE_STATUS_ERROR = typeof FILE_UPDATE_STATUS_ERROR;

export interface IFileUpdateStatus extends Action<FILE_UPDATE_STATUS> {
  payload: IFileStatus;
}

export interface IFileUpdateStatusSuccess extends Action<FILE_UPDATE_STATUS_SUCCESS> {}

export interface IFileUpdateStatusError extends Action<FILE_UPDATE_STATUS_ERROR> {
  payload: IErrors;
}

/**
 * Update list of files status
 */
export const UPDATE_LIST_FILES = 'UPDATE_LIST_FILES';
export type UPDATE_LIST_FILES  = typeof UPDATE_LIST_FILES;

export const UPDATE_LIST_FILES_SUCCESS = 'UPDATE_LIST_FILES_SUCCESS';
export type UPDATE_LIST_FILES_SUCCESS  = typeof UPDATE_LIST_FILES_SUCCESS;

export const UPDATE_LIST_FILES_ERROR = 'UPDATE_LIST_FILES_ERROR';
export type UPDATE_LIST_FILES_ERROR  = typeof UPDATE_LIST_FILES_ERROR;

export interface IUpdateListFilesAction extends Action<UPDATE_LIST_FILES> {
  payload: IFileChangeStatus;
}

export interface IUpdateListFilesSuccessAction extends Action<UPDATE_LIST_FILES_SUCCESS> {}

export interface IUpdateListFilesErrorAction extends Action<UPDATE_LIST_FILES_ERROR> {
  payload: IErrors;
}

/**
 * Update file (Dispatch file team)
 */
export const FILE_DISPATCH_TEAM = 'FILE_DISPATCH_TEAM';
export type FILE_DISPATCH_TEAM  = typeof FILE_DISPATCH_TEAM;

export const FILE_DISPATCH_TEAM_SUCCESS = 'FILE_DISPATCH_TEAM_SUCCESS';
export type FILE_DISPATCH_TEAM_SUCCESS  = typeof FILE_DISPATCH_TEAM_SUCCESS;

export const FILE_DISPATCH_TEAM_ERROR = 'FILE_DISPATCH_TEAM_ERROR';
export type FILE_DISPATCH_TEAM_ERROR  = typeof FILE_DISPATCH_TEAM_ERROR;

export interface IFileDIspatchTeamAction extends Action<FILE_DISPATCH_TEAM> {
  payload: IFileTeamToSave;
}

export interface IFileDIspatchTeamSuccessAction extends Action<FILE_DISPATCH_TEAM_SUCCESS> {}

export interface IFileDIspatchTeamErrorAction extends Action<FILE_DISPATCH_TEAM_ERROR> {
  payload: IErrors;
}

/**
 * Update file priority
 */
export const FILE_UPDATE_PRIORITY = 'FILE_UPDATE_PRIORITY';
export type FILE_UPDATE_PRIORITY  = typeof FILE_UPDATE_PRIORITY;

export const FILE_UPDATE_PRIORITY_SUCCESS = 'FILE_UPDATE_PRIORITY_SUCCESS';
export type FILE_UPDATE_PRIORITY_SUCCESS  = typeof FILE_UPDATE_PRIORITY_SUCCESS;

export const FILE_UPDATE_PRIORITY_ERROR = 'FILE_UPDATE_PRIORITY_ERROR';
export type FILE_UPDATE_PRIORITY_ERROR  = typeof FILE_UPDATE_PRIORITY_ERROR;

export interface IFileUpdateStatusAction extends Action<FILE_UPDATE_PRIORITY> {
  payload: IUpdateFilePriority;
}

export interface IFileUpdateStatusSuccessAction extends Action<FILE_UPDATE_PRIORITY_SUCCESS> {}

export interface IFileUpdateStatusErrorAction extends Action<FILE_UPDATE_PRIORITY_ERROR> {
  payload: IErrors;
}

/**
 * Get File Preview
 */
export const GET_FILE_PREVIEW = 'GET_FILE_PREVIEW';
export type GET_FILE_PREVIEW  = typeof GET_FILE_PREVIEW;

export const GET_FILE_PREVIEW_SUCCESS = 'GET_FILE_PREVIEW_SUCCESS';
export type GET_FILE_PREVIEW_SUCCESS  = typeof GET_FILE_PREVIEW_SUCCESS;

export const GET_FILE_PREVIEW_ERROR = 'GET_FILE_PREVIEW_ERROR';
export type GET_FILE_PREVIEW_ERROR  = typeof GET_FILE_PREVIEW_ERROR;

export interface IFileGetPreviewAction extends Action<GET_FILE_PREVIEW> {
  payload: IFilePreview;
}

export interface IFileGetPreviewSuccessAction extends Action<GET_FILE_PREVIEW_SUCCESS> {
  payload: IFilePreviewUrls;
}

export interface IFileGetPreviewErrorAction extends Action<GET_FILE_PREVIEW_ERROR> {
  payload: IErrors;
}

/**
 * Get file logs
 */
export const GET_FILE_RETOUCH_LOGS = 'GET_FILE_RETOUCH_LOGS';
export type GET_FILE_RETOUCH_LOGS  = typeof GET_FILE_RETOUCH_LOGS;

export const GET_FILE_RETOUCH_LOGS_SUCCESS = 'GET_FILE_RETOUCH_LOGS_SUCCESS';
export type GET_FILE_RETOUCH_LOGS_SUCCESS  = typeof GET_FILE_RETOUCH_LOGS_SUCCESS;

export const GET_FILE_RETOUCH_LOGS_ERROR = 'GET_FILE_RETOUCH_LOGS_ERROR';
export type GET_FILE_RETOUCH_LOGS_ERROR  = typeof GET_FILE_RETOUCH_LOGS_ERROR;

export interface IGetFileRetouchLogsAction extends Action<GET_FILE_RETOUCH_LOGS> {
  payload: IDownloadTerms;
}

export interface IGetFileRetouchLogsSuccessAction extends Action<GET_FILE_RETOUCH_LOGS_SUCCESS> {
  payload: IFilesToDownload[];
}

export interface IGetFileRetouchLogsErrorAction extends Action<GET_FILE_RETOUCH_LOGS_ERROR> {
  payload: IErrors;
}

/**
 * Download brut file
 */
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export type DOWNLOAD_FILE  = typeof DOWNLOAD_FILE;

export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export type DOWNLOAD_FILE_SUCCESS  = typeof DOWNLOAD_FILE_SUCCESS;

export const DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR';
export type DOWNLOAD_FILE_ERROR  = typeof DOWNLOAD_FILE_ERROR;

export interface IDownloadFileAction extends Action<DOWNLOAD_FILE> {
  payload: IDownloadTerms;
}

export interface IDownloadFileSuccessAction extends Action<DOWNLOAD_FILE_SUCCESS> {
  payload: string;
}

export interface IDownloadFileErrorAction extends Action<DOWNLOAD_FILE_ERROR> {
  payload: IErrors;
}

/**
 * Request presigned URL for versionning
 */
export const REQUEST_VERSION_PRESIGNED_URL = 'REQUEST_VERSION_PRESIGNED_URL';
export type REQUEST_VERSION_PRESIGNED_URL  = typeof REQUEST_VERSION_PRESIGNED_URL;

export const REQUEST_VERSION_PRESIGNED_URL_SUCCESS = 'REQUEST_VERSION_PRESIGNED_URL_SUCCESS';
export type REQUEST_VERSION_PRESIGNED_URL_SUCCESS  = typeof REQUEST_VERSION_PRESIGNED_URL_SUCCESS;

export const REQUEST_VERSION_PRESIGNED_URL_ERROR = 'REQUEST_VERSION_PRESIGNED_URL_ERROR';
export type REQUEST_VERSION_PRESIGNED_URL_ERROR  = typeof REQUEST_VERSION_PRESIGNED_URL_ERROR;

export interface IRequestVersionPresignedURLAction extends Action<REQUEST_VERSION_PRESIGNED_URL> {
  payload: IRequestPreSignedURL;
}

export interface IRequestVersionPresignedURLSuccessAction extends Action<REQUEST_VERSION_PRESIGNED_URL_SUCCESS> {
  payload: string;
}

export interface IRequestVersionPresignedURLErrorAction extends Action<REQUEST_VERSION_PRESIGNED_URL_ERROR> {
  payload: IErrors;
}

/**
 * Load FIles to check
 */
export const GET_FILES_TO_BE_CHECKED = 'GET_FILES_TO_BE_CHECKED';
export type GET_FILES_TO_BE_CHECKED  = typeof GET_FILES_TO_BE_CHECKED;

export const GET_FILES_TO_BE_CHECKED_SUCCESS = 'GET_FILES_TO_BE_CHECKED_SUCCESS';
export type GET_FILES_TO_BE_CHECKED_SUCCESS  = typeof GET_FILES_TO_BE_CHECKED_SUCCESS;

export const GET_FILES_TO_BE_CHECKED_ERROR = 'GET_FILES_TO_BE_CHECKED_ERROR';
export type GET_FILES_TO_BE_CHECKED_ERROR  = typeof GET_FILES_TO_BE_CHECKED_ERROR;

export interface IGetFilesToBeCheckedAction extends Action<GET_FILES_TO_BE_CHECKED> {
  payload: number;
}

export interface IGetFilesToBeCheckedSuccessAction extends Action<GET_FILES_TO_BE_CHECKED_SUCCESS> {
  payload: IFilesAlertPayload;
}

export interface IGetFilesToBeCheckedErrorAction extends Action<GET_FILES_TO_BE_CHECKED_ERROR> {
  payload: IErrors;
}

/**
 * Load FIles to send
 */
export const GET_FILES_TO_BE_SENT = 'GET_FILES_TO_BE_SENT';
export type GET_FILES_TO_BE_SENT  = typeof GET_FILES_TO_BE_SENT;

export const GET_FILES_TO_BE_SENT_SUCCESS = 'GET_FILES_TO_BE_SENT_SUCCESS';
export type GET_FILES_TO_BE_SENT_SUCCESS  = typeof GET_FILES_TO_BE_SENT_SUCCESS;

export const GET_FILES_TO_BE_SENT_ERROR = 'GET_FILES_TO_BE_SENT_ERROR';
export type GET_FILES_TO_BE_SENT_ERROR  = typeof GET_FILES_TO_BE_SENT_ERROR;

export interface IGetFilesToBeSentAction extends Action<GET_FILES_TO_BE_SENT> {
  payload: number;
}

export interface IGetFilesToBeSentSuccessAction extends Action<GET_FILES_TO_BE_SENT_SUCCESS> {
  payload: IFilesAlertPayload;
}

export interface IGetFilesToBeSentErrorAction extends Action<GET_FILES_TO_BE_SENT_ERROR> {
  payload: IErrors;
}

export type IUploadFileActionTypes =
  | IUploadRequestUrlAction
  | IUploadRequestUrlSuccessAction
  | IUploadRequestUrlErrorAction
  | IFileAddNewAction
  | IFileAddNewSuccessAction
  | IFileAddNewErrorAction
  | IGetBudgetFilesAction
  | IGetBudgetFilesSuccessAction
  | IGetBudgetFilesErrorAction
  | IDownloadFilesAction
  | IDownloadFilesSuccessAction
  | IDownloadFilesErrorAction
  | IGetSingleFile
  | IGetSingleFileSuccess
  | IGetSingleFileError
  | IFileUpdateStatus
  | IFileUpdateStatusSuccess
  | IFileUpdateStatusError
  | IUpdateListFilesAction
  | IUpdateListFilesSuccessAction
  | IUpdateListFilesErrorAction
  | IFileDIspatchTeamAction
  | IFileDIspatchTeamSuccessAction
  | IFileDIspatchTeamErrorAction
  | IFileUpdateStatusAction
  | IFileUpdateStatusSuccessAction
  | IFileUpdateStatusErrorAction
  | IFileGetPreviewAction
  | IFileGetPreviewSuccessAction
  | IFileGetPreviewErrorAction
  | IGetFileRetouchLogsAction
  | IGetFileRetouchLogsSuccessAction
  | IGetFileRetouchLogsErrorAction
  | IDownloadFileAction
  | IDownloadFileSuccessAction
  | IDownloadFileErrorAction
  | IRequestVersionPresignedURLAction
  | IRequestVersionPresignedURLSuccessAction
  | IRequestVersionPresignedURLErrorAction
  | IAddLogFileAction
  | IAddLogFileSuccessAction
  | IAddLogFileErrorAction
  | IGetFilesToBeCheckedAction
  | IGetFilesToBeCheckedSuccessAction
  | IGetFilesToBeCheckedErrorAction
  | IGetFilesToBeSentAction
  | IGetFilesToBeSentSuccessAction
  | IGetFilesToBeSentErrorAction;
