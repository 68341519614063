import { Layout, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ConnectedUserSider } from '../../../components/ConnectedUserSider';
import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionsHandler } from '../../../components/permissions-handlers/PermissionsHandler';
import { SiderProfileDetails } from '../../../components/SiderProfileDetails';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { getAllProjectBriefs, getOneBudget, updateProject } from '../../../store/project/actions';
import { getUsers } from '../../../store/users/actions';
import { IPageProps, IPermissionsHandler, IUpdateBudget } from '../../../utils/types';
import { siderWidth } from '../../../utils/vars';

import { UpdateForm } from './components/UpdateForm';

interface IUpdateBudgetProps extends IPageProps {}

const { Content, Sider } = Layout;

export const UpdateBudgetInner: React.FC<IUpdateBudgetProps & ConnectedProps<typeof connector> & RouteComponentProps<{id: string}>> = ({
  match,
  route,
  connectedUser,
  budget,
  users,
  briefs,
  isProjectLoading,
  isProjectCreated,
  isBriefCreated,
  isLoadingBriefs,
  getOneBudget: getOneBudgetAction,
  getUsers: getUsersAction,
  getAllProjectBriefs: getAllProjectBriefsAction,
  updateProject: updateProjectAction,
}) => {
  const permissions: IPermissionsHandler | null = useContext(PermissionContext);

  useEffect(() => {
    getOneBudgetAction(match.params.id);
  },        [getOneBudgetAction, match]);

  useEffect(() => {
    getUsersAction();
  },        [getUsersAction]);

  useEffect(() => {
    if (budget) getAllProjectBriefsAction(budget.directory);
  },        [getAllProjectBriefsAction, budget]);

  const handleUpdateBudget = (updatedBudget: IUpdateBudget) => updateProjectAction(updatedBudget);

  return (
    <>
      <CustomBreadcrumb route={ route } />
      <Layout className="page-layout has-card-view">
        <PageTitle title={ route.title } />
        <Sider width={ siderWidth }>
          {!!connectedUser && <ConnectedUserSider userData={ connectedUser } />}
        </Sider>
        <Layout>
          <Content>
            <Spin spinning={ isProjectLoading }>
            {
              !!connectedUser && !!budget && (
                <PermissionsHandler
                  blocPermission="CAN_PUT"
                  resourceName="Project"
                  userPermissions={permissions}
                  redirectOption
                >
                    <UpdateForm
                      connectedUserName={`${ connectedUser.firstName } ${ connectedUser.lastName }`}
                      budget={ budget }
                      users={users}
                      briefs={briefs}
                      isBriefCreated={isBriefCreated}
                      isProjectLoading={isProjectLoading}
                      isLoadingBriefs={isLoadingBriefs}
                      onSubmitUpdateForm={ handleUpdateBudget }
                    />
                </PermissionsHandler>
              )
            }
            </Spin>
          </Content>
        </Layout>

        <Sider width={ siderWidth }>
          {!!connectedUser && <SiderProfileDetails userData={connectedUser} />}
        </Sider>
      </Layout>
    </>
  );
};

const mapDispatchToProps = {
  getOneBudget,
  updateProject,
  getUsers,
  getAllProjectBriefs,
};

const mapStateToProps = (state: GlobalState) => ({
  connectedUser: state.connectedUser.userData,
  budget: state.project.singleProject,
  users: state.listUsers.users,
  briefs: state.project.briefs.briefList,
  isProjectLoading: state.project.isLoading,
  isProjectCreated: state.project.isCreated,
  isBriefCreated: state.project.briefs.isCreated,
  isLoadingBriefs: state.project.briefs.isLoading,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const UpdateBudget = connector(UpdateBudgetInner);
