import moment from 'moment';

import { dateFormatter } from './date-formatter';
import { IBudget, IClient, INotification } from './types';

/**
 * These functions below are created for the UI part of notifications
 */
const findClient = (clientList: IClient[], targetClient: string) => {
  return clientList.find(el => el.id === targetClient.replace('/clients/', ''))?.companyName;
};

export const generateNotifications = (notifs: any[], listClients: IClient[], budgets: IBudget[]) => {
  const generatedNotifs: INotification[] = [];
  notifs.forEach(n => {
    let notif: INotification;
    switch (n.type) {
      case 'budget-status':
      notif = {
        class: 'budget-status',
        icon: 'like',
        category: `Budget ${ n.data.status }`,
        content: `${ findClient(listClients, n.data.client) }: ${ n.data.name }`,
        date: dateFormatter(n.data.updatedAt),
        time: moment(n.data.updatedAt).format('HH:mm'),
      };
      break;

      case 'budget-prio':
      notif = {
        class: 'new-priority',
        icon: 'clock-circle',
        category: 'Nouvelle Priorité',
        content: `${ findClient(listClients, n.data.client) }: ${ n.data.name }`,
        date: dateFormatter(n.data.createdAt),
        time: moment(n.data.createdAt).format('HH:mm'),
      };
      break;

      case 'new-user':
      notif = {
        class: 'new-user',
        icon: 'user',
        category: 'Nouvelle Utilisateur',
        content: `Bienvenue parmi nous ${ n.data.firstName } ${ n.data.lastName }`,
        date: dateFormatter(n.data.createdAt),
        time: moment(n.data.createdAt).format('HH:mm'),
      };
      break;

      default:
        return;
    }
    generatedNotifs.push(notif);
  });
  return generatedNotifs;
};
