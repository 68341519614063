import { IErrors } from '../../utils/types';

import {
  AUTH_LOGIN,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_LOGOUT_SUCCESS,
  AUTH_SET_LOGGED_IN,
  IAuthLoginAction,
  IAuthLoginErrorAction,
  IAuthLoginSuccessAction,
  IAuthLogoutAction,
  IAuthLogoutSuccessAction,
  IAuthSetLoggedInAction,
  ICredentials,
  IRequestResetPasswordAction,
  IRequestResetPasswordErrorAction,
  IRequestResetPasswordSuccessAction,
  IResetErrorsAction,
  IResetPasswordAction,
  IResetPasswordData,
  IResetPasswordErrorAction,
  IResetPasswordSuccessAction,
  IResetRequestedNewPasswordAction,
  ITokens,
  REQUEST_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD_ERROR,
  REQUEST_RESET_PASSWORD_SUCCESS,
  RESET_ERRORS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_REQUESTED_NEW_PASSWORD,
} from './types';

/**
 * Login actions
 */
export const authLogin = (credentials: ICredentials): IAuthLoginAction => ({
  type: AUTH_LOGIN,
  payload: credentials,
});

export const authLoginSuccess = (token: ITokens): IAuthLoginSuccessAction => ({
  type: AUTH_LOGIN_SUCCESS,
  payload: token,
});

export const authLoginError = (errors: IErrors): IAuthLoginErrorAction => ({
  type: AUTH_LOGIN_ERROR,
  payload: errors,
});

/**
 * Set is loggedIn actions
 */
export const authSetLoggedIn = (isLoggedIn: boolean): IAuthSetLoggedInAction => ({
  type: AUTH_SET_LOGGED_IN,
  payload: isLoggedIn,
});

/**
 * Logout actions
 */
export const authLogout = (): IAuthLogoutAction => ({ type: AUTH_LOGOUT });

export const authLogoutSuccess = (): IAuthLogoutSuccessAction => ({ type: AUTH_LOGOUT_SUCCESS });

/**
 * Request reset password actions
 */
export const requestResetPassword = (email: string): IRequestResetPasswordAction => ({
  type: REQUEST_RESET_PASSWORD,
  payload: email,
});

export const requestResetPasswordSuccess = (): IRequestResetPasswordSuccessAction => ({
  type: REQUEST_RESET_PASSWORD_SUCCESS,
});

export const requestResetPasswordError = (error: IErrors): IRequestResetPasswordErrorAction => ({
  type: REQUEST_RESET_PASSWORD_ERROR,
  payload: error,
});

export const resetErrors = (): IResetErrorsAction => ({
 type: RESET_ERRORS,
});

/**
 * Reset password actions
 */
export const resetPassword = (resetData: IResetPasswordData): IResetPasswordAction => ({
  type: RESET_PASSWORD,
  payload: resetData,
});

export const resetPasswordSuccess = (): IResetPasswordSuccessAction => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const resetPasswordError = (error: IErrors): IResetPasswordErrorAction => ({
  type: RESET_PASSWORD_ERROR,
  payload: error,
});

export const resetRequestedNewPassword = (): IResetRequestedNewPasswordAction => ({
  type: RESET_REQUESTED_NEW_PASSWORD,
});
