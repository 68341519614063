import { Action } from 'redux';

import { IAlertModalTerm, IErrors, ISync } from '../../utils/types';

export interface ISettings {
  isBriefModalOpen: boolean;
  alertModal: IAlertModalTerm;
  sync: ISync;
}

/**
 * Toggle open brief modal
 */
export const TOGGLE_OPEN_MODAL = 'TOGGLE_OPEN_MODAL';
export type TOGGLE_OPEN_MODAL = typeof TOGGLE_OPEN_MODAL;

export interface IToggleOpenModal extends Action<TOGGLE_OPEN_MODAL> {
  payload: boolean;
}

/**
 * Open files alert modal
 */
export const OPEN_ALERT_MODAL = 'OPEN_ALERT_MODAL';
export type OPEN_ALERT_MODAL = typeof OPEN_ALERT_MODAL;

export interface IOpenAlertModalAction extends Action<OPEN_ALERT_MODAL> {
  payload: IAlertModalTerm;
}

/**
 * Synchronize the app with the filesystem
 */
export const SYNCHRONIZE_REQUEST = 'SYNCHRONIZE_REQUEST';
export type SYNCHRONIZE_REQUEST = typeof SYNCHRONIZE_REQUEST;

export const SYNCHRONIZE_REQUEST_SUCCESS = 'SYNCHRONIZE_REQUEST_SUCCESS';
export type SYNCHRONIZE_REQUEST_SUCCESS = typeof SYNCHRONIZE_REQUEST_SUCCESS;

export const SYNCHRONIZE_REQUEST_ERROR = 'SYNCHRONIZE_REQUEST_ERROR';
export type SYNCHRONIZE_REQUEST_ERROR = typeof SYNCHRONIZE_REQUEST_ERROR;

export interface ISynchronizeRequestAction extends Action<SYNCHRONIZE_REQUEST> {
  payload: string;
}

export interface ISynchronizeRequestSuccessAction extends Action<SYNCHRONIZE_REQUEST_SUCCESS> {}

export interface ISynchronizeRequestErrorAction extends Action<SYNCHRONIZE_REQUEST_ERROR> {
  payload: IErrors;
}

export type SettingsActionTypes =
  | IToggleOpenModal
  | IOpenAlertModalAction
  | ISynchronizeRequestAction
  | ISynchronizeRequestSuccessAction
  | ISynchronizeRequestErrorAction;
