import { Button, Card, Col, Icon, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { PageTitle } from '../../components/PageTitle';
import { IPageProps } from '../../utils/types';

import './unauthorized.less';

export const Unauthorized: React.FC<IPageProps> = ({ route }) => {
  return (
    <div className="unauthorized">
      <PageTitle title={ route.title } />
      <div className="table">
        <div className="cell">
          <Card>
            <Row gutter={[16, 16]}>
              <Col>
                <Icon type="lock" className="lock-icon" />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col>
                <span className="not-found-number">OOPS</span>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col>
                <span className="not-found-text">Non autorisé: l'accès à cette ressource est refusé</span>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col>
                <Link to="/">
                  <Button type="primary" icon="arrow-left" className="dashboard-btn">
                    Retour au dashboard
                  </Button>
                </Link>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </div>
  );
};
