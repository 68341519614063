import { Layout } from 'antd';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { CustomBreadcrumb } from '../../components/CustomBreadcrumb';
import { PageTitle } from '../../components/PageTitle';
import { GlobalState } from '../../store';
import { IPageProps } from '../../utils/types';

import { ListNotifications } from './components/ListNotifications';

const NotificationsInner: React.FC<{} & ConnectedProps<typeof connector> & IPageProps> = ({ clients, budgets, notifications, route }) => {
  return (
    <>
      <CustomBreadcrumb route={route} />
      <Layout>
        <PageTitle title={`${route.title} (${notifications.length})`} />
        <Layout.Content>
          <ListNotifications clients={clients} budgets={budgets} notifications={notifications} />
        </Layout.Content>
      </Layout>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  clients: state.clients.listClients,
  notifications: state.alerts.notifications.items,
  budgets: state.project.listProjects,
});

const connector = connect(mapStateToProps);

export const Notifications = connector(NotificationsInner);
