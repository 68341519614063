import { Action } from 'redux';

import { IAlertUser, IBudget, IBudgetFiles, IErrors } from '../../utils/types';

export interface IAlertFilesContent {
  items: IBudgetFiles[];
  newItems: number;
}

export interface INotifItem {
  type: string;
  data: IAlertUser | IBudget;
}
export interface IAlertNotificationsContent {
  items: INotifItem[];
  newItems: number;
}

export interface IAlert {
  filesToSend: IAlertFilesContent;
  filesToCheck: IAlertFilesContent;
  notifications: IAlertNotificationsContent;
  isLoading: boolean;
  errors: IErrors | null;
}

/**
 * Types
 */
export const ALERT_GET_TO_BE_SENT_FILES = 'ALERT_GET_TO_BE_SENT_FILES';
export type ALERT_GET_TO_BE_SENT_FILES  = typeof ALERT_GET_TO_BE_SENT_FILES;

export const ALERT_GET_TO_CHECK_FILES = 'ALERT_GET_TO_CHECK_FILES';
export type ALERT_GET_TO_CHECK_FILES  = typeof ALERT_GET_TO_CHECK_FILES;

export const ALERT_GET_NOTIFICATIONS = 'ALERT_GET_NOTIFICATIONS';
export type ALERT_GET_NOTIFICATIONS  = typeof ALERT_GET_NOTIFICATIONS;

export const ALERT_RESET_SENT_FILES_COUNTER = 'ALERT_RESET_SENT_FILES_COUNTER';
export type ALERT_RESET_SENT_FILES_COUNTER  = typeof ALERT_RESET_SENT_FILES_COUNTER;

export const ALERT_RESET_CHECK_FILES_COUNTER = 'ALERT_RESET_CHECK_FILES_COUNTER';
export type ALERT_RESET_CHECK_FILES_COUNTER  = typeof ALERT_RESET_CHECK_FILES_COUNTER;

export const ALERT_RESET_BOTIFICATIONS_COUNTER = 'ALERT_RESET_BOTIFICATIONS_COUNTER';
export type ALERT_RESET_BOTIFICATIONS_COUNTER  = typeof ALERT_RESET_BOTIFICATIONS_COUNTER;

export const ALERT_REQUEST_SUBSCRIPTION_ERROR = 'ALERT_REQUEST_SUBSCRIPTION_ERROR';
export type ALERT_REQUEST_SUBSCRIPTION_ERROR  = typeof ALERT_REQUEST_SUBSCRIPTION_ERROR;

/**
 * Action types
 */
export interface IAlertGetToBeSentFilesAction extends Action<ALERT_GET_TO_BE_SENT_FILES> {
  payload: IBudgetFiles;
}

export interface IAlertGetToCheckFilesAction extends Action<ALERT_GET_TO_CHECK_FILES> {
  payload: IBudgetFiles;
}

export interface IAlertGetNotificationsAction extends Action<ALERT_GET_NOTIFICATIONS> {
  payload: INotifItem;
}

export interface IAlertResetToSendFilesCounterAction extends Action<ALERT_RESET_SENT_FILES_COUNTER> {}

export interface IAlertResetToCheckFilesCounterAction extends Action<ALERT_RESET_CHECK_FILES_COUNTER> {}

export interface IAlertResetNotificationsCounterAction extends Action<ALERT_RESET_BOTIFICATIONS_COUNTER> {}

export interface IAlertRequestSubscriptionErrorAction extends Action<ALERT_REQUEST_SUBSCRIPTION_ERROR> {
  payload: IErrors;
}

export type IAlertActionType =
    IAlertGetToBeSentFilesAction
  | IAlertGetToCheckFilesAction
  | IAlertGetNotificationsAction
  | IAlertRequestSubscriptionErrorAction
  | IAlertResetToSendFilesCounterAction
  | IAlertResetToCheckFilesCounterAction
  | IAlertResetNotificationsCounterAction;
