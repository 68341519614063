import React from 'react';

import { IDispatchTeamState } from '../../utils/types';

import './users-counter.less';

interface IUsersCounterFooterProps {
  dispatchTeam: IDispatchTeamState;
}

export const UsersCounterFooter: React.FC<IUsersCounterFooterProps> = ({ dispatchTeam }) => {
  const { totale, numberActive, numberLibre } = dispatchTeam.data;
  return (
    <div className="users-counter">
      <span className="fragment">
        {totale} utilisateur{totale > 1 ? 's' : ''},
      </span>
      <span className="fragment">
        Actif {numberActive},
      </span>
      <span className="fragment">
        Libre {numberLibre}
      </span>
    </div>
  );
};
