import { Layout } from 'antd';
import React, { useContext, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ConnectedUserSider } from '../../../components/ConnectedUserSider';
import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionsHandler } from '../../../components/permissions-handlers/PermissionsHandler';
import { SiderProfileDetails } from '../../../components/SiderProfileDetails';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { getFilesystemClients, getListClients } from '../../../store/clients/actions';
import { getDirectories } from '../../../store/directory/actions';
import { addBudget, getAllProjectBriefs } from '../../../store/project/actions';
import { getUsers } from '../../../store/users/actions';
import { IBudgetToSave, IPermissionsHandler, IPageProps } from '../../../utils/types';
import { siderWidth } from '../../../utils/vars';

import { NewBudgetForm } from './components/NewBudgetForm';

const { Content, Sider } = Layout;

// tslint:disable-next-line: no-empty-interface
interface INewBudgetProps extends IPageProps {}

const NewBudgetInner: React.FC<INewBudgetProps & ConnectedProps<typeof connector>> = ({
  route,
  folders,
  clients,
  fsClients,
  briefs,
  users,
  isLoading,
  isCreated,
  isLoadingBriefs,
  budgetErrors,
  connectedUser,
  getDirectories: getDirectoriesAction,
  getListClients: getListClientsAction,
  getFilesystemClients: getFilesystemClientsAction,
  getAllProjectBriefs: getAllProjectBriefsAction,
  getUsers: getUsersAction,
  addBudget: addBudgetAction,
}) => {

  const permissions: IPermissionsHandler | null = useContext(PermissionContext);

  useEffect(() => {
    getFilesystemClientsAction();
  },        [getFilesystemClientsAction]);

  useEffect(() => {
    getDirectoriesAction();
  },        [getDirectoriesAction]);

  useEffect(() => {
    getListClientsAction({
      status: null,
    });
  },        [getListClientsAction]);

  useEffect(() => {
    getUsersAction();
  },        [getUsersAction]);

  const getSelectedFolder = (folder: string) => {
    getAllProjectBriefsAction(folder);
  };

  const getNewBudget = (budget: IBudgetToSave) => {
    addBudgetAction(budget);
  };

  return (
    <>
      <CustomBreadcrumb route={ route } />
      <Layout className="page-layout has-card-view">
        <PageTitle title={ route.title } />
        <Sider width={ siderWidth }>
          {!!connectedUser && <ConnectedUserSider userData={ connectedUser } />}
        </Sider>
        <Layout>
          <Content>
            <PermissionsHandler
              blocPermission="CAN_CREATE"
              resourceName="Project"
              userPermissions={permissions}
              redirectOption
            >
              <NewBudgetForm
                briefs={briefs}
                users={users}
                folders={folders}
                clients={clients}
                fsClients={ fsClients }
                isLoading={isLoading}
                isCreated={ isCreated }
                onSubmitNewBudget={getNewBudget}
                onChangeFolder={getSelectedFolder}
                isLoadingBriefs={isLoadingBriefs}
                budgetErrors={budgetErrors}
                connectedUserName={`${connectedUser?.firstName} ${connectedUser?.lastName}`}
              />
            </PermissionsHandler>
          </Content>
        </Layout>

        <Sider width={ siderWidth }>
          {!!connectedUser && <SiderProfileDetails userData={connectedUser} />}
        </Sider>
      </Layout>
    </>
  );
};

const mapDispatchToProps = {
  getDirectories,
  getListClients,
  getFilesystemClients,
  getAllProjectBriefs,
  getUsers,
  addBudget,
};

const mapStateToProps = (state: GlobalState) => ({
  folders: state.directories.directories,
  clients: state.clients.listClients,
  fsClients: state.clients.filesystemClients,
  briefs: state.project.briefs.briefList,
  users: state.listUsers.users,
  isLoading: state.project.isLoading,
  isCreated: state.project.isCreated,
  isBriefCreated: state.project.briefs.isCreated,
  isLoadingBriefs: state.project.briefs.isLoading,
  budgetErrors: state.project.errors,
  connectedUser: state.connectedUser.userData,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const NewBudget = connector(NewBudgetInner);
