import { Layout } from 'antd';
import React, { useContext, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ConnectedUserSider } from '../../../components/ConnectedUserSider';
import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionsHandler } from '../../../components/permissions-handlers/PermissionsHandler';
import { SiderProfileDetails } from '../../../components/SiderProfileDetails';
import { SiderUsersList } from '../../../components/SiderUsersList';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { createNewUser, getListRoles, getUsers } from '../../../store/users/actions';
import { IPermissionsHandler, IPageProps, IUserData } from '../../../utils/types';
import { siderWidth } from '../../../utils/vars';

import { ProfileForm } from './components/ProfileForm';

const { Sider, Content } = Layout;

// tslint:disable-next-line: no-empty-interface
interface IProfileProps extends IPageProps {}

export const CreateProfilePageInner: React.FC<IProfileProps & ConnectedProps<typeof connector>> = ({
  route,
  rolesList,
  errors,
  isLoading,
  isCreated,
  listUsers,
  connectedUser,
  getListRoles: getListRolesAction,
  getUsers: getUsersAction,
  createNewUser: createNewUserAction,
}) => {
  const permissions: IPermissionsHandler = useContext(PermissionContext);

  const getNewUserData = (newProfile: IUserData) => {
    createNewUserAction(newProfile);
  };

  useEffect(() => {
    getListRolesAction();
    // tslint:disable-next-line: align
  }, [getListRolesAction]);

  useEffect(() => {
    getUsersAction();
    // tslint:disable-next-line: align
  }, [getUsersAction]);

  return (
    <>
      <CustomBreadcrumb route={route} />
      <PageTitle title={route.title} />
      <Layout className="page-layout has-card-view">
        <Sider width={siderWidth}>
          {!!connectedUser && <ConnectedUserSider userData={connectedUser} />}
          {!!connectedUser && <SiderUsersList listPeople={listUsers} connectedUserId={connectedUser.id} />}
        </Sider>

        <Layout>
          <Content>
            {!!connectedUser && (
              <PermissionsHandler blocPermission="CAN_CREATE" resourceName="User" userPermissions={permissions} redirectOption>
                <ProfileForm
                  onSubmitProfileClick={getNewUserData}
                  roleList={rolesList}
                  errors={errors}
                  isLoading={isLoading}
                  isCreated={isCreated}
                  connectedUser={connectedUser}
                />
              </PermissionsHandler>
            )}
          </Content>
        </Layout>

        <Sider width={siderWidth} style={{ textAlign: 'center' }}>
          {!!connectedUser && <SiderProfileDetails userData={connectedUser} />}
        </Sider>
      </Layout>
    </>
  );
};

const mapDsiaptchToProps = {
  createNewUser,
  getListRoles,
  getUsers,
};

const mapStateToProps = (state: GlobalState) => ({
  rolesList: state.listUsers.roles.listRoles,
  errors: state.listUsers.create.errors,
  isLoading: state.listUsers.create.isLoading,
  isCreated: state.listUsers.create.isCreated,
  connectedUser: state.connectedUser.userData,
  listUsers: state.listUsers.users,
});

const connector = connect(mapStateToProps, mapDsiaptchToProps);

export const CreateProfilePage = connector(CreateProfilePageInner);
