import { Avatar, Card, Col } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import './add-card.less';

const { Meta } = Card;

export const AddUserCard: React.FC<{}> = () => {
  return (
    <Col span={6} xl={6} md={12} sm={24}>
      <div className="add-new-profile">
        <Link to="/create-profile">
          <Card hoverable bordered={false}>
            <Avatar
              size={200}
              icon="plus-circle"
            />
            <Meta title="Ajouter un nouveau profil" />
          </Card>
        </Link>
      </div>
    </Col>
  );
};
