import { Avatar, Col, Popover, Row, Tooltip } from 'antd';
import React from 'react';

import { CustomAvatar } from '../../../../../components/CustomAvatar';
import { IUser } from '../../../../../store/users/types';

import './budget-team.less';

interface IBudgetTeamProps {
  team: {
    retoucher: IUser[];
    prodManagers: IUser[];
    projManager: IUser[];
  };
}

export const BudgetTeam: React.FC<IBudgetTeamProps> = ({ team }) => {
  const size: number = 3;
  const concatedUsers = team.retoucher.concat(team.prodManagers).concat(team.projManager);
  const visibleItems = concatedUsers.slice(0, size);
  const restItems = concatedUsers.slice(size, concatedUsers.length);

  const renderAvatars = (users: IUser[], type: 'size' | 'rest') => {
    return users.map(usr => {
      return (
        <div className={`budget-team-avatar-${ type }`} key={usr.id}>
          <Tooltip title={`${usr.firstName} ${usr.lastName}`}>
            <CustomAvatar shape="square" src={usr.avatar?.path} />
          </Tooltip>
        </div>
      );
    });
  };

  const renderRestNames = (people: IUser[]) => {
    return (
      <Row className="budget-team-rest-avatars">
        {people.map(user => (
          <Col key={user.id}>
            {user.firstName} {user.lastName}
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <div className="budget-team">
      {renderAvatars(visibleItems, 'size')}
      {!!restItems.length && (
        <Popover placement="bottomLeft" content={renderAvatars(restItems, 'rest')} trigger="click">
          <span className="budget-team-rest">
            <Tooltip title={ renderRestNames(restItems) }>
              <Avatar shape="square" icon={<span>+{concatedUsers.length - size}</span>} />
            </Tooltip>
          </span>
        </Popover>
      )}
    </div>
  );
};
