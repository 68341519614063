import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { PageTitle } from '../../../components/PageTitle';
import { GlobalState } from '../../../store';
import { authLogin } from '../../../store/auth/actions';
import { ICredentials } from '../../../store/auth/types';
import { IPageProps } from '../../../utils/types';
import { lastVisitedPath } from '../../../utils/vars';
import '../auth-styles.less';

import { LoginForm } from './components/LoginForm';

// tslint:disable-next-line: no-empty-interface
interface ILoginProps extends IPageProps {}

const LoginPage: React.FC<ILoginProps & RouteComponentProps & ConnectedProps<typeof connector>> = ({
  route,
  authLogin: login,
  loginErrors,
  isLoggedIn,
  isLoading,
  history,
  location,
}) => {
  const handleSubmit = (credentials: ICredentials) => {
    login(credentials);
  };

  useEffect(() => {
      if (isLoggedIn) {
        const lastVisited = localStorage.getItem(lastVisitedPath) || '/';
        const to = (location.state) ? lastVisited : '/';
        history.push(to);
      }
    },      [history, isLoggedIn, location.state]);

  return (
    <>
      <PageTitle title={ route.title } />
      <LoginForm
        isLoading={ isLoading }
        loginErrors={ loginErrors }
        onLoginButtonClick={ handleSubmit }
      />
    </>

  );
};

const mapStateToProps = (state: GlobalState) => ({
  isLoggedIn: state.auth.isLoggedIn,
  loginErrors: state.auth.loginErrors,
  isLoading: state.auth.isLoading,
});
const mapDispatchToProps = { authLogin };

const connector = connect(mapStateToProps, mapDispatchToProps);

export const Login = connector(LoginPage);
