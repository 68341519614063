import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

import * as requests from '../../api';
import { IConnectedUserData } from '../connectedUser/types';

import {
  authLoginError,
  authLoginSuccess,
  authLogoutSuccess,
  requestResetPasswordError,
  requestResetPasswordSuccess,
  resetPasswordError,
  resetPasswordSuccess,
} from './actions';
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  IAuthLoginAction,
  IRequestResetPasswordAction,
  IResetPasswordAction,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
} from './types';

/**
 * Login generator
 */
function* login({ payload }: IAuthLoginAction) {
  try {
    const res: AxiosResponse<{ token: string; mercureToken: string; me: IConnectedUserData }> = yield requests.login(payload);
    yield put(authLoginSuccess({
      token: res.data.token,
      mercureToken: res.data.mercureToken,
    }));
  } catch (e) {
    yield put(authLoginError(e));
  }
}

/**
 * Logout generator
 */
function* logout() {
  yield put(authLogoutSuccess());
}

/**
 * Request reset password generator
 */
function* requestResetPassword({ payload }: IRequestResetPasswordAction) {
  try {
    yield requests.requestResetPassword(payload);
    yield put(requestResetPasswordSuccess());
  } catch (e) {
    yield put(requestResetPasswordError(e));
  }
}

/**
 * Reset password
 */
function* resetPassword({ payload }: IResetPasswordAction) {
  try {
    yield requests.resetPassword(payload);
    yield put(resetPasswordSuccess());
    message.success('Votre mot de passe a été réinitialisé avec succès.');
  } catch (e) {
    yield put(resetPasswordError(e));
    message.error(e.response.data.message);
  }
}

/**
 * Login watcher
 */
export function* watchLogin() {
  yield takeLatest(AUTH_LOGIN, login);
}

/**
 * Logout watcher
 */
export function* watchLogout() {
  yield takeLatest(AUTH_LOGOUT, logout);
}

/**
 * Request reset password watcher
 */
export function* watchRequestResetPassword() {
  yield takeLatest(REQUEST_RESET_PASSWORD, requestResetPassword);
}

/**
 * Reset password watcher
 */
export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}
