import { IUser } from '../../store/users/types';

/**
 * This function was created to accept a list of users as a first parameter, and a role (string) as its second parameter.
 * It uses the role to filter and give a list of roles back.
 *
 * Example: if you give it a list of users alongside with the role
 * "developer", the function will return an array of users their roles matches developer.
 *
 * @param users
 * @param role
 */
export const generateGroup = (users: IUser[], role: string) => {
  return users.filter(user => user.role?.slug === role);
};
