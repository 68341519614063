import { Checkbox, Col, Dropdown, Form, Icon, Menu, Row, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormComponentProps } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';

import { PermissionsHandler } from '../../../../../components/permissions-handlers/PermissionsHandler';
import { IBudgetFiles, IPermissionsHandler } from '../../../../../utils/types';

const { Option } = Select;

interface IFilesFiltersProps extends FormComponentProps {
  permissions: IPermissionsHandler | null;
  tabsToShow: string[];
  listFiles: IBudgetFiles[];
  onTabsToShowChange: (tabs: string[]) => void;
  onImagesNbrToShowChange: (nbr: number) => void;
  onImagesToShowChange: (listStatus: string[]) => void;
  onItemToCheckChange: (status: string) => void;
  onToggleEnableActions: (isEnabled: boolean) => void;
  onStatusToUpdateChange: (status: string) => void;
}

export const FilesFiltersInner: React.FC<IFilesFiltersProps> = ({
  permissions,
  form,
  listFiles,
  tabsToShow,
  onTabsToShowChange,
  onImagesNbrToShowChange,
  onImagesToShowChange,
  onItemToCheckChange,
  onToggleEnableActions,
  onStatusToUpdateChange,
}) => {
  // List of states
  const [isActionActive, setIsActionActive] = useState(false);
  const { getFieldDecorator, resetFields } = form;

  // Lists of consts
  const statusMenu = [
    {
      text: 'Tout',
      ref: 'all',
    },
    {
      text: 'Aucun',
      ref: 'none',
    },
    {
      text: 'À Envoyer',
      ref: 'À envoyer',
    },
    {
      text: 'En cours',
      ref: 'En cours',
    },
    {
      text: 'Transféré',
      ref: 'Transféré',
    },
    {
      text: 'Stand-by',
      ref: 'Stand by',
    },
    {
      text: 'Annulé',
      ref: 'Annulé',
    },
  ];

  const actionsOptions = [
    {
      text: 'Mettre en Stand-by',
      ref: 'Stand by',
    },
    {
      text: 'Mettre en cours',
      ref: 'En cours',
    },
    {
      text: 'Je récupère !',
      ref: 'pin',
    },
    {
      text: 'Je chéck !',
      ref: 'À checké',
    },
    {
      text: 'Je transfère !',
      ref: 'Transféré',
    },
  ];

  const imagesToShow = [
    {
      text: 'En cours',
      ref: 'En cours',
    },
    {
      text: 'Transféré',
      ref: 'Transféré',
    },
    {
      text: 'À venir',
      ref: 'À venir',
    },
    {
      text: 'Non entamés',
      ref: 'À faire',
    },
    {
      text: 'En Stand-by',
      ref: 'Stand by',
    },
    {
      text: 'Annulés',
      ref: 'Annulé',
    },
  ];

  const imagesNbrToShow: number[] = [10, 25, 50, 100];

  const filesTabs = ['Chécké par', 'Team', 'Timing RT', 'Début RT', 'Fin RT', 'Prio', 'Timing Final'];

  const handleEnablingStatusActions = (e: CheckboxChangeEvent) => {
    setIsActionActive(!isActionActive);
  };

  const handleImagesToShowChange = (values: string[]) => onImagesToShowChange(values);

  const handleImagessPerPageChange = (nbr: number) => onImagesNbrToShowChange(nbr);

  const handleTabsToShowChange = (tabs: string[]) => {
    localStorage.setItem('lunatik-files-visible-tabs', JSON.stringify(tabs));
    onTabsToShowChange(tabs);
  };

  const handleStatusToUpdate = (status: string) => onStatusToUpdateChange(status);

  // Effects
  useEffect(() => {
    onToggleEnableActions(isActionActive);
  },        [isActionActive, onToggleEnableActions]);

  useEffect(() => {
    setIsActionActive(false);
    resetFields(['actions']);
  },        [listFiles, resetFields]);

  const menu = (
    <Menu>
      {statusMenu.map(item => {
        return (
          <Menu.Item key={item.ref}>
            <div onClick={() => onItemToCheckChange(item.ref)}>{item.text}</div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Row className="custom-filter" gutter={8}>
      <PermissionsHandler resourceName="File" blocPermission="CAN_PUT" userPermissions={permissions}>
        <Col xxl={1} xl={2} md={2}>
          <div className="actions">
            <Checkbox checked={ isActionActive } onChange={handleEnablingStatusActions} />{' '}
            <Dropdown overlay={menu} trigger={isActionActive ? ['click'] : ['contextMenu']}>
              <Icon type="caret-down" />
            </Dropdown>
          </div>
        </Col>

        <Col xxl={3} xl={3} md={3}>
          {
            getFieldDecorator('actions')(
              <Select
                onChange={handleStatusToUpdate}
                disabled={!isActionActive}
                style={{ width: '100%' }}
                placeholder={ 'Actions' }
              >
                {actionsOptions.map(option => (
                  <Option value={option.ref} key={option.ref}>
                    {option.text}
                  </Option>
                ))}
              </Select>
            )
          }
        </Col>
      </PermissionsHandler>

      <Col xxl={10} xl={8} md={8} className="justify-end">
        <span>Images à afficher:</span>{' '}
        <Select maxTagCount={0} mode="multiple" placeholder="Choix multiple" style={{ width: '60%' }} onChange={handleImagesToShowChange}>
          {imagesToShow.map(item => (
            <Option key={item.ref} value={item.ref}>
              <div>{item.text}</div>
            </Option>
          ))}
        </Select>
      </Col>

      <Col xxl={6} xl={5} md={5} className="justify-end">
        Afficher{' '}
        <Select style={{ width: '70px' }} defaultValue={10} onChange={handleImagessPerPageChange}>
          {imagesNbrToShow.map(option => (
            <Option value={option} key={option}>
              {option}
            </Option>
          ))}
        </Select>{' '}
        images
      </Col>

      <Col xxl={4} xl={5} md={5} className="justify-end">
        <span>Onglets :</span>{' '}
        {getFieldDecorator('toggleHideTabs', {
          initialValue: tabsToShow,
        })(
          <Select maxTagCount={0} mode="multiple" placeholder="Choix multiple" style={{ width: '60%' }} onChange={handleTabsToShowChange}>
            {filesTabs.map(item => (
              <Option key={item} value={item}>
                <div>{item}</div>
              </Option>
            ))}
          </Select>
        )}
      </Col>
    </Row>
  );
};

export const FilesFilters = Form.create<IFilesFiltersProps>()(FilesFiltersInner);
