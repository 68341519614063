import { List } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { IUser } from '../../store/users/types';
import { renderPaginationItems } from '../../utils/table-pagination-item-render';
import { CustomAvatar } from '../CustomAvatar';

import './sider-users-list.less';

const { Item } = List;

// tslint:disable-next-line: no-empty-interface
interface ISiderPeopleListProps {
  listPeople: IUser[];
  connectedUserId: string;
  selectedUser?: string;
}

export const SiderUsersList: React.FC<ISiderPeopleListProps> = ({ listPeople, connectedUserId, selectedUser }) => {

  const [restUsers, setRestUsers] = useState<IUser[]>([]);

  const excludeConnectedUser = (users: IUser[], id: string) => users.filter(u => u.id !== id);

  useEffect(() => {
    if (connectedUserId && listPeople) setRestUsers(excludeConnectedUser(listPeople, connectedUserId));
  },        [listPeople, connectedUserId]);

  const renderItem = (user: IUser) => {
    return (
      <List.Item
        className={`list-user-item ${ selectedUser && selectedUser === user.id ? 'list-user-item-active' : '' }`}
        key={user.id}
      >
        <Link to={`/team/${ user.id }`}>
          <Item.Meta
            avatar={<CustomAvatar shape="square" src={user.avatar?.path} type="user" />}
            title={`${user.firstName} ${user.lastName}`}
          />
        </Link>
      </List.Item>
    );
  };

  return (
    <div className="users-list-container small-non-pages-paginator">
      <h4 className="orange-title">Profils utilisateurs</h4>
      <List
        pagination={{
          pageSize: 10,
          size: 'small',
          hideOnSinglePage: true,
          itemRender: (page, type, original) => renderPaginationItems(page, type, original),
        }}
        dataSource={restUsers}
        renderItem={renderItem}
        footer={(
          <span className="footer-counter">
            {restUsers?.length === 1 && restUsers[0].id === connectedUserId ? '0' : restUsers?.length} utilisateur{ restUsers?.length > 1 ? 's' : '' }
          </span>
        )}
      />
    </div>
  );
};
