import { Button, Layout, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import { ConnectedUserSider } from '../../../components/ConnectedUserSider';
import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { PageLoader } from '../../../components/PageLoader';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionsHandler } from '../../../components/permissions-handlers/PermissionsHandler';
import { SiderUsersList } from '../../../components/SiderUsersList';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { disableUser, getUser, getUsers } from '../../../store/users/actions';
import { IPermissionsHandler, IPageProps } from '../../../utils/types';
import { siderWidth } from '../../../utils/vars';

import { ProfileDetails } from './components/ProfileDetails';
import './profile.less';

const { Content, Sider } = Layout;

interface IProfileProps extends IPageProps {}

export const ProfileInner: React.FC<IProfileProps & ConnectedProps<typeof connector> & RouteComponentProps<{ id: string }>> = ({
  route,
  connectedUser,
  users,
  targetUser,
  isLoadingUsers,
  match,
  isDisactivated,
  getUsers: getUsersAction,
  getUser: getUserAction,
  disableUser: disableUserAction,
}) => {
  const permissions: IPermissionsHandler | null = useContext(PermissionContext);

  useEffect(() => {
    getUsersAction();
  },        [getUsersAction]);

  useEffect(() => {
    getUserAction(match.params.id);
  },        [match, getUserAction]);

  useEffect(() => {
    getUserAction(match.params.id);
  },        [isDisactivated, getUserAction, match]);

  const getDesactivatedUser = () => {
    if (targetUser.user)
      disableUserAction({
        id: match.params.id,
        nextStatus: !targetUser.user.enabled,
        name: `${targetUser.user.firstName} ${targetUser.user.lastName}`,
      });
  };

  if (!permissions?.listPermissions && !permissions?.userRole && !!connectedUser) return <PageLoader />;

  return (
    <>
      <CustomBreadcrumb route={route} />
      <Layout className="profile-page page-layout has-card-view">
        {targetUser.user && <PageTitle title={`${targetUser.user.firstName} ${targetUser.user.lastName}`} />}
        <Spin spinning={isLoadingUsers}>
          <Sider className="right-border" width={siderWidth}>
            {!!connectedUser && <ConnectedUserSider userData={connectedUser} />}

            <PermissionsHandler blocPermission="CAN_CREATE" resourceName="User" userPermissions={permissions}>
              <Link to="/create-profile">
                <Button type="primary">
                  <b style={{ fontSize: 12 }}>+ Ajouter un nouveu Profil</b>
                </Button>
              </Link>
            </PermissionsHandler>

            {!!connectedUser && <SiderUsersList listPeople={users} connectedUserId={connectedUser.id} selectedUser={match.params.id} />}
          </Sider>
        </Spin>

        <Layout>
          <Content>
            <Spin spinning={targetUser.isLoading}>
              {!!targetUser.user && !!connectedUser && (
                <ProfileDetails
                  user={targetUser.user}
                  connectedUser={connectedUser}
                  permissions={permissions}
                  onUserDesactivated={getDesactivatedUser}
                  isDisactivated={isDisactivated}
                />
              )}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

const mapDispatchToProps = {
  getUsers,
  disableUser,
  getUser,
};

const mapStateToProps = (state: GlobalState) => ({
  connectedUser: state.connectedUser.userData,
  users: state.listUsers.users,
  isLoadingUsers: state.listUsers.loading,
  roles: state.listUsers.roles.listRoles,
  isDisactivated: state.listUsers.isEnablingComplete,
  targetUser: state.listUsers.singleUser,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const Profile = connector(ProfileInner);
