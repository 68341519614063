import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as requests from '../../api';
import { parseClientLogo } from '../../utils/client-utils';

import {
  addClientError,
  addClientSuccess,
  disableClientError,
  disableClientSuccess,
  getFilesystemClientsError,
  getFilesystemClientsSuccess,
  getListClients as getListClientsAction,
  getListClientsAndReplaceSuccess,
  getListClientsError,
  getListClientsSuccess,
  getSingleClientError,
  getSingleClientSuccess,
  updateClientError,
  updateClientSuccess,
} from './actions';
import {
  CLIENTS_ADD_CLIENT,
  CLIENTS_DISABLE_CLIENT,
  CLIENTS_GET_LIST_CLIENTS,
  CLIENTS_GET_SINGLE_CLIENT,
  CLIENTS_UPDATE_CLIENT,
  GET_FILESYSTEM_CLIENTS,
  IClientsAddClientAction,
  IClientsDisableClientAction,
  IClientsGetListAction,
  IClientsGetSingleClientAction,
  IClientsUpdateClientAction,
} from './types';

// Add client
function* addClient({ payload: client }: IClientsAddClientAction) {
  try {
    yield requests.addClientRequest(client, yield select(state => state.auth.token));
    yield put(addClientSuccess());
    yield put(getListClientsAction({ status: null }));
    message.success(`Le client ${ client.companyName } a été ajouté avec succès`);
  } catch (error) {
    yield put(addClientError(error));
  }
}

export function* watchAddClient() {
  yield takeLatest(CLIENTS_ADD_CLIENT, addClient);
}

// Get clients
function* getListClients({ payload: { status, page = 1 } }: IClientsGetListAction) {
  try {
    const res: AxiosResponse = yield requests.getClients({status, page}, yield select(state => state.auth.token));
    if (page === 1)
      yield put(getListClientsAndReplaceSuccess(parseClientLogo(res.data)));
    else
      yield put(getListClientsSuccess(parseClientLogo(res.data)));

  } catch (error) {
    yield put(getListClientsError(error));
  }
}

export function* watchGetListClients() {
  yield takeLatest(CLIENTS_GET_LIST_CLIENTS, getListClients);
}

/**
 * Get file system clients list
 */
function* getFilesystemClients() {
  try {
    const res = yield requests.getClientsFolders(yield select(state => state.auth.token));
    yield put(getFilesystemClientsSuccess(res.data));
  } catch (error) {
    yield put(getFilesystemClientsError(error));
  }
}

export function* watchGetFilesystemClients() {
  yield takeLatest(GET_FILESYSTEM_CLIENTS, getFilesystemClients);
}

// Get single client
function* getSingleClient({ payload: clientId }: IClientsGetSingleClientAction) {
  try {
    const res: AxiosResponse = yield requests.getClient(clientId, yield select(state => state.auth.token));
    yield put(getSingleClientSuccess(parseClientLogo(res.data)));
  } catch (error) {
    yield put(getSingleClientError(error));
  }
}

export function* watchSingleClient() {
  yield takeLatest(CLIENTS_GET_SINGLE_CLIENT, getSingleClient);
}

// Update client
function* updateClient({ payload: client }: IClientsUpdateClientAction) {
  try {
    yield requests.updateClientData(client, yield select(state => state.auth.token));
    yield put(updateClientSuccess());
    message.success(`Le client ${ client.companyName } a été modifié avec succès`);
  } catch (error) {
    yield put(updateClientError(error));
  }
}

export function* watchUpdateClient() {
  yield takeLatest(CLIENTS_UPDATE_CLIENT, updateClient);
}

// Disable Client
function* disableClient({ payload: { id, nextStatus, name } }: IClientsDisableClientAction) {
  try {
    yield requests.toggleDisableClient(id, nextStatus, yield select(state => state.auth.token));
    yield put(disableClientSuccess());
    message.success(`Le client ${name} a été ${ nextStatus ? 'activé' : 'désactivé' } avec succès.`);
    yield put(getListClientsAction({
      status: null,
      page: 1,
    }));
  } catch (error) {
    yield put(disableClientError(error));
  }
}
export function* watchDisableClient() {
  yield takeLatest(CLIENTS_DISABLE_CLIENT, disableClient);
}
