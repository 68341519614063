import { put, select, takeLatest } from 'redux-saga/effects';

import * as requests from '../../api';
import { pathnameToStatus } from '../../utils/project-utils';
import { getListClients } from '../clients/actions';
import { getAllBudgets, getInProgressBudgets } from '../project/actions';

import { synchronizeRequestError, synchronizeRequestSuccess } from './actions';
import { ISynchronizeRequestAction, SYNCHRONIZE_REQUEST } from './types';

function* triggerSynchronization({ payload: pathname }: ISynchronizeRequestAction) {
  try {
    const fragment = yield pathnameToStatus(pathname);
    yield requests.synchronizeApplication(yield select(state => state.auth.token));
    yield put(synchronizeRequestSuccess());
    yield put(getInProgressBudgets(['En cours', 'À faire', 'Stand by', 'Checké', 'À venir']));
    yield put(getListClients({
      status: [true, false],
      page: 1,
    }));
    if (fragment) yield put(getAllBudgets({
      page: 1,
      status: [],
    }));
  } catch (error) {
    yield put(synchronizeRequestError(error));
  }
}

export function* watchTriggerSynchronization() {
  yield takeLatest(SYNCHRONIZE_REQUEST, triggerSynchronization);
}
