import { ILangTransformer } from './types';

const statusList: ILangTransformer[] = [
  {
    en: 'To come up',
    fr: 'À venir',
  },
  {
    en: 'To do',
    fr: 'À faire',
  },
  {
    en: 'In progress',
    fr: 'En cours',
  },
  {
    en: 'Stand-by',
    fr: 'Stand by',
  },
  {
    en: 'Canceled',
    fr: 'Annulé',
  },
  {
    en: 'Checked',
    fr: 'Confirmé',
  },
  {
    en: 'Transferred',
    fr: 'Transféré',
  },
  {
    en: 'Archived',
    fr: 'Archivé',
  },
  {
    en: 'to check',
    fr: 'À checker',
  },
];

export const getAlternativeStatus = (status: string) => statusList.find(st => status && st.fr === status);

export const statusToClass = (status: string) => status.toLocaleLowerCase().split(' ').join('-');
