import { Button, Col, Form, Icon, Input, Layout, Row, Select, Upload } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../../../store';
import { mergeErrors } from '../../../../../utils/form-errors-utils';
import { IClient, IErrors } from '../../../../../utils/types';

const { Option } = Select;
const { Header } = Layout;
const { TextArea } = Input;

// tslint:disable-next-line: no-empty-interface
interface IUpdateClientFormProps extends FormComponentProps {
  onUpdateClient: (updatedClient: IClient) => void;
  client: IClient | null;
  errors: IErrors | null;
}

export const UpdateClientFormInner: React.FC<IUpdateClientFormProps> = ({
  onUpdateClient,
  form,
  client,
  errors,
  }) => {
  const [fieldsValues, setFieldsValues] = useState();

  const { getFieldDecorator, getFieldsValue, setFields, resetFields } = form;

  const token = useSelector((state: GlobalState) => state.auth.token);

  const avatarProps = {
    name: 'file',
    action: `${process.env.REACT_APP_SERVER_URL}/avatars`,
    headers: { authorization: `Bearer ${token}` },
    multiple: false,
  };

  // tslint:disable-next-line: no-any
  const getAvatar = (e: any) => {
    if (e) {
      return e.fileList.slice(-1);
    }
  };

  const handleUpdateClient = (e: React.FormEvent) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onUpdateClient({
          ...values,
          id: client && client.id,
          logo: values.logo ? values.logo[0].response['@id'] : undefined,
        });
        resetFields(['logo']);
      }
    });
    setFieldsValues(getFieldsValue());
  };
  useEffect(() => {
    const fields = mergeErrors(fieldsValues, errors);
    if (fields) setFields(fields);
  },        [errors, fieldsValues]);

  return (
    <>
      <Header>
        <span className="header-title">Modifier le client {client && client.companyName}</span>
      </Header>
      <Form onSubmit={handleUpdateClient} className="inner-form-padding">
        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator('type', {
                rules: [{ required: true, message: 'Veuillez sélectionner un type !' }],
                initialValue: client && client.type,
                validateTrigger: 'onBlur',
              })(
                <Select>
                  <Option value="Client">Client</Option>
                  <Option value="Prospect">Prospect</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator('companyName', {
                rules: [{ required: true, message: 'Veuillez entrer le nom de la société !' }],
                validateTrigger: 'onBlur',
                initialValue: client && client.companyName,
              })(<Input type="text" placeholder="Nom de la société" />)}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={8} md={24}>
            <Form.Item>
              {getFieldDecorator('contactFirstName', {
                rules: [{ required: true, message: 'Veuillez entrer le prénom !' }],
                validateTrigger: 'onBlur',
                initialValue: client && client.contactFirstName,
              })(<Input type="text" placeholder="Prénom du contact" />)}
            </Form.Item>
          </Col>
          <Col xl={8} md={24}>
            <Form.Item>
              {getFieldDecorator('contactLastName', {
                rules: [{ required: true, message: 'Veuillez entrer le nom !' }],
                validateTrigger: 'onBlur',
                initialValue: client && client.contactLastName,
              })(<Input type="text" placeholder="Nom du contact" />)}
            </Form.Item>
          </Col>
          <Col xl={8} md={24}>
            <Form.Item>
              {getFieldDecorator('phone', {
                initialValue: client && client.phone,
              })(<Input addonBefore={<Icon type="phone" />} type="phone" placeholder="Numéro de Téléphone" />)}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  { required: true, message: 'Veuillez entrer un email !' },
                  { type: 'email', message: 'l\'email n\'est pas valid !' },
                ],
                validateTrigger: 'onBlur',
                initialValue: client && client.email,
              })(<Input addonBefore={<Icon type="mail" />} type="email" placeholder="Adresse Email" />)}
            </Form.Item>
          </Col>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator('address', {
                initialValue: client && client.address,
              })(<Input type="text" placeholder="Adresse" />)}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={8} md={24}>
            <Form.Item>
              {getFieldDecorator('gender', {
                initialValue: client?.gender ? client?.gender : '-- Selectionner Genre --',
              })(
                <Select>
                  <Option value="Female">Femme</Option>
                  <Option value="Male">Homme</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xl={8} md={24}>
            <Form.Item>
              {getFieldDecorator('logo', {
                validateTrigger: 'onChange',
                valuePropName: 'fileList',
                getValueFromEvent: getAvatar,
              })(
                <Upload {...avatarProps} style={{ width: '100%' }}>
                  <Input
                    addonBefore={<Icon type="camera" />}
                    className="file"
                    type="button"
                    value="Logo de la société"
                    addonAfter={<Icon type="search" />}
                  />
                </Upload>
              )}
            </Form.Item>
          </Col>
          <Col xl={8} md={24}>
            <Form.Item>
              {getFieldDecorator('comment', {
                initialValue: client && client.comment,
              })(<TextArea placeholder="Commentaire..." />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xl={20} md={24}>
            <Button htmlType="submit" className="btn-success">
              Modifier le client
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const UpdateClientForm = Form.create<IUpdateClientFormProps>()(UpdateClientFormInner);
