/**
 * Permissions handler
 *
 * userPermissions: user's permissions (not filtered by resource name), filters are applied here.
 * blocPermission: CAN_PUT / CAN_CREATE / ...
 * resourceName: the rseource name for the element, this will be the match to extract permissions based on resource name from users' permissions.
 * owner: the ID of the connected user.
 * ownershipTarget: the ID of the target person to be compared with the owner ID
 * redirectOption: add the option to redirect to the page in case user is not permitted
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IPermissionsHandler } from '../../../utils/types';

import * as roles from './../../../utils/roles-utils';

interface IPermissionsHandlerProps {
  userPermissions: IPermissionsHandler | null;
  blocPermission: string;
  resourceName: string;
  owner?: string;
  ownershipTarget?: string;
  redirectOption?: boolean;
}

export const PermissionsHandler: React.FC<IPermissionsHandlerProps> = ({
  children,
  userPermissions,
  blocPermission,
  resourceName,
  owner,
  ownershipTarget,
  redirectOption,
}) => {
  const [isPermitted, setIsPermitted] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!!userPermissions && !!userPermissions.listPermissions) {
      const hasPermissions =
        userPermissions.listPermissions[resourceName]?.find((resource: (string | null)[]) => resource.includes(blocPermission)) || [];
      const isAdmin = userPermissions?.userRole === roles.ROLE_ADMIN || userPermissions?.userRole === roles.ROLE_SUPER_ADMIN;
      const hasPosession =
        hasPermissions?.includes('ALL') || hasPermissions?.includes(null) || (owner === ownershipTarget && hasPermissions?.includes('OWN'));
      setIsPermitted(isAdmin || hasPosession);
      if (!(isAdmin || hasPosession) && redirectOption) history.push('/unauthorized');
    }
  },        [userPermissions, resourceName, blocPermission, owner, ownershipTarget, redirectOption, history]);

  return <>{isPermitted && children}</>;
};
