import { IErrors } from '../types';

interface IFieldsValues {
  [key: string]: any;
}

interface IFields {
  [key: string]: {
    value: string,
    errors: Error[]
  };
}

/**
 *
 * @param fieldsValues: fields values (current values).
 * @param errors: list of incoming API errors.
 * @param setFields: function reference to re-set fields.
 */

export const mergeErrors = (fieldsValues: IFieldsValues, errors: IErrors | null) => {
  if(!fieldsValues) return;
  if (!errors) return;
  const fields: IFields = {};
  for (const [key, value] of Object.entries(errors))
    fields[key] = {
      value: fieldsValues[key],
      errors: value.map(e => new Error(e)),
    };
  return fields;
};
