import { Avatar, Button, Col, Divider, Dropdown, Empty, Form, Icon, Input, Menu, message, Modal, Row, Tag, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Briefs } from '../../../../../components/Briefs';
import { IConnectedUserData } from '../../../../../store/connectedUser/types';
import { IFileStatus } from '../../../../../store/file/types';
import { IBriefs } from '../../../../../store/project/types';
import { IUser } from '../../../../../store/users/types';
import * as roles from '../../../../../utils/roles-utils';
import { IBrief, IBrutFile, IBudgetFiles, IFilePreviewUrls, IFileStartEnd, IFilesToDownload, IFileVersions, ILogFile } from '../../../../../utils/types';
import { defaultAvatar } from '../../../../../utils/vars';

import './file-modal.less';

const { TextArea } = Input;
const { Dragger } = Upload;

interface IFileDetailsModalProps {
  isVisible: boolean;
  isLoadingBriefs: boolean;
  briefList: IBrief[];
  briefsPack: IBriefs;
  users: IUser[] | undefined;
  connectedUser?: IConnectedUserData;
  filePreview: IFilePreviewUrls;
  singleFile: IBudgetFiles | null;
  fileVersions: IFileVersions;
  brutFile: IBrutFile;
  onModalClose: () => void;
  onModalSave: (brief?: string, file?: string) => void;
  onStatusButtonsClick: (file: IFileStatus) => void;
  onFileDispatched: (fileId: string, type: string) => void;
  onRequestPreSignedUrl: (filename: string) => void;
  onUploadLogFile: (logFile: ILogFile) => void;
}

export const FileDetailsModal: React.FC<IFileDetailsModalProps> = ({
  isVisible,
  isLoadingBriefs,
  singleFile,
  briefList,
  briefsPack,
  users,
  fileVersions,
  brutFile,
  connectedUser,
  filePreview,
  onModalClose,
  onModalSave,
  onStatusButtonsClick,
  onFileDispatched,
  onRequestPreSignedUrl,
  onUploadLogFile,
}) => {

  const [retoucher, setRetoucher] = useState<IUser | undefined>();
  const [checker, setChecker] = useState<IUser | undefined>();
  const [transferer, setTransferer] = useState<IUser | undefined>();

  const [retouchFileAction, setRetouchFileAction] = useState('');
  const [checkFileAction, setCheckFileAction] = useState('');

  const [disabledRetoucher, setDesabledRetouch] = useState(false);
  const [disabledChecker, setDesabledChecker] = useState(false);
  const [disabledTransfer, setDesabledTransfer] = useState(false);

  const [canRetouch, setCanRetouch] = useState(false);
  const [canCheck, setCanCheck] = useState(false);
  const [canTransfer, setCanTransfer] = useState(false);

  const [fileBrief, setFileBrief] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [params, setParams] = useState<RcCustomRequestOptions | null>(null);
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    if (singleFile) {
      setRetoucher(getUser(users, singleFile.currentRetoucher));
      setChecker(getUser(users, singleFile.checker));
      setTransferer(getUser(users, singleFile.transfer));

      // Determine file pause, play retouch
      setRetouchFileAction(checkPlayStatus(singleFile.retoucherTimes));

      // Determine file pause, play check
      setCheckFileAction(checkPlayStatus(singleFile.checkerTimes));

      // Determine Actions block for each role
      setDesabledRetouch(
        singleFile.status !== 'À venir' &&
        singleFile.status !== 'À faire' &&
        singleFile.status !== 'En cours' &&
        singleFile.status !== 'Réviser'
        );
      setDesabledChecker(singleFile.status !== 'À checker');
      setDesabledTransfer(singleFile.status !== 'Checké');
    }
  },        [users, singleFile, connectedUser]);

  useEffect(() => {
    if (connectedUser && singleFile?.retoucher) setCanRetouch(
      !disabledRetoucher &&
      (singleFile?.retoucher.includes(`/users/${connectedUser.id}`) ||
      connectedUser?.role.slug === roles.ROLE_ADMIN ||
      connectedUser?.role.slug === roles.ROLE_SUPER_ADMIN ||
      connectedUser?.role.slug === roles.ROLE_PRODUCTION_MANAGER ||
      connectedUser?.role.slug === roles.ROLE_PROJECT_MANAGER)
    );

    // Check if user can check or transfer file
    if (connectedUser) setCanCheck(
      !disabledChecker &&
      (connectedUser?.role.slug === roles.ROLE_ADMIN ||
      connectedUser?.role.slug === roles.ROLE_SUPER_ADMIN ||
      connectedUser?.role.slug === roles.ROLE_PRODUCTION_MANAGER ||
      connectedUser?.role.slug === roles.ROLE_PROJECT_MANAGER)
    );

    if (connectedUser) setCanTransfer(
      !disabledTransfer &&
      (connectedUser?.role.slug === roles.ROLE_ADMIN ||
      connectedUser?.role.slug === roles.ROLE_SUPER_ADMIN)
    );
  },        [connectedUser, retoucher, checker, disabledRetoucher, disabledChecker, disabledTransfer, singleFile]);

  const handleSelfAssignFile = (type: string) => {
    if (singleFile) onFileDispatched(singleFile.id, type);
  };

  const getUser = (ListUsers: IUser[] | undefined, id?: string) => {
    if (!ListUsers || !id) return undefined;
    return ListUsers.find(u => u.id === id.replace('/users/', ''));
  };

  const checkPlayStatus = (times: IFileStartEnd[]) => {
    if (!times.length) return '';
    return times[times.length - 1].end ? 'paused' : 'playing';
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setFileBrief(e.target.value);

  const handleSaveAndClose = () => !!singleFile && onModalSave(fileBrief, singleFile.id);

  /**
   * DOWNLOAD / UPLOAD
   */
  const handleUploadFile = (parameters: RcCustomRequestOptions) => {
    setParams(parameters);
    onRequestPreSignedUrl(parameters.file.name);
  };

  const handleUploadedFilesChange = (info: UploadChangeParam) => setUploadedFiles(info.fileList);

  const thumbnailCustomRequest = (parameters: RcCustomRequestOptions, presignedUrl: string) => {
    Axios.put(presignedUrl, parameters.file, {
      onUploadProgress: progressEvent => {
        parameters.onProgress(
          {
            percent: Math.round((progressEvent.loaded * 100) / progressEvent.total),
          },
          parameters.file
        );
      },
    })
    .then(() => {
      // message.success(`Le fichier a été téléchargé avec succès.`);
      parameters.onSuccess({}, parameters.file);
      setUploadedFiles([]);
      setParams(null);
      setUrl('');
      if (singleFile) onUploadLogFile({
        name: parameters.file.name,
        file: `/files/${singleFile.id}`,
      });
    })
    .catch(err => {
      parameters.onError(err);
    });
  };

  useEffect(() => {
    setUrl(fileVersions.presignedURL);
  },        [fileVersions]);

  useEffect(() => {
    if (url && params) thumbnailCustomRequest(params, url);
  },        [url, params]);

  useEffect(() => {
    if (briefsPack.isCreated) {
      onModalClose();
      setFileBrief('');
    }
  },        [briefsPack.isCreated]);

  const retoucherAvatar = retoucher && retoucher.avatar ?
                          `${process.env.REACT_APP_SERVER_URL}/${ retoucher.avatar.path }` :
                          defaultAvatar;

  const checkerAvatar = checker && checker.avatar ?
                        `${process.env.REACT_APP_SERVER_URL}/${ checker.avatar.path }` :
                        defaultAvatar;

  const transfererAvatar = transferer && transferer.avatar ?
                          `${process.env.REACT_APP_SERVER_URL}/${ transferer.avatar.path }` :
                          defaultAvatar;

  const renderVersions = (versions: IFilesToDownload[]) => {
    return (
      <Menu className="list-log-versions">
        {
          versions.length ?
          versions.map((version, i) => (
            <Menu.Item key={ `${version.filename}-${i}` }>
              <a href={ version.url } download>
                { version.filename }
              </a>
            </Menu.Item>
          )) : (
            <Empty
              key="empty"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )
        }
      </Menu>
    );
  };

  /**
   * DOWNLOAD / UPLOAD
   */

  return (
    <Modal
      visible={isVisible}
      title={singleFile?.fileName}
      onCancel={onModalClose}
      closable={false}
      style={{ top: 20 }}
      width={570}
      className="file-modal"
      footer={[
        (
        <Button key="lcose" type="danger" onClick={onModalClose}>
          Fermer
        </Button>
        ),
        (
        <Button key="save" type="primary" onClick={handleSaveAndClose}>
          Enregistrer
        </Button>
        ),
      ]}>
      <img
        src={ filePreview.url_thumbnail }
        alt={`${ singleFile?.name } - Aperçu`}
        width="100"
        style={{ display: 'block', margin: 'auto' }}
      />
      <Divider />
      <Row gutter={8}>
        <Col span={8}>
          Statut:{' '}
          <Tag color="green" style={{ textTransform: 'uppercase' }}>
            {singleFile?.status}
          </Tag>
        </Col>
        <Col span={8}>
          Type de Retouche: <Icon type="desktop" style={{ fontSize: '16px' }} />
        </Col>
        <Col span={8}>
          <span className={`file-priority ${ singleFile?.filePriority ? 'priority-indicator' : '' }`}>
            <Icon type="clock-circle" />
            <span className="priority-indicator-text">Budget Prioritaire</span>
          </span>
        </Col>
      </Row>
      <Divider />
      <Row gutter={8}>
        <Col span={8}>
          <Row gutter={8}>
            <Col span={4}>
              <Icon type="clock-circle" />
            </Col>
            <Col span={20}>
              Début:
              <br />
             {!!singleFile?.startDate ?
              `${ moment(singleFile?.startDate).format('DD/MM/YYYY')} à ${moment(singleFile?.startDate).format('HH:mm')}`
              : '--'}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={8}>
            <Col span={4}>
              <Icon type="clock-circle" />
            </Col>
            <Col span={20}>
              Fin:
              <br />
              {
                !!singleFile?.dateTransfer ?
                `${moment(singleFile?.dateTransfer).format('DD/MM/YYYY')} à ${moment(singleFile?.dateTransfer).format('HH:mm')}`
                : '--'
              }
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <span>
            <Row gutter={8}>
              <Col span={4}>
                <Icon type="clock-circle" />
              </Col>
              <Col span={20}>
                Timing Total:
                <br />
                {singleFile?.totalTimes}
              </Col>
            </Row>
          </span>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16} justify="center">
        <Col span={8}>
          <Row gutter={8}>
            <Col span={18}>
              <span>
                <Icon type="team" />
                Retoucher par:
              </span>
            </Col>
            <Col span={6}>
              <Avatar
                shape="square"
                icon={ !retoucher && 'user' }
                src={ !!retoucher ? retoucherAvatar : '' }
              />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <span>
            <Icon type="clock-circle" /> Timing: { singleFile?.retoucherTotalTimes }
          </span>
        </Col>
        <Col span={10}>
          <Row gutter={[8, 16]} type="flex" justify="end">
            <Col span={5} style={{ textAlign: 'right' }}>
              {/* Pin */}
              <Button
                className={`file-action pin
                ${retoucher ? 'pinned' : ''}`}
                onClick={() => handleSelfAssignFile('retouch')}
                icon="pushpin"
                disabled={ !canRetouch }
              />
            </Col>
            {/* Play*/}
            <Col span={5} style={{ textAlign: 'right' }}>
              <Button
                className={`file-action play ${(retouchFileAction === 'playing') && disabledChecker ? retouchFileAction : ''}`}
                onClick={() => singleFile && onStatusButtonsClick({ status: 'start', id: singleFile.id, relativeBudget: '', action: 'Retouch' })}
                icon="caret-right"
                disabled={ !canRetouch }
              />
            </Col>
            {/* Stand By */}
            <Col span={5} style={{ textAlign: 'right' }}>
              <Button
                className={`file-action pause ${(retouchFileAction === 'paused') && disabledChecker ? retouchFileAction : ''}`}
                onClick={() => singleFile && onStatusButtonsClick({ status: 'standby', id: singleFile.id, relativeBudget: '', action: 'Retouch' })}
                icon="pause"
                disabled={ !canRetouch }
              />
            </Col>
            {/*Finish */}
            <Col span={5} style={{ textAlign: 'right' }}>
              <Button
                className={`file-action check ${!disabledChecker ? 'checked' : ''}`}
                onClick={() => singleFile && onStatusButtonsClick({ status: 'finish', id: singleFile.id, relativeBudget: '', action: 'Retouch' })}
                icon="check"
                disabled={ !canRetouch }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {
        !!retoucher && !!connectedUser && retoucher.id === connectedUser.id && (
          <>
            <Row gutter={[8, 24]} type="flex" justify="center">
              <Col span={12} className="align-end">
                <a href={ brutFile.fileURL } download>
                  <Button icon="download" loading={ brutFile.isLoading } block>
                    Télécharger le fichier brut
                  </Button>
                </a>
              </Col>
              <Col span={12} className="align-end">
                <Dropdown disabled={ !fileVersions.logs.length } overlay={ renderVersions(fileVersions.logs) } trigger={['click']}>
                  <Button type="ghost" block loading={ fileVersions.isLoading }>
                    {fileVersions.logs.length ? 'Fichiers retouchés' : 'Aucun fichier retouché'}
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
            <Row gutter={[8, 24]}>
              <Col span={24}>
                <Dragger
                  customRequest={handleUploadFile}
                  multiple={false}
                  fileList={uploadedFiles}
                  onChange={ handleUploadedFilesChange }
                >
                  <p className="ant-upload-text">Cliquez ou glisser le fichier pour télécharger</p>
                </Dragger>
              </Col>
            </Row>
          </>
        )
      }
      <Divider />
      <Row gutter={[8, 24]}>
        <Col span={8}>
          <Row gutter={8}>
            <Col span={18}>
              <span>
                <Icon type="file-done" /> Chécké par:{' '}
              </span>
            </Col>
            <Col span={6}>
              <Avatar
                shape="square"
                icon={ !checker && 'user' }
                src={ checker ? checkerAvatar : '' }
              />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <span>
            <Icon type="clock-circle" />{' '}Timing: { singleFile?.checkerTotalTimes }
          </span>
        </Col>
        <Col span={10}>
          <Row className="file-actions-wrapper" type="flex" justify="end">
            <Col>
              <Button
                className={`file-action pin ${ checker ? 'pinned' : '' }`}
                disabled={!canCheck}
                icon="pushpin"
                onClick={() => handleSelfAssignFile('check')}
              />
            </Col>
            <Col>
              <Button
                className={`file-action play ${(checkFileAction === 'playing') ? checkFileAction : ''}`}
                disabled={!canCheck}
                icon="caret-right"
                onClick={() => singleFile && onStatusButtonsClick({ status: 'start', id: singleFile.id, relativeBudget: '', action: 'Check' })}
              />
            </Col>
            <Col>
              <Button
                className={`file-action pause ${(checkFileAction === 'paused') ? checkFileAction : ''}`}
                disabled={!canCheck}
                icon="pause"
                onClick={() => singleFile && onStatusButtonsClick({ status: 'standby', id: singleFile.id, relativeBudget: '', action: 'Check' })}
              />
            </Col>
            <Col>
              <Button
                className={`file-action rewind ${ singleFile?.status === 'Réviser' ? 'rewined' : '' }`}
                disabled={!canCheck}
                icon="rollback"
                onClick={() => singleFile && onStatusButtonsClick({ status: 'undo', id: singleFile.id, relativeBudget: '', action: 'Check' })}
              />
            </Col>
            <Col>
              <Button
                className="file-action check"
                disabled={!canCheck}
                icon="check"
                onClick={() => singleFile && onStatusButtonsClick({ status: 'finish', id: singleFile.id, relativeBudget: '', action: 'Check' })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {
        !!checker && !!connectedUser && connectedUser.id === checker.id && (
          <Row gutter={[8, 24]} type="flex" justify="end">
            <Col span={12} className="align-end">
              <a href={ brutFile.fileURL } download>
                <Dropdown overlay={ renderVersions(fileVersions.logs) } trigger={['click']}>
                  <Button type="ghost" block loading={ fileVersions.isLoading }>
                    Fichiers retouchés
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </a>
            </Col>
          </Row>
        )
      }
      <Divider />
      <Row gutter={16}>
        <Col span={8}>
          <Row gutter={8}>
            <Col span={18}>
              <span>
                <Icon type="file-done" /> Transféré par:{' '}
              </span>
            </Col>
            <Col span={6}>
              <Avatar
                shape="square"
                icon={ !transferer && 'user' }
                src={ transferer ? transfererAvatar : '' }
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <span>
            {!!singleFile?.dateTransfer && `le ${ moment(singleFile?.dateTransfer).format('DD/MM/YY') } à ${ moment(singleFile?.dateTransfer).format('HH:mm') }`}
          </span>
        </Col>
        <Col span={4}>
          <Row type="flex" justify="end">
            <Col>
              <Button
                className={`file-action check ${ singleFile?.status === 'Transféré' ? 'checked' : '' }`}
                disabled={!canTransfer}
                icon="check"
                onClick={() => singleFile && onStatusButtonsClick({ status: 'transfert', id: singleFile.id, relativeBudget: '' })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {
        !!connectedUser && (connectedUser?.role.slug === roles.ROLE_ADMIN ||
        connectedUser?.role.slug === roles.ROLE_SUPER_ADMIN)  && (
          <Row gutter={[8, 24]} type="flex" justify="end">
            <Col span={12} className="align-end">
              <a href={ brutFile.fileURL } download>
                <Dropdown overlay={ renderVersions(fileVersions.logs) } trigger={['click']}>
                  <Button type="ghost" block loading={ fileVersions.isLoading }>
                    Fichiers retouchés
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </a>
            </Col>
          </Row>
        )
      }
      <Divider />
      <Briefs briefs={ briefList } isLoadingBriefs={isLoadingBriefs || briefsPack.isLoading} />
      <Form>
        <TextArea
          value={ fileBrief }
          placeholder="Informations complémentaires..."
          autoSize={{ minRows: 3, maxRows: 5 }}
          onChange={ handleTextareaChange }
        />
      </Form>
    </Modal>
  );
};