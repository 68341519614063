import { Dropdown, Icon, Menu, Tag } from 'antd';
import React from 'react';

import { getAlternativeStatus } from '../../../../../utils/status-transformer';

interface IFileStatusListProps {
  isDroppable: boolean;
  status: string;
  targetFile: string;
  onStatusChange: (status: string, filedId: string) => void;
}

interface IStatus {
  title: string;
  ref: string;
  color: string;
}

export const FileStatusList: React.FC<IFileStatusListProps> = ({ targetFile, isDroppable, status, onStatusChange }) => {
  const statusList: IStatus[] = [
    {
      title: 'en cours',
      ref: 'En cours',
      color: 'orange',
    },
    {
      title: 'a faire',
      ref: 'À faire',
      color: 'cyan',
    },
    {
      title: 'stand-by',
      ref: 'Stand by',
      color: 'purple',
    },
    {
      title: 'annulé',
      ref: 'Annulé',
      color: 'red',
    },
    {
      title: 'à venir',
      ref: 'À venir',
      color: 'blue',
    },
    {
      title: 'transféré',
      ref: 'Transféré',
      color: 'green',
    },
  ];

  const handleSelectedStatus = (value: string) => onStatusChange(value, targetFile);

  const menu = (
    <Menu>
      {statusList.map(stat => (
        <Menu.Item key={stat.ref}>
          <Tag style={{ cursor: 'pointer', textTransform: 'uppercase' }} color={stat.color} onClick={() => handleSelectedStatus(stat.ref)}>
            {stat.title}
          </Tag>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={isDroppable ? ['click'] : ['contextMenu']}>
      <span style={{ cursor: isDroppable ? 'pointer' : 'normal' }}>
        <Tag className={ getAlternativeStatus(status)?.en.toLocaleLowerCase().split(' ').join('-') } style={{ cursor: isDroppable ? 'pointer' : 'normal', textTransform: 'uppercase' }}>
          {status}
        </Tag>
        {
          isDroppable && <Icon style={{ fontSize: 10 }} type="caret-down" />
        }
      </span>
    </Dropdown>
  );
};
