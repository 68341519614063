import { Reducer } from 'redux';

import {
  ADD_LOG_FILE,
  ADD_LOG_FILE_ERROR,
  ADD_LOG_FILE_SUCCESS,
  DOWNLOAD_FILE,
  DOWNLOAD_FILES,
  DOWNLOAD_FILES_ERROR,
  DOWNLOAD_FILES_SUCCESS,
  DOWNLOAD_FILE_ERROR,
  DOWNLOAD_FILE_SUCCESS,
  FILE_ADD_NEW,
  FILE_ADD_NEW_ERROR,
  FILE_ADD_NEW_SUCCESS,
  FILE_DISPATCH_TEAM,
  FILE_DISPATCH_TEAM_ERROR,
  FILE_DISPATCH_TEAM_SUCCESS,
  FILE_UPDATE_PRIORITY,
  FILE_UPDATE_PRIORITY_ERROR,
  FILE_UPDATE_PRIORITY_SUCCESS,
  FILE_UPDATE_STATUS,
  FILE_UPDATE_STATUS_ERROR,
  FILE_UPDATE_STATUS_SUCCESS,
  GET_BUDGET_FILES,
  GET_BUDGET_FILES_ERROR,
  GET_BUDGET_FILES_SUCCESS,
  GET_FILES_TO_BE_CHECKED,
  GET_FILES_TO_BE_CHECKED_ERROR,
  GET_FILES_TO_BE_CHECKED_SUCCESS,
  GET_FILES_TO_BE_SENT,
  GET_FILES_TO_BE_SENT_ERROR,
  GET_FILES_TO_BE_SENT_SUCCESS,
  GET_FILE_PREVIEW,
  GET_FILE_PREVIEW_ERROR,
  GET_FILE_PREVIEW_SUCCESS,
  GET_FILE_RETOUCH_LOGS,
  GET_FILE_RETOUCH_LOGS_ERROR,
  GET_FILE_RETOUCH_LOGS_SUCCESS,
  GET_SINGLE_FILE,
  GET_SINGLE_FILE_ERROR,
  GET_SINGLE_FILE_SUCCESS,
  IFile,
  IUploadFileActionTypes,
  REQUEST_VERSION_PRESIGNED_URL,
  REQUEST_VERSION_PRESIGNED_URL_ERROR,
  REQUEST_VERSION_PRESIGNED_URL_SUCCESS,
  UPDATE_LIST_FILES,
  UPDATE_LIST_FILES_ERROR,
  UPDATE_LIST_FILES_SUCCESS,
  UPLOAD_FILE_REQUEST_URL,
  UPLOAD_FILE_REQUEST_URL_ERROR,
  UPLOAD_FILE_REQUEST_URL_SUCCESS,
} from './types';

const initialState: IFile = {
  upload: {
    preSigned: null,
    errors: null,
  },
  listFiles: undefined,
  filesCollection: [],
  filesToDwonload: null,
  isLoading: false,
  SingleFile: null,
  errors: null,
  filePreview: {
    url: '',
    url_thumbnail: '',
  },
  fileLogs: {
    logs: [],
    isLoading: false,
    errors: null,
    presignedURL: '',
  },
  brutFile: {
    fileURL: '',
    isLoading: false,
    errors: null,
  },
  filesToCheck: {
    files: [],
    total: 0,
    isLoading: false,
    errors: null,
  },
  filesToSend: {
    files: [],
    total: 0,
    isLoading: false,
    errors: null,
  },
};

export const uploadFileReducer: Reducer<typeof initialState, IUploadFileActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE_REQUEST_URL:
      return {
        ...state,
        isLoading: true,
        upload: {
          ...state.upload,
          errors: null,
        },
      };

    case UPLOAD_FILE_REQUEST_URL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        upload: {
          ...state.upload,
          preSigned: action.payload,
        },
      };

    case UPLOAD_FILE_REQUEST_URL_ERROR:
      return {
        ...state,
        isLoading: false,
        upload: {
          ...state.upload,
          errors: action.payload,
        },
      };

    case GET_BUDGET_FILES:
      return { ...state, isLoading: true };

    case GET_BUDGET_FILES_SUCCESS:
      return { ...state, isLoading: false, listFiles: action.payload };

    case GET_BUDGET_FILES_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case GET_SINGLE_FILE:
      return { ...state, isLoading: true };

    case GET_SINGLE_FILE_SUCCESS:
      return { ...state, isLoading: false, SingleFile: action.payload };

    case GET_SINGLE_FILE_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case FILE_ADD_NEW:
      return { ...state, isLoading: true, errors: null };

    case FILE_ADD_NEW_SUCCESS:
      return { ...state, isLoading: false };

    case FILE_ADD_NEW_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case DOWNLOAD_FILES:
      return { ...state, isLoading: true, errors: null };

    case DOWNLOAD_FILES_SUCCESS:
      return { ...state, isLoading: false, filesToDwonload: action.payload };

    case DOWNLOAD_FILES_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case FILE_UPDATE_STATUS:
      return { ...state, isLoading: true };

    case FILE_UPDATE_STATUS_SUCCESS:
      return { ...state, isLoading: false, errors: null };

    case FILE_UPDATE_STATUS_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case UPDATE_LIST_FILES:
      return { ...state, isLoading: true, errors: null };

    case UPDATE_LIST_FILES_SUCCESS:
      return { ...state, isLoading: false };

    case UPDATE_LIST_FILES_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case FILE_DISPATCH_TEAM:
      return { ...state, isLoading: true, errors: null };

    case FILE_DISPATCH_TEAM_SUCCESS:
      return { ...state, isLoading: false };

    case FILE_DISPATCH_TEAM_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case FILE_UPDATE_PRIORITY:
      return { ...state, isLoading: true, errors: null };

    case FILE_UPDATE_PRIORITY_SUCCESS:
      return { ...state, isLoading: false };

    case FILE_UPDATE_PRIORITY_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case GET_FILE_PREVIEW:
      return { ...state, isLoading: true, errors: null };

    case GET_FILE_PREVIEW_SUCCESS:
      return { ...state, isLoading: false, filePreview: action.payload };

    case GET_FILE_PREVIEW_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    case GET_FILE_RETOUCH_LOGS:
      return {
        ...state,
        fileLogs: {
          ...state.fileLogs,
          isLoading: true,
          errors: null,
        },
      };

    case GET_FILE_RETOUCH_LOGS_SUCCESS:
      return {
        ...state,
        fileLogs: {
          ...state.fileLogs,
          isLoading: false,
          logs: action.payload,
        },
      };

    case GET_FILE_RETOUCH_LOGS_ERROR:
      return {
        ...state,
        fileLogs: {
          ...state.fileLogs,
          isLoading: false,
          errors: action.payload,
        },
      };

    case DOWNLOAD_FILE:
      return {
        ...state,
        brutFile: {
          ...state.brutFile,
          isLoading: true,
          errors: null,
        },
      };

    case DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        brutFile: {
          ...state.brutFile,
          isLoading: false,
          fileURL: action.payload,
        },
      };

    case DOWNLOAD_FILE_ERROR:
      return {
        ...state,
        brutFile: {
          ...state.brutFile,
          isLoading: false,
          errors: action.payload,
        },
      };

    case REQUEST_VERSION_PRESIGNED_URL:
      return {
        ...state,
        fileLogs: {
          ...state.fileLogs,
          isLoading: true,
          errors: null,
        },
      };

    case REQUEST_VERSION_PRESIGNED_URL_SUCCESS:
      return {
        ...state,
        fileLogs: {
          ...state.fileLogs,
          isLoading: false,
          presignedURL: action.payload,
        },
      };

    case REQUEST_VERSION_PRESIGNED_URL_ERROR:
      return {
        ...state,
        fileLogs: {
          ...state.fileLogs,
          isLoading: false,
          errors: action.payload,
        },
      };

    case ADD_LOG_FILE:
      return {
        ...state,
        fileLogs: {
          ...state.fileLogs,
          isLoading: true,
          errors: null,
        },
      };

    case ADD_LOG_FILE_SUCCESS:
      return {
        ...state,
        fileLogs: {
          ...state.fileLogs,
          isLoading: false,
        },
      };

    case ADD_LOG_FILE_ERROR:
      return {
        ...state,
        fileLogs: {
          ...state.fileLogs,
          isLoading: false,
          errors: action.payload,
        },
      };

      case GET_FILES_TO_BE_CHECKED:
        return {
          ...state,
          filesToCheck: {
            ...state.filesToCheck,
            isLoading: true,
            errors: null,
          },
        };

      case GET_FILES_TO_BE_CHECKED_SUCCESS:
        return {
          ...state,
          filesToCheck: {
            ...state.filesToCheck,
            isLoading: false,
            files: action.payload.files,
            total: action.payload.total,
          },
        };

      case GET_FILES_TO_BE_CHECKED_ERROR:
        return {
          ...state,
          filesToCheck: {
            ...state.filesToCheck,
            isLoading: false,
            errors: action.payload,
          },
        };

      case GET_FILES_TO_BE_SENT:
        return {
          ...state,
          filesToSend: {
            ...state.filesToSend,
            isLoading: true,
            errors: null,
          },
        };

      case GET_FILES_TO_BE_SENT_SUCCESS:
        return {
          ...state,
          filesToSend: {
            ...state.filesToSend,
            isLoading: false,
            files: action.payload.files,
            total: action.payload.total,
          },
        };

      case GET_FILES_TO_BE_SENT_ERROR:
        return {
          ...state,
          filesToSend: {
            ...state.filesToSend,
            isLoading: false,
            errors: action.payload,
          },
        };

    default:
      return state;
  }
};
