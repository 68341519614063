import { Button, Checkbox, Col, DatePicker, Form, Icon, Input, Layout, Row, Select, Spin } from 'antd';
import { FormComponentProps } from 'antd/es/form';
// tslint:disable-next-line: no-implicit-dependencies
import moment from 'moment';
import React, { FormEvent, useEffect, useState } from 'react';

import { Briefs } from '../../../../../components/Briefs';
import { FormElementPlaceholder } from '../../../../../components/FormElementPlaceholder';
import TeamSelect from '../../../../../components/TeamSelect';
import { IUser } from '../../../../../store/users/types';
import { generateGroup } from '../../../../../utils/map-users-by-role';
import * as roles from '../../../../../utils/roles-utils';
import { IBrief, IBudget, IUpdateBudget } from '../../../../../utils/types';

// tslint:disable-next-line: ordered-imports

const { Option } = Select;
const { TextArea } = Input;
const { Header } = Layout;

interface IUpdateFormProps extends FormComponentProps {
  connectedUserName: string;
  budget: IBudget;
  users: IUser[];
  briefs: IBrief[];
  isProjectLoading: boolean;
  isLoadingBriefs: boolean;
  isBriefCreated: boolean;
  onSubmitUpdateForm: (budget: IUpdateBudget) => void;
}

const UpdateFormInner: React.FC<IUpdateFormProps> = ({
  budget,
  connectedUserName,
  users,
  briefs,
  form,
  isProjectLoading,
  isLoadingBriefs,
  isBriefCreated,
  onSubmitUpdateForm,
}) => {

  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [deadline, setDeadline] = useState<moment.Moment | null>(null);

  const { getFieldDecorator, resetFields } = form;

  const disableStartDate = (current: moment.Moment | null) => {
    // Can not select days before today and today
    if (!deadline || !current) return false;
    return deadline < current;
  };

  const handleStartDate = (date: moment.Moment | null, _dateString: string) => {
    setStartDate(date);
  };

  const handleDeadline = (date: moment.Moment | null, _dateString: string) => {
    setDeadline(date);
  };

  const disableDeadline = (current: moment.Moment | null) => {
    // Can not select days before today and today
    if (!startDate || !current) return false;
    return startDate > current;
  };

  const handleSubmitUpdatedBudget = (e: FormEvent) => {
    e.preventDefault();
    form.validateFields((err: any, values: any) => {
      if (!err) onSubmitUpdateForm({...values, id: budget.id, name: budget.name});
    });
  };

  useEffect(() => {
    if (isBriefCreated) resetFields(['additionalInfo']);
  },        [isBriefCreated, resetFields]);

  return (
    <>
      <Header>
        <Row>
          <Col span={12}>
            <span className="header-title">Modifier le budget { budget.name }</span>
          </Col>
          <Col span={12}>
            <span className="created-by">
              Ajouter par <strong>{connectedUserName} <Icon type="clock-circle" /></strong>
            </span>
          </Col>
        </Row>
      </Header>

      <Form className="inner-padding" onSubmit={handleSubmitUpdatedBudget}>
        <Row>
          <Col>
            <Form.Item>
              Champs obligatoires <span className="required-field">*</span>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator('status', {
                rules: [
                  {
                    required: true,
                    message: 'Veuillez selectionner le status du budget !',
                  },
                ],
                validateTrigger: ['onBlur', 'onChange'],
                initialValue: budget.status,
              })(
                <Select placeholder={ <FormElementPlaceholder text="-- Statut --" /> }>
                  <Option value="À venir">À Venir</Option>
                  <Option value="À faire">À Faire</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator('retouchType', {
                rules: [
                  {
                    required: true,
                    message: 'Veuillez selectionner un Type !',
                  },
                ],
                validateTrigger: ['onBlur', 'onChange'],
                initialValue: budget.retouchType,
              })(
                <Select placeholder={ <FormElementPlaceholder text="-- Type RT --" /> }>
                  <Option value="Campaign">Campagne</Option>
                  <Option value="Digital">Digital</Option>
                  <Option value="Edito">Edito</Option>
                  <Option value="Gif">Gif</Option>
                  <Option value="Lookbook">Lookbook</Option>
                  <Option value="Video">Video</Option>
                  <Option value="Web">Web</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Item>
              {
                getFieldDecorator('projectPriority', {
                  initialValue: budget.projectPriority,
                })(
                  <div className="budget-priority priority-indicator">
                    <Checkbox defaultChecked={budget.projectPriority}>
                      <Icon type="clock-circle" />
                      <span className="priority-indicator-text">Budget Prioritaire</span>
                    </Checkbox>
                  </div>
                )
              }
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Row gutter={16}>
              <Col span={24}>
                <Icon type="clock-circle" /> <span style={{ marginLeft: '7px' }}>
                  <FormElementPlaceholder text="Date de début" />
                </span>
              </Col>
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator('startDate', {
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Veuillez selectionner une date !',
                      },
                    ],
                    initialValue: moment(budget.startDate),
                  })(
                    <DatePicker
                      showTime
                      placeholder="Selectionner la date de debut"
                      style={{ width: '100%' }}
                      onChange={handleStartDate}
                      disabledDate={disableStartDate}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xl={12} md={24}>
            <Row gutter={16}>
              <Col span={24}>
                <Icon type="clock-circle" /> <span style={{ marginLeft: '7px' }}>
                  <FormElementPlaceholder text="Deadline" />
                </span>
              </Col>
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator('deadline', {
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Veuillez selectionner une date !',
                      },
                    ],
                    initialValue: moment(budget.deadline),
                  })(
                    <DatePicker
                      showTime
                      placeholder="Selectionner le deadline"
                      style={{ width: '100%' }}
                      onChange={handleDeadline}
                      disabledDate={disableDeadline}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <Icon type="team" />
            <span style={{ marginLeft: '10px' }}>&#xC9;quipe dédiée:</span>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator('productionManager', {
                rules: [
                  {
                    required: true,
                    message: 'Veuillez selectionner un résponsable budget !',
                  },
                ],
                initialValue: budget.productionManager || undefined,
              })(
                <TeamSelect
                  users={generateGroup(users, roles.ROLE_PRODUCTION_MANAGER)}
                  placeholder={ <FormElementPlaceholder text="Selectionner un résponsable budget" /> }
                  size="large"
                  allowClear
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator('projectManager', {
                rules: [
                  {
                    required: true,
                    message: 'Veuillez selectionner un chef de projet !',
                  },
                ],
                initialValue: budget.projectManager || undefined,
              })(
                <TeamSelect
                  users={generateGroup(users, roles.ROLE_PROJECT_MANAGER)}
                  placeholder={ <FormElementPlaceholder text="Selectionner un chef de projet" /> }
                  size="large"
                  allowClear
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('retoucher', {
                rules: [
                  {
                    required: true,
                    message: 'Veuillez selectionner des retoucheurs !',
                  },
                ],
                initialValue: budget.retoucher || undefined,
              })(
                <TeamSelect
                  users={generateGroup(users, roles.ROLE_RETOUCHER)}
                  placeholder={ <FormElementPlaceholder text="Selectionner des retoucheurs" /> }
                  mode="multiple"
                  size="large"
                  allowClear
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={24} md={24}>
            <Form.Item>
              <Briefs briefs={briefs} isLoadingBriefs={isLoadingBriefs} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={24} md={24}>
            <Form.Item>{getFieldDecorator('additionalInfo')(<TextArea placeholder="Informations complémentaires..." />)}</Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button loading={isProjectLoading} key="submit" className="btn-success" onClick={handleSubmitUpdatedBudget}>
              Modifier Budget
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const UpdateForm = Form.create<IUpdateFormProps>()(UpdateFormInner);
