export const generateStatusList = (list: string[] | undefined) => {
  if (!list || !list.length) return;
  let request: string = '';
  list.forEach(status => {
    request = `${ request }status[]=${ status }&`;
  });
  return request.substring(0, request.length - 1);
};

export const pathnameToStatus = (pathname: string) => {
  const orgPathname = ['in-progress', 'to-come-up', 'closed', 'archived'];
  const statusList  = ['En cours', 'À venir', 'Confirmé', 'Archivé'];
  const splittedPathname = pathname.split('/');
  const lastFragment = splittedPathname[splittedPathname.length - 1];
  if (orgPathname.includes(lastFragment)) {
    return statusList[orgPathname.indexOf(lastFragment)];
  }
  return null;
};
