import { Button, Col, Icon, Layout, Modal, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CustomAvatar } from '../../../../../components/CustomAvatar';
import { PermissionsHandler } from '../../../../../components/permissions-handlers/PermissionsHandler';
import { IConnectedUserData } from '../../../../../store/connectedUser/types';
import { IUser } from '../../../../../store/users/types';
import * as roles from '../../../../../utils/roles-utils';
import { IPermissionsHandler } from '../../../../../utils/types';
import { siderWidth } from '../../../../../utils/vars';

const { Content, Header, Sider } = Layout;

interface IProfileDetailsProps {
  user: IUser;
  connectedUser: IConnectedUserData;
  permissions: IPermissionsHandler | null;
  isDisactivated: boolean;
  onUserDesactivated: () => void;
}

export const ProfileDetails: React.FC<IProfileDetailsProps> = ({ user, connectedUser, permissions, onUserDesactivated, isDisactivated }) => {

  const [isVisibleModal, setModalVisible] = useState(false);

  useEffect(() => {
    if (isDisactivated) setModalVisible(false);
  },        [isDisactivated]);

  const handleUserDisableClick = () => setModalVisible(true);

  // This (ce) or My (mon)
  const profileOwnerText = connectedUser?.id !== user?.id &&
  (connectedUser?.role.slug === roles.ROLE_SUPER_ADMIN ||
  connectedUser?.role.slug === roles.ROLE_ADMIN) ? 'ce' : 'mon';

  const toggleActivate = user?.enabled ? 'Désactiver' : 'Activer';
  const toggleActivateModalText = user?.enabled ? 'désactiver' : 'activer';

  const handleDesactivateUserConfirm = () => onUserDesactivated();

  const handleCloseModal = () => setModalVisible(false);

  return (
    <>
      <Layout>
        <Header>
          <Row gutter={8}>
            <Col span={12}>
            <h2 className="header-title">{ user.id === connectedUser.id ? 'Mon Profil' : `${user.firstName} ${user.lastName}` }</h2>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end" align="middle">
                <Col className="align-end" xl={2} md={4}>
                  <Tooltip title="Gérer les profils">
                    <Link to={`/team`}>
                      <Button className="profile-btn cards" type="ghost" icon="appstore" size="default" />
                    </Link>
                  </Tooltip>
                </Col>
                {
                  user?.enabled && (
                    <PermissionsHandler
                      blocPermission="CAN_PUT"
                      resourceName="User"
                      userPermissions={permissions}
                      owner={ user?.id }
                      ownershipTarget={ connectedUser?.id }
                    >
                      <Col className="align-end" xl={2} md={4}>
                        <Tooltip title={`Modifier ${ profileOwnerText } profil`}>
                          <Link to={`/update-profile/${user?.id}`}>
                            <Button className="profile-btn edit" type="primary" icon="edit" size="default" />
                          </Link>
                        </Tooltip>
                      </Col>
                    </PermissionsHandler>
                  )
                }
                {
                  user?.role.slug !== roles.ROLE_SUPER_ADMIN && (
                    <PermissionsHandler
                      blocPermission="CAN_DESACTIVATE"
                      resourceName="User"
                      userPermissions={ permissions }
                    >
                      <Col className="align-end" xl={2} md={4}>
                        <Tooltip placement="topRight" title={`${ toggleActivate } ${ profileOwnerText } profil`}>
                          <Button
                            className={`profile-btn ${user.enabled ? 'disable-btn' : 'enable-btn'}`}
                            onClick={handleUserDisableClick}
                            icon={user.enabled ? 'stop' : 'check'}
                            size="default"
                          />
                        </Tooltip>
                      </Col>
                    </PermissionsHandler>
                  )
                }
              </Row>
            </Col>
          </Row>
        </Header>
        <Layout>
          <Sider className="center-content no-border" width={ siderWidth }>
            <CustomAvatar
              size={150}
              src={ user?.avatar?.path}
              styleClassName="profile-avatar"
              type="user"
            />
            {
              user?.enabled && (
                <PermissionsHandler
                  blocPermission="CAN_PUT"
                  resourceName="User"
                  userPermissions={permissions}
                  owner={ user?.id }
                  ownershipTarget={ connectedUser?.id }
                >
                  <Link to={`/update-profile/${user?.id}`}>
                    <Button className="btn-success" shape="round">
                      <strong>Modifier { profileOwnerText } profil</strong>
                    </Button>
                  </Link>
                </PermissionsHandler>
              )
            }
          </Sider>

          <Content className="profile-details">
            <Row gutter={[8, 16]}>
              <Col>
                <span className="username">{`${user?.firstName} ${user?.lastName}`}</span>
                <span className="occupation">{user?.role.title}</span>
              </Col>

              <Col>
                <Row gutter={8}>
                  <Col xl={24}>
                    <span className="details-indicator"><Icon type="home" />Adresse: </span>
                    <span className="details-value">{user?.address}</span>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row gutter={8}>
                  <Col xl={24}>
                    <span className="details-indicator"><Icon type="mail" />Adresse Email: </span>
                    <span className="details-value">{user?.email}</span>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row gutter={8}>
                  <Col xl={24}>
                    <span className="details-indicator"><Icon type="phone" />Téléphone: </span>
                    <span className="details-value">{user?.phone}</span>
                  </Col>
                </Row>
              </Col>

              {
                !!user?.birthday
                &&
                (
                <Col>
                  <Row gutter={8}>
                    <Col xl={24}>
                      <span className="details-indicator"><Icon type="gift" />Anniversaire: </span>
                      <span className="details-value">{moment(user.birthday).format('DD/MM/YYYY')}</span>
                    </Col>
                  </Row>
                </Col>
                )
              }

              <Col>
                <Row gutter={8}>
                  <Col xl={23}>
                    <span className="details-indicator"><Icon type="lock" />Niveau d'accès: </span>
                    <span className="details-value">{user?.role.title}</span>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Modal
              title={`Confirmation d${ user?.enabled ? 'e désactivation' : '\'activation' }`}
              className="confirmation-modal"
              centered
              visible={isVisibleModal}
              onOk={handleDesactivateUserConfirm}
              onCancel={handleCloseModal}
              footer={[
                (
                  <Button key="Annuler" type="primary" onClick={ handleCloseModal }>
                    Annuler
                  </Button>
                ),
                (
                  <Button key="Désactiver" type="danger" onClick={ handleDesactivateUserConfirm }>
                    { toggleActivate }
                  </Button>
                ),
              ]}>
              <p>Voulez vous vraiment { toggleActivateModalText } {`${user?.firstName} ${user?.lastName}`} ?</p>
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};