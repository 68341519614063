import { Store } from 'redux';

import { GlobalState } from '..';
import { isValidToken } from '../../utils/jwt-utils';

import { authSetLoggedIn } from './actions';

export const manageToken = (store: Store<GlobalState>) => () => {

  const { token, isLoggedIn } = store.getState().auth;
  const { mercureToken } = store.getState().auth;

  const tokenIsValid = isValidToken(token);
  if (tokenIsValid !== isLoggedIn) {
    token ? localStorage.setItem('token', token) : localStorage.removeItem('token');
    store.dispatch(authSetLoggedIn(tokenIsValid));
  }
  if (mercureToken) localStorage.setItem('mercureToken', mercureToken);
};
