import { Button, Col, DatePicker, Form, Icon, Input, Layout, Row, Select, Upload } from 'antd';
import { FormComponentProps } from 'antd/es/form';
// tslint:disable-next-line: no-implicit-dependencies
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../../../store';
import { IConnectedUserData } from '../../../../../store/connectedUser/types';
import { IUser } from '../../../../../store/users/types';
import countries from '../../../../../utils/countries/index.json';
import { mergeErrors } from '../../../../../utils/form-errors-utils';
import * as roles from '../../../../../utils/roles-utils';
import { IErrors, IRole, IUpdateUser } from '../../../../../utils/types';

const { Option } = Select;
const { Header } = Layout;

interface ICountries {
  name: string;
  code: string;
}

interface IUpdateProfileFormProps extends FormComponentProps {
  onUpdateProfileClick: (updatedData: IUpdateUser) => void;
  userData: IUser | null;
  connectedUser?: IConnectedUserData;
  listRoles: IRole[];
  isLoading: boolean;
  isLoadingUpdates: boolean;
  errors: IErrors | null;
}

const UpdateProfileFormValidation: React.FC<IUpdateProfileFormProps> = ({
  userData,
  onUpdateProfileClick,
  listRoles,
  connectedUser,
  form,
  isLoading,
  isLoadingUpdates,
  errors,
}) => {

  const token = useSelector((state: GlobalState) => state.auth.token);
  const { getFieldDecorator, getFieldsValue, setFields, validateFields, setFieldsValue, resetFields } = form;

  // tslint:disable-next-line: no-any
  const loadCountriesData: any = () => JSON.parse(JSON.stringify(countries));

  const [fieldsValues, setFieldsValues] = useState();
  const countriesList = loadCountriesData();

  // Handle avatar change
  const avatarProps = {
    name: 'file',
    action: `${ process.env.REACT_APP_SERVER_URL }/avatars`,
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  // tslint:disable-next-line: no-any
  const func = (e: any) => {
    return e && e.fileList.slice(-1);
  };

  const handleFormSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    // tslint:disable-next-line: no-any
    validateFields((err: any, values: any) => {
      if (!err && connectedUser) {
        onUpdateProfileClick({
          ...values,
          id: userData?.id,
          role: `/roles/${userData?.role.id}`,
          birthday: values.birthday && (values.birthday as moment.Moment).format('YYYY-MM-DD'),
          avatar: values.avatar && values.avatar[0].response['@id'],
          connectedUser: connectedUser.id,
        });
        resetFields(['avatar']);
      }
    });
    setFieldsValues(getFieldsValue());
  };

  useEffect(() => {
    setFieldsValue({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [userData]);

  useEffect(() => {
      const fields = mergeErrors(fieldsValues, errors);
      if (fields) setFields(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },      [errors, fieldsValues]);

  return (
    <>
      <Header>
        <h2>
          {
            isLoading
            ?
            <span>Chargement...</span>
            :
            <span>Modifier le profil de {userData && userData.firstName} {userData && userData.lastName}</span>
          }
        </h2>
      </Header>

      <Form className="inner-form-padding">
        <h4 className="header-title">Informations personnelles</h4>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Form.Item
              { ...(errors?.firstName && {
                help: errors.firstName,
                validateStatus: 'error',
              }) }
            >
              {getFieldDecorator('firstName', {
                rules: [{ required: true, message: 'Veuillez entrer le prénom !' }],
                initialValue: userData && userData.firstName,
                validateTrigger: 'onBlur',
              })(<Input type="text" placeholder="Prénom" />)}
            </Form.Item>
          </Col>
          <Col xl={12} md={24}>
            <Form.Item
              { ...(errors?.lastName && {
                help: errors.lastName,
                validateStatus: 'error',
              }) }
            >
              {getFieldDecorator('lastName', {
                rules: [{ required: true, message: 'Veuillez entrer le nom !' }],
                initialValue: userData && userData.lastName,
                validateTrigger: 'onBlur',
              })(<Input type="text" placeholder="Nom de Famille" />)}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={8} md={24}>
            <Form.Item
              { ...(errors?.gender && {
                help: errors.gender,
                validateStatus: 'error',
              }) }
            >
              {getFieldDecorator('gender', {
                rules: [{ required: true, message: 'Veuillez selectionner le gendre !' }],
                initialValue: userData && userData.gender,
                validateTrigger: 'onBlur',
              })(
                <Select>
                  <Option value="Female">Femme</Option>
                  <Option value="Male">Homme</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xl={8} md={24}>
            <Form.Item
              { ...(errors?.birthday && {
                help: errors.birthday,
                validateStatus: 'error',
              }) }
            >
              {getFieldDecorator('birthday', {
                initialValue: userData && userData.birthday && moment(userData.birthday),
              })(<DatePicker placeholder="Date de naissance" style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>
          <Col xl={8} md={24}>
            <Form.Item>
              {getFieldDecorator('birthdayAlert')(
                <Select placeholder="-- Séléctionner une option --">
                  <Option value="1">Oui</Option>
                  <Option value="0">Non</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator(
                'avatar', {
                  validateTrigger: 'onChange',
                  valuePropName: 'fileList',
                  getValueFromEvent: func,
                })(
                <Upload {...avatarProps} style={{ width: '100%' }}>
                  <Input
                    addonBefore={<Icon type="camera" />}
                    type="button"
                    value="Uploader une photo de profil"
                    addonAfter={<Icon type="search" />}
                  />
                  <Input type="hidden" />
                </Upload>
              )}
            </Form.Item>
          </Col>
          <Col xl={12} md={24}>
            <Form.Item
              { ...(errors?.phone && {
                help: errors.phone,
                validateStatus: 'error',
              }) }
            >
              {getFieldDecorator('phone', {
                rules: [{ required: true, message: 'Veuillez entrer un numéro de téléphone !' }],
                initialValue: userData && userData.phone,
                validateTrigger: 'onBlur',
              })(<Input addonBefore={<Icon type="phone" />} type="phone" placeholder="Numéro de Téléphone" />)}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator('phoneExtra', {
                initialValue: userData && userData.phoneExtra,
                validateTrigger: 'onBlur',
              })(<Input addonBefore={<Icon type="phone" />} type="phone" placeholder="Numéro à appeler en cas d'urgence !" />)}
            </Form.Item>
          </Col>
          <Col xl={12} md={24}>
            <Form.Item
              { ...(errors?.address && {
                help: errors.address,
                validateStatus: 'error',
              }) }
            >
              {getFieldDecorator('address', {
                rules: [{ required: true, message: 'Veuillez entrer une adresse !' }],
                initialValue: userData && userData.address,
                validateTrigger: 'onBlur',
              })(<Input type="text" placeholder="Adresse" />)}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={8} md={24}>
            <Form.Item
              { ...(errors?.zipCode && {
                help: errors.zipCode,
                validateStatus: 'error',
              }) }
            >
              {getFieldDecorator('zipCode', {
                rules: [{ required: true, message: 'Veuillez entrer un code postal !' }],
                initialValue: userData && userData.zipCode,
                validateTrigger: 'onBlur',
              })(<Input addonBefore="Code Postal" type="text" placeholder="Code Postal" />)}
            </Form.Item>
          </Col>
          <Col xl={8} md={24}>
            <Form.Item
              { ...(errors?.city && {
                help: errors.city,
                validateStatus: 'error',
              }) }
            >
              {getFieldDecorator('city', {
                rules: [{ required: true, message: 'Veuillez entrer une ville !' }],
                initialValue: userData && userData.city,
                validateTrigger: 'onBlur',
              })(<Input addonBefore="Ville" type="text" placeholder="Ville" />)}
            </Form.Item>
          </Col>
          <Col xl={8} md={24}>
            <Form.Item
              { ...(errors?.country && {
                help: errors.country,
                validateStatus: 'error',
              }) }
            >
              {getFieldDecorator('country', {
                rules: [{ required: true, message: 'Veuillez entrer une ville !' }],
                initialValue: userData && userData.country,
                validateTrigger: 'onBlur',
              })(
                <Select>
                  {countriesList.list.map((c: ICountries, i: number) => (
                    <Option key={i} value={c.name}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        {
          (connectedUser?.role.slug === roles.ROLE_ADMIN ||
          connectedUser?.role.slug === roles.ROLE_SUPER_ADMIN)
          && (
            <>
              <h4 className="form-text-indicator">Status & Niveau d'accès</h4>
              <Row gutter={16}>
                <Col xl={12} md={24}>
                  <Form.Item>
                    {getFieldDecorator('role', {
                      initialValue: userData?.role.title,
                      validateTrigger: 'onBlur',
                    })(
                      <Select>
                        {listRoles.map(role => {
                          return (
                            <Option key={role.id} value={`/roles/${role.id}`}>
                              {role.title}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )
        }

        <Row gutter={16}>
          <Col>
            <Button loading={ isLoadingUpdates } className="btn-success" onClick={handleFormSubmit}>
              Sauvegarder
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const UpdateProfileForm = Form.create<IUpdateProfileFormProps>()(UpdateProfileFormValidation);
