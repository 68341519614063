import { Col, List, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { IConnectedUserData } from '../../store/connectedUser/types';
import { renderPaginationItems } from '../../utils/table-pagination-item-render';
import { IClient } from '../../utils/types';
import { CustomAvatar } from '../CustomAvatar';

import './clients-sider.less';

const { Item } = List;

interface ISiderClientsListProps {
  listPeople: IClient[];
  userData?: IConnectedUserData;
  selectedUser?: string;
  onResetCurrentUser?: () => void;
}

export const SiderClientsList: React.FC<ISiderClientsListProps> = ({
  listPeople,
  selectedUser,
}) => {

  const [clients, setClients] = useState<IClient[]>([]);

  useEffect(() => {
    setClients(listPeople.filter(({ enabled }) => enabled));
  },        [listPeople]);

  const renderItem = (client: IClient) => {
    return (
      <List.Item
        key={client.id}
        className={`list-user-item ${ selectedUser && selectedUser === client.id ? 'list-client-item-active' : '' }`}
      >
        <Link to={`/clients/${ client.id }`}>
          <Item.Meta
            avatar={<CustomAvatar shape="square" type="client" src={client.logo} />}
            title={ client.companyName }
          />
        </Link>
      </List.Item>
    );
  };

  return (
    <div className="clients-sider small-non-pages-paginator">
      <h3 className="orange-title">Clients</h3>
      <List
        pagination={{
          pageSize: 10,
          size: 'small',
          hideOnSinglePage: true,
          itemRender: (page, type, original) => renderPaginationItems(page, type, original),
        }}
        dataSource={clients}
        renderItem={renderItem}
        footer={(
          <Row type="flex" justify="end">
            <Col>{clients.length} client{ clients.length > 1 ? 's' : '' }</Col>
          </Row>
        )}
      />
    </div>
  );
};
