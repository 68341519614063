import { Reducer } from 'redux';

import { isValidToken } from '../../utils/jwt-utils';

import {
  AuthActionType, AUTH_LOGIN,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_SET_LOGGED_IN,
  IAuth,
  REQUEST_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD_ERROR,
  REQUEST_RESET_PASSWORD_SUCCESS,
  RESET_ERRORS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_REQUESTED_NEW_PASSWORD,
} from './types';

const token = localStorage.getItem('token');
const tokenIsValid = isValidToken(token);

const mercureToken = localStorage.getItem('mercureToken');
const mercureTokenIsValid = isValidToken(mercureToken);

const initialState: IAuth = {
  token: tokenIsValid ? token : null,
  mercureToken: mercureTokenIsValid ? mercureToken : null,
  isLoggedIn: tokenIsValid,
  isLoading: false,
  loginErrors: null,
  resetPasswordErrors: null,
  requestResetPassword: null,
  hasRequestedNewPassword: false,
  isReset: false,
};

export const authReducer: Reducer<typeof initialState, AuthActionType> = (state = initialState, action) => {
  switch (action.type) {

    case RESET_ERRORS:
      return { ...state, requestResetPassword: null };

    case AUTH_LOGIN:
      return { ...state, isLoading: true, loginErrors: null };

    case AUTH_LOGIN_SUCCESS:
      return { ...state, isLoading: false, token: action.payload.token, mercureToken: action.payload.mercureToken };

    case AUTH_LOGIN_ERROR:
      return { ...state, isLoading: false, loginErrors: action.payload };

    case AUTH_SET_LOGGED_IN:
      return { ...state, isLoggedIn: action.payload, loginErrors: null };

    case AUTH_LOGOUT_SUCCESS:
      return { ...state, token: null };

    case REQUEST_RESET_PASSWORD:
      return { ...state, isLoading: true };

    case REQUEST_RESET_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, hasRequestedNewPassword: true };

    case REQUEST_RESET_PASSWORD_ERROR:
      return { ...state, isLoading: false, requestResetPassword: action.payload };

    case RESET_PASSWORD:
      return { ...state, isLoading: true };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, isReset: true };

    case RESET_PASSWORD_ERROR:
      return { ...state, isLoading: false, resetPasswordErrors: action.payload };

    case RESET_REQUESTED_NEW_PASSWORD:
      return { ...state, hasRequestedNewPassword: false };

    default:
      return state;
  }
};
