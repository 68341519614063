import { Alert, Button, Form, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import React, { FormEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { IErrors } from '../../../../../utils/types';

interface IRequestResetPasswordProps extends FormComponentProps {
  isLoading?: boolean;
  errors: IErrors | null;
  onSendEmailClick: (email: string) => void;
}

const RequestResetPasswordInner: React.FC<IRequestResetPasswordProps> = ({ onSendEmailClick, isLoading, errors, form }) => {
  const { getFieldDecorator } = form;

  const handleFormSubmitEvent = (e: FormEvent) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) onSendEmailClick(values.email);
    });
  };

  return (
    <Form onSubmit={handleFormSubmitEvent}>
      <Form.Item>
        {getFieldDecorator('email', {
          rules: [
            { type: 'email', message: 'votre email n\'est pas valide !' },
            { required: true, message: 'Veuillez entrer votre email !' },
          ],
          validateTrigger: 'onBlur',
        })(<Input type="email" placeholder="Entrer votre email" />)}
      </Form.Item>

      <Button className="margin-bottom" loading={ isLoading && true } type="primary" shape="round" block htmlType="submit">
        <FormattedMessage id="auth.forgotpw.sendBtn" />
      </Button>
      {
        !!errors && !!errors.general.length && errors.general.map(error => <Alert key={error} message={error} type="error" showIcon />)
      }
    </Form>
  );
};

export const RequestResetPassword = Form.create<IRequestResetPasswordProps>()(RequestResetPasswordInner);
