import { Avatar, Col, List, Row } from 'antd';
import React from 'react';

import { INotification } from '../../../../../../utils/types';

interface INotificationItemProps {
  notif: INotification;
}

export const NotificationItem: React.FC<INotificationItemProps> = ({ notif }) => {

  return (
    <List.Item.Meta
      className={`notif ${ notif.class }`}
      avatar={<Avatar size={40} shape="square" icon={notif.icon} />}
      description={(
        <Row type="flex" align="middle" gutter={8}>
          <Col span={16}>
            <Row>
              <Col>
                <span className="category">{ notif.category }</span>
              </Col>
              <Col>
                <span className="notif-content">
                  { notif.content.substring(0, 15) }...
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row type="flex" justify="end">
              <Col span={ 24 }>
                <span className="item-date">{ notif.date }</span>
              </Col>
              <Col span={ 24 }>
                <span className="item-time">{ notif.time }</span>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    />
  );
};
