import { IErrors } from '../../utils/types';

import {
  DIRECTORY_GET_ALL,
  DIRECTORY_GET_ALL_ERROR,
  DIRECTORY_GET_ALL_SUCCESS,
  IGetAllDirectoriesAction,
  IGetAllDirectoriesErrorAction,
  IGetAllDirectoriesSuccessAction,
} from './types';

export const getDirectories = (): IGetAllDirectoriesAction  => ({
  type: DIRECTORY_GET_ALL,
});

export const getDirectoriesSuccess = (directories: string[]): IGetAllDirectoriesSuccessAction => ({
  type: DIRECTORY_GET_ALL_SUCCESS,
  payload: directories,
});

export const getDirectoriesError = (errors: IErrors[]): IGetAllDirectoriesErrorAction => ({
  type: DIRECTORY_GET_ALL_ERROR,
  payload: errors,
});
