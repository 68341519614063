import { Button, Card, Col, Icon, List, Row, Tag, Tooltip } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BudgetDetailsModal } from '../../../../../components/BudgetDetailsModal';
import { CustomAvatar } from '../../../../../components/CustomAvatar';
import { TableStatusCounterFooter } from '../../../../../components/TableStatusCounterFooter';
import { IUser } from '../../../../../store/users/types';
import { renderPaginationItems } from '../../../../../utils/table-pagination-item-render';
import { IBrief, IBriefToSave, IBudget, IBudgetChangeStatus, IBudgetDetailsModal, IClient } from '../../../../../utils/types';

interface IDispatchTeamBudgetsProps {
  users: IUser[];
  clients: IClient[];
  budgets: IBudget[];
  isLoading: boolean;
  listBriefs: IBrief[];
  isLoadingBriefs: boolean;
  onBudgetDetailsClick: (budget: IBudget) => void;
  onNewBriefSubmit: (brief: IBriefToSave) => void;
  onArchiveProject: (project: IBudgetChangeStatus) => void;
}

const BudgetsListContainer = styled.div`
  .ant-pagination .ant-pagination-item {
    display: none;
  }
`;

export const DispatchTeamBudgets: React.FC<IDispatchTeamBudgetsProps> = ({
  users,
  clients,
  budgets,
  isLoading,
  listBriefs,
  isLoadingBriefs,
  onBudgetDetailsClick,
  onNewBriefSubmit,
  onArchiveProject,
}) => {

  const [isModalVisible, setModalVisible] = useState(false);
  const [targetBudget, setTargetBudget] = useState<IBudgetDetailsModal | null>(null);

  // Return Client name
  const getClientName = (clientId: string) => {
    return clientId && clients?.filter(({ id }) => id === clientId.replace('/clients/', ''))[0]?.companyName;
  };

  const getUsers = (listIds: (string | null)[]) => {
    return users.filter(({ id }) => listIds.includes(`/users/${id}`));
  };

  const handleModalCloseClick = () => setModalVisible(false);

  const getNewBrief = (newBrief: IBriefToSave) => onNewBriefSubmit(newBrief);

  const renderAvatars = (filteredUsers: IUser[]) => {
    return filteredUsers.map(({ id, avatar, firstName, lastName }) => {
      return (
        <span key={id} style={{ margin: '0 3px' }}>
          <Tooltip title={`${firstName} ${lastName}`}>
            <CustomAvatar size="large" shape="square" type="user" src={avatar?.path} />
          </Tooltip>
        </span>
      );
    });
  };

  const renderItem = (budget: IBudget) => {
    const prodManager = budget.productionManager ? budget.productionManager : null;
    const projManager = budget.projectManager ? budget.projectManager : null;
    const companyName = getClientName(budget.client);
    const budgetUsers = getUsers([prodManager, projManager, ...budget.retoucher]);

    const handleDetailsBudgetClick = () => {
      setTargetBudget({
        budget,
        client: companyName,
        relativeFiles: [],
      });
      setModalVisible(true);
      onBudgetDetailsClick(budget);
    };

    return (
      <Card className="small-padding" key={budget.id} style={{ width: '100%', marginBottom: '5px' }}>
        <Row type="flex" align="middle" gutter={8}>
          <Col span={2}>
            <Icon style={{ fontSize: 20, color: budget.projectPriority ? '#f00' : '#dddddd' }} type="clock-circle" />
          </Col>

          <Col xl={8}>
            <div style={{ textTransform: 'uppercase' }}>
              <Link to="#">{companyName}</Link>
            </div>
            <div style={{ textTransform: 'capitalize' }}>{budget.name}</div>
          </Col>

          <Col xl={9}>{renderAvatars(budgetUsers)}</Col>

          <Col xl={3}>
            <Tag className={ budget.status.toLocaleLowerCase().split(' ').join('-').replace('à', 'a') }>
              {budget.status}
            </Tag>
          </Col>

          <Col xl={2}>
            <Row type="flex" justify="end">
              <Col>
                <Button onClick={handleDetailsBudgetClick} icon="info" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <BudgetsListContainer>
      <h1 className="orange-title">Budgets</h1>
      <List
        pagination={{
          pageSize: 10,
          size: 'small',
          hideOnSinglePage: true,
          itemRender: (page, type, original) => renderPaginationItems(page, type, original),
        }}
        dataSource={budgets}
        renderItem={renderItem}
        footer={(
          <span>
            <TableStatusCounterFooter totalIndicator={false} projects={budgets} category="budgets" />
          </span>
        )}
      />

      <BudgetDetailsModal
        isVisible={isModalVisible}
        data={ targetBudget }
        users={ users }
        briefs={listBriefs || []}
        isLoadingBriefs={isLoadingBriefs}
        onModalClose={ handleModalCloseClick }
        onCreateNewBrief={ getNewBrief }
        onArchiveProject={ onArchiveProject }
      />
    </BudgetsListContainer>
  );
};
