import { Dropdown, Icon, Menu } from 'antd';
import React from 'react';

import { IBudgetFiles, IUpdateFilePriority } from '../../../../../utils/types';

import './file-prio-updater.less';

interface IFilePrioUpdaterProps {
  isDroppable: boolean;
  prioValue: boolean;
  file: IBudgetFiles;
  onMenuItemClick: (newPrio: IUpdateFilePriority) => void;
}

export const FilePrioUpdater: React.FC<IFilePrioUpdaterProps> = ({ isDroppable, prioValue, file, onMenuItemClick, children }) => {

  const handleMenuClick = () => onMenuItemClick({
    projectId: null,
    fileId: file.id,
    isPriority: !prioValue,
    filename: file.fileName,
  });

  const content = (
    <Menu className="file-prio-updater" onClick={ handleMenuClick }>
      <Menu.Item style={{ textAlign: 'center' }}>
        <Icon type="clock-circle" className={`priority ${ !prioValue ? 'is-prio' : '' }`} />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={content} trigger={isDroppable ? ['click'] : ['contextMenu']}>
      <span style={{ cursor: isDroppable ? 'pointer' : 'normal' }}>
        { children }
        {
          isDroppable && <Icon style={{ marginLeft: 10, fontSize: 10 }} type="caret-down" />
        }
      </span>
    </Dropdown>
  );
};
