import { Button, Col, Icon, Layout, Modal, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
// tslint:disable-next-line: ordered-imports
import { Link } from 'react-router-dom';

import { CustomAvatar } from '../../../../../components/CustomAvatar';
import { PermissionsHandler } from '../../../../../components/permissions-handlers/PermissionsHandler';
import { IConnectedUserData } from '../../../../../store/connectedUser/types';
import { IClient, IPermissionsHandler } from '../../../../../utils/types';
import {  } from '../../../../../utils/vars';
import '../../client-details.less';

const { Sider, Content, Header } = Layout;

// tslint:disable-next-line: no-empty-interfacePermissionContext
interface Props {
  client: IClient | null;
  permissions: IPermissionsHandler | null;
  connectedUser: IConnectedUserData;
  onDesactivateConfirmed: () => void;
}

export const BlockDetailClient: React.FC<Props> = props => {
  const { client, onDesactivateConfirmed, permissions } = props;

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const handleDisableClient = () => {
    setIsVisibleModal(true);
  };

  const handleCloseModal = () => {
    setIsVisibleModal(false);
  };

  const DisableClient = () => {
    onDesactivateConfirmed();
    setIsVisibleModal(false);
  };

  const activationText = client?.enabled ? 'désactiver' : 'activer';

  return (
    <>
      <Layout className="details-clients">
      <Header>
          <Row gutter={8}>
            <Col span={12}>
              <h2>
                <b>Détails Client</b>
              </h2>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end" align="middle">
                <Col className="align-end" xl={2} md={4}>
                  <Tooltip title="Liste des clients">
                    <Link to={`/clients`}>
                      <Button className="profile-btn cards" type="ghost" icon="appstore" size="default" />
                    </Link>
                  </Tooltip>
                  </Col>
                  {
                    client?.enabled && (
                      <PermissionsHandler
                        blocPermission="CAN_PUT"
                        resourceName="Client"
                        userPermissions={permissions}
                      >
                        <Col className="align-end" xl={2} md={4}>
                          <Tooltip title="Modifier ce client">
                            <Link to={`/clients/update/${ client?.id }`}>
                              <Button className="profile-btn edit" type="primary" icon="edit" size="default" />
                            </Link>
                          </Tooltip>
                        </Col>
                      </PermissionsHandler>
                    )
                  }
                <PermissionsHandler
                  blocPermission="CAN_DESACTIVATE"
                  resourceName="Client"
                  userPermissions={ permissions }
                >
                  <Col className="align-end" xl={2} md={4}>
                    <Tooltip placement="topRight" title={`${ client?.enabled ? 'Désactiver' : 'Activer' } ce client`}>
                      <Button
                        className={`profile-btn ${ client?.enabled ? 'disable-btn' : 'enable-btn' }`}
                        onClick={handleDisableClient}
                        icon={ client?.enabled ? 'stop' : 'check-circle' }
                        size="default"
                      />
                    </Tooltip>
                  </Col>
                </PermissionsHandler>
              </Row>
            </Col>
          </Row>
        </Header>
        <Layout>
          <Sider className="no-border">
            <CustomAvatar size={150} type="client" src={client?.logo}/>
            <PermissionsHandler blocPermission="CAN_PUT" resourceName="Client" userPermissions={permissions}>
            {client?.enabled && (
              <Link to={`/clients/update/${client?.id}`}>
                <Button className="btn-success" shape="round" block>
                  Modifier ce client
                </Button>
              </Link>
            )}
            </PermissionsHandler>
          </Sider>
          <Content>

            <div className="name">
              <h2 className="username">
                {client && client.companyName}
              </h2>
              <span>
                {client && client.gender === 'Male' ? 'Mr.' : 'Ms.'} {client && client.contactFirstName} {client && client.contactLastName}
              </span>
            </div>

            <div className="ref">
              <Icon type="home" />
              <span> Adresse: </span>
              <strong>
                {client && client.address}
              </strong>
            </div>

            <div className="ref">
              <Icon type="mail" />
              <span>Adresse Email: </span>
              <strong>
                {client && client.email}
              </strong>
            </div>

            <div className="ref">
              <Icon type="phone" />
              <span>Téléphone: </span>
              <strong>
                {client && client.phone}
              </strong>
            </div>

            <div className="ref">
              <Icon type="profile" />
              <span>Commentaire:</span>
            </div>
            <div>
              {client && client.comment === ('' || null) ? 'Pas de Commentaire' : client && client.comment}
            </div>

            <Modal
              title={`Confirmation d${ client?.enabled ? 'e désactivation' : '\'activation' } !`}
              centered
              className="confirmation-modal"
              visible={isVisibleModal}
              onOk={DisableClient}
              onCancel={handleCloseModal}
              footer={[
                (
                  <Button key="Annuler" type="primary" onClick={ handleCloseModal }>
                    Annuler
                  </Button>
                ),
                (
                  <Button key="Désactiver" type="danger" onClick={ DisableClient }>
                    { activationText }
                  </Button>
                ),
              ]}>
              <p>Voulez vous vraiment { activationText } {client?.companyName} ?</p>
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
