import { put, select, takeLatest } from 'redux-saga/effects';

import * as requests from '../../api';

import { getDirectoriesError, getDirectoriesSuccess } from './actions';
import { DIRECTORY_GET_ALL } from './types';

function* getDirectories() {
  try {
    const result = yield requests.getDirectoriesList(yield select(state => state.auth.token));
    yield put(getDirectoriesSuccess(result.data));
  } catch (errors) {
      yield put(getDirectoriesError(errors));
  }
}

export function* watchGetDirectories() {
  yield takeLatest(DIRECTORY_GET_ALL, getDirectories);
}
