import React from 'react';

interface IFormElementPlaceholderProps {
  text?: string;
  empty?: boolean;
}

export const FormElementPlaceholder: React.FC<IFormElementPlaceholderProps> = ({ text, empty }) => {
  return (
    <span>
      <span>
        { empty ? '' : text }
      </span>
      <span className="required-field">
        { empty ? '' : '*' }
      </span>
    </span>
  );
};
