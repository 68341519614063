import { Layout, message, Spin } from 'antd';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ConnectedUserSider } from '../../../components/ConnectedUserSider';
import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionsHandler } from '../../../components/permissions-handlers/PermissionsHandler';
import { SiderClientsList } from '../../../components/SiderClientsList';
import { SiderProfileDetails } from '../../../components/SiderProfileDetails';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { getListClients, updateClient } from '../../../store/clients/actions';
import { IClient, IPermissionsHandler, IPageProps } from '../../../utils/types';

import { UpdateClientForm } from './components/UpdateClientForm';

const { Sider, Content } = Layout;

// tslint:disable-next-line: no-empty-interface
interface IUpdateClientProps extends IPageProps {}

const UpdateClientPage: React.FC<IUpdateClientProps & ConnectedProps<typeof connector> & RouteComponentProps<{ id: string }>> = ({
  route,
  match,
  connectedUser,
  errors,
  isLoading,
  isUpdated,
  listClients,
  history,
  getListClients: getListClientsAction,
  updateClient: updateClientAction,
}) => {

  const permissions: IPermissionsHandler | null = useContext(PermissionContext);

  const [targetClient, setClient] = useState<IClient | null>(null);

  const getUpdatedClient = (updatedClient: IClient) => {
    if (updatedClient && targetClient) {
      const { createdAt, updatedAt, projects, createdBy, enabled, ...restOfOriginal } = targetClient;
      if (_.isEqual({...restOfOriginal}, updatedClient)) {
        message.destroy();
        message.warning('Vous devriez faire quelques mises à jour!');
        return;
      }
      updateClientAction(updatedClient);
    }
  };

  const filterClient = (clients: IClient[], clientId: string) => {
    if (!clientId) return null;
    return clients.find(c => c.id === clientId);
  };

  useEffect(() => {
    const filteredClient = filterClient(listClients, match.params.id);
    if (filteredClient) setClient(filteredClient);
    else history.replace('/clients');
  },        [match, listClients, history]);

  useEffect(() => {
    getListClientsAction({
      status: null,
    });
  },        [getListClientsAction, isUpdated]);

  return (
    <>
      <CustomBreadcrumb route={route} />
      <Layout className="page-layout has-card-view">
        {
          !!targetClient && <PageTitle title={`${ route.title } ${ targetClient.companyName }`} />
        }
        <Sider>
          {!!connectedUser && <ConnectedUserSider userData={connectedUser} />}
          <SiderClientsList listPeople={listClients} />
        </Sider>
        <Layout>
          <Content>
            <PermissionsHandler blocPermission="CAN_PUT" resourceName="Client" userPermissions={permissions}>
              <Spin spinning={ isLoading }>
                <UpdateClientForm client={targetClient} onUpdateClient={getUpdatedClient} errors={errors} />
              </Spin>
            </PermissionsHandler>
          </Content>
        </Layout>

        <Sider>
          {!!connectedUser && <SiderProfileDetails userData={connectedUser} />}
        </Sider>
      </Layout>
    </>

  );
};

const mapDispatchToProps = {
  updateClient,
  getListClients,
};

const mapStateToProps = (state: GlobalState) => ({
  isLoading: state.clients.isLoading,
  errors: state.clients.errors,
  connectedUser: state.connectedUser.userData,
  listClients: state.clients.listClients,
  isUpdated: state.clients.isUpdated,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const UpdateClient = connector(UpdateClientPage);
