import { RouteComponentProps } from 'react-router-dom';

import { AnonymousLayout } from '../layouts/AnonymousLayout';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { ForgotPassword } from '../pages/auth/ForgotPassword';
import { Login } from '../pages/auth/Login';
import { ResetPassword } from '../pages/auth/ResetPassword';
import { Budgets } from '../pages/budgets/Budgets';
import { Files } from '../pages/budgets/Files';
import { NewBudget } from '../pages/budgets/NewBudget';
import { TransferFile } from '../pages/budgets/TransferFile';
import { UpdateBudget } from '../pages/budgets/UpdateBudget';
import { AddClientPage } from '../pages/clients/AddClient';
import { ClientsPage } from '../pages/clients/Clients';
import { DetailClient } from '../pages/clients/DetailClient';
import { UpdateClient } from '../pages/clients/UpdateClient';
import { Dashboard } from '../pages/Dashboard';
import { Notifications } from '../pages/Notifications';
import { Unauthorized } from '../pages/Unauthorized';
import { CreateProfilePage } from '../pages/users/CreateProfile';
import { DispatchTeam } from '../pages/users/DispatchTeam';
import { ManageProfiles } from '../pages/users/ManageProfiles';
import { Profile } from '../pages/users/Profile';
import { UpdateProfile } from '../pages/users/UpdateProfile';
import { IPageProps, IUserPermissions } from '../utils/types';

import { generateRoutes } from './generate-routes';

export interface IRoute {
  name: string;
  title: string;
  isPublic?: boolean;
  hasSiderLink?: boolean;
  hasHeaderLink?: boolean;
  // tslint:disable-next-line: no-any
  component?: React.ComponentType<RouteComponentProps<any> & IPageProps> | React.ComponentType;
  path?: string;
  exact?: boolean;
  icon?: string;
  routes?: IRoute[];
  resources?: IUserPermissions;
}

export interface ILayout {
  layout: typeof AnonymousLayout | typeof DefaultLayout;
  routes: IRoute[];
}

export const routes: ILayout[] = [
  {
    layout: AnonymousLayout,
    routes: [
      {
        name: 'login',
        path: '/login',
        title: 'Connexion',
        isPublic: true,
        component: Login,
      },
      {
        name: 'forgot-password',
        path: '/forgot-password',
        title: 'Mot de passe oublié',
        isPublic: true,
        component: ForgotPassword,
      },
      {
        name: 'reset-password',
        path: '/reset-password',
        title: 'Réinitialiser mot de passe',
        isPublic: true,
        component: ResetPassword,
      },
    ],
  },
  {
    layout: DefaultLayout,
    routes: [
      {
        name: 'unauthorized',
        path: '/unauthorized',
        title: 'Contenu non autorisé',
        component: Unauthorized,
      },
      {
        name: 'Tableau de bord',
        path: '/',
        title: 'Tableau de bord',
        component: Dashboard,
        exact: true,
        hasSiderLink: true,
        icon: 'dashboard',
      },
      {
        name: 'Gestion Budgets',
        title: 'Gestion Budgets',
        icon: 'folder',
        hasSiderLink: true,
        resources: {
          Project: [
            ['CAN_VIEW'],
          ],
        },
        routes: [
          {
            name: 'Gestion Budgets',
            path: '/budgets/new-budget',
            title: 'Nouveau Budget +',
            hasSiderLink: true,
            component: NewBudget,
            resources: {
              Project: [
                ['CAN_CREATE'],
              ],
            },
          },
          {
            name: 'Gestion Budgets',
            path: '/budgets',
            title: 'Budgets',
            hasSiderLink: true,
            exact: true,
            component: Budgets,
            resources: {
              Project: [
                ['CAN_VIEW'],
              ],
            },
          },
          {
            name: 'Gestion Budgets',
            path: '/budgets/in-progress',
            title: 'En cours',
            hasSiderLink: true,
            exact: true,
            component: Budgets,
            resources: {
              Project: [
                ['CAN_VIEW'],
              ],
            },
          },
          {
            name: 'Gestion Budgets',
            path: '/budgets/to-come-up',
            title: 'À venir',
            hasSiderLink: true,
            exact: true,
            component: Budgets,
            resources: {
              Project: [
                ['CAN_VIEW'],
              ],
            },
          },
          {
            name: 'Gestion Budgets',
            path: '/budgets/closed',
            title: 'Cloturés',
            hasSiderLink: true,
            exact: true,
            component: Budgets,
            resources: {
              Project: [
                ['CAN_VIEW'],
              ],
            },
          },
          {
            name: 'Gestion Budgets',
            path: '/budgets/archived',
            title: 'Archivés',
            hasSiderLink: true,
            exact: true,
            component: Budgets,
            resources: {
              Project: [
                ['CAN_VIEW'],
              ],
            },
          },
          {
            name: 'add-briefs',
            path: '#add-brief',
            title: 'Ajouter Briefs +',
            hasSiderLink: true,
            resources: {
              Brief: [
                ['CAN_CREATE'],
              ],
            },
          },
          {
            name: 'Gestion Budgets',
            path: '/budgets/upload',
            title: 'Télécharger Fichiers',
            exact: true,
            hasSiderLink: true,
            component: TransferFile,
            resources: {
              File: [
                ['CAN_UPLOAD'],
              ],
            },
          },
          {
            name: 'Gestion Budgets',
            path: '/budgets/:id',
            title: 'Images',
            exact: true,
            component: Files,
          },
          {
            name: 'Gestion Budgets',
            path: '/budgets/update/:id',
            title: 'Modifier budget',
            exact: true,
            component: UpdateBudget,
          },
        ],
      },
      {
        name: 'Gestion équipe',
        title: 'Gestion équipe',
        icon: 'team',
        hasSiderLink: true,
        routes: [
          {
            name: 'Gestion équipe',
            path: '/team',
            title: 'Gérer les profils',
            exact: true,
            hasSiderLink: true,
            component: ManageProfiles,
            resources: {
              User: [
                ['CAN_VIEW'],
              ],
            },
          },
          {
            name: 'Gestion équipe',
            path: '/dispatch',
            title: 'Dispatch équipe',
            hasSiderLink: true,
            component: DispatchTeam,
            resources: {
              User: [
                ['CAN_DISPATCH'],
              ],
            },
          },
          {
            name: 'Gestion équipe',
            path: '/create-profile',
            title: 'Ajouter profil',
            component: CreateProfilePage,
          },
          {
            name: 'Gestion équipe',
            path: '/update-profile/:id',
            title: 'Modifier profil',
            component: UpdateProfile,
          },
          {
            name: 'Gestion équipe',
            path: '/team/:id',
            exact: true,
            title: 'Profil',
            component: Profile,
          },
        ],
      },
      {
        name: 'gestion clients',
        title: 'Infos Clients',
        icon: 'global',
        hasSiderLink: true,
        exact: true,
        resources: {
          Client: [
            ['CAN_VIEW'],
          ],
        },
        routes: [
          {
            name: 'gestion clients',
            path: '/clients/add',
            title: 'Nouveau client +',
            hasSiderLink: true,
            exact: true,
            component: AddClientPage,
            resources: {
              Client: [
                ['CAN_CREATE'],
              ],
            },
          },
          {
            name: 'gestion clients',
            path: '/clients',
            title: 'Liste Clients',
            component: ClientsPage,
            hasSiderLink: true,
            exact: true,
            resources: {
              Client: [
                ['CAN_VIEW'],
              ],
            },
          },
          {
            name: 'gestion clients',
            path: '/clients/:id',
            title: 'Details clients',
            component: DetailClient,
            exact: true,
          },
          {
            name: 'gestion clients',
            path: '/clients/update/:id',
            title: 'Modifier client',
            component: UpdateClient,
            exact: true,
          },
        ],
      },
      {
        name: 'notifications',
        title: 'Notifications',
        path: '/notifications',
        component: Notifications,
        exact: true,
      },
    ],
  },
];

export const Routes = generateRoutes(routes);
