import { Reducer } from 'redux';

import {
  directoriesActionTypes,
  DIRECTORY_GET_ALL,
  DIRECTORY_GET_ALL_ERROR,
  DIRECTORY_GET_ALL_SUCCESS,
  IDirectories,
} from './types';

const initialState: IDirectories = {
  directories: [],
  isLoading : false,
  errors: null,
};

export const DirectoryReducer: Reducer<typeof initialState, directoriesActionTypes> = (state = initialState, action) => {

  switch (action.type) {
    case DIRECTORY_GET_ALL:
      return { ...state, isLoading: true };

      case DIRECTORY_GET_ALL_SUCCESS :
      return { ...state, directories: action.payload, isLoading: false };

      case DIRECTORY_GET_ALL_ERROR:
      return { ...state, errors: action.payload, isLoading: false };

    default:
      return state;
  }
};
