/**
 *
 * @param togglerValues Array of values [true, false]
 * @param people clients | users
 */
export const getTogglerRequestFragment = (togglerValues: boolean[] | null, people: 'clients' | 'users', page: number) => {
  let status = '';
  if (togglerValues)
    status = (togglerValues.length < 2)
      ? `&enabled[]=${togglerValues[0]}`
      : `&enabled[]=${togglerValues[0]}&enabled[]=${togglerValues[1]}`;
  return `/${ people }?_page=${ page }${ status }&itemsPerPage=100`;
};
