import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { ICredentials, IResetPasswordData } from '../store/auth/types';
import { IGetListClients } from '../store/clients/types';
import { IFileInfos, IFilePreview, IFileStatus, IRequestPreSignedURL } from '../store/file/types';
import { IFileBriefsTerm } from '../store/project/types';
import { parseAxiosError } from '../utils/axios-utils';
import { getUserIdFromToken } from '../utils/jwt-utils';
import { getTogglerRequestFragment } from '../utils/people-enable-toggler';
import { generateStatusList } from '../utils/project-utils';
import {
  IBriefToSave,
  IBudgetChangeStatus,
  IBudgetsTerm,
  IBudgetToSave,
  IClient,
  IDisableTerm,
  IDownloadTerms,
  IFileChangeStatus,
  IFileTeamToSave,
  ILogFile,
  IRequestUserBudgets,
  IUpdateBudget,
  IUpdateFilePriority,
  IUserData,
} from '../utils/types';
import { concatUsersPrefixToIds } from '../utils/users';

/**
 * Create Axios instance
 */
// let token: string;

const api = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  headers: {
    Accept: 'application/json',
  },
});

/**
 * Handle Axios requests and put auth token in header
 */

export const handleAxiosAuth = (authToken: string) => {
  // token = authToken;
  api.interceptors.request.use(
    (request: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
      if (request.url !== '/login' && authToken) request.headers.Authorization = `Bearer ${authToken}`;
      return request;
      // tslint:disable-next-line: align
    },
    error => {
      return Promise.reject(error);
    }
  );
};

/**
 * Handle Axios response errors
 */
export const handleAxiosErrors = () => {
  api.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
      return response;
      // tslint:disable-next-line: align
    },
    error => {
      return Promise.reject(parseAxiosError(error));
    }
  );
};

/**
 * Auth Requests
 */
export const login = ({ email, password }: ICredentials) =>
  api.post('/login', {
    username: email,
    password,
  });

export const requestResetPassword = (email: string) => api.post('/users/forgot-password', { email });

export const resetPassword = (payload: IResetPasswordData) => api.post('/users/reset-password', payload);

/**
 * User Requests
 */
export const getConnectedUserData = (token: string) => {
  const id = getUserIdFromToken(token);
  return api.get(`/users/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPerrmissions = (token: string) =>
  api.get(`/permission-by-role`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const addUser = (user: IUserData, token: string) =>
  api.post(`/users`, user, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateUserData = (user: IUserData, token: string) =>
  api.put(`/users/${user.id}`, user, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getUsers = (togglerFilter: boolean[] | null, token: string) => {
  const request = getTogglerRequestFragment(togglerFilter, 'users', 1);
  return api.get(request, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUser = (id: string, token: string) =>
  api.get(`/users/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const toggleDisableUser = ({ id, nextStatus }: IDisableTerm, token: string) =>
  api.put(
    `/users/${id}/status`,
    { enabled: nextStatus },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getRoleList = (token: string) =>
  api.get(`/roles`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getDispatchTeam = (page: number, token: string) => api.get(`/users/dispatch-teams?_page=${page}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

/**
 * Client Requests
 */
export const addClientRequest = (payload: IClient, token: string) => {
  const userID = getUserIdFromToken(token);
  const client = { ...payload, createdBy: `/users/${userID}` };
  return api.post(`/clients`, client, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getClients = ({ status, page }: IGetListClients, token: string) => {
  const request = getTogglerRequestFragment(status, `clients`, page || 1);
  return api.get(request, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getClientsFolders = (token: string) =>
  api.get(`/client`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getClient = (id: string, token: string) =>
  api.get(`/clients/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateClientData = (client: IClient, token: string) =>
  api.put(`/clients/${client.id}`, client, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const toggleDisableClient = (id: string, nextStatus: boolean, token: string) =>
  api.put(
    `/clients/${id}/status`,
    { enabled: nextStatus },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

/**
 * Directories Requests
 */
export const getDirectoriesList = (token: string) =>
  api.get(`/folders`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

/**
 * File Requests
 */
export const requestPresignedURL = ({ folder, filename }: IRequestPreSignedURL, token: string) =>
  api.get(`/upload/${folder}/${filename}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const addFile = (file: IFileInfos, token: string) =>
  api.post(`/files`, file, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const addLogFile = (logFile: ILogFile, token: string) =>
  api.post(`/files_retouchers`, logFile, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getProjectFiles = (id: string, token: string) =>
  api.get(`/projects/${id}/files`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const downloadFiles = (folder: string, token: string) =>
  api.get(`/download/${folder}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getFile = (id: string, token: string) =>
  api.get(`/files/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const changeFileStatus = (file: IFileStatus, token: string) =>
  api.put(
    `/files/${file.status}/${file.id}`,
    { action: file.action },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateFiles = (file: IFileChangeStatus, token: string) => {
  const { relativeBudget, ...rest } = file;
  return api.post(
    `/files/update-status`,
    { ...rest },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const dispatchTeam = (team: IFileTeamToSave, token: string) => {
  const { fileId, checker, transfer, retoucher, currentRetoucher, action } = team;
  return api.put(
    `/files/${fileId}/dispatch-team`,
    {
      checker: checker && concatUsersPrefixToIds(checker),
      transfer: transfer && concatUsersPrefixToIds(transfer),
      retoucher: retoucher && concatUsersPrefixToIds(retoucher),
      currentRetoucher: currentRetoucher && concatUsersPrefixToIds(currentRetoucher),
      action,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateFilePrio = ({ fileId, isPriority }: IUpdateFilePriority, token: string) =>
  api.put(
    `/files/${fileId}`,
    { filePriority: isPriority },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getThumbnail = ({ file, folder }: IFilePreview, token: string) =>
  api.get(`/file/${folder}/bruts/${file}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getFileRetouchLog = ({ folder, originalFileId }: IDownloadTerms, token: string) =>
  api.get(`/fileretouchers/${folder}/${originalFileId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getBrutFileURL = ({ folder, originalFileId }: IDownloadTerms, token: string) =>
  api.get(`/file/${folder}/bruts/${originalFileId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getFilesPerProject = (projectId: string, token: string) =>
  api.get(`/projects/${projectId}/files?itemsPerPage=${null}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getFilesToCheck = (page: number, token: string) =>
  api.get(`/files-tocheck?_page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getFilesToSend = (page: number, token: string) =>
  api.get(`/files-checked?_page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

/**
 * Project Requests
 */
export const addProject = ({ additionalInfo, ...rest }: IBudgetToSave, token: string) =>
  api.post(
    `/projects`,
    { ...rest },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getProjects = ({ status, page }: IBudgetsTerm, token: string) => {
  const request = generateStatusList(status);
  return api.get(`/projects?_page=${page}&${request}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProject = (id: string, token: string) =>
  api.get(`/projects/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getClientProjects = (client: string, token: string) =>
  api.get(`/clients/${client}/projects`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getUserProjects = ({ page, userId }: IRequestUserBudgets, token: string) =>
  api.get(`/users/${userId}/projects?_page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateProjectsStatus = (budgets: IBudgetChangeStatus, token: string) =>
  api.post(`/projects/update-status`, budgets, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getCheckpoint = (token: string) =>
  api.get(`/projects/checkpoint/pdf`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const addBrief = (brief: IBriefToSave, token: string) =>
  api.post(`/briefs`, brief, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getBriefList = (folder: string, token: string) =>
  api.get(`/briefs/${folder}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getProjectsByStatus = (byStatus: string[], token: string) => {
  const request = generateStatusList(byStatus);
  return api.get(`/projects?${request}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBriefsByFile = ({ budgetId, fileId }: IFileBriefsTerm, token: string) =>
  api.get(`/projects/${budgetId}/files/${fileId}/briefs`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getProjectNumberPerStatus = (statusList: string[], token: string) => {
  const request = generateStatusList(statusList);
  return api.get(`projects/count-status?${request}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateProject = (project: IUpdateBudget, token: string) =>
  api.put(`/projects/${project.id}`, project, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

/**
 * Synchronization Requests
 */
export const synchronizeApplication = (token: string) =>
  api.get(`/synchronization`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
