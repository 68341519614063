import { Action } from 'redux';

import { IBrief, IBriefToSave, IBudget, IBudgetChangeStatus, IBudgetsTerm, IBudgetToSave, IErrors, IPlainBrief, IProjectStatus, IRequestUserBudgets, IUpdateBudget } from '../../utils/types';

// Initial state interface
export interface IBudgets {
  listProjects: IBudget[];
  projectsNumberPerStatus: IProjectStatus[];
  singleProject: IBudget | null;
  isLoading: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  errors: IErrors | null;
  checkpoints: ICheckpoints | null;
  isCheckpointLoading: boolean;
  briefs: IBriefs;
  budgetInProgress: IBudget[];
  relativeFileBriefs: {
    plainBriefs: IPlainBrief[];
    isLoading: boolean;
    errors: IErrors | null;
  };
}

export interface IBriefs {
  briefList: IBrief[];
  isCreated: boolean;
  isLoading: boolean;
  errors: IErrors[] | null;
}

interface ITeam {
  productionManager: string | null;
  projectManager: string | null;
  retouchers: string[];
}

interface ICheckpointProject {
  id: string;
  name: string;
  deadline: string;
  teams: ITeam;
  totalFiles: number;
  totalFileTransfered: number;
  totalFileToTransfer: number;
  totalFileToCheck: number;
  totalFileToDo: number;
  totalFileToRevise: number;
  totalFileInProgress: string[];
}

export interface ICheckpoints {
  projects: ICheckpointProject[];
  downloadLink: string;
  date: string;
}

export interface IReloadBudgets {
  page: number;
  budgets: IBudget[];
}

export interface IFileBriefsTerm {
  budgetId: string;
  fileId: string;
}

/**
 * Add Budget
 */
export const BUDGET_ADD = 'BUDGET_ADD';
export type BUDGET_ADD  = typeof BUDGET_ADD;

export const BUDGET_ADD_SUCCESS = 'BUDGET_ADD_SUCCESS';
export type BUDGET_ADD_SUCCESS  = typeof BUDGET_ADD_SUCCESS;

export const BUDGET_ADD_ERROR = 'BUDGET_ADD_ERROR';
export type BUDGET_ADD_ERROR  = typeof BUDGET_ADD_ERROR;

export interface IBudgetAddAction extends Action<BUDGET_ADD> {
  payload: IBudgetToSave;
}

export interface IBudgetAddSuccessAction extends Action<BUDGET_ADD_SUCCESS> {}

export interface IBudgetAddErrorAction extends Action<BUDGET_ADD_ERROR> {
  payload: IErrors;
}

/**
 * Get budgets
 */
export const BUDGET_GET_ALL = 'BUDGET_GET_ALL';
export type BUDGET_GET_ALL  = typeof BUDGET_GET_ALL;

export const BUDGET_GET_ALL_SUCCESS = 'BUDGET_GET_ALL_SUCCESS';
export type BUDGET_GET_ALL_SUCCESS  = typeof BUDGET_GET_ALL_SUCCESS;

export const BUDGET_GET_ALL_ERROR = 'BUDGET_GET_ALL_ERROR';
export type BUDGET_GET_ALL_ERROR  = typeof BUDGET_GET_ALL_ERROR;

export interface IBudgetGetAllAction extends Action<BUDGET_GET_ALL> {
  payload: IBudgetsTerm;
}

export interface IBudgetGetAllSuccessAction extends Action<BUDGET_GET_ALL_SUCCESS> {
  payload: IReloadBudgets;
}

export interface IBudgetGetAllErrorAction extends Action<BUDGET_GET_ALL_ERROR> {
  payload: IErrors;
}

/**
 * Get Budget by ID
 */
export const BUDGET_GET_ONE = 'BUDGET_GET_ONE';
export type BUDGET_GET_ONE  = typeof BUDGET_GET_ONE;

export const BUDGET_GET_ONE_SUCCESS = 'BUDGET_GET_ONE_SUCCESS';
export type BUDGET_GET_ONE_SUCCESS  = typeof BUDGET_GET_ONE_SUCCESS;

export const BUDGET_GET_ONE_ERROR = 'BUDGET_GET_ONE_ERROR';
export type BUDGET_GET_ONE_ERROR  = typeof BUDGET_GET_ONE_ERROR;

export interface IBudgetGetOneAction extends Action<BUDGET_GET_ONE> {
  payload: string;
}

export interface IBudgetGetOneSuccessAction extends Action<BUDGET_GET_ONE_SUCCESS> {
  payload: IBudget;
}

export interface IBudgetGetOneErrorAction extends Action<BUDGET_GET_ONE_ERROR> {
  payload: IErrors;
}

/**
 * Get budgets by client
 */
export const BUDGET_GET_BY_CLIENT = 'BUDGET_GET_BY_CLIENT';
export type BUDGET_GET_BY_CLIENT  = typeof BUDGET_GET_BY_CLIENT;

export const BUDGET_GET_BY_CLIENT_SUCCESS = 'BUDGET_GET_BY_CLIENT_SUCCESS';
export type BUDGET_GET_BY_CLIENT_SUCCESS  = typeof BUDGET_GET_BY_CLIENT_SUCCESS;

export const BUDGET_GET_BY_CLIENT_ERROR = 'BUDGET_GET_BY_CLIENT_ERROR';
export type BUDGET_GET_BY_CLIENT_ERROR  = typeof BUDGET_GET_BY_CLIENT_ERROR;

export interface IBudgetGetByClient extends Action<BUDGET_GET_BY_CLIENT> {
  payload: string;
}

export interface IBudgetGetByClientSuccess extends Action<BUDGET_GET_BY_CLIENT_SUCCESS> {
  payload: IBudget[];
}

export interface IBudgetGetByClientError extends Action<BUDGET_GET_BY_CLIENT_ERROR> {
  payload: IErrors;
}

export const UPDATE_LIST_BUDGETS = 'UPDATE_LIST_BUDGETS';
export type UPDATE_LIST_BUDGETS  = typeof UPDATE_LIST_BUDGETS;

export const UPDATE_LIST_BUDGETS_SUCCESS = 'UPDATE_LIST_BUDGETS_SUCCESS';
export type UPDATE_LIST_BUDGETS_SUCCESS  = typeof UPDATE_LIST_BUDGETS_SUCCESS;

export const UPDATE_LIST_BUDGETS_ERROR = 'UPDATE_LIST_BUDGETS_ERROR';
export type UPDATE_LIST_BUDGETS_ERROR  = typeof UPDATE_LIST_BUDGETS_ERROR;

/**
 * Get budgets by connected user
 */
export const GET_USER_BUDGETS = 'GET_USER_BUDGETS';
export type GET_USER_BUDGETS  = typeof GET_USER_BUDGETS;

export const GET_USER_BUDGETS_SUCCESS = 'GET_USER_BUDGETS_SUCCESS';
export type GET_USER_BUDGETS_SUCCESS  = typeof GET_USER_BUDGETS_SUCCESS;

export const GET_USER_BUDGETS_ERROR = 'GET_USER_BUDGETS_ERROR';
export type GET_USER_BUDGETS_ERROR  = typeof GET_USER_BUDGETS_ERROR;

export interface IGetUserBudgetsAction extends Action<GET_USER_BUDGETS> {
  payload: IRequestUserBudgets;
}

export interface IGetUserBudgetsSuccessAction extends Action<GET_USER_BUDGETS_SUCCESS> {
  payload: IReloadBudgets;
}

export interface IGetUserBudgetsErrorAction extends Action<GET_USER_BUDGETS_ERROR> {
  payload: IErrors;
}

/**
 * Update status of list of budgets
 */
export interface IUpdateListBudgetsAction extends Action<UPDATE_LIST_BUDGETS> {
  payload: IBudgetChangeStatus;
}

export interface IUpdateListBudgetsSuccessAction extends Action<UPDATE_LIST_BUDGETS_SUCCESS> {}

export interface IUpdateListBudgetsErrorAction extends Action<UPDATE_LIST_BUDGETS_ERROR> {
  payload: IErrors;
}

/**
 * Get checkpoints
 */
export const GET_CHECKPOINTS = 'GET_CHECKPOINTS';
export type GET_CHECKPOINTS = typeof GET_CHECKPOINTS;

export const GET_CHECKPOINTS_SUCCESS = 'GET_CHECKPOINTS_SUCCESS';
export type GET_CHECKPOINTS_SUCCESS = typeof GET_CHECKPOINTS_SUCCESS;

export const GET_CHECKPOINTS_ERROR = 'GET_CHECKPOINTS_ERROR';
export type GET_CHECKPOINTS_ERROR = typeof GET_CHECKPOINTS_ERROR;

export const RESET_CHECKPOINT = 'RESET_CHECKPOINT';
export type RESET_CHECKPOINT  = typeof RESET_CHECKPOINT;

export interface IGetCheckpointsAction extends Action<GET_CHECKPOINTS> {}

export interface IGetCheckpointsSuccessAction extends Action<GET_CHECKPOINTS_SUCCESS> {
  payload: ICheckpoints;
}

export interface IGetCheckpointsErrorAction extends Action<GET_CHECKPOINTS_ERROR> {
  payload: IErrors;
}

export interface IResetCheckpoint extends Action<RESET_CHECKPOINT> {}

/**
 * Add Project Brief
 */
export const ADD_PROJECT_BRIEF = 'ADD_PROJECT_BRIEF';
export type ADD_PROJECT_BRIEF  = typeof ADD_PROJECT_BRIEF;

export const ADD_PROJECT_BRIEF_SUCCESS = 'ADD_PROJECT_BRIEF_SUCCESS';
export type ADD_PROJECT_BRIEF_SUCCESS  = typeof ADD_PROJECT_BRIEF_SUCCESS;

export const ADD_PROJECT_BRIEF_ERROR = 'ADD_PROJECT_BRIEF_ERROR';
export type ADD_PROJECT_BRIEF_ERROR  = typeof ADD_PROJECT_BRIEF_ERROR;

export interface IProjectBriefAddAction extends Action<ADD_PROJECT_BRIEF> {
  payload: IBriefToSave;
}

export interface IProjectBriefAddSuccessAction extends Action<ADD_PROJECT_BRIEF_SUCCESS> {}

export interface IProjectBriefAddErrorAction extends Action<ADD_PROJECT_BRIEF_ERROR> {
  payload: IErrors[];
}

/**
 * Get Project briefs
 */
export const GET_PROJECT_BRIEFS = 'GET_PROJECT_BRIEFS';
export type GET_PROJECT_BRIEFS  = typeof GET_PROJECT_BRIEFS;

export const GET_PROJECT_BRIEFS_SUCCESS = 'GET_PROJECT_BRIEFS_SUCCESS';
export type GET_PROJECT_BRIEFS_SUCCESS  = typeof GET_PROJECT_BRIEFS_SUCCESS;

export const GET_PROJECT_BRIEFS_ERROR = 'GET_PROJECT_BRIEFS_ERROR';
export type GET_PROJECT_BRIEFS_ERROR  = typeof GET_PROJECT_BRIEFS_ERROR;

export interface IGetProjectBriefAllAction extends Action<GET_PROJECT_BRIEFS> {
  payload: string;
}

export interface IGetProjectBriefAllSuccessAction extends Action<GET_PROJECT_BRIEFS_SUCCESS> {
  payload: IBrief[];
}

export interface IGetProjectBriefErrorAction extends Action<GET_PROJECT_BRIEFS_ERROR> {
  payload: IErrors[];
}

export const RESET_BRIEF_CREATED_PROPERTY = 'RESET_BRIEF_CREATED_PROPERTY';
export type RESET_BRIEF_CREATED_PROPERTY  = typeof RESET_BRIEF_CREATED_PROPERTY;

export interface IBriefResetCreatedPropertyAction extends Action<RESET_BRIEF_CREATED_PROPERTY> {}

/**
 * In progress budgets
 */
export const GET_IN_PROGRESS_BUDGETS = 'GET_IN_PROGRESS_BUDGETS';
export type GET_IN_PROGRESS_BUDGETS  = typeof GET_IN_PROGRESS_BUDGETS;

export const GET_IN_PROGRESS_BUDGETS_SUCCESS = 'GET_IN_PROGRESS_BUDGETS_SUCCESS';
export type GET_IN_PROGRESS_BUDGETS_SUCCESS  = typeof GET_IN_PROGRESS_BUDGETS_SUCCESS;

export const GET_IN_PROGRESS_BUDGETS_ERROR = 'GET_IN_PROGRESS_BUDGETS_ERROR';
export type GET_IN_PROGRESS_BUDGETS_ERROR  = typeof GET_IN_PROGRESS_BUDGETS_ERROR;

export interface IGetInProgressBudgetsAction extends Action<GET_IN_PROGRESS_BUDGETS> {
  payload: string[];
}

export interface IGetInProgressBudgetsSuccessAction extends Action<GET_IN_PROGRESS_BUDGETS_SUCCESS> {
  payload: IBudget[];
}

export interface IGetInProgressBudgetsErrorAction extends Action<GET_IN_PROGRESS_BUDGETS_ERROR> {
  payload: IErrors;
}

/**
 * Get File Briefs
 */
export const GET_FILE_BRIEFS = 'GET_FILE_BRIEFS';
export type GET_FILE_BRIEFS  = typeof GET_FILE_BRIEFS;

export const GET_FILE_BRIEFS_SUCCESS = 'GET_FILE_BRIEFS_SUCCESS';
export type GET_FILE_BRIEFS_SUCCESS  = typeof GET_FILE_BRIEFS_SUCCESS;

export const GET_FILE_BRIEFS_ERROR = 'GET_FILE_BRIEFS_ERROR';
export type GET_FILE_BRIEFS_ERROR  = typeof GET_FILE_BRIEFS_ERROR;

export interface IGetFileBriefsAction extends Action<GET_FILE_BRIEFS> {
  payload: IFileBriefsTerm;
}

export interface IGetFileBriefsSuccessAction extends Action<GET_FILE_BRIEFS_SUCCESS> {
  payload: IPlainBrief[];
}

export interface IGetFileBriefsErrorAction extends Action<GET_FILE_BRIEFS_ERROR> {
  payload: IErrors;
}

/**
 * Get number of project per status
 */
export const GET_PROJECTS_NUMBER_PER_STATUS = 'GET_PROJECTS_NUMBER_PER_STATUS';
export type GET_PROJECTS_NUMBER_PER_STATUS  = typeof GET_PROJECTS_NUMBER_PER_STATUS;

export const GET_PROJECTS_NUMBER_PER_STATUS_SUCCESS = 'GET_PROJECTS_NUMBER_PER_STATUS_SUCCESS';
export type GET_PROJECTS_NUMBER_PER_STATUS_SUCCESS  = typeof GET_PROJECTS_NUMBER_PER_STATUS_SUCCESS;

export const GET_PROJECTS_NUMBER_PER_STATUS_ERROR = 'GET_PROJECTS_NUMBER_PER_STATUS_ERROR';
export type GET_PROJECTS_NUMBER_PER_STATUS_ERROR  = typeof GET_PROJECTS_NUMBER_PER_STATUS_ERROR;

export interface IGetProjectNumberPerStatusAction extends Action<GET_PROJECTS_NUMBER_PER_STATUS> {
  payload: string[];
}

export interface IGetProjectNumberPerStatusSuccessAction extends Action<GET_PROJECTS_NUMBER_PER_STATUS_SUCCESS> {
  payload: IProjectStatus[];
}

export interface IGetProjectNumberPerStatusErrorAction extends Action<GET_PROJECTS_NUMBER_PER_STATUS_ERROR> {
  payload: IErrors;
}

/**
 * Update Project
 */
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export type UPDATE_PROJECT  = typeof UPDATE_PROJECT;

export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export type UPDATE_PROJECT_SUCCESS  = typeof UPDATE_PROJECT_SUCCESS;

export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';
export type UPDATE_PROJECT_ERROR  = typeof UPDATE_PROJECT_ERROR;

export interface IUpdateProjectAction extends Action<UPDATE_PROJECT> {
  payload: IUpdateBudget;
}

export interface IUpdateProjectSuccessAction extends Action<UPDATE_PROJECT_SUCCESS> {}

export interface IUpdateProjectErrorAction extends Action<UPDATE_PROJECT_ERROR> {
  payload: IErrors;
}

export type budgetActionTypes =
  IBudgetAddAction
| IBudgetAddSuccessAction
| IBudgetAddErrorAction
| IBudgetGetAllAction
| IBudgetGetAllSuccessAction
| IBudgetGetAllErrorAction
| IBudgetGetOneAction
| IBudgetGetOneSuccessAction
| IBudgetGetOneErrorAction
| IBudgetGetByClient
| IBudgetGetByClientSuccess
| IBudgetGetByClientError
| IGetUserBudgetsAction
| IGetUserBudgetsSuccessAction
| IGetUserBudgetsErrorAction
| IGetCheckpointsAction
| IGetCheckpointsSuccessAction
| IGetCheckpointsErrorAction
| IResetCheckpoint
| IUpdateListBudgetsAction
| IUpdateListBudgetsSuccessAction
| IUpdateListBudgetsErrorAction
| IProjectBriefAddAction
| IProjectBriefAddSuccessAction
| IProjectBriefAddErrorAction
| IGetProjectBriefAllAction
| IGetProjectBriefAllSuccessAction
| IGetProjectBriefErrorAction
| IBriefResetCreatedPropertyAction
| IGetInProgressBudgetsAction
| IGetInProgressBudgetsSuccessAction
| IGetInProgressBudgetsErrorAction
| IGetFileBriefsAction
| IGetFileBriefsSuccessAction
| IGetFileBriefsErrorAction
| IGetProjectNumberPerStatusAction
| IGetProjectNumberPerStatusSuccessAction
| IGetProjectNumberPerStatusErrorAction
| IUpdateProjectAction
| IUpdateProjectSuccessAction
| IUpdateProjectErrorAction;
