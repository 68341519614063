import { Layout, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BriefFormModal } from '../../components/BriefFormModal';
import { GlobalState } from '../../store';
import { alertResetNotificationsCounter, alertResetToCheckCounter, alertResetToSendCounter } from '../../store/alerts/actions';
import { authLogout } from '../../store/auth/actions';
import { getDirectories } from '../../store/directory/actions';
import { getFilesToBeChecked, getFilesToBeSent } from '../../store/file/actions';
import { createProjectBrief, getAllProjectBriefs, getAllProjectBriefsSuccess } from '../../store/project/actions';
import { openAlertModal, synchronizeRequest, toggleOpenModal } from '../../store/settings/actions';
import { IBrief, IBriefToSave, IBudgetFiles, IFilesAlertPayload } from '../../utils/types';
import { lastVisitedPath } from '../../utils/vars';

import AppContent from './components/AppContent';
import { AppHeader } from './components/AppHeader';
import { FilesProgressNotifModal } from './components/AppHeader/components/FilesProgressNotifModal';
import { AppSider } from './components/AppSider';
import './default-layout.less';

// tslint:disable-next-line: no-empty-interface
interface IDefaultLayoutProps {}

const DefaultLayoutInner: React.FC<IDefaultLayoutProps & ConnectedProps<typeof connector>> = ({
  isSynchronizing,
  children,
  connectedUser,
  permissions,
  budgets,
  isLoadingBriefs,
  listBriefs,
  isModalOpen,
  isBriefCreated,
  briefsPack,
  alerts,
  clients,
  filesToCheck,
  filesToSend,
  fileAlertsModal,
  isConnectedUserLoading,
  synchronizeRequest: synchronizeRequestAction,
  authLogout: authLogoutAction,
  getAllProjectBriefs: getAllProjectBriefsAction,
  getAllProjectBriefsSuccess: getAllProjectBriefsSuccessAction,
  toggleOpenModal: toggleOpenModalAction,
  createProjectBrief: createProjectBriefAction,
  alertResetToSendCounter: alertResetToSendCounterAction,
  alertResetToCheckCounter: alertResetToCheckCounterAction,
  alertResetNotificationsCounter: alertResetNotificationsCounterAction,
  openAlertModal: openAlertModalAction,
  getDirectories: getDirectoriesAction,
  getFilesToBeChecked: getFilesToBeCheckedAction,
  getFilesToBeSent: getFilesToBeSentAction,
}) => {
  const history = useHistory();
  const [briefs, setBriefs] = useState<IBrief[]>([]);
  const [alertModalData, setAlertModalData] = useState<IBudgetFiles[]>([]);
  const [alertDefaultData, setAlertDefaultData] = useState<IFilesAlertPayload | null>(null);
  const [selectedKey, setSelectedKey] = useState<string[]>([]);
  const [_pathname, setPathname] = useState('');

  // Handle logout
  const getLogoutEvent = () => {
    authLogoutAction();
  };

  useEffect(() => {
    getFilesToBeCheckedAction(1);
  },        [getFilesToBeCheckedAction]);

  useEffect(() => {
    getFilesToBeSentAction(1);
  },        [getFilesToBeSentAction]);

  useEffect(() => {
    getDirectoriesAction();
  },        [getDirectoriesAction]);

  useEffect(() => {
    setBriefs(listBriefs);
  },        [listBriefs]);

  useEffect(() => {
    const path = history.location.pathname;
    setPathname(path);
    localStorage.setItem(lastVisitedPath, path);
    const formattedPath = removePossibleIds(path).join('/');
    setSelectedKey([formattedPath]);
  },        [history.location.pathname]);

  useEffect(() => {
    if (isBriefCreated) setBriefs([]);
  },        [isBriefCreated]);

  useEffect(() => {
    if (fileAlertsModal.status)
      if (fileAlertsModal.status === 'checked') {
        setAlertModalData(alerts.filesToCheck.items);
        setAlertDefaultData({
          files: filesToCheck.files,
          total: filesToCheck.total,
        });
      } else {
        setAlertModalData(alerts.filesToSend.items);
        setAlertDefaultData({
          files: filesToSend.files,
          total: filesToSend.total,
        });
      }
  },        [fileAlertsModal.status]);

  const handleModalClick = () => toggleOpenModalAction(true);

  const handleGettingBrief = (newBrief: IBriefToSave) => createProjectBriefAction(newBrief);

  const handleModalClose = () => {
    history.push(history.location.pathname.split('#')[0]);
    toggleOpenModalAction(false);
    getAllProjectBriefsSuccessAction([]);
  };

  const getSelectedBudget = (dir: string) => getAllProjectBriefsAction(dir);

  const handleResetNotifs = () => alertResetNotificationsCounterAction();

  const removePossibleIds = (pathname: string) => {
    const urlFragments = pathname.split('/');
    const hasNumbers = /\d/;
    return urlFragments.filter(f => !hasNumbers.test(f));
  };

  const handleOpenedAlerts = (category: 'checked' | 'transferred') => {
    openAlertModalAction({
      status: category,
      isOpen: true,
    });
    if (category === 'checked') alertResetToCheckCounterAction();
    else alertResetToSendCounterAction();
  };

  const handleAlertModalClose = () => openAlertModalAction({
      status: null,
      isOpen: false,
    });

  const handleSyncWithFilesystem = () => synchronizeRequestAction(_pathname);

  const handleLoadingNextPage = (page: number, status: string) => {
    if (status === 'checked') getFilesToBeCheckedAction(page);
    else getFilesToBeSentAction(page);
  };

  return (
    <Layout className="main-layout">
      <AppSider
        trigger={null}
        connectedUser={connectedUser}
        isConnectedUserLoading={isConnectedUserLoading}
        permissions={permissions}
        selectedKey={selectedKey}
        onLogoutClick={getLogoutEvent}
        onBriefModalTriggered={handleModalClick}
      />
      <Layout>
        <AppHeader
          connectedUserRole={connectedUser?.role.slug}
          clients={clients}
          budgets={budgets}
          alerts={alerts}
          onLogoutBtnClick={getLogoutEvent}
          onAlertResetClick={handleOpenedAlerts}
          onNotifResetClick={handleResetNotifs}
          onSyncButtonClick={handleSyncWithFilesystem}
        />
        <Spin spinning={isSynchronizing} tip="Synchronisation..." wrapperClassName="sync-app">
          <AppContent>{children}</AppContent>
        </Spin>
        {!!connectedUser && (
          <BriefFormModal
            isVisible={isModalOpen}
            projects={budgets}
            isLoading={isLoadingBriefs}
            isCreated={isBriefCreated}
            briefsPack={briefsPack}
            briefs={briefs}
            connectedUser={connectedUser}
            onGetBriefClick={handleGettingBrief}
            onModalCloseClick={handleModalClose}
            onSelectBudget={getSelectedBudget}
          />
        )}
      </Layout>
      <FilesProgressNotifModal
        isVisible={fileAlertsModal.isOpen}
        defaultData={ alertDefaultData }
        status={fileAlertsModal.status}
        dataSource={alertModalData}
        budgets={budgets}
        clients={clients}
        onAlertModalCloseClick={handleAlertModalClose}
        onLoadMoreItemsChange={ handleLoadingNextPage }
      />
    </Layout>
  );
};

const mapDispatchToProps = {
  synchronizeRequest,
  authLogout,
  toggleOpenModal,
  createProjectBrief,
  alertResetToSendCounter,
  alertResetToCheckCounter,
  alertResetNotificationsCounter,
  openAlertModal,
  getAllProjectBriefsSuccess,
  getAllProjectBriefs,
  getDirectories,
  getFilesToBeChecked,
  getFilesToBeSent,
};

const mapStateToProps = (state: GlobalState) => ({
  isSynchronizing: state.settings.sync.isLoading,
  connectedUser: state.connectedUser.userData,
  isConnectedUserLoading: state.connectedUser.loading,
  permissions: state.connectedUser.permissions,
  budgets: state.project.listProjects,
  isLoadingBriefs: state.project.briefs.isLoading,
  listBriefs: state.project.briefs.briefList,
  isBriefCreated: state.project.briefs.isCreated,
  isModalOpen: state.settings.isBriefModalOpen,
  alerts: state.alerts,
  clients: state.clients.listClients,
  fileAlertsModal: state.settings.alertModal,
  briefsPack: state.project.briefs,
  filesToCheck: state.file.filesToCheck,
  filesToSend: state.file.filesToSend,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const DefaultLayout = connector(DefaultLayoutInner);
