import { Reducer } from 'redux';

import { ISettings, OPEN_ALERT_MODAL, SettingsActionTypes, SYNCHRONIZE_REQUEST, SYNCHRONIZE_REQUEST_ERROR, SYNCHRONIZE_REQUEST_SUCCESS, TOGGLE_OPEN_MODAL } from './types';

const initialState: ISettings = {
  isBriefModalOpen: false,
  alertModal: {
    status: null,
    isOpen: false,
  },
  sync: {
    isLoading: false,
    errors: null,
  },
};

export const settingsReducer: Reducer<typeof initialState, SettingsActionTypes> = (state = initialState, action) => {

  switch (action.type) {

    case TOGGLE_OPEN_MODAL:
      return { ...state, isBriefModalOpen: action.payload };

    case OPEN_ALERT_MODAL:
      return { ...state, alertModal: action.payload };

    case SYNCHRONIZE_REQUEST:
      return {
        ...state,
        sync: {
          ...state.sync,
          isLoading: true,
          errors: null,
        },
      };

    case SYNCHRONIZE_REQUEST_SUCCESS:
      return {
        ...state,
        sync: {
          ...state.sync,
          isLoading: false,
        },
      };

    case SYNCHRONIZE_REQUEST_ERROR:
      return {
        ...state,
        sync: {
          ...state.sync,
          isLoading: false,
          errors: action.payload,
        },
      };

    default:
      return state;
  }
};
