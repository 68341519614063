import { Action } from 'redux';

import { IDisableTerm, IDispatchTeamData, IDispatchTeamState, IErrors, IRole, IUpdateUser, IUserData, IUserRole } from '../../utils/types';

export interface IRoles {
  listRoles: IRole[];
  error: IErrors | null;
  isLoading: boolean;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  birthday: string;
  gender: string;
  email: string;
  phone: string;
  phoneExtra: string;
  enabled: boolean;
  zipCode: string;
  country: string;
  createdAt: string;
  updatedAt: string;
  avatar: {
    path: string;
  };
  role: IUserRole;
  city: string;
  address: string;
  birthdayAlert: boolean;
}

export interface IUsers {
  create: {
    isLoading: boolean;
    isCreated: boolean;
    errors: IErrors | null;
  };
  users: IUser[];
  singleUser: {
    user: IUser | null;
    isLoading: boolean;
    error: IErrors | null;
  };
  roles: IRoles;
  error: IErrors | null;
  loading: boolean;
  isEnablingComplete: boolean;
  dispatchTeam: IDispatchTeamState;
}

/**
 * CREATE USER
 */
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export type CREATE_NEW_USER  = typeof CREATE_NEW_USER;

export const CREATE_NEW_USER_SUCCESS = 'CREATE_NEW_USER_SUCCESS';
export type CREATE_NEW_USER_SUCCESS  = typeof CREATE_NEW_USER_SUCCESS;

export const CREATE_NEW_USER_ERROR = 'CREATE_NEW_USER_ERROR';
export type CREATE_NEW_USER_ERROR  = typeof CREATE_NEW_USER_ERROR;

export interface ICreateNewUser extends Action<CREATE_NEW_USER> {
  payload: IUserData;
}

export interface ICreateNewUserSuccess extends Action<CREATE_NEW_USER_SUCCESS> {}

export interface ICreateNewUserError extends Action<CREATE_NEW_USER_ERROR> {
  payload: IErrors;
}

/**
 * GET USERS
 */
export const GET_USERS = 'GET_USERS';
export type GET_USERS  = typeof GET_USERS;

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export type GET_USERS_SUCCESS  = typeof GET_USERS_SUCCESS;

export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export type GET_USERS_ERROR  = typeof GET_USERS_ERROR;

export interface IGetUsersAction extends Action<GET_USERS> {
  payload: boolean[] | null;
}

export interface IGetUsersSuccessAction extends Action<GET_USERS_SUCCESS> {
  payload: IUser[];
}

export interface IGetUsersErrorAction extends Action<GET_USERS_ERROR> {
  payload: IErrors;
}

/**
 * GET USER
 */
export const GET_USER = 'GET_USER';
export type GET_USER  = typeof GET_USER;

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export type GET_USER_SUCCESS  = typeof GET_USER_SUCCESS;

export const GET_USER_ERROR = 'GET_USER_ERROR';
export type GET_USER_ERROR  = typeof GET_USER_ERROR;

export interface IGetUserAction extends Action<GET_USER> {
  payload: string;
}

export interface IGetUserSuccessAction extends Action<GET_USER_SUCCESS> {
  payload: IUser;
}

export interface IGetUserErrorAction extends Action<GET_USER_ERROR> {
  payload: IErrors;
}

/**
 * Enable Disable User
 */

export const USER_DISABLE_ENABLE = 'USER_DISABLE_ENABLE';
export type USER_DISABLE_ENABLE = typeof USER_DISABLE_ENABLE;

export const USER_DISABLE_ENABLE_SUCCESS = 'USER_DISABLE_ENABLE_SUCCESS';
export type USER_DISABLE_ENABLE_SUCCESS = typeof USER_DISABLE_ENABLE_SUCCESS;

export const USER_DISABLE_ENABLE_ERRROR = 'USER_DISABLE_ENABLE_ERRROR';
export type USER_DISABLE_ENABLE_ERRROR = typeof USER_DISABLE_ENABLE_ERRROR;

export interface IUserDisableEnableAction extends Action<USER_DISABLE_ENABLE> {
  payload: IDisableTerm;
}

export interface IUserDisableEnableSuccessAction extends Action<USER_DISABLE_ENABLE_SUCCESS> { }

export interface IUserDisableEnableErrorAction extends Action<USER_DISABLE_ENABLE_ERRROR> {
  payload: IErrors;
}

/**
 * UPDATE User
 */
export const UPDATE_USER = 'UPDATE_USER';
export type UPDATE_USER  = typeof UPDATE_USER;

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export type UPDATE_USER_SUCCESS  = typeof UPDATE_USER_SUCCESS;

export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export type UPDATE_USER_ERROR  = typeof UPDATE_USER_ERROR;

export interface IUpdateUserAction extends Action<UPDATE_USER> {
  payload: IUpdateUser;
}

export interface IUpdateUserSuccessAction extends Action<UPDATE_USER_SUCCESS> {}

export interface IUpdateUserErrorAction extends Action<UPDATE_USER_ERROR> {
  payload: IErrors;
}

/**
 * ROLES
 */
export const GET_ROLES = 'GET_ROLES';
export type GET_ROLES  = typeof GET_ROLES;

export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export type GET_ROLES_SUCCESS  = typeof GET_ROLES_SUCCESS;

export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';
export type GET_ROLES_ERROR  = typeof GET_ROLES_ERROR;

export interface IGetListRoles extends Action<GET_ROLES> {}

export interface IGetRolesSuccess extends Action<GET_ROLES_SUCCESS> {
  payload: IRole[];
}

export interface IGetRolesError extends Action<GET_ROLES_ERROR> {
  payload: IErrors;
}

/**
 * Get Users ==> Dispatch team
 */
export const GET_DISPATCH_TEAM = 'GET_DISPATCH_TEAM';
export type GET_DISPATCH_TEAM  = typeof GET_DISPATCH_TEAM;

export const GET_DISPATCH_TEAM_SUCCESS = 'GET_DISPATCH_TEAM_SUCCESS';
export type GET_DISPATCH_TEAM_SUCCESS  = typeof GET_DISPATCH_TEAM_SUCCESS;

export const GET_DISPATCH_TEAM_ERROR = 'GET_DISPATCH_TEAM_ERROR';
export type GET_DISPATCH_TEAM_ERROR  = typeof GET_DISPATCH_TEAM_ERROR;

export interface IGetDispatchTeamAction extends Action<GET_DISPATCH_TEAM> {
  payload: number;
}

export interface IGetDispatchTeamSuccessAction extends Action<GET_DISPATCH_TEAM_SUCCESS> {
  payload: IDispatchTeamData;
}

export interface IGetDispatchTeamErrorAction extends Action<GET_DISPATCH_TEAM_ERROR> {
  payload: IErrors;
}

export type getUsersActionTypes =
    ICreateNewUser
  | ICreateNewUserSuccess
  | ICreateNewUserError
  | IGetUsersAction
  | IGetUsersSuccessAction
  | IGetUsersErrorAction
  | IUserDisableEnableAction
  | IUserDisableEnableSuccessAction
  | IUserDisableEnableErrorAction
  | IGetListRoles
  | IGetRolesSuccess
  | IGetRolesError
  | IUpdateUserAction
  | IUpdateUserSuccessAction
  | IUpdateUserErrorAction
  | IGetUserAction
  | IGetUserSuccessAction
  | IGetUserErrorAction
  | IGetDispatchTeamAction
  | IGetDispatchTeamSuccessAction
  | IGetDispatchTeamErrorAction;
