import { Icon } from 'antd';
import React, { useState } from 'react';

import './people-enabler.less';

interface IEnabledPeopleTogglerProps {
  onActionChange: (value: boolean[]) => void;
}

export const EnabledPeopleToggler: React.FC<IEnabledPeopleTogglerProps> = ({ onActionChange }) => {

  const [status, setStatus] = useState('active');

  const handleTogglerClick = (btnStatus: string, value: boolean[]) => {
    setStatus(btnStatus);
    onActionChange(value);
  };

  return (
    <div className="custom-status-filter">
      <div className="toggler">
        <div className="toggler-content has-card-view">
          <span className={`toggler-btn ${ status === 'active' ? 'active' : '' }`} onClick={ () => handleTogglerClick('active', [true]) }>
            <Icon type="check-circle" />
            {' '}
            Activé
          </span>
          <span className={`toggler-btn ${ status === 'inactive' ? 'active' : '' }`} onClick={ () => handleTogglerClick('inactive', [false]) }>
            <Icon type="stop" />
            {' '}
            Désactivé
          </span>
          <span className={`toggler-btn ${ status === 'all' ? 'active' : '' }`} onClick={ () => handleTogglerClick('all', [true, false]) }>
            <Icon type="appstore" />
            {' '}
            Tout
          </span>
        </div>
      </div>
    </div>
  );
};
