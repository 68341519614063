import { Layout, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ConnectedUserSider } from '../../../components/ConnectedUserSider';
import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionsHandler } from '../../../components/permissions-handlers/PermissionsHandler';
import { SiderClientsList } from '../../../components/SiderClientsList';
import { SiderProfileDetails } from '../../../components/SiderProfileDetails';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { addClient, getListClients } from '../../../store/clients/actions';
import { IClient, IPageProps, IPermissionsHandler } from '../../../utils/types';
import { siderWidth } from '../../../utils/vars';

import { AddClientForm } from './components/AddClientForm';

const { Sider, Content } = Layout;

// tslint:disable-next-line: no-empty-interface
interface IAddClientProps extends IPageProps {}

const AddClientInner: React.FC<IAddClientProps & ConnectedProps<typeof connector>> = ({
  route,
  isLoading,
  isCreated,
  errors,
  listClients,
  connectedUser,
  addClient: addNewClient,
  getListClients: getListClientsAction,
}) => {

  const permissions: IPermissionsHandler | null = useContext(PermissionContext);

  const getNewClient = (client: IClient) => {
    addNewClient(client);
  };

  useEffect(() => {
    getListClientsAction({
      status: null,
    });
    // tslint:disable-next-line: align
  }, [getListClientsAction]);

  return (
    <>
      <CustomBreadcrumb route={route} />
      <PageTitle title={ route.title } />
      <Layout className="page-layout has-card-view">
        <Sider width={siderWidth}>
          {!!connectedUser && <ConnectedUserSider userData={connectedUser} />}
          <SiderClientsList listPeople={listClients} />
        </Sider>

        <Layout>
          <Content>
            <PermissionsHandler
              resourceName="Client"
              blocPermission="CAN_CREATE"
              userPermissions={permissions}
              redirectOption>
              <Spin spinning={isLoading}>
                <AddClientForm
                  onSubmitNewClient={getNewClient}
                  isCreated={isCreated}
                  errors={errors}
                />
              </Spin>
            </PermissionsHandler>
          </Content>
        </Layout>

        <Sider width={siderWidth} style={{ textAlign: 'center' }}>
          {!!connectedUser && <SiderProfileDetails userData={connectedUser} />}
        </Sider>
      </Layout>
    </>
  );
};

const mapDispatchToProps = {
  addClient,
  getListClients,
};

const mapStateToProps = (state: GlobalState) => ({
  isLoading: state.clients.isLoading,
  isCreated: state.clients.isCreated,
  errors: state.clients.errors,
  connectedUser: state.connectedUser.userData,
  listClients: state.clients.listClients,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const AddClientPage = connector(AddClientInner);
