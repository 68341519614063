import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { connect, ConnectedProps, useStore } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { handleAxiosAuth, handleAxiosErrors } from './api';
import './App.less';
import { PermissionContext } from './context/permissions/permissions-create-context';
import { formattedMessages } from './i18n';
import { Routes } from './routes';
import { GlobalState } from './store';
import { alertHandler } from './store/alerts/alert-handler';
import { getListClients } from './store/clients/actions';
import { getConnectedUserData, getConnectedUserPermissions } from './store/connectedUser/actions';
import './themes/dark.less';

// tslint:disable-next-line: no-empty-interface
interface IAppProps {}

const AppInner: React.FC<IAppProps & ConnectedProps<typeof connector>> = ({
  isLoggedIn,
  permissions,
  connectedUser,
  getConnectedUserData: getConnectedUserDataAction,
  getConnectedUserPermissions: getConnectedUserPermissionsAction,
  getListClients: getListClientsAction,
}) => {
  useEffect(() => {
    handleAxiosErrors();
  },        []);

  useEffect(() => {
    if (isLoggedIn) getConnectedUserPermissionsAction();
  },        [getConnectedUserPermissionsAction, isLoggedIn]);

  const store = useStore();

  useEffect(() => {
    if (isLoggedIn) {
      getConnectedUserDataAction();
      alertHandler(store, 'users');
      alertHandler(store, 'files');
      alertHandler(store, 'projects');
      getListClientsAction({
        status: [false, true],
      });
    }
  },        [isLoggedIn, store, getListClientsAction, getConnectedUserDataAction]);

  /* Intl */
  const lang = 'fr';
  const getMessages = (language: string) => {
    if (language === 'fr') return formattedMessages.fr;
    return formattedMessages.en;
  };

  return (
    <IntlProvider locale={lang} messages={getMessages(lang)}>
      <ConfigProvider locale={frFR}>
        <Router>
          <PermissionContext.Provider
            value={{
              userRole: connectedUser?.role.slug,
              listPermissions: permissions,
            }}>
            <Routes authorized={isLoggedIn} />
          </PermissionContext.Provider>
        </Router>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapDispatchToProps = {
  getConnectedUserPermissions,
  getListClients,
  getConnectedUserData,
};

const mapStateToProps = (state: GlobalState) => ({
  isLoggedIn: state.auth.isLoggedIn,
  token: state.auth.token,
  permissions: state.connectedUser.permissions,
  connectedUser: state.connectedUser.userData,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const App = connector(AppInner);
