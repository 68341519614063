import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { PageTitle } from '../../../components/PageTitle';
import { Team } from '../../../components/Team';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { getListClients } from '../../../store/clients/actions';
import { createProjectBrief, getAllBudgets, getAllProjectBriefs, updateListBudgets } from '../../../store/project/actions';
import { getTispatchTeam, getUsers } from '../../../store/users/actions';
import { permissionArrayFilter } from '../../../utils/permission-handlers';
import * as roles from '../../../utils/roles-utils';
import { IBriefToSave, IBudget, IBudgetChangeStatus, IPageProps, IPermissionsHandler } from '../../../utils/types';

import { DispatchTeamBudgets } from './components/DispatchTeamBudgets';

const DispatchTeamInner: React.FC<{} & ConnectedProps<typeof connector> & RouteComponentProps & IPageProps> = ({
  route,
  users,
  clients,
  budgets,
  listBriefs,
  isUsersLoading,
  isClientsLoading,
  isBudgetsLoading,
  isLoadingBriefs,
  connectedUser,
  dispatchTeam,
  history,
  getUsers: getUsersAction,
  getListClients: getListClientsAction,
  getAllProjectBriefs: getAllProjectBriefsAction,
  createProjectBrief: createProjectBriefAction,
  getAllBudgets: getAllBudgetsAction,
  getTispatchTeam: getTispatchTeamAction,
  updateListBudgets: updateListBudgetsAction,
}) => {
  const [userBusgets, setUserBudgets] = useState<IBudget[]>([]);

  const permissions: IPermissionsHandler | null = useContext(PermissionContext);

  useEffect(() => {
    getTispatchTeamAction(1);
  },        [getTispatchTeamAction]);

  useEffect(() => {
    if (connectedUser && !!permissions) {
      const filteredBudgets = permissionArrayFilter(budgets, permissions, 'Project', 'CAN_VIEW', connectedUser.id);
      if (!!filteredBudgets) setUserBudgets(filteredBudgets);
    }
  },        [budgets, permissions, connectedUser]);

  useEffect(() => {
    getUsersAction();
  },        [getUsersAction]);

  useEffect(() => {
    getListClientsAction({
      status: [true, false],
    });
  },        [getListClientsAction]);

  useEffect(() => {
    getAllBudgetsAction({
      page: 1,
    });
  },        [getAllBudgetsAction]);

  // Callbacks
  const getBudgetDetails = (budget: IBudget) => {
    if (budget) getAllProjectBriefsAction(budget.directory);
  };

  const handleNewBriefSubmit = (brief: IBriefToSave) => createProjectBriefAction(brief);

  const handleArchiveProject = (project: IBudgetChangeStatus) => updateListBudgets(project);

  if (connectedUser?.role.slug === roles.ROLE_RETOUCHER) history.replace('/unauthorized');

  return (
    <>
      <CustomBreadcrumb route={route} />
      <PageTitle title={route.title} />
      <Row gutter={16}>
        <Col xl={10} md={24}>
          <Team
            blockTitle="Team"
            ViewAs="cards"
            blockSize={10}
            dispatchTeam={dispatchTeam}
          />
        </Col>
        <Col xl={14} md={24}>
          <DispatchTeamBudgets
            users={users}
            clients={clients}
            budgets={userBusgets}
            isLoading={isUsersLoading || isClientsLoading || isBudgetsLoading}
            listBriefs={listBriefs}
            isLoadingBriefs={isLoadingBriefs}
            onBudgetDetailsClick={getBudgetDetails}
            onNewBriefSubmit={handleNewBriefSubmit}
            onArchiveProject={ handleArchiveProject }
          />
        </Col>
      </Row>
    </>
  );
};

const mapDispatchToProps = {
  getListClients,
  getUsers,
  getAllProjectBriefs,
  createProjectBrief,
  getAllBudgets,
  getTispatchTeam,
  updateListBudgets,
};

const mapStateToProps = (state: GlobalState) => ({
  users: state.listUsers.users,
  isUsersLoading: state.listUsers.loading,
  clients: state.clients.listClients,
  isClientsLoading: state.clients.isLoading,
  budgets: state.project.listProjects,
  listBriefs: state.project.briefs.briefList,
  isLoadingBriefs: state.project.briefs.isLoading,
  isBudgetsLoading: state.project.isLoading,
  connectedUser: state.connectedUser.userData,
  dispatchTeam: state.listUsers.dispatchTeam,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const DispatchTeam = connector(DispatchTeamInner);
