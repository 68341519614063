import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as requests from './../../api';
import {
  getConnectedUserDataError,
  getConnectedUserDataSuccess,
  getConnectedUserPermissionsError,
  getConnectedUserPermissionsSuccess,
} from './actions';
import {
  CONNECTED_USER_GET_PERMISSIONS,
  GET_CONNECTED_USER_DATA,
} from './types';

function* getConnectedUserData() {
  try {
    const response = yield requests.getConnectedUserData(yield select(state => state.auth.token));
    yield put(getConnectedUserDataSuccess(response.data));
  } catch (error) {
    yield put(getConnectedUserDataError(error));
  }
}

export function* watchGetConnectedUserData() {
  yield takeEvery(GET_CONNECTED_USER_DATA, getConnectedUserData);
}

/**
 * Get Permissions
 */
function* getPerrmissions() {
  try {
    const response = yield requests.getPerrmissions(yield select(state => state.auth.token));
    yield put(getConnectedUserPermissionsSuccess(response.data));
  } catch (error) {
    getConnectedUserPermissionsError(error);
  }
}

export function* watchGetPermissions() {
  yield takeLatest(CONNECTED_USER_GET_PERMISSIONS, getPerrmissions);
}
