import { message } from 'antd';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as requests from '../../api';

import {
  addBudgetError,
  addBudgetSuccess,
  createProjectBrief,
  createProjectBriefError,
  createProjectBriefSuccess,
  getAllBudgets as getAllBudgetsAction,
  getAllBudgetsError,
  getAllBudgetsSuccess,
  getAllProjectBriefsError,
  getAllProjectBriefsSuccess,
  getBudgetsByClientError,
  getBudgetsByClientSuccess,
  getCheckpointsError,
  getCheckpointsSuccess,
  getFileBriefs as getFileBriefsAction,
  getFileBriefsError,
  getFileBriefsSuccess,
  getInProgressBudgetsSuccess,
  getOneBudgetsError,
  getOneBudgetsSuccess,
  getProjectNumberPerStatusError,
  getProjectNumberPerStatusSuccess,
  getUserBudgetsError,
  getUserBudgetsSuccess,
  updateListBudgetsError,
  updateListBudgetsSuccess,
  updateProjectError,
  updateProjectSuccess,
} from './actions';
import {
  ADD_PROJECT_BRIEF,
  BUDGET_ADD,
  BUDGET_GET_ALL,
  BUDGET_GET_BY_CLIENT,
  BUDGET_GET_ONE,
  GET_CHECKPOINTS,
  GET_FILE_BRIEFS,
  GET_IN_PROGRESS_BUDGETS,
  GET_PROJECTS_NUMBER_PER_STATUS,
  GET_PROJECT_BRIEFS,
  GET_USER_BUDGETS,
  IBudgetAddAction,
  IBudgetGetAllAction,
  IBudgetGetByClient,
  IBudgetGetOneAction,
  IGetFileBriefsAction,
  IGetInProgressBudgetsAction,
  IGetProjectBriefAllAction,
  IGetProjectNumberPerStatusAction,
  IGetUserBudgetsAction,
  IProjectBriefAddAction,
  IUpdateListBudgetsAction,
  IUpdateProjectAction,
  UPDATE_LIST_BUDGETS,
  UPDATE_PROJECT,
} from './types';

/**
 * Add Budget
 */
function* addBudget({ payload: budget }: IBudgetAddAction) {
  try {
    const result = yield requests.addProject(budget, yield select(state => state.auth.token));
    yield put(addBudgetSuccess());
    message.success(`Le projet ${budget.name} a été créé avec succès.`);
    if (budget.additionalInfo)
      yield put(
        createProjectBrief({
          additionalInfo: budget.additionalInfo,
          project: `/projects/${result.data.id}`,
        })
      );
  } catch (error) {
    yield put(addBudgetError(error));
  }
}

export function* watchAddBudget() {
  yield takeLatest(BUDGET_ADD, addBudget);
}

/**
 * Get Budgets
 */
function* getAllBudgets({ payload: term }: IBudgetGetAllAction) {
  try {
    const result = yield requests.getProjects(term, yield select(state => state.auth.token));
    yield put(getAllBudgetsSuccess({
      page: term.page,
      budgets: result.data,
    }));
  } catch (error) {
    yield put(getAllBudgetsError(error));
  }
}

export function* watchGetAllBudgets() {
  yield takeLatest(BUDGET_GET_ALL, getAllBudgets);
}

/**
 * Get Budget by ID
 */
function* getSingleBudget({ payload: id }: IBudgetGetOneAction) {
  try {
    const result = yield requests.getProject(id, yield select(state => state.auth.token));
    yield put(getOneBudgetsSuccess(result.data));
  } catch (error) {
    yield put(getOneBudgetsError(error));
  }
}

export function* watchGetSingleBudget() {
  yield takeLatest(BUDGET_GET_ONE, getSingleBudget);
}

/**
 * Get Budgets by client ID
 */
function* getBudgetsByClient({ payload: client }: IBudgetGetByClient) {
  try {
    const result = yield requests.getClientProjects(client, yield select(state => state.auth.token));
    yield put(getBudgetsByClientSuccess(result.data));
  } catch (error) {
    yield put(getBudgetsByClientError(error));
  }
}

export function* watchGetBudgetsByClient() {
  yield takeLatest(BUDGET_GET_BY_CLIENT, getBudgetsByClient);
}

/**
 * Get budgets by connected user
 */
function* getUserBudgets({ payload: term }: IGetUserBudgetsAction) {
  try {
    const res = yield requests.getUserProjects(term, yield select(state => state.auth.token));
    yield put(getUserBudgetsSuccess({
      budgets: res.data,
      page: term.page,
    }));
  } catch (error) {
    getUserBudgetsError(error);
  }
}

export function* watchGetUserBudgets() {
  yield takeLatest(GET_USER_BUDGETS, getUserBudgets);
}

/**
 * Update status of list of budgets
 */
function* updateBudgetList({ payload: budgets }: IUpdateListBudgetsAction) {
  try {
    yield requests.updateProjectsStatus(budgets, yield select(state => state.auth.token));
    yield put(updateListBudgetsSuccess());
    yield put(getAllBudgetsAction({
      page: 1,
    }));
    message.destroy();
    message.success(`Le${budgets.listIds.length > 1 ? 's' : ''} projet${budgets.listIds.length > 1 ? 's sont' : ' à'} bien passé vers le statut ${budgets.status}.` , 10);
  } catch (errors) {
    message.destroy();
    if (errors.general.length) errors.general.forEach((error: string) => {
      return message.warning(error, 10);
    });
    yield put(updateListBudgetsError(errors));
  }
}

export function* watchUpdateBudgetList() {
  yield takeLatest(UPDATE_LIST_BUDGETS, updateBudgetList);
}

/**
 * Get checkpoints
 */
function* getCheckpoints() {
  try {
    const result = yield requests.getCheckpoint(yield select(state => state.auth.token));
    yield put(getCheckpointsSuccess(result.data));
  } catch (error) {
    yield put(getCheckpointsError(error));
  }
}

export function* watchGetCheckpoints() {
  yield takeLatest(GET_CHECKPOINTS, getCheckpoints);
}

/**
 * Add Brief
 */
function* addBrief({ payload: brief }: IProjectBriefAddAction) {
  try {
    yield requests.addBrief(brief, yield select(state => state.auth.token));
    yield put(createProjectBriefSuccess());
    message.success('Le brief a été créé avec succès.');
  } catch (error) {
    yield put(createProjectBriefError(error));
  }
}

export function* watchAddBrief() {
  yield takeLatest(ADD_PROJECT_BRIEF, addBrief);
}

/**
 * Get Briefs
 */
function* getBriefs({ payload: folder }: IGetProjectBriefAllAction) {
  try {
    const result = yield requests.getBriefList(folder, yield select(state => state.auth.token));
    yield put(getAllProjectBriefsSuccess(result.data));
  } catch (error) {
    yield put(getAllProjectBriefsError(error));
  }
}

export function* watchGetBriefs() {
  yield takeLatest(GET_PROJECT_BRIEFS, getBriefs);
}

/**
 * In progress budgets
 */
function* getInProgressBudgets({ payload: byStatus }: IGetInProgressBudgetsAction) {
  try {
    const result = yield requests.getProjectsByStatus(byStatus, yield select(state => state.auth.token));
    yield put(getInProgressBudgetsSuccess(result.data));
  } catch (error) {
    yield put(getCheckpointsError(error));
  }
}

export function* watchGetInProgressBudgets() {
  yield takeLatest(GET_IN_PROGRESS_BUDGETS, getInProgressBudgets);
}

/**
 * Get File Briefs
 */
function* getFileBriefs({ payload: terms }: IGetFileBriefsAction) {
  try {
    const result = yield requests.getBriefsByFile(terms, yield select(state => state.auth.token));
    yield put(getFileBriefsSuccess(result.data));
  } catch (error) {
    yield put(getFileBriefsError(error));
  }
}

export function* watchGetFileBriefs() {
  yield takeLatest(GET_FILE_BRIEFS, getFileBriefs);
}

/**
 * Get number of project per status
 */
function* getProjectNumberPerStatus({ payload: statusList }: IGetProjectNumberPerStatusAction) {
  try {
    const res = yield requests.getProjectNumberPerStatus(statusList, yield select(state => state.auth.token));
    yield put(getProjectNumberPerStatusSuccess(res.data));
  } catch (error) {
    yield put(getProjectNumberPerStatusError(error));
  }
}

export function* watchGetProjectNumberPerStatus() {
  yield takeLatest(GET_PROJECTS_NUMBER_PER_STATUS, getProjectNumberPerStatus);
}

/**
 * Update Project
 */
function* updateProject({ payload: budget }: IUpdateProjectAction) {
  try {
    yield requests.updateProject(budget, yield select(state => state.auth.token));
    yield put(updateProjectSuccess());
    message.destroy();
    message.success(`Le projet ${ budget.name } a été modifié avec succès.`);
    if (budget.additionalInfo)
    yield put(
      createProjectBrief({
        additionalInfo: budget.additionalInfo,
        project: `/projects/${budget.id}`,
      })
    );
  } catch (error) {
    yield put(updateProjectError(error));
  }
}

export function* watchUpdateProject() {
  yield takeLatest(UPDATE_PROJECT, updateProject);
}
