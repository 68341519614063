import { Button, Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { IConnectedUserData } from '../../store/connectedUser/types';
import { CustomAvatar } from '../CustomAvatar';

import './connected-user-details.less';

interface ISiderProfileDetailsProps {
  userData: IConnectedUserData;
}

export const SiderProfileDetails: React.FC<ISiderProfileDetailsProps> = ({ userData }) => {

  return (
    <div className="connected-user-details">
      <Row>
        <Col>
          <Link to={`/team/${userData.id}`}>
            <Button className="profile-anchor" type="link" block icon="search">
              <strong> Voir Mon profil</strong>
            </Button>
          </Link>
        </Col>

        <Col>
          <CustomAvatar size={100} src={userData.avatar?.path} type="user" />
        </Col>

        <Col>
          <h3 className="username">
            {userData.firstName} {userData.lastName}
          </h3>
          <h4 className="occupation">{userData.role.title}</h4>
        </Col>

        <Col>
          <div className="ref">Adresse:</div>
          <div className="val">{userData.address}</div>
        </Col>

        <Col>
          <div className="ref">Adresse Email:</div>
          <div className="val email">{userData.email}</div>
        </Col>

        <Col>
          <div className="ref">Téléphone:</div>
          <div className="val">{userData.phone.slice(0, 20)}</div>
        </Col>

        {userData.birthday && (
          <Col>
            <div className="ref">Anniversaire:</div>
            <div className="val">{moment(userData.birthday).format('DD-MM-YYYY')}</div>
          </Col>
        )}
        <Link to={`/update-profile/${userData.id}`}>
          <Button shape="round" className="btn-success">
            Modifier mon profil
          </Button>
        </Link>
      </Row>
    </div>
  );
};
