import { Reducer } from 'redux';

import {
  ALERT_GET_NOTIFICATIONS,
  ALERT_GET_TO_BE_SENT_FILES,
  ALERT_GET_TO_CHECK_FILES,
  ALERT_REQUEST_SUBSCRIPTION_ERROR,
  ALERT_RESET_BOTIFICATIONS_COUNTER,
  ALERT_RESET_CHECK_FILES_COUNTER,
  ALERT_RESET_SENT_FILES_COUNTER,
  IAlert,
  IAlertActionType,
} from './types';

const initialState: IAlert = {
  filesToSend: {
    items: [],
    newItems: 0,
  },
  filesToCheck: {
    items: [],
    newItems: 0,
  },
  notifications: {
    items: [],
    newItems: 0,
  },
  isLoading: false,
  errors: null,
};

export const alertReducer: Reducer<typeof initialState, IAlertActionType> = (state = initialState, action) => {
  switch (action.type) {
    case ALERT_GET_TO_BE_SENT_FILES:
      return {
        ...state,
        filesToBenSent: {
          ...state.filesToSend,
          items: [...state.filesToSend.items, action.payload],
          newItems: state.filesToSend.newItems + 1,
        },
      };

    case ALERT_GET_TO_CHECK_FILES:
      return {
        ...state,
        filesToCheck: {
          ...state.filesToCheck,
          items: [...state.filesToCheck.items, action.payload],
          newItems: state.filesToCheck.newItems + 1,
        },
      };

    case ALERT_GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          items: [...state.notifications.items, action.payload],
          newItems: state.notifications.newItems + 1,
        },
      };

    case ALERT_RESET_SENT_FILES_COUNTER:
      return {
        ...state,
        filesToSend: {
          ...state.filesToSend,
          newItems: 0,
        },
      };

    case ALERT_RESET_CHECK_FILES_COUNTER:
      return {
        ...state,
        filesToCheck: {
          ...state.filesToCheck,
          newItems: 0,
        },
      };

    case ALERT_RESET_BOTIFICATIONS_COUNTER:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          newItems: 0,
        },
      };

    case ALERT_REQUEST_SUBSCRIPTION_ERROR:
      return { ...state, errors: action.payload };

    default:
      return state;
  }
};
