import { Icon, Progress, Table } from 'antd';
// tslint:disable-next-line: no-implicit-dependencies
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { TableStatusCounterFooter } from '../../../../components/TableStatusCounterFooter';
import { permissionArrayFilter } from '../../../../utils/permission-handlers';
import { renderPaginationItems } from '../../../../utils/table-pagination-item-render';
import { IBudget, IClient, IPermissionsHandler } from '../../../../utils/types';

import './in-progress-budgets.less';

const { Column } = Table;

interface IInProgressBudgetsProps {
  clients: IClient[];
  budgets: IBudget[];
  // location is added when you have special processing for a specific page
  location?: string;
  isLoading: boolean;
  permissions: IPermissionsHandler;
  connectedUser: string;
  onBudgetClick: (budget: IBudget) => void;
}

export const InProgressBudgets: React.FC<IInProgressBudgetsProps> = ({
  clients,
  budgets,
  location,
  isLoading,
  permissions,
  connectedUser,
  onBudgetClick,
}) => {

  const [listBudgets, setListBudgets] = useState<IBudget[]>([]);

  // Get target budget
  const getTargetBudget = (budget: IBudget) => {
    onBudgetClick(budget);
  };

  // Return Client name
  const getClientName = (clientId: string) => {
    return clientId && clients?.filter(({ id }) => id === clientId.replace('/clients/', ''))[0]?.companyName;
  };

  useEffect(() => {
    const filteredBudgets = permissionArrayFilter(budgets, permissions, 'Project', 'CAN_VIEW', connectedUser);
    if (!!filteredBudgets) setListBudgets(filteredBudgets);
  },        [budgets, permissions, connectedUser]);

  return (
    <div className="in-progress-budgets small-non-pages-paginator">
      <h1 className="orange-title outer-box-title">Etat budgets en cours</h1>
      <Table
        className="has-card-view"
        rowKey={record => record.id}
        loading={ isLoading }
        dataSource={listBudgets}
        pagination={{
          pageSize: 7,
          hideOnSinglePage: true,
          itemRender: (page, type, original) => renderPaginationItems(page, type, original),
        }}
        footer={() => <TableStatusCounterFooter totalIndicator projects={listBudgets} category="budgets" location={ location } />}
      >
        <Column
          key="client"
          title="Client"
          sorter={(a: IBudget, b: IBudget) => {
            return getClientName(a.client).localeCompare(getClientName(b.client));
          }}
          render={record => (
            <span className="client-name">
              {getClientName(record.client)}
            </span>
          )}
        />

        <Column key="budget" title="Budget" sorter={(a: IBudget, b: IBudget) => a.name.localeCompare(b.name)} render={record => (
          <span className="budget-name" onClick={ () => getTargetBudget(record) }>
            {record.name}
          </span>
        )} />

        <Column
          key="recieved"
          title="Reçus"
          sorter={(a: IBudget, b: IBudget) => a.files.length - b.files.length}
          render={record => record.files.length}
        />

        <Column
          key="deadline"
          title="Deadline"
          sorter={(a: IBudget, b: IBudget) => moment(a.deadline).diff(moment(b.deadline))}
          render={record => moment(record.deadline).format('DD/MM/YYYY')}
        />

        <Column
          key="prio"
          title="Prio"
          sorter={(a: IBudget, b: IBudget) => (a.projectPriority === b.projectPriority ? 0 : a.projectPriority ? -1 : 1)}
          render={record => <Icon type="clock-circle" className={`priority ${ record.projectPriority ? 'is-prio' : '' }`} />}
        />

        <Column
          key="progress"
          title="Progression"
          sorter={(a: IBudget, b: IBudget) => a.percentageCheckedFile - b.percentageCheckedFile}
          render={record => (
            <Progress
              percent={record.percentageCheckedFile}
              size="small"
              strokeLinecap="square"
              strokeWidth={2}
              className={``}
              successPercent={ record.percentageCheckedFile }
            />
          )}
        />
      </Table>
    </div>
  );
};
