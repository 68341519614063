import { Button, Checkbox, Col, Dropdown, Form, Icon, Menu, Row, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormComponentProps } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CustomTabsSelector } from '../../../../../components/CustomTabsSelector';
import { PermissionsHandler } from '../../../../../components/permissions-handlers/PermissionsHandler';
import { IBudget, IClient, IPermissionsHandler } from '../../../../../utils/types';

import './budget-filter.less';

const { Option, OptGroup } = Select;

interface IBudgetFilterProps extends FormComponentProps {
  byStatus: boolean;
  defaultTabs: string[];
  permissions: IPermissionsHandler | null;
  budgets: IBudget[];
  clients: IClient[];
  onFilterTabsStatusChange: (status: string[]) => void;
  onFilterFieldsChange: (fields: string[]) => void;
  onBudgetPerPageChange: (perPage: number) => void;
  onItemToCheckChange: (toCheck: string) => void;
  onToggleEnableActions: (isEnable: boolean) => void;
  onActionToApplyChange: (action: string) => void;
  onProgressionChange: (value: string) => void;
  onTriggerBriefModal: () => void;
}

const BudgetFilterInner: React.FC<IBudgetFilterProps> = ({
  byStatus,
  defaultTabs,
  permissions,
  clients,
  budgets,
  onFilterTabsStatusChange,
  onFilterFieldsChange,
  onBudgetPerPageChange,
  onItemToCheckChange,
  onToggleEnableActions,
  onActionToApplyChange,
  onProgressionChange,
  onTriggerBriefModal,
}) => {

  const [isActionActive, setIsActionActive] = useState<boolean>(false);
  const [defaultTabsToShow, setDefaultTabsToShow] = useState<string[]>([]);
  const [actionsValue, setActionValue] = useState('Actions');

  const handleFilterStatusChange = (e: CheckboxChangeEvent) => {
    setIsActionActive(!isActionActive);
  };

  useEffect(() => {
    setIsActionActive(false);
    setActionValue('Actions');
  },        [budgets]);

  useEffect(() => {
    setDefaultTabsToShow(defaultTabs);
  },        [defaultTabs]);

  useEffect(() => {
    onToggleEnableActions(isActionActive);
  },        [isActionActive, onToggleEnableActions]);

  const statusMenu = [
    {
      text: 'Tout',
      ref: 'all',
    },
    {
      text: 'Aucun',
      ref: 'none',
    },
    {
      text: 'À envoyer',
      ref: 'À envoyer',
    },
    {
      text: 'En cours',
      ref: 'En cours',
    },
    {
      text: 'Transféré',
      ref: 'Transféré',
    },
    {
      text: 'Stand-by',
      ref: 'Stand by',
    },
    {
      text: 'Annulé',
      ref: 'Annulé',
    },
  ];

  const actionsOptions = [
    {
      text: 'Archiver',
      ref: 'Archivé',
    },
    {
      text: 'Mettre en stand by',
      ref: 'Stand by',
    },
    {
      text: 'Annuler',
      ref: 'Annulé',
    },
    {
      text: 'Mettre en cours',
      ref: 'En cours',
    },
    {
      text: 'Transférer',
      ref: 'Transféré',
    },
    {
      text: 'Confirmer',
      ref: 'Confirmé',
    },
  ];

  const budgetsNbrToShow: string[] = ['10', '25', '50', '100'];

  const budgetsToShow = [
    {
      title: 'Budgets',
      options: [
        {
          text: 'En cours',
          ref: 'En cours',
        },
        {
          text: 'Transférés',
          ref: 'Transféré',
        },
        {
          text: 'À venir',
          ref: 'À venir',
        },
        {
          text: 'Non entamés',
          ref: 'À faire',
        },
        {
          text: 'En Stand-by',
          ref: 'Stand by',
        },
        {
          text: 'Annulés',
          ref: 'Annulé',
        },
      ],
    },
  ];

  const getCustomSelectValues = (values: string[]) => {
    localStorage.setItem('lunatik-visible-tabs', JSON.stringify(values));
    onFilterTabsStatusChange(values);
  };

  const getCustomSelectProgress = (ref: string) => {
    localStorage.setItem('lunatik-visible-progression', ref);
    onProgressionChange(ref);
  };

  const handleFilterByChange = (values: string[]) => {
    onFilterFieldsChange(values);
  };

  const handleBudgetsPerPageChange = (perPage: number) => {
    onBudgetPerPageChange(perPage);
  };

  const handleActionChange = (value: string) => onActionToApplyChange(value);

  const handleBriefModalTrigger = () => onTriggerBriefModal();

  const menu = (
    <Menu>
      {statusMenu.map(item => {
        return (
          <Menu.Item key={item.ref}>
            <div onClick={() => onItemToCheckChange(item.ref)}>{item.text}</div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Row className="custom-filter budget-filter" gutter={8}>
      <Col span={1}>
        <div className="actions">
          <Checkbox checked={ isActionActive } onChange={handleFilterStatusChange} />{' '}
          <Dropdown overlay={menu} trigger={isActionActive ? ['click'] : ['contextMenu']}>
            <Icon type="caret-down" />
          </Dropdown>
        </div>
      </Col>

      <Col span={3}>
        <Select onChange={handleActionChange} value={ actionsValue } disabled={!isActionActive}  placeholder={ actionsValue || 'Actions'}>
          {actionsOptions.map(option => (
            <Option value={option.ref} key={option.ref}>
              {option.text}
            </Option>
          ))}
        </Select>
      </Col>

      <Col span={4}>
        Afficher{' '}
        <Select className="number-items-to-show" defaultValue={10} onChange={handleBudgetsPerPageChange}>
          {budgetsNbrToShow.map(option => (
            <Option value={parseInt(option, 10)} key={option}>
              {option}
            </Option>
          ))}
        </Select>{' '}
        budgets
      </Col>

      <Col span={3}>
        <Select maxTagCount={0} mode="multiple" placeholder="Budgets à afficher" onChange={handleFilterByChange}>
          {!byStatus && budgetsToShow.map(group => (
            <OptGroup key={group.title} label={<strong>{group.title}</strong>}>
              {group.options.map(option => (
                <Option key={option.ref || option.text} value={option.ref || option.text}>
                  <div>
                    {option.text}
                  </div>
                </Option>
              ))}
            </OptGroup>
          ))}
          <OptGroup key="clients" label={<strong>Clients</strong>}>
          {
            clients.map(client => (
              <Option key={client.id} value={`/clients/${ client.id }`}>
                <div>
                  {client.companyName}
                </div>
              </Option>
            ))
          }
          </OptGroup>
        </Select>
      </Col>

      <Col span={4}>
        <CustomTabsSelector
          placeholder="Onglets"
          onTabsValueChange={ getCustomSelectValues }
          onProgressValueChange={ getCustomSelectProgress }
        />
      </Col>

      <PermissionsHandler resourceName="Project" blocPermission="CAN_CREATE" userPermissions={permissions}>
        <Col span={3} offset={3}>
          <Link to="/budgets/new-budget">
            <Button className="new-budget-btn" icon="plus">
              <strong>Nouveau Budget</strong>
            </Button>
          </Link>
        </Col>
      </PermissionsHandler>

      <PermissionsHandler resourceName="Brief" blocPermission="CAN_CREATE" userPermissions={permissions}>
        <Col span={3}>
          <Link onClick={ handleBriefModalTrigger } to="#">
            <Button className="new-brief-btn" icon="plus">
              <strong>Ajouter Brief</strong>
            </Button>
          </Link>
        </Col>
      </PermissionsHandler>

    </Row>
  );
};

export const BudgetFilter = Form.create<IBudgetFilterProps>()(BudgetFilterInner);
