import { IAlertModalTerm, IErrors } from '../../utils/types';

import { IOpenAlertModalAction, ISynchronizeRequestAction, ISynchronizeRequestErrorAction, ISynchronizeRequestSuccessAction, IToggleOpenModal, OPEN_ALERT_MODAL, SYNCHRONIZE_REQUEST, SYNCHRONIZE_REQUEST_ERROR, SYNCHRONIZE_REQUEST_SUCCESS, TOGGLE_OPEN_MODAL } from './types';

/**
 * Toggle brief modal
 */
export const toggleOpenModal = (modalStatus: boolean): IToggleOpenModal => ({
  type: TOGGLE_OPEN_MODAL,
  payload: modalStatus,
});

/**
 * Open Alert modal
 */
export const openAlertModal = (modalTerms: IAlertModalTerm): IOpenAlertModalAction => ({
  type: OPEN_ALERT_MODAL,
  payload: modalTerms,
});

/**
 * Synchronize the app with the filesystem
 */
export const synchronizeRequest = (pathname: string): ISynchronizeRequestAction => ({
  type: SYNCHRONIZE_REQUEST,
  payload: pathname,
});

export const synchronizeRequestSuccess = (): ISynchronizeRequestSuccessAction => ({
  type: SYNCHRONIZE_REQUEST_SUCCESS,
});

export const synchronizeRequestError = (errors: IErrors): ISynchronizeRequestErrorAction => ({
  type: SYNCHRONIZE_REQUEST_ERROR,
  payload: errors,
});
