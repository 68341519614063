import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { BudgetDetailsModal } from '../../components/BudgetDetailsModal';
import { CustomBreadcrumb } from '../../components/CustomBreadcrumb';
import { PageLoader } from '../../components/PageLoader';
import { PageTitle } from '../../components/PageTitle';
import { PermissionsHandler } from '../../components/permissions-handlers/PermissionsHandler';
import { Team } from '../../components/Team';
import { PermissionContext } from '../../context/permissions/permissions-create-context';
import { GlobalState } from '../../store';
import { getListClients } from '../../store/clients/actions';
import { getFilesToBeChecked, getFilesToBeSent } from '../../store/file/actions';
import { getAllBudgets, getAllProjectBriefs, getCheckpoints, getInProgressBudgets, resetCheckpoint, updateListBudgets } from '../../store/project/actions';
import { openAlertModal, toggleOpenModal } from '../../store/settings/actions';
import { getTispatchTeam, getUsers } from '../../store/users/actions';
import { IBudget, IBudgetChangeStatus, IPageProps, IPermissionsHandler } from '../../utils/types';

import { Card } from './components/Card';
import { CheckpointModal } from './components/CheckpointModal';
import { FileStatusCard } from './components/FileStatusCard';
import { InProgressBudgets } from './components/InProgressBudgets';
import './dashboard.less';

// tslint:disable-next-line: no-empty-interface
interface IDashboardProps extends IPageProps {}

const DashboardInner: React.FC<IDashboardProps & ConnectedProps<typeof connector>> = ({
  route,
  checkpoints,
  isCheckpointLoading,
  clients,
  budgets,
  InProgressBudgets: InProgressBudgetsList,
  users,
  budgetBriefs,
  isBudgetBriefLodaing,
  isBudgetLoading,
  isClientLoading,
  connectedUser,
  dispatchTeam,
  isAlertModalOpen,
  filesToCheck,
  filesToSend,
  getCheckpoints: getCheckpointsAction,
  getListClients: getListClientsAction,
  getUsers: getUsersAction,
  toggleOpenModal: toggleOpenModalAction,
  openAlertModal: openAlertModalAction,
  resetCheckpoint: resetCheckpointAction,
  getAllProjectBriefs: getAllProjectBriefsAction,
  getInProgressBudgets: getInProgressBudgetsAction,
  getAllBudgets: getAllBudgetsAction,
  getTispatchTeam: getTispatchTeamAction,
  updateListBudgets: updateListBudgetsAction,
  getFilesToBeChecked: getFilesToBeCheckedAction,
  getFilesToBeSent: getFilesToBeSentAction,
}) => {
  const permissions: IPermissionsHandler | null = useContext(PermissionContext);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isDetailsModalVisible, setDetailsModalVisible] = useState(false);
  const [targetBudget, setTargetBudget] = useState<IBudget | null>(null);
  const [clientName, setClientName] = useState('');

  // Get target budget
  const getSelectedBudget = (budget: IBudget) => {
    setDetailsModalVisible(true);
    setTargetBudget(budget);
    setClientName(getClientName(budget.client));
    getAllProjectBriefsAction(budget.directory);
  };

  // Return Client name
  const getClientName = (clientId: string) => {
    return clientId && clients?.filter(({ id }) => id === clientId.replace('/clients/', ''))[0]?.companyName;
  };

  //
  const handleCloseModal = () => setDetailsModalVisible(false);

  const handleCreateBrief = () => {};

  const getCheckpointClickEvent = () => {
    getCheckpointsAction();
  };

  const getModalCloseEvent = () => {
    resetCheckpointAction();
    setIsModalVisible(false);
  };

  useEffect(() => {
    getTispatchTeamAction(1);
  },        [getTispatchTeamAction]);

  useEffect(() => {
    getAllBudgetsAction({
      page: 1,
    });
  },        [getAllBudgetsAction]);

  useEffect(() => {
    getInProgressBudgetsAction(['En cours', 'À faire', 'Stand by', 'Checké', 'À venir']);
  },        [getInProgressBudgetsAction]);

  useEffect(() => {
    getListClientsAction({
      status: [true, false],
    });
  },        [getListClientsAction]);

  useEffect(() => {
    getUsersAction();
  },        [getUsersAction]);

  useEffect(() => {
    if (checkpoints) setIsModalVisible(true);
  },        [checkpoints]);

  useEffect(() => {
    getFilesToBeCheckedAction(1);
    getFilesToBeSentAction(1);
  },        [getFilesToBeCheckedAction, getFilesToBeSentAction]);

  const openBriefModal = () => toggleOpenModalAction(true);

  const handleOpenAlertModal = (status: 'checked' | 'transferred') => {
    openAlertModalAction({
      status,
      isOpen: true,
    });
  };

  const handleArchiveProject = (project: IBudgetChangeStatus) => updateListBudgetsAction(project);

  if (!permissions?.listPermissions && !permissions?.userRole) return <PageLoader />;

  return (
    <>
      <PageTitle title={ route.title } />
      <CustomBreadcrumb route={ route } />
      {/* Cards begins here */}
      <Row type="flex" style={{ margin: '0 -10px' }}>
        <PermissionsHandler blocPermission="CAN_CREATE" resourceName="Project" userPermissions={permissions}>
          <Card decoratorClass="budget" cardIcon="folder-add" cardLinkTo="/budgets/new-budget">
            Nouveau Budget
          </Card>
        </PermissionsHandler>

        <PermissionsHandler blocPermission="CAN_CREATE" resourceName="Brief" userPermissions={permissions}>
          <Card decoratorClass="brief" cardIcon="form"  onCardClick={ openBriefModal }>
            Ajouter Un Brief
          </Card>
        </PermissionsHandler>

        <PermissionsHandler blocPermission="CAN_GENERATE" resourceName="Project" userPermissions={permissions}>
          <Card
            decoratorClass="checkpoint"
            cardIcon={isCheckpointLoading ? 'loading' : 'check-circle'}
            cardLinkTo="#"
            onCardClick={getCheckpointClickEvent}>
            {isCheckpointLoading && 'Loading'} Check Point
          </Card>
        </PermissionsHandler>

        <PermissionsHandler blocPermission="CAN_DISPATCH" resourceName="User" userPermissions={permissions}>
          <Card decoratorClass="dispatch" cardIcon="team" cardLinkTo="/dispatch">
            Dispatch Equipe
          </Card>
        </PermissionsHandler>

        <Card decoratorClass="agenda" cardIcon="calendar" cardLinkTo="#">
          Agenda
        </Card>
      </Row>

      {/* Cards ends here */}

      <Row gutter={[16, 16]} style={{ paddingTop: '20px' }}>
        <Col className="dashboard-left-side" xl={10} md={ 24 }>
          <Row style={{ marginTop: '10px' }} gutter={16} type="flex" justify="start">

            <Col span={ 24 }>
              <Row gutter={[16, 24]}>
                <FileStatusCard
                  vNumber={ filesToCheck.total }
                  icon="file-done"
                  status="checked"
                  onStatusCardClick={ handleOpenAlertModal }
                >
                  Fichiers à Checker
                </FileStatusCard>
                <FileStatusCard
                  vNumber={ filesToSend.total }
                  icon="file-protect"
                  status="transferred"
                  onStatusCardClick={ handleOpenAlertModal }
                >
                  Fichiers à Envoyer
                </FileStatusCard>
              </Row>
            </Col>

            <Col span={ 24 }>
              <Team
                blockTitle="Dispach équipe"
                ViewAs="list"
                blockSize={6}
                dispatchTeam={dispatchTeam}
              />
            </Col>
          </Row>
        </Col>

        <Col className="dashboard-right-side" xl={14}>
          {
            !!connectedUser && (
              <InProgressBudgets
                clients={ clients }
                budgets={ InProgressBudgetsList }
                location="dashboard"
                isLoading={ isBudgetLoading || isClientLoading }
                permissions={ permissions }
                connectedUser={ connectedUser.id }
                onBudgetClick={ getSelectedBudget }
              />
            )
          }
        </Col>
      </Row>

      {/* Budget details Modal */}

      {
        !!targetBudget && (
          <BudgetDetailsModal
            isVisible={isDetailsModalVisible}
            data={{
              budget: targetBudget,
              client: clientName,
              relativeFiles: [],
            }}
            users={ users }
            briefs={budgetBriefs}
            isLoadingBriefs={isBudgetBriefLodaing}
            onModalClose={ handleCloseModal }
            onCreateNewBrief={ handleCreateBrief }
            onArchiveProject={ handleArchiveProject }
          />
        )
      }
      {/* Budget details Modal */}

      {/* Checkpoint POPIN */}
      <CheckpointModal checkpoints={checkpoints} isModalVisible={isModalVisible} onModalClose={getModalCloseEvent} />
      {/* Checkpoint POPIN */}
    </>
  );
};

const mapDispatchToProps = {
  getCheckpoints,
  getListClients,
  getUsers,
  toggleOpenModal,
  resetCheckpoint,
  openAlertModal,
  getAllProjectBriefs,
  getInProgressBudgets,
  getAllBudgets,
  getTispatchTeam,
  updateListBudgets,
  getFilesToBeChecked,
  getFilesToBeSent,
};

const mapStateToProps = (state: GlobalState) => ({
  checkpoints: state.project.checkpoints,
  isCheckpointLoading: state.project.isCheckpointLoading,
  clients: state.clients.listClients,
  budgets: state.project.listProjects,
  InProgressBudgets: state.project.budgetInProgress,
  users: state.listUsers.users,
  budgetBriefs: state.project.briefs.briefList,
  isBudgetBriefLodaing: state.project.briefs.isLoading,
  isBudgetLoading: state.project.isLoading,
  isClientLoading: state.clients.isLoading,
  connectedUser: state.connectedUser.userData,
  dispatchTeam: state.listUsers.dispatchTeam,
  isAlertModalOpen: state.settings.alertModal.isOpen,
  filesToCheck: state.file.filesToCheck,
  filesToSend: state.file.filesToSend,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const Dashboard = connector(DashboardInner);
