import { Card as AntdCard, Col, Icon } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import './card.less';

interface ICardProps {
  decoratorClass: string;
  cardIcon: string;
  cardLinkTo?: string;
  onCardClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Card: React.FC<ICardProps> = (props) => {
  const {
    children,
    decoratorClass,
    cardIcon,
    cardLinkTo,
    onCardClick,
  } = props;

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onCardClick) onCardClick(e);
  };

  return (
    <Col className="dashboard-card-wrapper">
      <Link to={ cardLinkTo ? cardLinkTo : '#' }>
        <AntdCard className={`dashboard-shortcut-card ${ decoratorClass }`} onClick={ handleCardClick }>
          <Icon type={ cardIcon } />
          <h4>{ children }</h4>
        </AntdCard>
      </Link>
    </Col>
  );
};
