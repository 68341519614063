import { Button, Checkbox, Col, DatePicker, Form, Icon, Input, Layout, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
// tslint:disable-next-line: no-implicit-dependencies
import moment from 'moment';
import React, { FormEvent, useEffect, useState } from 'react';

import { Briefs } from '../../../../../components/Briefs';
import { FormElementPlaceholder } from '../../../../../components/FormElementPlaceholder';
import TeamSelect from '../../../../../components/TeamSelect';
import { IUser } from '../../../../../store/users/types';
import { filenameValidator } from '../../../../../utils/client-utils';
import { generateGroup } from '../../../../../utils/map-users-by-role';
import * as roles from '../../../../../utils/roles-utils';
import { IBrief, IBudgetToSave, IClient, IErrors, IFileSystemClient } from '../../../../../utils/types';
import { defaultAvatar } from '../../../../../utils/vars';
import { CustomAvatar } from '../../../../../components/CustomAvatar';

// tslint:disable-next-line: ordered-imports

const { Option } = Select;
const { TextArea } = Input;
const { Header } = Layout;

interface INewBudgetFormProps extends FormComponentProps {
  briefs: IBrief[];
  users: IUser[];
  folders: string[];
  clients: IClient[];
  fsClients: IFileSystemClient | null;
  isLoading: boolean;
  isCreated: boolean;
  isLoadingBriefs: boolean;
  connectedUserName?: string;
  budgetErrors: IErrors | null;
  onChangeFolder: (folder: string) => void;
  onSubmitNewBudget: (budget: IBudgetToSave) => void;
}

const NewBudgetFormInner: React.FC<INewBudgetFormProps> = ({
  clients,
  fsClients,
  folders,
  briefs,
  users,
  form,
  isLoading,
  isCreated,
  isLoadingBriefs,
  budgetErrors,
  onChangeFolder,
  onSubmitNewBudget,
  connectedUserName,
}) => {
  const { getFieldDecorator, resetFields } = form;

  const [periority, setPeriority] = useState(false);
  const [startDate, setStartDate] = useState<null | moment.Moment>(null);
  const [deadline, setDeadline] = useState<null | moment.Moment>(null);
  const [briefsList, setBriefsList] = useState<IBrief[]>([]);
  const [budgetLoader, setBudgetLoader] = useState<boolean[]>([false]);
  const [isSwitchChecked, setSwitchChecked] = useState(false);

  const onPeriorityChange = (e: CheckboxChangeEvent) => {
    setPeriority(e.target.checked);
  };

  const handleSubmitNewBudget = (e: FormEvent) => {
    e.preventDefault();
    // tslint:disable-next-line: no-any
    form.validateFields((err: any, values: any) => {
      if (!err) {
        let projectName;
        if (values.directory) {
          projectName = values.directory.split('/');
          projectName = projectName[projectName.length - 1];
        }
        const budget: IBudgetToSave = {
          name: values.name || projectName,
          status: values.status,
          retouchType: values.retouchType,
          projectPriority: periority,
          startDate: (values.startDate as moment.Moment).format('YYYY-MM-DD HH:mm:ss'),
          deadline: (values.deadline as moment.Moment).format('YYYY-MM-DD HH:mm:ss'),
          client: values.client,
          directory: values.directory,
          projectManager: values.projectManager,
          productionManager: values.productionManager,
          retoucher: values.retoucher,
          additionalInfo: values.additionalInfo,
        };
        onSubmitNewBudget(budget);
      }
    });
  };

  useEffect(() => {
    resetFields(['directory']);
  },        [isSwitchChecked, resetFields]);

  useEffect(() => {
    setBriefsList(briefs);
  },        [briefs]);

  useEffect(() => {
    setBudgetLoader([...budgetLoader, isLoading]);
  },        [isLoading]);

  useEffect(() => {
    if (isCreated) {
      resetFields();
      setBriefsList([]);
      setPeriority(false);
      setSwitchChecked(false);
    }
  },        [isCreated, resetFields]);

  const disableDeadline = (current: moment.Moment | null) => {
    // Can not select days before today and today
    if (!startDate || !current) return false;
    return startDate > current;
  };

  const disableStartDate = (current: moment.Moment | null) => {
    // Can not select days before today and today
    if (!deadline || !current) return false;
    return deadline < current;
  };

  const handleStartDate = (date: moment.Moment | null, _dateString: string) => {
    setStartDate(date);
  };

  const handleDeadline = (date: moment.Moment | null, _dateString: string) => {
    setDeadline(date);
  };

  return (
    <>

      <Header>
        <Row>
          <Col span={12}>
            <span className="header-title">Créer un nouveau Budget</span>
          </Col>
          <Col span={12}>
            <span className="created-by">
              Ajouter par <strong>{connectedUserName} <Icon type="clock-circle" /></strong>
            </span>
          </Col>
        </Row>
      </Header>

      <Form className="inner-padding" onSubmit={handleSubmitNewBudget}>
        <Row>
          <Col>
            <Form.Item>
              Champs obligatoires <span className="required-field">*</span>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Row>
              <Col>
                <span>
                  <FormElementPlaceholder empty />
                </span>
              </Col>
              <Col>
                <Form.Item
                  {...(budgetErrors?.client && {
                    help: budgetErrors.client,
                    validateStatus: 'error',
                  })}>
                  {getFieldDecorator('client', {
                    rules: [
                      {
                        required: true,
                        message: 'Veuillez sélectionner le nom du client !',
                      },
                    ],
                    validateTrigger: ['onBlur', 'onChange'],
                  })(
                    <Select placeholder={ <FormElementPlaceholder text="-- Client --" /> }>
                      {clients.map((client, i) => {
                        return (
                          <Option key={`client-${i}`} value={`/clients/${client.id}`}>
                            <div>
                              <CustomAvatar size="small" shape="square" src={client.logo} type="client" />
                              {` ${client.companyName}`}
                            </div>
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={12} md={24}>
            <Row>
              <Col span={24}>
                <span>
                  <FormElementPlaceholder empty={ !isSwitchChecked } />
                </span>
              </Col>
              <Col>
                <Form.Item
                  {...(budgetErrors?.name && {
                    help: budgetErrors.name,
                    validateStatus: 'error',
                  })}>
                  {getFieldDecorator('name', {
                    rules: [
                      { required: true, message: 'Veuillez Entrer le nom du projet !' },
                      { validator: filenameValidator },
                    ],
                    validateTrigger: ['onBlur', 'onChange'],
                  })(<Input type="text" placeholder="Nom du projet" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Form.Item
              {...(budgetErrors?.status && {
                help: budgetErrors.status,
                validateStatus: 'error',
              })}>
              {getFieldDecorator('status', {
                rules: [
                  {
                    required: true,
                    message: 'Veuillez selectionner le status du budget !',
                  },
                ],
                validateTrigger: ['onBlur', 'onChange'],
              })(
                <Select placeholder={ <FormElementPlaceholder text="-- Statut --" /> }>
                  <Option value="À venir">À Venir</Option>
                  <Option value="À faire">À Faire</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xl={12} md={24}>
            <Form.Item
              {...(budgetErrors?.retouchType && {
                help: budgetErrors.retouchType,
                validateStatus: 'error',
              })}>
              {getFieldDecorator('retouchType', {
                rules: [
                  {
                    required: true,
                    message: 'Veuillez selectionner un Type !',
                  },
                ],
                validateTrigger: ['onBlur', 'onChange'],
              })(
                <Select placeholder={ <FormElementPlaceholder text="-- Type RT --" /> }>
                  <Option value="Campaign">Campagne</Option>
                  <Option value="Digital">Digital</Option>
                  <Option value="Edito">Edito</Option>
                  <Option value="Gif">Gif</Option>
                  <Option value="Lookbook">Lookbook</Option>
                  <Option value="Video">Video</Option>
                  <Option value="Web">Web</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Item>
              <div className="budget-priority priority-indicator">
                <Checkbox checked={ periority } onChange={onPeriorityChange}>
                  <Icon type="clock-circle" />
                  <span className="priority-indicator-text">Budget Prioritaire</span>
                </Checkbox>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Row gutter={16}>
              <Col span={24}>
                <Icon type="clock-circle" /> <span style={{ marginLeft: '7px' }}>
                  <FormElementPlaceholder text="Date de début" />
                </span>
              </Col>
              <Col span={24}>
                <Form.Item
                  {...(budgetErrors?.startDate && {
                    help: budgetErrors.startDate,
                    validateStatus: 'error',
                  })}>
                  {getFieldDecorator('startDate', {
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Veuillez selectionner une date !',
                      },
                    ],
                  })(
                    <DatePicker
                      showTime
                      placeholder="Selectionner la date de debut"
                      style={{ width: '100%' }}
                      onChange={handleStartDate}
                      disabledDate={disableStartDate}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xl={12} md={24}>
            <Row gutter={16}>
              <Col span={24}>
                <Icon type="clock-circle" /> <span style={{ marginLeft: '7px' }}>
                  <FormElementPlaceholder text="Deadline" />
                </span>
              </Col>
              <Col span={24}>
                <Form.Item
                  {...(budgetErrors?.deadline && {
                    help: budgetErrors.deadline,
                    validateStatus: 'error',
                  })}>
                  {getFieldDecorator('deadline', {
                    rules: [
                      {
                        type: 'object',
                        required: true,
                        message: 'Veuillez selectionner une date !',
                      },
                    ],
                  })(
                    <DatePicker
                      showTime
                      placeholder="Selectionner le deadline"
                      style={{ width: '100%' }}
                      onChange={handleDeadline}
                      disabledDate={disableDeadline}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <Icon type="team" />
            <span style={{ marginLeft: '10px' }}>&#xC9;quipe dédiée:</span>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator('projectManager', {
                rules: [
                  {
                    required: true,
                    message: 'Veuillez selectionner un chef de projet !',
                  },
                ],
              })(
                <TeamSelect
                  users={generateGroup(users, roles.ROLE_PRODUCTION_MANAGER)}
                  placeholder={ <FormElementPlaceholder text="Selectionner un chef de projet" /> }
                  size="large"
                  allowClear
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={12} md={24}>
            <Form.Item>
              {getFieldDecorator('productionManager', {
                rules: [
                  {
                    required: true,
                    message: 'Veuillez selectionner un résponsable budget !',
                  },
                ],
              })(
                <TeamSelect
                  users={generateGroup(users, roles.ROLE_PROJECT_MANAGER)}
                  placeholder={ <FormElementPlaceholder text="Selectionner un résponsable budget" /> }
                  size="large"
                  allowClear
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('retoucher', {
                rules: [
                  {
                    required: true,
                    message: 'Veuillez selectionner des retoucheurs !',
                  },
                ],
              })(
                <TeamSelect
                  users={generateGroup(users, roles.ROLE_RETOUCHER)}
                  placeholder={ <FormElementPlaceholder text="Selectionner des retoucheurs" /> }
                  mode="multiple"
                  size="large"
                  allowClear
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={24} md={24}>
            <Form.Item>
              <Briefs briefs={briefsList} isLoadingBriefs={isLoadingBriefs} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xl={24} md={24}>
            <Form.Item>{getFieldDecorator('additionalInfo')(<TextArea placeholder="Informations complémentaires..." />)}</Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button loading={isLoading} key="submit" className="btn-success" onClick={handleSubmitNewBudget}>
              + Créer Budget
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const NewBudgetForm = Form.create<INewBudgetFormProps>()(NewBudgetFormInner);