import * as roles from '../utils/roles-utils';

import { IBudget, IPermissionsHandler, IUserPermissions } from './types';

export const permissionArrayFilter = (
  dataSource: IBudget[],
  userPermissions: IPermissionsHandler,
  resourceName: string,
  dataPermission: string,
  owner: string = ''
) => {
  if (!!userPermissions && !!userPermissions.listPermissions) {
    const permissions = userPermissions.listPermissions[resourceName]?.filter((resource: (string | null)[]) => resource.includes(dataPermission))[0];
    if (
      permissions.includes('ALL')
      || userPermissions.userRole === roles.ROLE_ADMIN
      || userPermissions.userRole === roles.ROLE_SUPER_ADMIN) return dataSource;
    return dataSource.filter((el: IBudget) => {
      const modifiedOwner = `/users/${ owner }`;
      switch (userPermissions.userRole) {
        case roles.ROLE_RETOUCHER:
          return el.retoucher.includes(modifiedOwner);
        case roles.ROLE_PRODUCTION_MANAGER:
          return el.productionManager === modifiedOwner;
        case roles.ROLE_PROJECT_MANAGER:
          return el.projectManager === modifiedOwner;
        default:
          return [];
      }
    });
  }
};

export const siderPermissionHandler = (userPermissions: IUserPermissions | null, routePermissions?: IUserPermissions) => {
  if (routePermissions && userPermissions) {
    for (const key in routePermissions) {
      if (userPermissions[key]) {
        const userP = userPermissions[key];
        const routeP = routePermissions[key][0];
        return userP.find(p => p.includes(routeP[0]));
      }
    }
  }
};
