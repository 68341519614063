import { Avatar } from 'antd';
import React from 'react';

import { clientDefaultAvatar, defaultAvatar } from '../../utils/vars';

interface ICustomAvatarProps {
  src?: string | null;
  shape?: 'circle' | 'square';
  size?: number | 'small' | 'large' | 'default';
  styleClassName?: string;
  type?: 'user' | 'client';
}

export const CustomAvatar: React.FC<ICustomAvatarProps> = ({
  src,
  type,
  styleClassName,
  ...rest
}) => {
  const altAvatar = type === 'user' ? defaultAvatar : clientDefaultAvatar;
  return (
    <Avatar
      { ...rest }
      src={src ? `${process.env.REACT_APP_SERVER_URL}/${src}` : altAvatar }
      className={ styleClassName }
    />
  );
};
