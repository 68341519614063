import { Action } from 'redux';

import { IClient, IDisableTerm, IErrors, IFileSystemClient } from '../../utils/types';

export interface IGetListClients {
  status: boolean[] | null;
  page?: number;
}

export interface IClients {
  listClients: IClient[];
  singleClient: IClient | null;
  filesystemClients: IFileSystemClient | null;
  isLoading: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
  errors: IErrors | null;
}

// Add client types
export const CLIENTS_ADD_CLIENT = 'CLIENTS_ADD_CLIENT';
export type CLIENTS_ADD_CLIENT  = typeof CLIENTS_ADD_CLIENT;

export const CLIENTS_ADD_CLIENT_SUCCESS = 'CLIENTS_ADD_CLIENT_SUCCESS';
export type CLIENTS_ADD_CLIENT_SUCCESS  = typeof CLIENTS_ADD_CLIENT_SUCCESS;

export const CLIENTS_ADD_CLIENT_ERROR = 'CLIENTS_ADD_CLIENT_ERROR';
export type CLIENTS_ADD_CLIENT_ERROR  = typeof CLIENTS_ADD_CLIENT_ERROR;

// Get list clients types
export const CLIENTS_GET_LIST_CLIENTS = 'CLIENTS_GET_LIST_CLIENTS';
export type CLIENTS_GET_LIST_CLIENTS  = typeof CLIENTS_GET_LIST_CLIENTS;

export const CLIENTS_GET_LIST_CLIENTS_SUCCESS = 'CLIENTS_GET_LIST_CLIENTS_SUCCESS';
export type CLIENTS_GET_LIST_CLIENTS_SUCCESS  = typeof CLIENTS_GET_LIST_CLIENTS_SUCCESS;

export const CLIENTS_GET_FILTERED_LIST_CLIENTS_SUCCESS = 'CLIENTS_GET_FILTERED_LIST_CLIENTS_SUCCESS';
export type CLIENTS_GET_FILTERED_LIST_CLIENTS_SUCCESS  = typeof CLIENTS_GET_FILTERED_LIST_CLIENTS_SUCCESS;

export const CLIENTS_GET_LIST_CLIENTS_ERROR = 'CLIENTS_GET_LIST_CLIENTS_ERROR';
export type CLIENTS_GET_LIST_CLIENTS_ERROR  = typeof CLIENTS_GET_LIST_CLIENTS_ERROR;

/**
 * Get file system clients list
 */
export const GET_FILESYSTEM_CLIENTS = 'GET_FILESYSTEM_CLIENTS';
export type GET_FILESYSTEM_CLIENTS  = typeof GET_FILESYSTEM_CLIENTS;

export const GET_FILESYSTEM_CLIENTS_SUCCESS = 'GET_FILESYSTEM_CLIENTS_SUCCESS';
export type GET_FILESYSTEM_CLIENTS_SUCCESS  = typeof GET_FILESYSTEM_CLIENTS_SUCCESS;

export const GET_FILESYSTEM_CLIENTS_ERROR = 'GET_FILESYSTEM_CLIENTS_ERROR';
export type GET_FILESYSTEM_CLIENTS_ERROR  = typeof GET_FILESYSTEM_CLIENTS_ERROR;

export interface IGetFilesystemClientsAction extends Action<GET_FILESYSTEM_CLIENTS> {}

export interface IGetFilesystemClientsSuccessAction extends Action<GET_FILESYSTEM_CLIENTS_SUCCESS> {
  payload: IFileSystemClient;
}

export interface IGetFilesystemClientsErrorAction extends Action<GET_FILESYSTEM_CLIENTS_ERROR> {
  payload: IErrors;
}

// Get single Client
export const CLIENTS_GET_SINGLE_CLIENT = 'CLIENTS_GET_SINGLE_CLIENT';
export type CLIENTS_GET_SINGLE_CLIENT  = typeof CLIENTS_GET_SINGLE_CLIENT;

export const CLIENTS_GET_SINGLE_CLIENT_SUCCESS = 'CLIENTS_GET_SINGLE_CLIENT_SUCCESS';
export type CLIENTS_GET_SINGLE_CLIENT_SUCCESS  = typeof CLIENTS_GET_SINGLE_CLIENT_SUCCESS;

export const CLIENTS_GET_SINGLE_CLIENT_ERROR = 'CLIENTS_GET_SINGLE_CLIENT_ERROR';
export type CLIENTS_GET_SINGLE_CLIENT_ERROR  = typeof CLIENTS_GET_SINGLE_CLIENT_ERROR;

// Update client types
export const CLIENTS_UPDATE_CLIENT = 'CLIENTS_UPDATE_CLIENT';
export type CLIENTS_UPDATE_CLIENT  = typeof CLIENTS_UPDATE_CLIENT;

export const CLIENTS_UPDATE_CLIENT_SUCCESS = 'CLIENTS_UPDATE_CLIENT_SUCCESS';
export type CLIENTS_UPDATE_CLIENT_SUCCESS  = typeof CLIENTS_UPDATE_CLIENT_SUCCESS;

export const CLIENTS_UPDATE_CLIENT_ERROR = 'CLIENTS_UPDATE_CLIENT_ERROR';
export type CLIENTS_UPDATE_CLIENT_ERROR  = typeof CLIENTS_UPDATE_CLIENT_ERROR;

// Disable Client Type
export const CLIENTS_DISABLE_CLIENT = 'CLIENTS_DISABLE_CLIENT';
export type CLIENTS_DISABLE_CLIENT = typeof CLIENTS_DISABLE_CLIENT;

export const CLIENTS_DISABLE_CLIENT_SUCCESS = 'CLIENTS_DISABLE_CLIENT_SUCCESS';
export type CLIENTS_DISABLE_CLIENT_SUCCESS = typeof CLIENTS_DISABLE_CLIENT_SUCCESS;

export const CLIENTS_DISABLE_CLIENT_ERROR = 'CLIENTS_DISABLE_CLIENT_ERROR';
export type CLIENTS_DISABLE_CLIENT_ERROR = typeof CLIENTS_DISABLE_CLIENT_ERROR;

// Add client action types
export interface IClientsAddClientAction extends Action<CLIENTS_ADD_CLIENT> {
  payload: IClient;
}

export interface IClientsAddClientSuccessAction extends Action<CLIENTS_ADD_CLIENT_SUCCESS> {}

export interface IClientsAddClientErrorAction extends Action<CLIENTS_ADD_CLIENT_ERROR> {
  payload: IErrors;
}

// Get list clients action types
export interface IClientsGetListAction extends Action<CLIENTS_GET_LIST_CLIENTS> {
  payload: IGetListClients;
}

export interface IClientsGetListSuccessAction extends Action<CLIENTS_GET_LIST_CLIENTS_SUCCESS> {
  payload: IClient[];
}

export interface IClientsGetFilteredListSuccessAction extends Action<CLIENTS_GET_FILTERED_LIST_CLIENTS_SUCCESS> {
  payload: IClient[];
}

export interface IClientsGetListErrorAction extends Action<CLIENTS_GET_LIST_CLIENTS_ERROR> {
  payload: IErrors;
}

// Get single client action types
export interface IClientsGetSingleClientAction extends Action<CLIENTS_GET_SINGLE_CLIENT> {
  payload: string;
}

export interface IClientsGetSingleClientSuccessAction extends Action<CLIENTS_GET_SINGLE_CLIENT_SUCCESS> {
  payload: IClient;
}

export interface IClientsGetSingleClientErrorAction extends Action<CLIENTS_GET_SINGLE_CLIENT_ERROR> {
  payload: IErrors;
}

// Update client action types
export interface IClientsUpdateClientAction extends Action<CLIENTS_UPDATE_CLIENT> {
  payload: IClient;
}

export interface IClientsUpdateClientSuccessAction extends Action<CLIENTS_UPDATE_CLIENT_SUCCESS> {}

export interface IClientsUpdateClientErrorAction extends Action<CLIENTS_UPDATE_CLIENT_ERROR> {
  payload: IErrors;
}

// Disable Client action type
export interface IClientsDisableClientAction extends Action<CLIENTS_DISABLE_CLIENT> {
  payload: IDisableTerm;
}
export interface IClientsDisableClientSuccessAction extends Action<CLIENTS_DISABLE_CLIENT_SUCCESS> {
}
export interface IClientsDisableClientErrorAction extends Action<CLIENTS_DISABLE_CLIENT_ERROR> {
  payload: IErrors;
}

export type IClientsActionType =
  IClientsAddClientAction
  | IClientsAddClientSuccessAction
  | IClientsAddClientErrorAction
  | IClientsGetListAction
  | IClientsGetListSuccessAction
  | IClientsGetFilteredListSuccessAction
  | IClientsGetListErrorAction
  | IClientsGetSingleClientAction
  | IClientsGetSingleClientSuccessAction
  | IClientsGetSingleClientErrorAction
  | IClientsUpdateClientAction
  | IClientsUpdateClientSuccessAction
  | IClientsUpdateClientErrorAction
  | IClientsDisableClientAction
  | IClientsDisableClientSuccessAction
  | IClientsDisableClientErrorAction
  | IGetFilesystemClientsAction
  | IGetFilesystemClientsSuccessAction
  | IGetFilesystemClientsErrorAction;
