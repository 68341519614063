import { Breadcrumb, Icon } from 'antd';
import React from 'react';

interface IFilesBreadcrumbProps {
  directory: string;
}

const FilesBreadcrumb: React.FC<IFilesBreadcrumbProps> = ({ directory }) => {
  const bcText = directory.split('/');
  return (
    <Breadcrumb separator={<Icon type="right" />}>
      <Breadcrumb.Item>
        <span className="highlighted">{bcText[0]}</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{bcText[1]}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default FilesBreadcrumb;
