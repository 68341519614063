import React from 'react';
import { Helmet } from 'react-helmet';

interface IPageTitleProps {
  title: string | null;
}

export const PageTitle: React.FC<IPageTitleProps> = ({ title }) => {
  return (
    <Helmet>
      <title>Lunatik Production | {title}</title>
    </Helmet>
  );
};
