import { Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ConnectedUserSider } from '../../../components/ConnectedUserSider';
import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionsHandler } from '../../../components/permissions-handlers/PermissionsHandler';
import { SiderUsersList } from '../../../components/SiderUsersList';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { GlobalState } from '../../../store';
import { getListRoles, getUsers, updateUser } from '../../../store/users/actions';
import { IUser } from '../../../store/users/types';
import { IPageProps, IPermissionsHandler, IUpdateUser } from '../../../utils/types';
import { siderWidth } from '../../../utils/vars';

import { UpdateProfileForm } from './components/UpdateProfileForm';

const { Sider, Content } = Layout;

// tslint:disable-next-line: no-empty-interface
interface IUpdateProfileProps extends IPageProps {}

export const UpdateProfileComponent: React.FC<IUpdateProfileProps &
  ConnectedProps<typeof connector> &
  RouteComponentProps<{ id: string }>> = ({
    route,
    listRoles,
    listUsers,
    connectedUser,
    isLoading,
    isLoadingUpdates,
    errors,
    match,
    history,
    updateUser: updateUserAction,
    getListRolesAction: getListRolesActionAction,
    getUsers: getUsersAction,
  }) => {

  const permissions: IPermissionsHandler | null = useContext(PermissionContext);

  const [targetUser, setTargetUser] = useState<IUser | null>(null);

  const getUpdatedData = (newProfile: IUpdateUser) => {
    updateUserAction(newProfile);
  };

  useEffect(() => {
    const user = listUsers.filter(u => u.id === match.params.id)[0];
    user ? setTargetUser(user) : history.replace('/team');
  },        [listUsers, match, history]);

  useEffect(() => {
    getListRolesActionAction();
  },        [getListRolesActionAction]);

  useEffect(() => {
    getListRolesActionAction();
  },        [getListRolesActionAction]);

  useEffect(() => {
    getUsersAction();
  },        [getUsersAction]);

  return (
    <>
      <CustomBreadcrumb route={route} />
      {
        targetUser && <PageTitle title={`${ route.title } ${targetUser.firstName} ${targetUser.lastName}`} />
      }
      <Layout className="page-layout has-card-view">
        <Sider width={siderWidth}>
          {!!connectedUser && <ConnectedUserSider userData={connectedUser} />}
          {!!connectedUser && <SiderUsersList connectedUserId={connectedUser.id} listPeople={listUsers} />}
        </Sider>

        <Layout>
          <Content>
            {!!connectedUser && (
              <PermissionsHandler
                blocPermission="CAN_PUT"
                resourceName="User"
                userPermissions={permissions}
                owner={connectedUser.id}
                ownershipTarget={match.params.id}
                redirectOption>
                <UpdateProfileForm
                  userData={targetUser}
                  onUpdateProfileClick={getUpdatedData}
                  connectedUser={ connectedUser }
                  listRoles={listRoles}
                  isLoading={isLoading}
                  isLoadingUpdates={ isLoadingUpdates }
                  errors={errors}
                />
              </PermissionsHandler>
            )}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

const mapDispatchToProps = {
  updateUser,
  getListRolesAction: getListRoles,
  getUsers,
};

const mapStateToProps = (state: GlobalState) => ({
  listRoles: state.listUsers.roles.listRoles,
  isLoading: state.connectedUser.loading,
  isLoadingUpdates: state.listUsers.create.isLoading,
  errors: state.listUsers.create.errors,
  listUsers: state.listUsers.users,
  connectedUser: state.connectedUser.userData,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const UpdateProfile = connector(UpdateProfileComponent);
