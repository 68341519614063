import { Layout } from 'antd';
import React, { useContext, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { CustomBreadcrumb } from '../../../components/CustomBreadcrumb';
import { PageLoader } from '../../../components/PageLoader';
import { PageTitle } from '../../../components/PageTitle';
import { PermissionContext } from '../../../context/permissions/permissions-create-context';
import { IRoute } from '../../../routes';
import { GlobalState } from '../../../store';
import { getSingleClient } from '../../../store/clients/actions';
import { addLogFile, dowloadFile, fileDispatchTeam, fileUpdatePriority, getAllFiles, getFilePreview, getFileRetouchLogs, requestVersionPresignedURL, updateFileStatus, updateListFiles } from '../../../store/file/actions';
import { IFileStatus } from '../../../store/file/types';
import { createProjectBrief, getAllProjectBriefs, getFileBriefs, getFileBriefsSuccess, getOneBudget } from '../../../store/project/actions';
import { getUsers } from '../../../store/users/actions';
import { IBriefToSave, IBudgetFiles, IDownloadTerms, IFileChangeStatus, IFileTeamToSave, ILogFile, IPageProps, IPermissionsHandler, IUpdateFilePriority } from '../../../utils/types';

import FilesBreadcrumb from './components/FilesBreadcrumb';
import { ListFiles } from './components/ListFiles';
import './files.less';

const { Content } = Layout;

// tslint:disable-next-line: no-empty-interface
interface IFilesProps {
  route: IRoute;
}

const FilesInner: React.FC<IFilesProps & ConnectedProps<typeof connector> & RouteComponentProps<{id: string}> & IPageProps> = ({
  route,
  match,
  listFiles,
  brutFile,
  fileVersions,
  users,
  isLoadingFiles,
  briefsPack,
  targetBudget,
  budgets,
  client,
  currentFileBriefs,
  isFileBriefsLoading,
  filePreview,
  connectedUser,
  history,
  getAllFiles: getAllFilesAction,
  getUsers: getUsersAction,
  updateFileStatus: updateFileStatusAction,
  updateListFiles: updateListFilesAction,
  fileDispatchTeam: fileDispatchTeamACtion,
  getAllProjectBriefs: getAllProjectBriefsAction,
  getSingleClient: getSingleClientAction,
  createProjectBrief: createProjectBriefAction,
  fileUpdatePriority: fileUpdatePriorityAction,
  getFilePreview: getFilePreviewAction,
  getFileBriefs: getFileBriefsAction,
  getFileBriefsSuccess: getFileBriefsSuccessAction,
  getOneBudget: getOneBudgetAction,
  getFileRetouchLogs: getFileRetouchLogsAction,
  dowloadFile: dowloadFileAction,
  requestVersionPresignedURL: requestVersionPresignedURLAction,
  addLogFile: addLogFileAction,
}) => {
  const permissions: IPermissionsHandler | null = useContext(PermissionContext);

  useEffect(() => {
    if (match.params.id) {
      if (match.params.id === ':id') history.replace('/budgets');
      getOneBudgetAction(match.params.id);
      getAllFilesAction(match.params.id);
    }
  },        [match, getAllFilesAction, getOneBudgetAction, history]);

  useEffect(() => {
    getUsersAction();
  },        [getUsersAction]);

  useEffect(() => {
    if (targetBudget) getSingleClientAction(targetBudget.client.replace('/clients/', ''));
  },        [getSingleClientAction, targetBudget]);

  const getFileState = (file: IFileStatus) => {
    updateFileStatusAction({...file, relativeBudget: match.params.id});
  };

  const getFileToUpdate = (file: IFileChangeStatus) => file.listIds.length && updateListFilesAction({ ...file, relativeBudget: match.params.id });

  // ********* Editable file
  const getEditedFileStatus = (status: string, file: string) => updateListFilesAction({
    status,
    listIds: [file],
    relativeBudget: match.params.id,
  });
  const getSelectedItems = (team: IFileTeamToSave) => fileDispatchTeamACtion({
    ...team,
    budgetId: match.params.id,
  });

  const getFileNewPrioValue = (val: IUpdateFilePriority) => fileUpdatePriorityAction({ ...val, projectId: match.params.id });

  const getBrief = (brief: IBriefToSave) => createProjectBriefAction(brief);

  const getSelectedFilePreview = (file: IBudgetFiles) => {
    if (targetBudget) {
      getAllProjectBriefsAction(targetBudget.directory);
      getFilePreviewAction({ file: file.name, folder: targetBudget.directory });
      getFileRetouchLogsAction({
        originalFileId: file.id,
        folder: targetBudget.directory,
      });
    }
  };

  const resetFileBriefs = () => getFileBriefsSuccessAction([]);

  const loadClickedFileBriefs = (fileId: string) => {
    if (targetBudget) getAllProjectBriefsAction(targetBudget.directory);
    getFileBriefsAction({
      budgetId: match.params.id,
      fileId,
    });
  };

  const handleSaveNewFileBrief = (text: string, file: string) => {
    createProjectBriefAction({
      additionalInfo: text,
      file: `/files/${ file }`,
    });
  };

  /**
   * Download / upload
   */
  const handleDownloadFile = (terms: IDownloadTerms) => {
    dowloadFileAction(terms);
  };

  const handlePresignedURL = (filename: string, folder: string) => requestVersionPresignedURLAction({
    filename,
    folder,
  });

  const handleAddLogFile = (logFile: ILogFile) => addLogFileAction(logFile);

  return (
    <>
      {
        !!targetBudget && <PageTitle title={`${route.title} ${ targetBudget.name }`} />
      }
      <CustomBreadcrumb route={ route } />
      <Layout className="files-page page-layout has-card-view">
        <Content>
          {
            targetBudget ?
            (
              <>
                <FilesBreadcrumb directory={ targetBudget.directory } />
                <ListFiles
                  budget={targetBudget}
                  budgets={ budgets }
                  client={ client }
                  isLoadingFiles={isLoadingFiles}
                  briefsPack={ briefsPack }
                  listFiles={listFiles || []}
                  brutFile={ brutFile }
                  fileVersions={ fileVersions }
                  users={users}
                  singleFile={null}
                  filePreview={ filePreview }
                  onFileStatusChange={getFileState}
                  onFileToUpdateChange={getFileToUpdate}
                  onEditFileStatusChange={getEditedFileStatus}
                  onSelectedItemsChange={getSelectedItems}
                  connectedUser={ connectedUser }
                  permissions={permissions}
                  fileBriefs={ currentFileBriefs }
                  fileBriefLoader={ isFileBriefsLoading }
                  onFilePrioChange={ getFileNewPrioValue }
                  onSelectFileToOpen={ getSelectedFilePreview }
                  onSelectFileToLoadBriefs={ loadClickedFileBriefs }
                  onSaveFileBriefText={ handleSaveNewFileBrief }
                  onSubmitBrief={ getBrief }
                  isFileBriefsLoading={ isFileBriefsLoading }
                  onResetFileBriefs={ resetFileBriefs }
                  onDownloadFile={ handleDownloadFile }
                  onRequestPresignedURL={ handlePresignedURL }
                  onAddLogFile={ handleAddLogFile }
                />
              </>
              )
              :
            <PageLoader />
          }
        </Content>
      </Layout>
    </>
  );
};

const mapDispatchToProps = {
  getAllFiles,
  getUsers,
  updateFileStatus,
  updateListFiles,
  fileDispatchTeam,
  getAllProjectBriefs,
  getSingleClient,
  createProjectBrief,
  fileUpdatePriority,
  getFilePreview,
  getFileBriefs,
  getOneBudget,
  getFileBriefsSuccess,
  getFileRetouchLogs,
  dowloadFile,
  requestVersionPresignedURL,
  addLogFile,
};

const mapStateToProps = (state: GlobalState) => ({
  budgets: state.project.listProjects,
  client: state.clients.singleClient,
  targetBudget: state.project.singleProject,
  currentFileBriefs: state.project.relativeFileBriefs.plainBriefs,
  isFileBriefsLoading: state.project.relativeFileBriefs.isLoading,
  listFiles: state.file.listFiles,
  users: state.listUsers.users,
  isLoadingFiles: state.file.isLoading,
  filePreview: state.file.filePreview,
  connectedUser: state.connectedUser.userData,
  fileVersions: state.file.fileLogs,
  brutFile: state.file.brutFile,
  briefsPack: state.project.briefs,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const Files = connector(FilesInner);
