import { message } from 'antd';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { getConnectedUserData } from '../connectedUser/actions';

import * as requests from './../../api';
import { createNewUserError, createNewUserSuccess, disableUserError, disableUserSuccess, getListRoleError, getListRoleSuccess, getTispatchTeamError, getTispatchTeamSuccess, getUserError, getUsers as getUsersAction, getUsersError, getUsersSuccess, getUserSuccess, updateUserError, updateUserSuccess } from './actions';
import { CREATE_NEW_USER, GET_DISPATCH_TEAM, GET_ROLES, GET_USER, GET_USERS, ICreateNewUser, IGetDispatchTeamAction, IGetUserAction, IGetUsersAction, IUpdateUserAction, IUserDisableEnableAction, UPDATE_USER, USER_DISABLE_ENABLE } from './types';

/**
 * CREATE USER
 */
function* createUser({ payload: user }: ICreateNewUser) {

  try {
    yield requests.addUser(user, yield select(state => state.auth.token));
    message.success(`L’utilisateur ${user.firstName} ${user.lastName} a été ajouté avec succès`);
    yield put(createNewUserSuccess());
    yield put(getUsersAction());
  } catch (error) {
    yield put(createNewUserError(error));
  }
}

export function* watchCreateUser() {
  yield takeEvery(CREATE_NEW_USER, createUser);
}

/**
 * UPDATE USER
 */
function* updateUser({ payload: user }: IUpdateUserAction) {
  try {
    yield requests.updateUserData(user, yield select(state => state.auth.token));
    yield put(updateUserSuccess());
    if (user.id === user.connectedUser) {
      message.success('Votre profile a été modifié avec succès.');
      yield put(getConnectedUserData());
    } else {
      message.success(`Le profile de ${user.firstName} ${user.lastName} a été modifié avec succès.`);
      yield put(getUsersAction());
    }
  } catch (error) {
    yield put(updateUserError(error));
  }
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}

/**
 * GET USERS
 */
function* getUsers({ payload: togglerFilter }: IGetUsersAction) {
  try {
    const result = yield requests.getUsers(togglerFilter, yield select(state => state.auth.token));
    yield put(getUsersSuccess(result.data));
  } catch (error) {
    yield put(getUsersError(error));
  }
}

export function* watchGetUsers() {
  yield takeEvery(GET_USERS, getUsers);
}

/**
 * GET USER
 */
function* getUser({ payload: id }: IGetUserAction) {
  try {
    const res = yield requests.getUser(id, yield select(state => state.auth.token));
    yield put(getUserSuccess(res.data));
  } catch (error) {
    yield put(getUserError(error));
  }
}

export function* watchGetUser() {
  yield takeLatest(GET_USER, getUser);
}

// Disable user
function* disableUser({ payload: terms }: IUserDisableEnableAction) {
  try {
    yield requests.toggleDisableUser(terms, yield select(state => state.auth.token));
    yield put(disableUserSuccess());
    message.destroy();
    message.success(`Utilisateur ${ terms.nextStatus ? 'activé' : 'désactivé' } avec succes`);
  } catch (error) {
    yield put(disableUserError(error));
  }
}
export function* watchDisableUser() {
  yield takeLatest(USER_DISABLE_ENABLE, disableUser);
}

/**
 * Roles
 */
function* getRoles() {
  try {
    const result = yield requests.getRoleList(yield select(state => state.auth.token));
    yield put(getListRoleSuccess(result.data));
  } catch (error) {
    yield put(getListRoleError(error));
  }
}

export function* watchGetRoles() {
  yield takeEvery(GET_ROLES, getRoles);
}

/**
 * Get Users ==> Dispatch team
 */
function* getDsipatchTeam({ payload: page }: IGetDispatchTeamAction) {
  try {
    const res = yield requests.getDispatchTeam(page, yield select(state => state.auth.token));
    yield put(getTispatchTeamSuccess(res.data));
  } catch (error) {
    yield put(getTispatchTeamError(error));
  }
}

export function* watchGetDispatchTeam() {
  yield takeLatest(GET_DISPATCH_TEAM, getDsipatchTeam);
}