import { Card, Col, List, Row, Tag } from 'antd';
import React, { useEffect, useState } from 'react';

import { renderPaginationItems } from '../../utils/table-pagination-item-render';
import { IDispatchTeamState, IDispatchTeamUser } from '../../utils/types';
import { CustomAvatar } from '../CustomAvatar';
import { UsersCounterFooter } from '../UsersCounterFooter';

import './team.less';

const { Item } = List;

interface ITeamProps {
  blockTitle: string;
  ViewAs: 'cards' | 'list';
  blockSize: number;
  dispatchTeam: IDispatchTeamState;
}

interface ITeamItemContentProps {
  user: IDispatchTeamUser;
}

const TeamItemContent: React.FC<ITeamItemContentProps> = ({ user }) => {

  return (
    <Row type="flex" align="middle" gutter={8}>
      <Col md={3}>
        <CustomAvatar size="large" shape="square" type="user" src={user.path} />
      </Col>

      <Col xl={5}>
        <Row className="username">
          <Col>{user.firstName}</Col>
          <Col>{user.lastName}</Col>
        </Row>
      </Col>

      <Col xl={12}>
        <Row>
          {!!user.companyName ? <Col className="client">{user.companyName}</Col> : null}
          {!!user.name && <Col className="budget">{user.name}</Col>}
        </Row>
      </Col>

      <Col xl={4}>
        <Row type="flex" justify="end">
          <Col>
            <Tag className={`${!!user.name ? 'active' : 'free'}`}>{!!user.name ? 'actif' : 'libre'}</Tag>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const Team: React.FC<ITeamProps> = ({ blockTitle, ViewAs, blockSize, dispatchTeam }) => {

  const [listUsers, setListUsers] = useState<IDispatchTeamUser[]>([]);

  useEffect(() => {
    setListUsers(sortListUsers(dispatchTeam.data.usersActives.concat(dispatchTeam.data.usersLibres)));
  },        [dispatchTeam]);

  const sortListUsers = (users: IDispatchTeamUser[]) => users.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1);

  const isList = ViewAs === 'list';

  const renderItem = (user: IDispatchTeamUser) => {
    return ViewAs === 'cards' ? (
      <Card className="team-item-card small-padding" key={user.id}>
        <TeamItemContent user={user} />
      </Card>
    ) : (
      <Item key={user.id}>
        <Item.Meta description={<TeamItemContent user={user} />} />
      </Item>
    );
  };

  return (
    <div className={`dispatch-team small-non-pages-paginator ${isList ? 'with-padding' : ''}`}>
      <h1 className="orange-title">{blockTitle}</h1>
      <List
        className={`has-card-view ${isList ? 'has-items with-padding split-items' : ''}`}
        loading={dispatchTeam.isLoading}
        pagination={{
          pageSize: blockSize,
          size: 'small',
          hideOnSinglePage: true,
          itemRender: (page, type, original) => renderPaginationItems(page, type, original),
        }}
        dataSource={listUsers}
        renderItem={renderItem}
        footer={<UsersCounterFooter dispatchTeam={dispatchTeam} />}
      />
    </div>
  );
};
