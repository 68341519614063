import { Reducer } from 'redux';

import {
  CREATE_NEW_USER,
  CREATE_NEW_USER_ERROR,
  CREATE_NEW_USER_SUCCESS,
  getUsersActionTypes,
  GET_DISPATCH_TEAM,
  GET_DISPATCH_TEAM_ERROR,
  GET_DISPATCH_TEAM_SUCCESS,
  GET_ROLES,
  GET_ROLES_ERROR,
  GET_ROLES_SUCCESS,
  GET_USER,
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  IUsers,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  USER_DISABLE_ENABLE,
  USER_DISABLE_ENABLE_ERRROR,
  USER_DISABLE_ENABLE_SUCCESS,
} from './types';

const initialState: IUsers = {
  create: {
    isLoading: false,
    isCreated: false,
    errors: null,
  },
  users: [],
  singleUser: {
    user: null,
    isLoading: false,
    error: null,
  },
  roles: {
    listRoles: [],
    isLoading: false,
    error: null,
  },
  error: null,
  loading: false,
  isEnablingComplete: false,
  dispatchTeam: {
    data: {
      usersLibres: [],
      usersActives: [],
      numberActive: 0,
      numberLibre: 0,
      totale: 0,
    },
    isLoading: false,
    errors: null,
  },
};

export const usersListReducer: Reducer<typeof initialState, getUsersActionTypes> = (state = initialState, action) => {
  switch (action.type) {

    case CREATE_NEW_USER:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          isCreated: false,
          errors: null,
        },
      };

    case CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true,
        },
      };

    case CREATE_NEW_USER_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          errors: action.payload,
        },
      };

    case UPDATE_USER:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
        },
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
        },
      };

    case UPDATE_USER_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          errors: action.payload,
        },
      };

    case GET_USERS:
      return { ...state, loading: true };

    case GET_USERS_SUCCESS:
      return { ...state, loading: false, users: action.payload };

    case GET_USERS_ERROR:
      return { ...state, loading: false };

    case GET_USER:
      return {
        ...state,
        singleUser: {
          ...state.singleUser,
          isLoading: true,
          error: null,
        },
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        singleUser: {
          ...state.singleUser,
          isLoading: false,
          user: action.payload,
        },
      };

    case GET_USER_ERROR:
      return {
        ...state,
        singleUser: {
          ...state.singleUser,
          isLoading: false,
          error: action.payload,
        },
      };

    case USER_DISABLE_ENABLE:
      return { ...state, loading: true, isEnablingComplete: false };

    case USER_DISABLE_ENABLE_SUCCESS:
      return { ...state, loading: false, isEnablingComplete: true, error: null };

    case USER_DISABLE_ENABLE_ERRROR:
      return { ...state, loading: false, isEnablingComplete: false, error: action.payload };

    case GET_ROLES:
      return {
        ...state,
        roles: {
          ...state.roles,
          isLoading: true,
        },
      };

    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: {
          ...state.roles,
          isLoading: false,
          listRoles: action.payload,
        },
      };

    case GET_ROLES_ERROR:
      return {
        ...state,
        roles: {
          ...state.roles,
          isLoading: false,
          error: action.payload,
        },
      };

    case GET_DISPATCH_TEAM:
      return {
        ...state,
        dispatchTeam: {
          ...state.dispatchTeam,
          isLoading: true,
          errors: null,
        },
      };

    case GET_DISPATCH_TEAM_SUCCESS:
      return {
        ...state,
        dispatchTeam: {
          ...state.dispatchTeam,
          isLoading: false,
          data: {
            ...state.dispatchTeam.data,
            numberActive: action.payload.numberActive,
            numberLibre: action.payload.numberLibre,
            totale: action.payload.totale,
            usersActives: action.payload.usersActives,
            usersLibres: action.payload.usersLibres,
          },
        },
      };

    case GET_DISPATCH_TEAM_ERROR:
      return {
        ...state,
        dispatchTeam: {
          ...state.dispatchTeam,
          isLoading: false,
          errors: action.payload,
        },
      };

    default:
      return state;
  }
};
