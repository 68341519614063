import { IBudgetFiles, IErrors } from '../../utils/types';

import { ALERT_GET_NOTIFICATIONS, ALERT_GET_TO_BE_SENT_FILES, ALERT_GET_TO_CHECK_FILES, ALERT_REQUEST_SUBSCRIPTION_ERROR, ALERT_RESET_BOTIFICATIONS_COUNTER, ALERT_RESET_CHECK_FILES_COUNTER, ALERT_RESET_SENT_FILES_COUNTER, IAlertGetNotificationsAction, IAlertGetToBeSentFilesAction, IAlertGetToCheckFilesAction, IAlertRequestSubscriptionErrorAction, IAlertResetNotificationsCounterAction, IAlertResetToCheckFilesCounterAction, IAlertResetToSendFilesCounterAction, INotifItem } from './types';

export const alertGetToBeSentFiles = (file: IBudgetFiles): IAlertGetToBeSentFilesAction => ({
  type: ALERT_GET_TO_BE_SENT_FILES,
  payload: file,
});

export const alertGetToCheckFiles = (file: IBudgetFiles): IAlertGetToCheckFilesAction => ({
  type: ALERT_GET_TO_CHECK_FILES,
  payload: file,
});

export const alertGetBudgetNotifications = (notif: INotifItem): IAlertGetNotificationsAction => ({
  type: ALERT_GET_NOTIFICATIONS,
  payload: notif,
});

export const alertResetToSendCounter = (): IAlertResetToSendFilesCounterAction => ({
  type: ALERT_RESET_SENT_FILES_COUNTER,
});

export const alertResetToCheckCounter = (): IAlertResetToCheckFilesCounterAction => ({
  type: ALERT_RESET_CHECK_FILES_COUNTER,
});

export const alertResetNotificationsCounter = (): IAlertResetNotificationsCounterAction => ({
  type: ALERT_RESET_BOTIFICATIONS_COUNTER,
});

export const alertRequestSubscriptionError = (errors: IErrors): IAlertRequestSubscriptionErrorAction => ({
  type: ALERT_REQUEST_SUBSCRIPTION_ERROR,
  payload: errors,
});
